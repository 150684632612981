import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import './components/global';
import Common from './plugins/common';
import moment from './plugins/moment';
import endpoint from './api/endpoint';
import VSAlertConfirm from './plugins/alert-confirm';
import VSNotify from './plugins/snackbar';
import * as Authenticate from './plugins/authenticate';

Vue.config.productionTip = false;
Vue.use(Common);
Vue.use(moment);
Vue.use(endpoint);
Vue.use(VSNotify, { $vuetify: vuetify.framework });
Vue.use(VSAlertConfirm, { $vuetify: vuetify.framework });
Vue.use(Authenticate);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
