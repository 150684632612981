<template>
  <v-popup small v-model="computedIsShow" :loading="isLoading">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on"></slot>
    </template>
    <template v-slot:title>{{ title }}</template>
    <ValidationProvider
      ref="Name"
      rules="required"
      name="Name"
      v-slot="{ errors }"
    >
      <v-label light>
        <b class="primary--text">Name</b>
      </v-label>
      <v-text-field
        dense
        outlined
        :error-messages="errors"
        v-model="name"
      ></v-text-field>
    </ValidationProvider>
    <v-checkbox
      color="primary"
      class="mt-0"
      label="Share this list?"
      v-model="isPublic"
    ></v-checkbox>
    <v-btn
      elevation="0"
      color="secondary1"
      dark
      class="mr-5"
      @click="saveClick"
    >
      <b>SAVE</b>
    </v-btn>
    <v-btn
      elevation="0"
      color="error"
      @click="computedIsShow = !computedIsShow"
    >
      <b>CANCEL</b>
    </v-btn>
  </v-popup>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
    isShow: {
      type: Boolean,
      default: undefined,
    },
    title: String,
    dataItem: {
      type: Object,
      default: () => ({ name: '', isPublic: false }),
    },
  },
  methods: {
    saveClick() {
      this.$refs.Name.validate().then((result) => {
        if (result.valid) {
          this.$emit('onSaveClick', {
            name: this.name.trim(),
            isPublic: this.isPublic,
          });
        }
      });
    },
  },
  computed: {
    computedIsShow: {
      get() {
        return typeof this.isShow == 'undefined' ? this.isActive : this.isShow;
      },
      set(val) {
        this.$emit('update:isShow', val);
        this.isActive = val;
      },
    },
  },
  data() {
    return {
      name: '',
      isPublic: false,
      isActive: false,
    };
  },
  watch: {
    isShow(val) {
      if (!val) {
        this.$refs.Name.reset();
      }
    },
    dataItem() {
      this.name = this.dataItem.name || '';
      this.isPublic = this.dataItem.isPublic || false;
    },
  },
};
</script>

<style></style>
