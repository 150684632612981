<template>
  <thead
    :class="{ 'v-data-table-header': true, 'v-grid-header--group': isHasGroup }"
  >
    <template v-if="isHasGroup">
      <template v-for="(headers, index) in groupHeaders">
        <tr
          :key="'header-' + index"
          :class="
            'v-grid-header__header v-grid-header__header--lv-' + (index + 1)
          "
        >
          <th v-if="showExpand && index === 0" :rowspan="numberGroupLevel"></th>
          <th v-if="showSelect && index === 0" :rowspan="numberGroupLevel">
            <v-simple-checkbox
              @input="$emit('toggle-select-all', $event)"
              :indeterminate="dataProps.someItems"
              :value="dataProps.everyItem"
              :ripple="false"
            ></v-simple-checkbox>
          </th>
          <template v-for="(headerItem, key) in headers">
            <th
              v-if="headerItem.isGroup && headerItem.colspan != 0"
              :key="headerItem.uid"
              :class="{
                'v-grid-header__group': !!headerItem.text,
                'text-center': !!headerItem.text,
              }"
              :colspan="headerItem.colspan"
            >
              <span
                class="align-center"
                v-if="headerItem.text"
                v-html="headerItem.text"
              >
                <!-- {{ headerItem.text }} -->
              </span>
            </th>
            <template
              v-else
              v-for="data in [
                columnHeaderBinding(headerItem, dataProps.options, key),
              ]"
            >
              <th
                v-if="!headerItem.hidden && headerItem.colspan != 0"
                :rowspan="numberGroupLevel"
                :key="data.value"
                v-bind="data.attrs"
                v-bind:style="columnHeaderBindingStyle(headerItem)"
                v-bind:class="data.classes"
                v-on="data.events"
              >
                <p class="ma-0 d-flex align-center justify-space-between">
                  <span>
                    <span @dragleave.stop>{{
                      headerItem.text || headerItem.value
                    }}</span>
                    <v-icon class="v-data-table-header__icon" tag="span">{{
                      icons.mdiArrowUp
                    }}</v-icon>
                    <span
                      :class="reorderClasses"
                      v-show="reorderColumn && reorderConfig.to.index == key"
                    ></span>
                  </span>

                  <menu-filter
                    v-if="isShowFilter && isMenuFilter"
                    :header-item="headerItem"
                    @filter="$emit('filter', $event, headerItem.value)"
                    :value="
                      filterValue.find(
                        (c) => c.propertyName == headerItem.value,
                      ) || {}
                    "
                  ></menu-filter>
                </p>
              </th>
            </template>
          </template>
        </tr>
      </template>
      <!-- <tr class="v-grid-header__header v-grid-header__header--lv-1">
        <th v-if="showSelect" :rowspan="numberGroupLevel">
          <v-simple-checkbox
            @input="$emit('toggle-select-all', $event)"
            :indeterminate="dataProps.someItems"
            :value="dataProps.everyItem"
            :ripple="false"
          ></v-simple-checkbox>
        </th>
        <template v-for="(headerItem, key) in groupHeaders.level1">
          <th
            v-if="headerItem.isGroup"
            :key="headerItem.uid"
            :class="{'v-grid-header__group': !!headerItem.text,'text-center': !!headerItem.text }"
            :colspan="headerItem.colspan"
          >
            <span class="align-center" v-if="headerItem.text">{{headerItem.text}}</span>
            <span v-if="headerItem.text.includes('Offline')" class="font-size-small font-weight-regular d-block">
              (Offline with state = Loggin-In)
            </span>
          </th>
          <th
            v-else
            :rowspan="numberGroupLevel"
            v-for="data in [columnHeaderBinding(headerItem, dataProps.options, key)]"
            :key="data.value"
            v-bind="data.attrs"
            v-bind:style="columnHeaderBindingStyle(headerItem)"
            v-bind:class="data.classes"
            v-on="data.events"
          >
            <p class="ma-0 d-flex align-center justify-space-between">
              <span>
                <span @dragleave.stop>{{headerItem.text || headerItem.value}}</span>
                <v-icon class="v-data-table-header__icon" tag="span">{{icons.mdiArrowUp}}</v-icon>
                <span
                  :class="reorderClasses"
                  v-show="reorderColumn && reorderConfig.to.index == key"
                ></span>
              </span>

              <menu-filter
                v-if="isShowFilter && isMenuFilter"
                :header-item="headerItem"
                @filter="$emit('filter', $event, headerItem.value)"
                :value="(filterValue.find(c => c.propertyName == headerItem.value)||{})"
              ></menu-filter>
            </p>
          </th>
        </template>
      </tr> -->
      <!-- <tr class="v-grid-header__header v-grid-header__header--lv-2">
        <template v-for="(headerItem, key) in groupHeaders.level2">
          <th
            v-for="data in [
              columnHeaderBinding(headerItem, dataProps.options, key),
            ]"
            :key="data.value"
            v-bind="data.attrs"
            v-bind:style="columnHeaderBindingStyle(headerItem)"
            v-bind:class="data.classes"
            v-on="data.events"
          >
            <p class="ma-0 d-flex align-center justify-space-between">
              <span>
                <span @dragleave.stop>{{
                  headerItem.text || headerItem.value
                }}</span>
                <v-icon class="v-data-table-header__icon" tag="span">{{
                  icons.mdiArrowUp
                }}</v-icon>
                <span
                  :class="reorderClasses"
                  v-show="reorderColumn && reorderConfig.to.index == key"
                ></span>
              </span>
              <menu-filter
                v-if="isShowFilter && isMenuFilter && isShowFilterHasGroup"
                :header-item="headerItem"
                @filter="$emit('filter', $event, headerItem.value)"
                :value="
                  filterValue.find((c) => c.propertyName == headerItem.value) ||
                  {}
                "
              ></menu-filter>
            </p>
          </th>
        </template>
      </tr>
      <tr class="v-grid-header__header v-grid-header__header--lv-3">
        <template v-for="(headerItem, key) in groupHeaders.level3">
          <th
            v-for="data in [
              columnHeaderBinding(headerItem, dataProps.options, key),
            ]"
            :key="data.value"
            v-bind="data.attrs"
            v-bind:style="columnHeaderBindingStyle(headerItem)"
            v-bind:class="data.classes"
            v-on="data.events"
          >
            <p class="ma-0 d-flex align-center justify-space-between">
              <span>
                <span @dragleave.stop>{{
                  headerItem.text || headerItem.value
                }}</span>
                <v-icon class="v-data-table-header__icon" tag="span">{{
                  icons.mdiArrowUp
                }}</v-icon>
                <span
                  :class="reorderClasses"
                  v-show="reorderColumn && reorderConfig.to.index == key"
                ></span>
              </span>
              <menu-filter
                v-if="isShowFilter && isMenuFilter && isShowFilterHasGroup"
                :header-item="headerItem"
                @filter="$emit('filter', $event, headerItem.value)"
                :value="
                  filterValue.find((c) => c.propertyName == headerItem.value) ||
                  {}
                "
              ></menu-filter>
            </p>
          </th>
        </template>
      </tr> -->
    </template>
    <template v-else>
      <tr class="v-grid-header__header">
        <template v-for="(headerItem, key) in dataProps.headers">
          <template
            v-if="
              ['data-table-select', 'data-table-expand'].includes(
                headerItem.value,
              )
            "
          >
            <th :key="key" rowspan="2">
              <v-simple-checkbox
                @input="$emit('toggle-select-all', $event)"
                :indeterminate="dataProps.someItems"
                :value="dataProps.everyItem"
                :ripple="false"
                v-if="headerItem.value === 'data-table-expand'"
              ></v-simple-checkbox>
              <v-simple-checkbox
                @input="$emit('toggle-select-all', $event)"
                :indeterminate="dataProps.someItems"
                :value="dataProps.everyItem"
                :ripple="false"
                v-if="headerItem.value === 'data-table-select'"
              ></v-simple-checkbox>
            </th>
          </template>
          <template v-else>
            <th
              v-for="data in [
                columnHeaderBinding(headerItem, dataProps.options, key),
              ]"
              :key="data.value"
              v-bind="data.attrs"
              v-bind:style="columnHeaderBindingStyle(headerItem)"
              v-bind:class="data.classes"
              v-on="data.events"
            >
              <p class="ma-0 d-flex align-center justify-space-between">
                <span>
                  <span @dragleave.stop>{{
                    headerItem.text || headerItem.value
                  }}</span>
                  <v-icon
                    v-if="headerItem.sortable != false"
                    class="v-data-table-header__icon"
                    tag="span"
                    >{{ icons.mdiArrowUp }}</v-icon
                  >
                  <span
                    :class="reorderClasses"
                    v-show="reorderColumn && reorderConfig.to.index == key"
                  ></span>
                </span>
                <menu-filter
                  v-if="isShowFilter && isMenuFilter"
                  :header-item="headerItem"
                  @filter="$emit('filter', $event, headerItem.value)"
                  :value="
                    filterValue.find(
                      (c) => c.propertyName == headerItem.value,
                    ) || {}
                  "
                ></menu-filter>
              </p>
            </th>
          </template>
        </template>
      </tr>
    </template>
  </thead>
</template>

<script>
import { mdiArrowUp } from '@mdi/js';
import { wrapInArray, convertToUnit } from 'vuetify/lib/util/helpers';
import MenuFilter from './_menu-filters';
import groupBy from 'lodash/groupBy';
export default {
  components: {
    'menu-filter': MenuFilter,
  },
  props: {
    isShowFilterHasGroup: Boolean,
    isMenuFilter: Boolean,
    isShowFilter: Boolean,
    reorderColumn: Boolean,
    dataProps: {},
    selectedConfig: {},
    showSelect: Boolean,
    filterValue: {},
    showExpand: Boolean,
  },
  data() {
    return {
      icons: { mdiArrowUp },
      reorderConfig: {
        from: {},
        to: {},
        direction: '',
      },
    };
  },
  computed: {
    isHasGroup() {
      return (this.selectedConfig.headers || []).some((c) => c.isGroup);
    },
    numberGroupLevel() {
      return this.getGroupLevel(this.selectedConfig.headers || [], 1);
    },
    reorderClasses() {
      let classes = ['v-grid-header__reorder'];
      if (this.reorderConfig.direction)
        classes.push('v-grid-header__reorder--' + this.reorderConfig.direction);
      return classes;
    },
    groupHeaders() {
      const colums = getDisplayColumn(this.selectedConfig.headers || []);
      const listHheaders = getHeaders(colums, 1);
      function getDisplayColumn(headers) {
        return (headers || []).filter((c) => !c.hidden);
      }
      function getColspan(headers) {
        let count = 0;
        for (let index = 0; index < headers.length; index++) {
          if (headers[index].isGroup) {
            count += getColspan(headers[index].headers);
          } else if (!headers[index].hidden) {
            count += 1;
          }
        }
        return count;
      }
      function getHeaders(headers, level) {
        const length = headers.length;
        const flatCol = [];
        for (let index = 0; index < length; index++) {
          const col = headers[index];
          if (col.isGroup) {
            flatCol.push({
              isGroup: col.isGroup,
              text: col.text,
              colspan: getColspan(col.headers),
              level: level,
            });
            flatCol.push(...getHeaders(col.headers, level + 1));
          } else {
            flatCol.push({ ...col, level: level });
          }
        }
        return flatCol;
      }
      return Object.values(groupBy(listHheaders, 'level'));
    },
    columnHeaderBindingStyle() {
      return function (header) {
        return {
          width: convertToUnit(header.width),
          minWidth: convertToUnit(header.width),
        };
      };
    },
  },
  methods: {
    columnOnDrop(ev, column, index) {
      ev.stopPropagation();
      if (!this.reorderColumn) ev.preventDefault();
      else if (this.reorderConfig.from.index != index) {
        if (this.isHasGroup) this.$notify.warning('Not implemented');
        else
          this.$emit('reorder-column', Object.assign({}, this.reorderConfig));
        this.$set(this.reorderConfig, 'from', {});
        this.$set(this.reorderConfig, 'to', {});
        this.$set(this.reorderConfig, 'direction', '');
      }
    },
    columnOnDragStart(ev, column, index) {
      ev.stopPropagation();
      if (!this.reorderColumn) ev.preventDefault();
      else {
        this.$set(this.reorderConfig, 'from', { index, column });
      }
    },
    columnOnDragEnter(ev, column, index) {
      if (!this.reorderColumn) ev.preventDefault();
      else if (this.reorderConfig.from.index != index) {
        this.$set(this.reorderConfig, 'to', { index, column });
        this.$set(
          this.reorderConfig,
          'direction',
          index < this.reorderConfig.from['index'] ? 'left' : 'right',
        );
      } else {
        this.$set(this.reorderConfig, 'to', {});
        this.$set(this.reorderConfig, 'direction', '');
      }
    },
    columnOnDragLeave(ev, column, index) {
      ev.stopPropagation();
      if (!this.reorderColumn) ev.preventDefault();
      else if (
        (this.reorderConfig.from.index != index &&
          this.reorderConfig.to.index != index) ||
        !ev.fromElement.closest('.v-grid-header__header')
      ) {
        this.$set(this.reorderConfig, 'to', {});
        this.$set(this.reorderConfig, 'direction', '');
      }
    },
    columnHeaderBinding(header, options, key) {
      let events = {};
      let classes = [
        `text-${header.align || 'start'}`,
        ...wrapInArray(header.class),
        header.divider && 'v-data-table__divider',
      ];
      let attrs = {
        role: 'columnheader',
        scope: 'col',
        'aria-label': header.text || '',
        'aria-sort': 'none',
      };
      if (this.reorderColumn) {
        attrs['draggable'] = 'true';
        events['drop'] = ($event) =>
          this.columnOnDrop($event, header.value, key);
        events['dragstart'] = ($event) =>
          this.columnOnDragStart($event, header.value, key);
        events['dragover'] = (ev) => {
          ev.preventDefault();
        };
        events['dragenter'] = ($event) =>
          this.columnOnDragEnter($event, header.value, key);
        events['dragleave'] = ($event) =>
          this.columnOnDragLeave($event, header.value, key);
      }
      if (
        !Object.prototype.hasOwnProperty.call(header, 'sortable') ||
        header.sortable
      ) {
        events['click'] = () => {
          this.$emit('click:sort', header.value);
        };

        const sortIndex = options.sortBy.findIndex((k) => k === header.value);
        const beingSorted = sortIndex >= 0;
        const isDesc = options.sortDesc[sortIndex];

        classes.push('sortable');

        if (beingSorted) {
          classes.push('active');
          classes.push(isDesc ? 'desc' : 'asc');
          attrs['aria-sort'] = isDesc ? 'descending' : 'ascending';
          attrs['aria-label'] += isDesc
            ? this.$vuetify.lang.t(
                '$vuetify.dataTable.ariaLabel.sortDescending',
              )
            : this.$vuetify.lang.t(
                '$vuetify.dataTable.ariaLabel.sortAscending',
              );
        } else {
          attrs['aria-label'] += this.$vuetify.lang.t(
            '$vuetify.dataTable.ariaLabel.sortNone',
          );
        }
      }

      return { attrs, classes, events };
    },
    getGroupLevel(headers, level) {
      const current = headers.some((c) => c.isGroup) ? level + 1 : level;
      const childCount = [current];
      for (let index = 0; index < headers.length; index++) {
        if (headers[index].isGroup) {
          childCount.push(
            this.getGroupLevel(headers[index].headers, level + 1),
          );
        }
      }
      return Math.max(...childCount);
    },
  },
};
</script>

<style></style>
