import Vue from 'vue';
import Vuex from 'vuex';
import module_enums from './modules/enums';
import module_authenticate from './modules/authenticate';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { enums: module_enums, authenticate: module_authenticate },
});
