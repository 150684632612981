import axios from 'axios';
import { mapUrl, mapQueryParams } from '../';

const getResources = (searchText, pageSize) => {
  let params = mapQueryParams({
    searchText: searchText,
    pageSize: pageSize,
  });
  return axios.get(mapUrl('api/resources/search?' + params));
};

const getResourcesDetail = (id) => {
  return axios.get(mapUrl(`api/resources/detail/${id}`));
};
const moveToTrash = (id) => {
  return axios.put(mapUrl(`api/resources/moveToTrash/${id}`));
};

const deteleResource = (id) => {
  return axios.delete(mapUrl(`api/resources/delete/${id}`));
};

const bulkDeteleResource = (listId) => {
  let data = listId.join(',');
  let params = mapQueryParams({
    ids: data,
  });
  return axios.delete(mapUrl(`api/resources/bulkDelete?` + params));
};

const getListCategory = (type = null) => {
  if (type || type == 0) {
    return axios.get(
      mapUrl(`api/resourceCategory/getListCategoryByType/${type}`),
    );
  } else {
    return axios.get(mapUrl(`api/resourceCategory/getListCategoryByType/null`));
  }
};

const bulkMoveToTrash = (listId) => {
  let params = new FormData();
  listId.forEach((c) => {
    params.append('ids', c);
  });
  return axios.put(mapUrl(`api/resources/bulkMoveToTrash`), params);
};

const bulkEdit = (listId, category, status) => {
  let data = new FormData();
  listId.forEach((c) => {
    data.append('ids', c);
  });
  let params = mapQueryParams({
    resourceCategory: category,
    status: status,
  });
  return axios.put(mapUrl('api/resources/bulkEdit?' + params), data);
};

const restoreResource = (id) => {
  return axios.put(mapUrl(`api/resources/restoreResource/${id}`));
};

const bulkRestore = (listId) => {
  let data = new FormData();
  listId.forEach((c) => {
    data.append('ids', c);
  });
  return axios.put(mapUrl(`api/resources/bulkRestoreResource`), data);
};

const createdResource = (data, id = null) => {
  let params = new FormData();
  params.append('title', data.title);
  params.append('description', data.description || '');
  params.append('tags', data.tags || []);
  params.append('type', data.type);
  (data.featuredImageFile || []).forEach((e) => {
    params.append('featuredImage', e);
  });
  (data.bannerImageFile || []).forEach((e) => {
    params.append('bannerImage', e);
  });
  params.append('status', data.status);
  params.append('resourceCategoryId', data.resourcesCategoryId);
  params.append('link', data.link || '');
  if (data.type == 4) {
    let listAttachment = [...[data.ingredient], ...data.listStep].map((c) => {
      let item = {
        id: c.id || null,
        description: c.description,
        files: c.attachments,
        filePaths: c.imagePreview || '',
      };
      return item;
    });
    // params.append('resourceItems', JSON.stringify(listAttachment));
    listAttachment.forEach((c, index) => {
      params.append(`resourceItems[${index}].description`, c.description);
      params.append(`resourceItems[${index}].id`, c.id || null);
      c.files.forEach((e) => {
        params.append(`resourceItems[${index}].files`, e);
      });
      params.append(
        `resourceItems[${index}].filePaths`,
        c.files.length > 0 ? '' : c.filePaths,
      );
    });
    if (data.attribute) {
      params.append('calories', data.attribute.calories);
      params.append('fat', data.attribute.fat);
      params.append('carb', data.attribute.carb);
      params.append('protein', data.attribute.protein);
    }
    params.append('videoURL', data.videoURL);
    params.append('serve', data.serve);
    params.append('duration', data.duration);
  }
  if (data.type == 1) {
    let listAttachment = data.listChapter.map((c) => {
      let item = {
        id: c.id || null,
        description: c.description,
        files: c.attachments,
        filePaths: c.imagePreview || '',
      };
      return item;
    });
    // params.append('resourceItems', JSON.stringify(listAttachment));
    listAttachment.forEach((c, index) => {
      params.append(`resourceItems[${index}].description`, c.description);
      params.append(`resourceItems[${index}].id`, c.id || null);
      // params.append(`resourceItems[${index}].filePaths`, c.attachment);
      c.files.forEach((e) => {
        params.append(`resourceItems[${index}].files`, e);
      });
      params.append(
        `resourceItems[${index}].filePaths`,
        c.files.length > 0 ? '' : c.filePaths,
      );
    });
    params.append('password', data.password);
  }
  if (!id) return axios.post(mapUrl(`api/resources/create`), params);
  else return axios.put(mapUrl(`api/resources/update/${id}`), params);
};

export default {
  getResources,
  getResourcesDetail,
  moveToTrash,
  deteleResource,
  createdResource,
  bulkMoveToTrash,
  bulkEdit,
  getListCategory,
  restoreResource,
  bulkRestore,
  bulkDeteleResource,
};
