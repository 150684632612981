<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }" tag="div">
    <v-form
      @submit.prevent="handleSubmit(formOnSubmit)"
      @reset.prevent="reset"
      class="mt-10"
    >
      <v-row>
        <div
          class="pa-10px"
          :style="
            [0, 1].includes(breakPointWidth)
              ? 'width: 520px;'
              : breakPointWidth == 2
              ? 'width: calc(50% - 20px)'
              : 'width: calc(100% - 20px)'
          "
        >
          <v-row class="ml-3">
            <v-col cols="6" class="py-0 pl-0 pr-10px">
              <v-label>
                <strong class="primary--text">First Name</strong>
              </v-label>
              <ValidationProvider
                name="First Name"
                rules="required"
                v-slot="{ errors }"
                :customMessages="{ required: 'Enter first name' }"
              >
                <v-text-field
                  :error-messages="errors"
                  v-model="formData.firstName"
                  class="py-0"
                  outlined
                  dense
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-label>
                <strong class="primary--text">Last Name</strong>
              </v-label>
              <ValidationProvider
                name="Last Name"
                rules="required"
                v-slot="{ errors }"
                :customMessages="{ required: 'Enter last name' }"
              >
                <v-text-field
                  type="text"
                  :error-messages="errors"
                  v-model="formData.lastName"
                  class="py-0"
                  outlined
                  dense
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row class="ml-3">
            <v-col cols="12" class="py-0 pl-0 mb-3 pr-0">
              <v-label>
                <strong class="primary--text">User Name</strong>
              </v-label>
              <v-text-field
                v-model="formData.userName"
                readonly
                disabled
                class="py-0 primary--text primary3"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ml-3">
            <v-col cols="12" class="py-0 pl-0 mb-3 pr-0">
              <v-label>
                <strong class="primary--text">Email</strong>
              </v-label>
              <v-text-field
                v-model="formData.email"
                readonly
                disabled
                class="py-0 primary--text primary3"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-1 pt-0 pl-0 ml-5">
              <a
                v-if="!isShowChangePass"
                @click="openChangePass()"
                class="secondary1--text text-decoration"
                ><strong>Change password</strong></a
              >
            </v-col>
          </v-row>
        </div>
        <div
          class="pa-10px"
          :style="
            [0, 1].includes(breakPointWidth)
              ? 'width: 520px;'
              : breakPointWidth == 2
              ? 'width: calc(50% - 10px)'
              : 'width: calc(100% - 30px)'
          "
        >
          <v-row>
            <v-col v-if="isShowChangePass" cols="12" class="py-0 pl-0 ml-5">
              <v-label for="currentPassword" class="primary--text">
                <strong class="primary--text">Current Password</strong>
              </v-label>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="current Password"
              >
                <v-text-field
                  name="currentPassword"
                  type="password"
                  :error-messages="errors"
                  v-model="formData.password"
                  class="py-0"
                  outlined
                  dense
                >
                  <template v-slot:message>
                    <span v-html="errors[0]"></span>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="isShowChangePass" cols="12" class="py-0 pl-0 ml-5">
              <v-label for="password">
                <strong class="primary--text">New Password</strong>
              </v-label>
              <ValidationProvider
                rules="required|passwordRegex"
                vid="password"
                v-slot="{ errors }"
                name="New Password"
              >
                <v-text-field
                  :error-messages="errors"
                  name="password"
                  type="password"
                  v-model="formData.newPassword"
                  class="py-0"
                  outlined
                  dense
                  :class="
                    !!errors[0] &&
                    errors[0].indexOf('minimum of 6 characters') > -1
                      ? 'alert-error-message'
                      : ''
                  "
                >
                  <template v-slot:message>
                    <span v-html="errors[0]"></span>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="isShowChangePass" cols="12" class="py-0 pl-0 ml-5">
              <v-label for="confirmPassword">
                <strong class="primary--text">Confirm New Password</strong>
              </v-label>
              <ValidationProvider
                rules="required|password:@password"
                v-slot="{ errors }"
                name="Confirm Password"
              >
                <v-text-field
                  name="confirmPassword"
                  type="password"
                  :error-messages="errors"
                  v-model="formData.confirmPassword"
                  class="py-0"
                  outlined
                  dense
                >
                  <template v-slot:message>
                    <span v-html="errors[0]"></span>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { extend } from 'vee-validate';
export default {
  props: {
    userProfile: Object,
    isLoading: Boolean,
  },
  data() {
    return {
      formData: {
        id: '',
        profileId: 0,
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        profileImage: '',
        role: '',
      },
      isShowChangePass: false,
      hasChangeForm: false,
      dataCompare: '',
    };
  },
  computed: {
    breakPointWidth() {
      const { width } = this.$vuetify.breakpoint;
      if (width >= 1410) return 0;
      if (width >= 1170) return 1;
      if (width >= 730) return 2;
      else return 3;
    },
  },
  created() {
    extend('password', {
      params: ['target'],
      validate(value, { target }) {
        return value === target;
      },
      message: 'Please make sure your passwords match.',
    });
    extend('passwordRegex', {
      validate: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{6,}/.test(
          value,
        ),
      message:
        '<span>The Password must have a minimum of 6 characters with at least:</span><ul><li>One uppercase letter,</li><li>One lowercase letter,</li><li>One number,</li><li>One special character.</li></ul>',
    });
  },
  methods: {
    openChangePass() {
      this.isShowChangePass = true;
    },
    setFormData() {
      this.$nextTick(() => {
        this.dataCompare = JSON.stringify(this.userProfile);
        this.formData = JSON.parse(JSON.stringify(this.userProfile));
      });
    },
    save() {
      this.$refs.observer.validate().then((valid) => {
        let data = new FormData();
        data.append('id', this.formData.id);
        data.append('FirstName', this.formData.firstName);
        data.append('LastName', this.formData.lastName);
        data.append('PassWord', this.formData.password);
        data.append('NewPassWord', this.formData.newPassword);
        if (!this.isShowChangePass) {
          data.delete('PassWord');
          data.delete('NewPassWord');
        }
        if (valid) {
          this.$emit('update:isLoading', true);
          return this.$http
            .put('/user/profile/' + this.userProfile.profileId, data)
            .then((response) => {
              let self = this;
              if (response.status === 200 && response.data.isSuccess) {
                self.$notify.success(response.data.message);
                this.$emit('hasChangeValue', false);
                this.reset();
                this.$store.dispatch('authenticate/validUser');
                if (self.isShowChangePass) {
                  self.isShowChangePass = false;
                  if (!this.isShowChangePass) {
                    self.formData.password = '';
                    self.formData.newPassword = '';
                    self.formData.confirmPassword = '';
                  }
                }
              } else {
                self.$notify.error(response.data.message);
              }
            })
            .catch((err) => this.$notify.error(err))
            .finally(() => {
              this.$emit('update:isLoading', false);
            });
        }
      });
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.observer.reset();
      this.$emit('onFormReset');
      this.setFormData();
    },
  },
  watch: {
    userProfile: {
      handler() {
        this.setFormData();
      },
      deep: true,
    },
    formData: {
      handler() {
        let check = this.dataCompare === JSON.stringify(this.formData);
        this.$emit('hasChangeValue', !check);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.box >>> .container,
.box > label {
  border: solid thin var(--color-primary2) !important;
}

.box > label {
  padding: 10.5px 21.5px;
  background-color: var(--color-primary3);
  position: absolute;
  top: 14px;
  left: 32px !important;
}
.col-4 .v-input--dense >>> .v-input__slot {
  margin-bottom: 2px !important;
}

.col-4 .v-input--dense >>> .v-text-field__details {
  margin-bottom: 0px !important;
}

.col-4 .v-input--is-disabled.v-input--dense >>> .v-input__slot {
  margin-bottom: 0px !important;
}

.theme--light.v-input--is-disabled >>> input {
  color: var(--color-primary) !important;
}

.alert-error-message {
  position: relative;
}

.alert-error-message >>> .v-messages {
  position: absolute;
  right: -250px;
  max-width: 240px;
  padding: 10px;
  background-color: var(--color-warning1);
  top: 0;
  border-radius: 10px;
}
@media only screen and (max-width: 1410px) {
  .alert-error-message >>> .v-messages {
    position: absolute;
    max-width: 240px;
    padding: 10px;
    left: 0;
    background-color: var(--color-warning1);
    top: 38px;
    right: auto;
    z-index: 99;
    border-radius: 10px;
  }
}
.v-text-field.alert-error-message >>> .v-messages .v-messages__message {
  text-align: left !important;
  line-height: 18px !important;
}
</style>
