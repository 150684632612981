<template>
  <v-list flat style="margin-top: 72px" class="font-weight-bold">
    <v-list-item-group v-model="model" color="indigo">
      <v-divider class="color-secondary"></v-divider>
      <v-list-item to="/" class="pl-0 pr-0" style="height: 80px" active-class>
        <v-list-item-content class="color-secondary text-center">
          <v-icon-svg
            width="26px"
            height="26px"
            :path="icons.home"
          ></v-icon-svg>
          <span class style="font-size: 10px; line-height: 1.3">HOME</span>
        </v-list-item-content>
      </v-list-item>
      <v-divider color="secondary" class="color-secondary"></v-divider>

      <v-list-item
        to="/resources"
        v-auth:roles="'Administrator,Editor,Author,Contributor'"
        class="pl-0 pr-0"
        style="height: 80px"
        active-class
      >
        <v-list-item-content class="color-secondary text-center work-item">
          <v-icon-svg
            width="26px"
            height="26px"
            transform="scale(0.6)"
            :path="icons.resource"
          ></v-icon-svg>
          <span class="pt-1" style="font-size: 10px; line-height: 1.3">
            RESOURCES
          </span>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="color-secondary"></v-divider>
      <!--  -->
      <v-list-item
        to="/moderation"
        class="pl-0 pr-0"
        style="height: 80px"
        active-class
        v-auth:roles="'Administrator,Editor'"
      >
        <v-list-item-content class="color-secondary text-center">
          <v-icon-svg
            width="26px"
            height="26px"
            transform="scale(0.6)"
            :path="icons.moderation"
          ></v-icon-svg>
          <span class style="font-size: 10px; line-height: 1.3">
            MODERATION
          </span>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="color-secondary"></v-divider>
      <v-list-item
        to="/admin"
        class="pl-0 pr-0"
        style="height: 80px"
        active-class
        v-auth:roles="'Administrator,Editor,Author'"
      >
        <v-list-item-content class="color-secondary text-center">
          <v-icon size="26px" color="secondary" v-text="icons.admin"></v-icon>
          <span class style="font-size: 10px; line-height: 1.3">ADMIN</span>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="color-secondary"></v-divider>
    </v-list-item-group>
  </v-list>
</template>

<script>
import {
  customHome,
  customResource,
  customModeration,
  customAdmin,
} from '../plugins/icons';

export default {
  data() {
    return {
      model: 1,
      icons: {
        home: customHome,
        resource: customResource,
        moderation: customModeration,
        admin: customAdmin,
      },
      remiderOverdueNumber: 0,
    };
  },
};
</script>

<style scoped>
.color-secondary {
  border-color: var(--color-secondary) !important;
  color: var(--color-secondary) !important;
}
.v-list >>> .v-list-item--active {
  background-color: var(--color-secondary) !important;
}
.v-list >>> .v-list-item--active .v-list-item__content,
.v-list >>> .v-list-item--active .v-icon {
  color: #ffffff !important;
}
.work-item >>> svg {
  overflow: unset !important;
}
.v-badge >>> .v-badge__badge {
  line-height: 15px;
  height: 15px !important;
  min-width: 15px !important;
  font-size: 10px;
  padding: 0 2px;
  font-weight: 500;
}
.v-list >>> .v-list-item--active--inbox {
  background-color: var(--color-secondary) !important;
}
.v-list >>> .v-list-item--active--inbox .v-list-item__content,
.v-list >>> .v-list-item--active--inbox .v-icon {
  color: #ffffff !important;
}
</style>
