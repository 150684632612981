import axios from 'axios';
import { mapUrl } from '../';

const getCategoryDetail = (id) => {
  return axios.get(mapUrl(`api/resourceCategory/detail/${id}`));
};

const createCategory = (data, id = null, filePath = '') => {
  let fd = new FormData();
  fd.append('name', data.name);
  fd.append('description', data.description || '');
  fd.append('type', data.type);
  (data.featuredImage || []).forEach((e) => {
    fd.append('featuredImage', e);
  });
  fd.append('filePath', filePath || '');
  if (!id) return axios.post(mapUrl(`api/resourceCategory/create/`), fd);
  else return axios.put(mapUrl(`api/resourceCategory/update/${id}`), fd);
};
const deleteCategory = (id) => {
  return axios.delete(mapUrl(`api/resourceCategory/delete/${id}`));
};
const isExistCategoryName = (id, name, type) => {
  return axios.put(
    mapUrl(`api/resourceCategory/isExist?id=${id}&name=${name}&type=${type}`),
  );
};

export default {
  getCategoryDetail,
  createCategory,
  deleteCategory,
  isExistCategoryName,
};
