<template>
  <v-container class="mx-0 px-0 pt-10px" style="height: 100%; max-width: 100%">
    <v-row style="height: 100%" class="px-10px">
      <v-col class="white pa-20px" cols="3">
        <div>
          <v-panel title="PUBLISH INFO" hideExpand color="primary3">
            <v-card elevation="0" class="pa-5">
              <div class="mt-0 mb-3">
                <p class="mb-0">
                  <b>Status</b>
                </p>
                <v-dropdownlist
                  :data-items="listStatus"
                  v-model="inforData.statusValue"
                  :itemText="'text'"
                  :itemValue="'value'"
                  class="mr-2"
                  hide-details
                  width="100%"
                >
                </v-dropdownlist>
              </div>
              <v-row class="mt-0 mb-3">
                <v-col cols="12" class="py-0">
                  <v-label>
                    <strong>Category</strong>
                  </v-label>
                  <ValidationProvider
                    rules="required"
                    name="Category"
                    class="flex-grow-1 primary--text"
                  >
                    <v-dropdownlist
                      :data-items="listCategory"
                      v-model="inforData.category"
                      :itemText="'text'"
                      :itemValue="'value'"
                      placeholder="Select Category"
                      class="mr-2"
                      hide-details
                      width="100%"
                    >
                    </v-dropdownlist>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <ValidationProvider
                v-if="inforData.typeName === 'Audio Book'"
                rules="max:20"
                name="Password"
                v-slot="{ errors }"
                :customMessages="{
                  max: 'The Password field cannot be greater than 20 characters',
                }"
                class="flex-grow-1 primary--text"
              >
                <v-row
                  v-if="inforData.typeName === 'Audio Book'"
                  class="mt-0 mb-3"
                >
                  <v-col class="py-0">
                    <v-label>
                      <strong>Password</strong>
                    </v-label>
                    <v-text-field
                      class="py-0"
                      outlined
                      dense
                      :error-messages="errors"
                      v-model="inforData.passWord"
                      :placeholder="'Enter Password'"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <v-row class="my-0">
                <v-col cols="12" class="py-0">
                  <v-label>
                    <strong>Type</strong>
                  </v-label>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="Type"
                    class="flex-grow-1 primary--text"
                  >
                    <v-text-field
                      disabled
                      class="py-0 font-weight-bold primary--text"
                      outlined
                      dense
                      hide-details
                      v-model="inforData.typeName"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card>
          </v-panel>
          <div class="mt-10px">
            <v-row class="mt-0 mb-3">
              <v-col cols="12" class="py-0">
                <v-label>
                  <v-icon-svg
                    :path="icons.customAttachment"
                    width="13px"
                    height="13px"
                    class="mr-1"
                    transform="translate(-655.654 -288) scale(1.2)"
                  ></v-icon-svg>
                  <strong>Featured Image</strong>
                </v-label>
                <div
                  v-if="!imagePreview"
                  class="d-flex align-baseline primary2 py-10px px-3"
                >
                  <input
                    v-show="false"
                    ref="inputFiles"
                    type="file"
                    accept="image/*"
                    @change="onChangeImage"
                  />
                  <v-btn
                    height="30"
                    class="font-weight-bold"
                    color="primary"
                    elevation="0"
                    @click="$refs.inputFiles.click()"
                  >
                    SELECT FILE
                  </v-btn>
                  <!-- <v-btn
                    width="80"
                    height="80"
                    color="white"
                    elevation="0"
                    @click="$refs.inputFiles.click()"
                    style="border: 1px dashed var(--color-primary2) !important"
                  >
                    <v-icon
                      size="24"
                      color="secondary"
                      v-text="icons.mdiPlusBox"
                    ></v-icon>
                  </v-btn> -->
                </div>
                <div v-if="imagePreview" class="d-flex align-end">
                  <v-img
                    max-height="80"
                    min-height="80"
                    min-width="80"
                    max-width="80"
                    :src="imagePreview"
                  ></v-img>
                  <v-btn icon small class="ml-1" @click="imagePreview = ''">
                    <v-icon color="error">{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="9" class="pa-0" style="max-height: 100%">
        <div class="text-uppercase white py-3" style="font-size: 26px">
          <strong>
            {{ initData.id ? 'Edit ' : 'Add New ' }}{{ inforData.typeName }}
          </strong>
        </div>
        <v-row
          class="white mx-20px mt-20px"
          style="height: calc(100% - 62px); max-heigth: calc(100% - 62px)"
        >
          <v-col cols="12" class="py-5 pl-5" style="height: 100%">
            <coupon-form
              ref="createEditForm"
              v-if="inforData.typeName === 'Coupon'"
              :inforData="inforData"
              :initData="initData"
            />
            <recipe-from
              ref="createEditForm"
              v-if="inforData.typeName === 'Recipe'"
              :inforData="inforData"
              :initData="initData"
            />
            <document-form
              ref="createEditForm"
              v-if="['Document', 'Audio'].includes(inforData.typeName)"
              :inforData="inforData"
              :initData="initData"
            />
            <audio-book-form
              ref="createEditForm"
              v-if="inforData.typeName === 'Audio Book'"
              :inforData="inforData"
              :initData="initData"
            />
            <div class="mt-10px">
              <v-btn
                type="submit"
                elevation="0"
                color="secondary1 white--text"
                class="mr-5"
                width="100px"
                @click="handleSaveForm"
              >
                <strong>SAVE</strong>
              </v-btn>
              <v-btn
                color="error"
                elevation="0"
                width="100px"
                @click="backPrevPage"
              >
                <strong>CANCEL</strong>
              </v-btn>
              <v-btn
                v-if="initData.id"
                class="ml-5"
                elevation="0"
                outlined
                color="error"
                @click="moveToTrash"
              >
                <strong>DELETE</strong>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CouponForm from './_coupon-form.vue';
import RecipeFrom from './_recipe-form.vue';
import DocumentForm from './_document-form.vue';
import AudioBookForm from './_audio-book-form.vue';
import { mdiPlusBox, mdiClose } from '@mdi/js';
import { customAttachment } from '../../../plugins/icons';
import api from '../../../api/resources';

export default {
  components: {
    CouponForm,
    RecipeFrom,
    DocumentForm,
    AudioBookForm,
  },
  data() {
    return {
      icons: { mdiPlusBox, mdiClose, customAttachment },
      inforData: {
        typeName: 'Video',
        type: 0,
        statusValue: 3,
        category: 0,
        passWord: '',
        featuredImageFile: [],
      },
      initData: {
        id: null,
        status: 2,
        title: 'Mot title gi do',
        category: 1,
        type: 'Audio',
        featuredImageFile: [],
        description: 'mot description',
        couponLink: 'abc.com',
        note: 'mot note gi do',
        passWord: 'abc@password',
      },
      imagePreview: '',
      listStatus: [
        { text: 'Draft', value: 3 },
        { text: 'Pending Review', value: 2 },
        { text: 'Private', value: 1 },
        { text: 'Publish ', value: 0 },
      ],
      listScheduled: [
        { text: 'Immediately', value: 0 },
        { text: 'Schedule', value: 1 },
      ],
      scheduledValue: 0,
      publishTime: this.$moment().format('YYYY-MM-DD'),
      listType: [
        { name: 'Video', router: 'resources.addNewVideo', value: 5 },
        { name: 'Document', router: 'resources.addNewDocument', value: 3 },
        { name: 'Audio', router: 'resources.addNewAudio', value: 0 },
        { name: 'Recipe', router: 'resources.addNewRecipe', value: 4 },
        { name: 'Coupon', router: 'resources.addNewCoupon', value: 2 },
        { name: 'Audio Book', router: 'resources.addNewAudioBook', value: 1 },
      ],
      listCategory: [
        { text: 'Article', value: 0 },
        { text: 'Event', value: 1 },
      ],
      fileUrl: 'https://drbergappdev.blob.core.windows.net/resource-',
      prevView: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevView = from;
    });
  },
  mounted() {
    this.getTypeName();
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.getTypeName();
        });
      },
    },
  },
  methods: {
    backPrevPage() {
      if (this.prevView) this.$router.push({ path: this.prevView.path });
      else this.$router.push({ path: '/resources' });
    },
    getTypeName() {
      if (this.listType.find((c) => c.router === this.$route.name)) {
        if (this.$store.state.authenticate.user.role === 'Contributor') {
          this.inforData.statusValue = 1;
          this.listStatus = [
            { text: 'Draft', value: 3 },
            { text: 'Pending review', value: 2 },
            { text: 'Private', value: 1 },
          ];
        }
        this.inforData.typeName = this.listType.find(
          (c) => c.router === this.$route.name,
        ).name;
        this.inforData.type = this.listType.find(
          (c) => c.router === this.$route.name,
        ).value;
        const id = +this.$route.params.id || null;
        if (id) {
          this.getDetail(id);
        }
        this.getListCategory();
      } else {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    firstUpperCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getListCategory() {
      const typeVal = this.listType.find(
        (c) => c.router === this.$route.name,
      ).value;
      api.getListCategory(typeVal).then((res) => {
        if (res.status === 200) {
          const listItem = [...res.data];
          this.listCategory = listItem.map((c) => {
            let item = { text: c.title, value: c.id };
            return item;
          });
          // this.inforData.category = this.listCategory[0]?.value;
        }
      });
    },
    getDetail(id) {
      api.getResourcesDetail(id).then((res) => {
        if (res.status === 200) {
          this.initData = { ...res.data };
          this.inforData.category = this.initData.resourceCategoryId;
          if (
            this.$store.state.authenticate.user.role === 'Contributor' &&
            res.data.status === 0
          ) {
            this.inforData.statusValue = this.initData.status = 2;
          } else this.inforData.statusValue = this.initData.status;
          this.inforData.passWord = this.initData.password;
          this.setPreviewImage();
          if (
            res.data.createdById !==
              this.$store.state.authenticate.user.profileId &&
            this.$store.state.authenticate.user.role === 'Contributor'
          ) {
            this.listStatus = [{ text: 'Pending review', value: 2 }];
          }
          if (
            res.data.createdById !==
              this.$store.state.authenticate.user.profileId &&
            this.$store.state.authenticate.user.role !== 'Contributor'
          ) {
            this.listStatus = [
              { text: 'Draft', value: 3 },
              { text: 'Pending review', value: 2 },
              { text: 'Publish', value: 0 },
            ];
          }
        }
      });
    },
    setPreviewImage() {
      if (this.initData && this.initData.featuredImage) {
        this.imagePreview = this.initData.featuredImage;
      }
    },
    moveToTrash() {
      this.$confirm(
        `Are you sure you want to move this ${this.initData.typeName} to <span class='error--text'>TRASH</span> now?<br/>
        <span class="secondary1--text font-weight-regular">${this.initData.title}</span>`,
        {
          width: 450,
        },
      ).then((isYes) => {
        if (isYes) {
          api
            .moveToTrash(this.initData.id)
            .then((res) => {
              if (res.status === 200) {
                this.$notify.success(res.data.message);
                this.$router.push({ path: '/resources' });
              }
            })
            .catch((err) => this.$notify.error(err));
        }
      });
      // api.moveToTrash(this.initData.id).then((res) => {
      //   if (res.status === 200) {
      //     this.$notify.success(res.data.message);
      //     this.$router.push({ path: '/resources' });
      //   }
      // });
    },
    handleSaveForm() {
      if ([-1, 0].includes(this.inforData.category)) {
        this.$notify.error('Category not found.');
      } else this.$refs.createEditForm.save();
    },
    onChangeImage(event) {
      if (event.target.files && event.target.files[0]) {
        this.inforData.featuredImageFile = event.target.files;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>

<style lang="scss">
.scheduled-publish {
  &__radio {
    .v-input {
      &__control {
        .v-input__slot {
          .v-input--radio-group {
            &__input {
              .v-radio {
                .v-input--selection-controls__input {
                  height: 18px;
                  width: 18px;
                  .v-icon {
                    height: 16px;
                    // padding-bottom: 1px;
                    &__svg {
                      height: 15px !important;
                      width: 15px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
