<template>
  <v-menu
    v-if="isShowFilter"
    offset-y
    :close-on-content-click="false"
    max-width="210px"
    v-model="isMenuShow"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :class="!isValueNull ? 'primary' : ''"
        x-small
        v-on="on"
        icon
        tile
        ref="menufilter"
      >
        <v-icon small v-if="isValueNull">{{ icons.mdiFilter }}</v-icon>
        <v-icon-svg small v-else :path="icons.customClearFilter"></v-icon-svg>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="py-10px px-10px">
        <span class="font-size-medium font-weight-bold"
          >Show item with value that:</span
        >
      </v-card-text>
      <v-card-text class="py-0 px-10px menu-row-filter">
        <row-filter
          :config="headerItem"
          :is-menu="true"
          class="mb-10px"
          @filter="onDefaultFilter"
          :value="computedDefaultValue.value"
          :default-comparison="computedDefaultValue.comparison"
        ></row-filter>
        <v-dropdownlist
          v-model="filterLogicValue"
          :data-items="operatorLogic"
          itemText="text"
          itemValue="value"
          class="pb-10px"
          hide-details
          height="36"
          style="width: 100px"
        ></v-dropdownlist>
        <row-filter
          :config="headerItem"
          :is-menu="true"
          @filter="onWithFilter"
          :value="computedWithValue.value"
          :default-comparison="computedWithValue.comparison"
        ></row-filter>
      </v-card-text>
      <v-card-actions class="pa-10px">
        <v-btn
          dark
          color="secondary1"
          depressed
          class="text-uppercase font-weight-bold flex-grow-1 font-size-small"
          height="30px"
          @click="filterClick"
          >Filter</v-btn
        >
        <v-btn
          dark
          color="error1"
          depressed
          class="text-uppercase font-weight-bold flex-grow-1 font-size-small"
          height="30px"
          @click="clearFilterClick"
          >Clear</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import RowFilter from './_row-filters';
import { mdiFilter } from '@mdi/js';
import { customClearFilter } from '../../plugins/icons';

export default {
  components: {
    'row-filter': RowFilter,
  },
  props: {
    headerItem: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isMenuShow: false,
      icons: {
        mdiFilter,
        customClearFilter,
      },
      operatorLogic: [
        { text: 'And', value: 0 },
        { text: 'Or', value: 1 },
      ],
      filterLogicValue: 0,
      defaultFilterData: {},
      withFilterData: null,
    };
  },
  methods: {
    clearFilterClick() {
      this.$emit('filter', { value: null });
      //set default Thinh
      this.defaultFilterData = {};
      this.withFilterData = null;
      this.isMenuShow = false;
    },
    filterClick() {
      this.isMenuShow = false;
      let dataFilter = { ...this.defaultFilterData };
      if (this.withFilterData) {
        dataFilter['with'] = {
          ...this.withFilterData,
          isAnd: this.filterLogicValue === 0,
        };
      }
      this.$emit('filter', dataFilter);
    },
    onDefaultFilter(event) {
      this.defaultFilterData = event;
    },
    onWithFilter(event) {
      this.withFilterData = event;
    },
  },
  created() {
    this.filterLogicValue =
      this.value['and'] || this.value['or'] ? (this.value['and'] ? 0 : 1) : 0;
  },
  computed: {
    isShowFilter() {
      return !this.headerItem.hideFilter;
    },
    computedDefaultValue() {
      return {
        value: this.value.value,
        comparison: this.value.comparison,
      };
    },
    computedWithValue() {
      return {
        value: (this.value['and'] || this.value['or'] || {}).value,
        comparison: (this.value['and'] || this.value['or'] || {}).comparison,
      };
    },
    isValueNull() {
      return (
        Object.keys(this.value).length === 0 &&
        this.value.constructor === Object
      );
    },
  },
  watch: {
    value(val) {
      this.filterLogicValue =
        val['and'] || val['or'] ? (val['and'] ? 0 : 1) : 0;
    },
  },
};
</script>
<style scoped>
.menu-row-filter >>> .v-input__slot {
  height: 30px !important;
  min-height: 30px !important;
}
.menu-row-filter >>> .inside-button {
  height: 30px !important;
}
.menu-row-filter >>> .v-datepicker__input__icon {
  height: 30px !important;
}
</style>
