<template>
  <v-app-bar
    absolute
    color="white"
    app
    height="80px"
    elevation="1"
    :extension-height="responsiveHeaderNav == 'sm' && isShowSearch ? 80 : 0"
    style="z-index: 8"
    :class="
      responsiveHeaderNav == 'sm' && isShowSearch ? 'v-app-bar--callslat' : ''
    "
  >
    <router-link class="d-flex justify-center align-center" to="/">
      <v-img :src="logoUrl"></v-img>
    </router-link>
    <v-spacer></v-spacer>
    <v-toolbar-items
      v-if="!isErrorPage && !isSubscriber"
      class="justify-center align-center mr-10px"
    >
      <search-bar
        v-if="responsiveHeaderNav != 'sm'"
        style="width: 650px"
        :hasEnterRedirect="true"
        @onShowPopup="onShowPopup"
      ></search-bar>
      <div v-if="responsiveHeaderNav == 'sm'">
        <v-btn
          icon
          small
          @click="isShowSearch = !isShowSearch"
          width="24px"
          height="24px"
        >
          <v-icon-svg
            small
            class="primary--text"
            :path="icons.search"
          ></v-icon-svg>
        </v-btn>
      </div>
    </v-toolbar-items>
    <v-menu
      v-if="!isErrorPage"
      bottom
      left
      offset-y
      color="primary"
      content-class="v-dropdownlist__menu__content"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="pl-10px"
          text
          color="primary"
          height="45px"
          :min-width="responsiveHeaderNav == 'lg' ? '71px' : 'unset'"
          :class="responsiveHeaderNav !== 'lg' ? 'pr-0' : ''"
        >
          <v-img
            class="image-border__radius"
            :class="responsiveHeaderNav == 'lg' ? 'mr-2' : ''"
            v-if="avatarUrl != ''"
            height="44"
            width="44"
            :src="avatarUrl"
          ></v-img>
          <span
            v-else
            class="
              font-size-large
              avatar-text
              primary--text
              font-weight-bold
              image-border__radius
              avatar-text__size avatar-text__background
            "
            :class="responsiveHeaderNav == 'lg' ? 'mr-2' : ''"
            >{{ sortName }}</span
          >
          <b v-if="responsiveHeaderNav == 'lg'">{{ fullname }}</b>
          <v-icon
            v-if="responsiveHeaderNav == 'lg'"
            v-text="icons.chevronDown"
          ></v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item to="/userprofile" color="primary">
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>
        <v-list-item color="primary" @click="logoutClick">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="primary"
    >
    </v-progress-linear>
    <template v-slot:extension>
      <search-bar
        class="px-20px"
        v-if="responsiveHeaderNav == 'sm' && isShowSearch"
        style="width: 100%"
        :hasEnterRedirect="true"
        @onShowPopup="onShowPopup"
      ></search-bar>
    </template>
    <template v-if="dataItem.typeName === 'Coupon'">
      <coupon-detail v-model="isShowPopup" :id="dataItem.id" />
    </template>
    <template v-if="['Audio', 'Document'].includes(dataItem.typeName)">
      <document-audio-detail
        v-model="isShowPopup"
        :id="dataItem.id"
        :type="dataItem.typeName"
      />
    </template>
    <template v-if="dataItem.typeName === 'Recipe'">
      <recipe-detail v-model="isShowPopup" :id="dataItem.id" />
    </template>
    <template v-if="dataItem.typeName === 'Audio Book'">
      <audio-book-detail
        v-if="isShowPopup"
        v-model="isShowPopup"
        :id="dataItem.id"
      />
    </template>
    <template v-if="dataItem.typeName === 'Video'">
      <video-detail
        v-if="isShowPopup"
        v-model="isShowPopup"
        :id="dataItem.id"
      />
    </template>
  </v-app-bar>
</template>

<script>
import logoImage from '../assets/logo.png';
import { mdiMagnify, mdiAccount, mdiChevronDown } from '@mdi/js';
import SearchBar from './_search';
import { customsearch } from '../plugins/icons';
import CouponDetail from '../views/resources/all-resources/_coupon-detail-popup.vue';
import DocumentAudioDetail from '../views/resources/all-resources/_document-audio-detail-popup.vue';
import RecipeDetail from '../views/resources/all-resources/_recipe-detail-popup.vue';
import AudioBookDetail from '../views/resources/all-resources/_audio-book-detail-popup.vue';
import VideoDetail from '../views/resources/all-resources/_video-detail-popup.vue';
export default {
  components: {
    SearchBar,
    CouponDetail,
    DocumentAudioDetail,
    RecipeDetail,
    AudioBookDetail,
    VideoDetail,
  },
  data() {
    return {
      isShowSearch: false,
      logoUrl: logoImage,
      icons: {
        magnify: mdiMagnify,
        account: mdiAccount,
        chevronDown: mdiChevronDown,
        search: customsearch,
      },
      loading: false,
      isCallSlatShow: false,
      dataItem: {
        id: 0,
        typeName: 'Audio',
      },
      isShowPopup: false,
    };
  },
  computed: {
    isErrorPage() {
      return (
        this.$route.name === '404' ||
        this.$route.name === '403' ||
        this.$route.name === '500' ||
        this.$route.name === '*'
      );
    },
    responsiveHeaderNav() {
      if (this.$vuetify.breakpoint.width >= 1280) return 'lg';
      else if (this.$vuetify.breakpoint.width >= 1100) return 'md';
      else return 'sm';
    },
    isSubscriber() {
      return this.$store.state.authenticate.user.role === 'Subscriber';
    },
    fullname() {
      let user = this.$store.state.authenticate.user;
      return user.profileName || user.userName;
    },
    avatarUrl() {
      return this.$store.state.authenticate.user.profileImage;
    },
    sortName() {
      let userName = this.$store.state.authenticate.user.profileName;
      let sortName =
        userName.split(' ')[0].substring(0, 1) +
        userName.split(' ')[1].substring(0, 1);
      return sortName;
    },
  },
  methods: {
    logoutClick() {
      this.$store.dispatch('authenticate/logout');
    },
    onShowPopup($event) {
      this.dataItem = { ...$event };
      this.isShowPopup = true;
    },
  },
};
</script>
<style lang="scss">
.v-app-bar {
  .image-border__radius {
    border-radius: 50% !important;
    border: solid 3px var(--color-primary3) !important;
  }
  .avatar-text {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    &__size {
      height: 44px !important;
      width: 44px !important;
    }
    &__background {
      background-color: get-color(secondary2);
    }
  }
  &.v-toolbar--extended.v-app-bar--callslat {
    .v-toolbar__extension {
      padding: 0 !important;
      flex-direction: column;
    }
    .v-toolbar__content {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
      z-index: 1 !important;
    }
  }
  .v-toolbar__content {
    z-index: 1 !important;
  }
}
</style>
