<template>
  <v-popup
    v-model="isShow"
    :loading="isLoading"
    contentClass="v-category-popup pb-3"
  >
    <template v-slot:title>CATEGORY DETAIL</template>
    <div>
      <p class="mb-0" style="font-size: 26px">
        <strong>{{ categoryItem.name }}</strong>
      </p>
      <v-row class="my-0">
        <v-col cols="6" class="py-0">
          <p class="mb-0">
            <strong>Resource Type:</strong>
            {{ categoryItem.typeName }}
          </p>
        </v-col>
        <v-col cols="6" class="py-0">
          <p class="mb-0">
            <strong>#Post: </strong>
            <span
              class="
                secondary1--text
                text-decoration-underline
                font-weight-medium
                cursor-pointer
              "
              @click="redirectResource(categoryItem)"
            >
              {{ categoryItem.countPost }}
            </span>
          </p>
        </v-col>
      </v-row>
      <div>
        {{ categoryItem.description }}
      </div>
      <v-row v-if="![5, 6].includes(initData.type)">
        <v-col v-if="categoryItem.id != -1">
          <v-label>
            <strong>Featured Image: </strong>
          </v-label>
          <v-img
            v-if="categoryItem.featuredImage"
            max-height="100"
            min-height="100"
            min-width="100"
            max-width="100"
            :src="categoryItem.featuredImage"
          ></v-img>
          <v-no-image v-else />
        </v-col>
      </v-row>
    </div>
    <template v-slot:footer>
      <v-btn
        type="submit"
        v-if="![5, 6].includes(initData.type)"
        depressed
        class="mr-5 font-weight-bold"
        color="info"
        width="100"
        @click="onEditDetail"
      >
        EDIT
      </v-btn>
      <v-btn
        type="reset"
        depressed
        class="font-weight-bold mr-5"
        color="error"
        width="100"
        @click="isShow = false"
      >
        CANCEL
      </v-btn>
    </template>
  </v-popup>
</template>

<script>
import api from '../../../api/category';

export default {
  props: {
    value: {
      type: Boolean,
    },
    initData: {
      type: Object,
    },
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    isShow() {
      if (this.isShow) {
        this.getCategoryDetail();
      }
    },
  },
  data() {
    return {
      isLoading: false,
      categoryItem: {},
      fileUrl: 'https://drbergappdev.blob.core.windows.net/category-',
    };
  },
  methods: {
    redirectResource(item) {
      this.isShow = false;
      this.$emit('filterCategory', item);
    },
    onEditDetail() {
      this.$emit('onEditDetail', this.initData.id);
      this.isShow = false;
    },
    getCategoryDetail() {
      this.isLoading = true;
      api.getCategoryDetail(this.initData.id).then((res) => {
        if (res.status === 200) {
          this.categoryItem = { ...res.data };
          this.isLoading = false;
        }
      });
    },
  },
};
</script>

<style></style>
