<template>
  <v-menu
    offset-y
    v-model="dropdownOpen"
    max-width="290"
    :close-on-content-click="false"
    v-on:click.prevent
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        autocomplete="off"
        complete
        v-model="displayTime"
        outlined
        dense
        v-on="on"
        :readonly="readonly"
        v-on:click.stop
        :disabled="disabled"
        :hide-details="hidedetails"
        :height="height"
        v-bind:style="{ width: widthText, 'min-width': minWidthText }"
        v-bind:class="inputClass"
        @blur="displayDateTimeOnBlur"
        :error-messages="errorMessages"
      >
        <template v-slot:append>
          <v-btn
            class="inside-button border-l"
            icon
            tile
            :height="height"
            width="36"
            v-on="on"
          >
            <v-icon
              class="primary--text"
              v-text="icons.calendar"
              medium
            ></v-icon>
          </v-btn>
          <template v-if="hasSlot('append')">
            <slot name="append"></slot>
          </template>
        </template>
        <template v-slot:append-outer v-if="hasSlot('append-outer')">
          <slot name="append-outer"></slot>
        </template>
      </v-text-field>
    </template>
    <form>
      <v-card :disabled="disabled">
        <v-date-picker
          :next-icon="icons.mdiMenuRight"
          :prev-icon="icons.mdiMenuLeft"
          class="elevation-0 v-datepicker"
          v-model="dateModel"
          width="100%"
          color="primary"
          no-title
          data-vv-name="Date"
          mode="dateTime"
          :min="min"
        >
        </v-date-picker>
        <v-divider class="mx-4 mt-2"></v-divider>
        <v-list>
          <v-list-item>
            <v-flex pr-2>
              <v-label><strong>Hr</strong></v-label>
              <v-select
                ref="hr"
                :items="
                  timeModelHrList.split(',').map((c) => {
                    return {
                      value: parseInt(c),
                      text: parseInt(c) < 10 ? `0${parseInt(c)}` : parseInt(c),
                    };
                  })
                "
                v-model="timeModel.hr"
                @blur="blur('hr')"
                data-vv-name="Hour"
                item-text="text"
                item-value="value"
                hide-details
                color="primary"
                @input="checkValidate"
                :menu-props="{
                  dense: true,
                  contentClass: 'v-dropdownlist__menu__content',
                }"
                outlined
                dense
                solo
                flat
              >
                <template v-slot:append>
                  <v-icon-svg
                    class="mr-1"
                    small
                    :path="icons.customDown"
                  ></v-icon-svg>
                </template>
              </v-select>
            </v-flex>
            <v-flex pr-2>
              <v-label><strong>Min</strong></v-label>
              <v-select
                ref="min"
                :items="
                  timeModelMinList.split(',').map((c) => {
                    return {
                      value: parseInt(c),
                      text: parseInt(c) < 10 ? `0${parseInt(c)}` : parseInt(c),
                    };
                  })
                "
                v-model="timeModel.min"
                @blur="blur('min')"
                data-vv-name="Minute"
                item-text="text"
                item-value="value"
                hide-details
                color="primary"
                @input="checkValidate"
                :menu-props="{
                  dense: true,
                  contentClass: 'v-dropdownlist__menu__content',
                }"
                outlined
                dense
                solo
                flat
              >
                <template v-slot:append>
                  <v-icon-svg
                    small
                    class="mr-1"
                    :path="icons.customDown"
                  ></v-icon-svg>
                </template>
              </v-select>
            </v-flex>
            <v-flex pr-2>
              <v-label><strong>Mer</strong></v-label>
              <v-select
                ref="mer"
                color="primary"
                v-model="meridiam"
                :items="meridiamItems"
                @blur="blur('mer')"
                data-vv-name="Meridiam"
                hide-details
                @input="checkValidate"
                outlined
                :menu-props="{
                  dense: true,
                  contentClass: 'v-dropdownlist__menu__content',
                }"
                dense
                solo
                flat
              >
                <template v-slot:append>
                  <v-icon-svg
                    small
                    class="mr-1"
                    :path="icons.customDown"
                  ></v-icon-svg>
                </template>
              </v-select>
            </v-flex>
          </v-list-item>
        </v-list>
        <p class="pr-2 pl-2 error--text mb-0" v-show="timeValidMessage">
          {{ timeValidMessage }}
        </p>
        <v-card-actions class="px-4 pb-5 pt-1">
          <v-btn
            color="primary white--text"
            height="30px"
            width="80px"
            class="font-size-small"
            depressed
            @click="onUpdateDate"
            ><strong>UPDATE</strong></v-btn
          >
          <v-btn
            color="error white--text"
            height="30px"
            width="80px"
            class="font-size-small"
            depressed
            @click="cancel"
            ><strong>CANCEL</strong></v-btn
          >
        </v-card-actions>
      </v-card>
    </form>
  </v-menu>
</template>

<script>
import { mdiCalendarMonth, mdiMenuRight, mdiMenuLeft } from '@mdi/js';
import { customDown } from '../plugins/icons';
import moment from 'moment';
export default {
  props: {
    value: {
      type: [String, Date],
    },
    timeFormat: {
      type: String,
      default: 'MM/DD/YYYY h:mm A',
    },
    disabled: Boolean,
    readonly: {
      Type: Boolean,
      default: false,
    },
    hidedetails: {
      Type: Boolean,
      default: false,
    },
    requried: {
      Type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: '230px',
    },
    height: {
      type: [Number, String],
      default: '36',
    },
    minWidth: {
      type: [Number, String],
      default: '230px',
    },
    inputClass: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
  },
  data() {
    return {
      icons: {},
      dropdownOpen: false,
      meridiam: null,
      displayDate: '',
      dateTimeModel: '',
      dateModel: null,
      timeModel: {
        hr: null,
        min: null,
      },
      meridiamItems: ['AM', 'PM'],
      timeModelHrList: `1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12`,
      timeModelMinList: `0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,58, 59`,
      monthNames: [],
      monthNamesShort: [],
      timeValid: true,
      timeValidMessage: '',
      min: this.$moment().format(),
    };
  },
  created() {
    this.icons = Object.freeze({
      calendar: mdiCalendarMonth,
      mdiMenuLeft,
      mdiMenuRight,
      customDown,
    });
    (this.meridiamItems = Object.freeze(['AM', 'PM'])),
      (this.monthNames = Object.freeze([
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]));
    this.monthNamesShort = Object.freeze([
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]);
  },
  computed: {
    displayTime: {
      get() {
        if (this.value == null) return '';
        return this.$moment.utc(this.value).local().format(this.timeFormat);
      },
      set(value) {
        this.dateTimeModel = value;
      },
    },
    checkRequried() {
      return (
        !!this.timeModel.hr ||
        !!this.timeModel.min ||
        !!this.meridiam ||
        !!this.dateModel
      );
    },
    startDate() {
      return this.$moment().format('YYYY-MM-DD');
    },
    timeIsValid() {
      let date = new Date(`${this.dateModel} ${this.formatTime()}`);
      return date != 'Invalid Date';
    },
    widthText() {
      if (typeof this.width === 'string') return this.width;
      else return this.width + 'px';
    },
    minWidthText() {
      if (typeof this.minWidth === 'string') return this.minWidth;
      else return this.minWidth + 'px';
    },
    computedWidth() {
      if (typeof this.width === 'string') return this.width;
      else return this.width + 'px';
    },
  },
  mounted() {
    this.setDefaultValue();
  },
  methods: {
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date == null) return '';
      const [year, month, day] = date.split('-');
      return `${this.monthNamesShort[parseInt(month) - 1]} ${day}, ${year}`;
    },
    formatTime() {
      if (this.timeModel.hr || this.timeModel.min || this.meridiam)
        return `${this.formatZero(this.timeModel.hr || '')}:${this.formatZero(
          this.timeModel.min || '',
        )} ${this.meridiam || ''}`;
      else return '';
    },
    blur(type) {
      this.$refs[type].isMenuActive = false;
      this.$refs[type].isFocused = false;
    },
    onUpdateDate() {
      let that = this;
      let date = new Date(`${this.dateModel} ${this.formatTime()}`);
      if (that.timeValid) {
        that.$emit('input', date == 'Invalid Date' ? null : date);
        that.$nextTick().then(() => {
          that.dropdownOpen = false;
        });
      }
    },
    cancel() {
      this.dropdownOpen = false;
    },
    formatZero(val) {
      if (!val) return '00';
      return val <= 9 ? `0${val}` : `${val}`;
    },
    checkValidate() {
      this.timeValid = this.timeIsValid;
    },
    setDefaultValue() {
      let that = this;
      this.$nextTick().then(() => {
        that.timeValid = true;
        if (that.value == null) {
          that.dateModel = null;
          that.timeModel.hr = null;
          that.timeModel.min = null;
          that.meridiam = null;
          return true;
        } else {
          let d = this.$moment.utc(that.value).local();
          that.dateModel = d.format('YYYY-MM-DD');
          that.timeModel.hr = parseInt(d.format('hh'));
          that.timeModel.min = d.minute();
          that.meridiam = d.hour() >= 12 ? 'PM' : 'AM';
          that.dateTimeModel = that.value;
        }
      });
    },
    displayDateTimeOnBlur() {
      let that = this;
      if (this.disabled || this.readonly) return;
      let date = new Date(this.displayTime);
      that.$emit('input', date == 'Invalid Date' ? null : date);
    },
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
  watch: {
    value() {
      this.setDefaultValue();
    },
    dropdownOpen(val) {
      if (val) {
        this.$nextTick().then(() => {
          this.setDefaultValue();
        });
      }
    },
  },
};
</script>
<style scoped>
.v-text-field >>> .v-input__slot {
  padding-right: 0 !important;
}
.v-text-field >>> .v-input__append-inner {
  margin: 0 !important;
}
.v-text-field >>> .inside-button {
  border-width: 0 0 0 1px;
  border-color: inherit;
}
.v-text-field.v-text-field--enclosed >>> .v-text-field__details {
  padding: 0px !important;
}
.v-datepicker >>> .v-date-picker-table {
  height: auto !important;
  padding: 5px 0;
}
.v-datepicker >>> .v-date-picker-table th {
  color: var(--color-primary) !important;
  padding: 3px 0;
  font-size: 14px;
}
.v-datepicker >>> .v-date-picker-table thead {
  background: var(--color-primary3) !important;
}
.v-datepicker >>> table {
  border-spacing: 0 !important;
}
.v-datepicker >>> .v-btn--active {
  color: unset !important;
}
.v-datepicker >>> .v-btn--active > .v-btn__content {
  color: var(--color-primary4) !important;
}
.v-datepicker >>> .v-date-picker-table--date .v-btn {
  height: 26px;
  width: 26px;
  font-size: 14px;
}
.v-datepicker >>> .v-date-picker-table--date tr {
  height: 30px;
}
.v-menu__content > .v-list {
  padding: 0 !important;
}
.v-list--dense >>> .v-list-item__title {
  font-size: 14px !important;
}
.theme--light.v-label {
  color: var(--color-primary) !important;
}
form >>> .v-select > .v-input__control > .v-input__slot {
  max-height: 30px !important;
  min-height: 30px !important;
}
.v-text-field >>> .v-messages__message {
  text-align: left;
  font-style: normal;
  word-spacing: normal;
}
</style>
