const BASE_API_URL = window.location.origin;

function mapUrl(url) {
  return `${BASE_API_URL}/${url}`;
}
function mapQueryParams(prarams) {
  function iter(o, path) {
    if (Array.isArray(o)) {
      o.forEach(function (a) {
        iter(a, path);
      });
      return;
    }
    if (o !== null && typeof o === 'object') {
      Object.keys(o).forEach(function (k) {
        iter(o[k], path + '[' + k + ']');
      });
      return;
    }
    data.push(path + '=' + o);
  }

  let data = [];
  Object.keys(prarams).forEach(function (k) {
    iter(prarams[k], k);
  });
  return data.join('&');
}
function mapQueryParamsEncodeURI(prarams) {
  function iter(o, path) {
    if (Array.isArray(o)) {
      o.forEach(function (a) {
        iter(a, path);
      });
      return;
    }
    if (o !== null && typeof o === 'object') {
      Object.keys(o).forEach(function (k) {
        iter(o[k], path + '[' + k + ']');
      });
      return;
    }
    data.push(path + '=' + encodeURIComponent(o));
  }

  let data = [];
  Object.keys(prarams).forEach(function (k) {
    iter(prarams[k], k);
  });
  return data.join('&');
}

export { BASE_API_URL, mapUrl, mapQueryParams, mapQueryParamsEncodeURI };
