<template>
  <v-scroll-x-reverse-transition>
    <v-app id="app" :key="pageKey">
      <template v-if="!isLogin">
        <v-content class="primary2" v-bind:style="contentStyles">
          <v-expand-transition>
            <router-view></router-view>
          </v-expand-transition>
        </v-content>
      </template>
      <template v-else>
        <nav-header></nav-header>
        <v-navigation-drawer
          v-if="navRightIsActive"
          app
          permanent
          mini-variant
          mini-variant-width="70"
          color="primary"
        >
          <nav-menu></nav-menu>
        </v-navigation-drawer>
        <v-content class="primary2" v-bind:style="contentStyles">
          <v-breadcrumbs
            v-if="breadcrumbsItems.length > 0"
            :items="breadcrumbsItems"
            style="height: 30px"
          >
            <template v-slot:item="{ item }">
              <router-link
                :to="item.to"
                :class="{
                  'v-breadcrumbs__item': true,
                  'v-breadcrumbs__item--disabled': item.disabled,
                }"
              >
                {{ item.text }}
              </router-link>
            </template>
          </v-breadcrumbs>
          <v-slide-x-transition>
            <keep-alive :max="2">
              <router-view></router-view>
            </keep-alive>
          </v-slide-x-transition>
        </v-content>
      </template>
    </v-app>
  </v-scroll-x-reverse-transition>
</template>

<script>
import NavMenu from './components/_nav-menu';
import NavHeader from './components/_header-nav';

import router from './router';

export default {
  components: {
    'nav-menu': NavMenu,
    'nav-header': NavHeader,
  },
  computed: {
    contentHeight() {
      return (
        this.$vuetify.breakpoint.height - this.$vuetify.application.top + 'px'
      );
    },
    breadcrumbsItems() {
      if (
        !this.$route.meta.breadcrumbs ||
        this.$route.meta.breadcrumbs(this.$route).length == 0
      )
        return [];
      let breadcrumbs = this.$route.meta.breadcrumbs(this.$route);
      breadcrumbs =
        this.$route.path != '/togglefeatures'
          ? [{ text: 'Home', to: '/', disabled: false }].concat(breadcrumbs)
          : [
              {
                text: 'Toggle Features',
                to: '/togglefeatures',
                disabled: false,
              },
            ];
      return breadcrumbs;
    },
    contentStyles() {
      let chatboxSize = 0;
      if (this.isMounded) {
        chatboxSize = this.$refs.chatbox ? this.$refs.chatbox.getWidth() : 0;
      }
      if (typeof chatboxSize == 'number') chatboxSize += 'px';
      return {
        height: this.contentHeight,
        marginRight: this.isChatBoxOpen ? chatboxSize : 0,
      };
    },
    isLogin() {
      return this.$store.state.authenticate.user.isAuthorized;
    },
  },
  mounted() {
    this.$nextTick(() => (this.isMounded = true));
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  data() {
    return {
      is100Height: false,
      isChatBoxOpen: false,
      isMounded: false,
      pageKey: 0,
      isOnline: true,
      navRightIsActive: true,
      isActiveWarning: false,
    };
  },
  created() {
    router.beforeEach((to, from, next) => {
      this.$progress.show();
      return next();
    });
    router.afterEach(() => {
      document.title = `${this.$route.meta.title || 'Home'} - Dr Berg App`;
      this.$nextTick(() => {
        this.$progress.hide();
      });
    });
    // this.$store.dispatch('authenticate/validUser');
    // this.$store.dispatch('authenticate/getRolesList');
  },
  methods: {
    set100Height(is100) {
      this.is100Height = !!is100;
    },
    forceUpdateApp() {
      this.pageKey += 1;
    },
  },
};
</script>

<style lang="scss">
html,
body {
  overflow-y: hidden !important;
}
@import './styles/site.scss';
</style>
