<template>
  <v-popup v-model="isShow" contentClass="pb-2">
    <template v-slot:title>SETTING OPTIONS</template>
    <fieldset class="v-fieldset mb-2">
      <legend>
        ANDROID
        <v-tooltip
          top
          v-tooltip-triangle
          color="secondary"
          content-class="v-tooltip-android"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn height="20" width="20" icon text v-bind="attrs" v-on="on">
              <v-icon>{{ icons.mdiInformation }}</v-icon>
            </v-btn>
          </template>
          <span>Via Google Cloud Messaging, GCM/FCM</span>
        </v-tooltip>
      </legend>
      <v-row>
        <v-col cols="6">
          <v-label>
            <strong>Google API Key</strong>
          </v-label>
          <v-text-field
            class="py-0 font-weight-bold"
            outlined
            dense
            v-model="objectData.googleApi"
          ></v-text-field>
        </v-col>
      </v-row>
    </fieldset>
    <fieldset class="v-fieldset mb-2">
      <legend>IOS</legend>
      <v-row>
        <v-col cols="6">
          <v-label>
            <strong>Send push notifications to iOS devices: </strong>
          </v-label>
          <div class="environment-option">
            <v-radio-group
              v-model="objectData.environmentValue"
              column
              class="my-0 ml-n1"
              hide-details
            >
              <v-radio
                :ripple="false"
                v-for="(item, index) in listEnvironment"
                :key="index"
                :value="item.value"
                class="mb-1"
              >
                <template v-slot:label>
                  <span>
                    {{ item.text }}
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex justify-end">
            <div
              v-if="objectData.file.length > 0"
              class="d-flex align-center font-weight-medium"
              style="
                width: calc(100% - 120px);
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
              "
            >
              {{ compactFileName(objectData.file[0].name) }}
            </div>
            <div>
              <input
                v-show="false"
                ref="inputFiles"
                type="file"
                accept=".pem"
                @change="onChangeFile"
              />
              <v-btn
                color="primary"
                elevation="0"
                height="30px"
                @click="$refs.inputFiles.click()"
              >
                <strong>SELECT FILE</strong>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-label>
            <strong>CERT Pass</strong>
          </v-label>
          <v-text-field
            class="py-0 font-weight-bold"
            outlined
            dense
            v-model="objectData.certPass"
          ></v-text-field>
        </v-col>
      </v-row>
    </fieldset>
    <template v-slot:footer>
      <v-btn
        type="submit"
        depressed
        class="mr-5 font-weight-bold white--text"
        color="secondary1"
        width="100"
        @click="onSaveSetting"
        >SAVE</v-btn
      >
      <v-btn
        type="reset"
        depressed
        class="font-weight-bold mr-5"
        color="error"
        width="100"
        @click="isShow = false"
        >CANCEL</v-btn
      >
    </template>
  </v-popup>
</template>

<script>
import { mdiInformation } from '@mdi/js';
import api from '../../api/moderation';

export default {
  props: {
    value: Boolean,
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      icons: { mdiInformation },
      objectData: {
        googleApi: '',
        environmentValue: true,
        file: [],
        certPass: '',
      },
      listEnvironment: [
        { text: 'Using Sandbox Environment', value: true },
        { text: 'Using Production Environment', value: false },
      ],
    };
  },
  watch: {
    isShow() {
      if (this.isShow) this.getCurrentSetting();
    },
  },
  methods: {
    onChangeFile(event) {
      this.objectData.file = event.target.files;
    },
    compactFileName(fileName) {
      if (fileName.length > 30) {
        const fileExt = fileName.substring(fileName.lastIndexOf('.') - 3);
        return fileName.substring(0, 15) + '...' + fileExt;
      } else return fileName;
    },
    onSaveSetting() {
      console.log(this.objectData);
      api.saveSettingOption(this.objectData).then((res) => {
        if (res.status === 200) {
          this.$notify.success(res.data.message);
        }
      });
    },
    getCurrentSetting() {
      api.getCurrentSetting().then((res) => {
        if (res.status === 200) {
          const data = { ...res.data };
          this.objectData.googleApi = data.googleAPIKey;
          this.objectData.environmentValue = data.isUsingSanbox;
          this.objectData.certPass = data.certPass;
        }
      });
    },
  },
};
</script>

<style>
.environment-option {
  margin-left: 30px;
}
</style>
