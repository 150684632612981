<template>
  <v-card class="v-audio-player pl-0" outlined height="40">
    <v-slider
      v-model="currentTime"
      @click.stop="setCurrentTime()"
      color="primary"
      track-color="grey"
      :max="audioLength"
      min="0"
      hide-details
      dense
      class="v-audio-player__slider voice pl-1"
    >
      <template v-slot:prepend>
        <v-btn
          icon
          outlined
          x-small
          color="primary"
          class="mr-1"
          @click="playAudio()"
        >
          <v-icon small>{{ !isPlay ? icons.play : icons.pause }}</v-icon>
        </v-btn>
        <label :key="currentTime" v-text="getTimerDisplay()"></label>
      </template>
      <template v-slot:append>
        <v-hover v-slot:default="{ hover }">
          <v-card flat width="auto" class="v-audio-player__volume">
            <v-expand-x-transition>
              <v-slider
                v-model="volume"
                color="primary"
                track-color="grey"
                min="0"
                max="1"
                step="0.1"
                hide-details
                v-show="hover"
              ></v-slider>
            </v-expand-x-transition>
            <v-btn icon x-small color="primary" @click="isMute = !isMute">
              <v-icon>{{ computedAudioIcon }}</v-icon>
            </v-btn>
          </v-card>
        </v-hover>
      </template>
    </v-slider>
  </v-card>
</template>

<script>
import {
  mdiPlay,
  mdiVolumeHigh,
  mdiVolumeMedium,
  mdiVolumeLow,
  mdiPause,
  mdiVolumeVariantOff,
} from '@mdi/js';
export default {
  props: {
    audioURL: String,
    audioFile: [Object, File],
  },
  data() {
    return {
      isPlay: false,
      icons: {
        play: mdiPlay,
        pause: mdiPause,
        volumeMute: mdiVolumeVariantOff,
        mdiVolumeLow,
        mdiVolumeMedium,
        mdiVolumeHigh,
      },
      volume: 0.5,
      currentTime: 0,
      audioLength: 0,
      audio: null,
      isMute: false,
    };
  },
  created() {
    this.setAudio();
    window.vm = this;
  },
  computed: {
    computedAudioIcon() {
      if (this.isMute) return this.icons.volumeMute;
      else if (this.volume >= 0.75) return this.icons.mdiVolumeHigh;
      else if (this.volume >= 0.35) return this.icons.mdiVolumeMedium;
      else return this.icons.mdiVolumeLow;
    },
  },
  methods: {
    setCurrentTime() {
      this.audio.currentTime = this.currentTime;
    },
    playAudio() {
      if (this.audio == null) {
        this.setAudio();
      }
      if (this.audio) {
        this.isPlay = !this.isPlay;
        try {
          this.audio[!this.isPlay ? 'pause' : 'play']();
        } catch (error) {
          console.error(error);
        }
      }
    },
    setAudio() {
      let self = this;
      if (!self.audio) {
        if (!self.audioURL && !self.audioFile) return;

        if (self.audioURL) {
          self.audio = new Audio(self.audioURL);
        } else if (self.audioFile) {
          self.audio = document.createElement('audio');
          let fileReader = new FileReader();
          fileReader.onload = function (e) {
            self.audio.src = e.target.result;
          };
          fileReader.readAsDataURL(self.audioFile);
        }

        self.audio.volume = self.isMute ? 0 : self.volume;
        self.audio.addEventListener('canplaythrough', () => {
          if (self.audio.duration == Infinity) {
            self.audio.currentTime = 1e101;
            self.audio.currentTime = 0;
          } else {
            self.audioLength = self.audio.duration;
          }
        });
        self.audio.addEventListener('timeupdate', function () {
          if (self.audioLength == 0) {
            self.audioLength = self.audio.duration;
          }
          if (self.audio) self.currentTime = self.audio.currentTime;
        });
        self.audio.addEventListener('ended', () => {
          self.isPlay = false;
          self.audioLength = self.currentTime;
        });
      }
    },
    getVideoDuration() {
      if (this.audio) this.audioLength = this.audio.duration;
    },
    removeAudio() {
      let self = this;
      if (self.audio) {
        self.audio.pause();
        self.audio = null;
      }
    },
    calcDuration(duration) {
      if (duration) {
        let h = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));
        let m = Math.floor((duration % (60 * 60)) / 60);
        let s = Math.round(duration % 60);
        return `${h > 0 ? (h < 10 ? '0' + h : h) + ':' : ''}${
          m < 10 ? '0' + m : m
        }:${s < 10 ? '0' + s : s}`;
      } else {
        return '00:00';
      }
    },
    getTimerDisplay() {
      return (
        this.calcDuration(this.currentTime) +
        '/' +
        this.calcDuration(this.audioLength)
      );
    },
  },
  watch: {
    volume() {
      if (this.audio) {
        this.audio.volume = this.volume;
        this.isMute = this.volume === 0;
      }
    },
    isMute(val) {
      this.audio.volume = val ? 0 : this.volume;
    },
    audioURL() {
      this.setAudio();
    },
  },
  deactivated() {
    this.removeAudio();
  },
  beforeDestroy() {
    this.removeAudio();
  },
};
</script>

<style lang="scss">
.v-audio-player {
  max-width: 250px;
  min-width: 250px;
  width: 100%;
  border-color: var(--color-primary1) !important;
  display: flex;
  align-items: center;
  background-color: transparent !important;
  padding-left: 8px;
  padding-right: 4px;
  label {
    color: var(--color-accent) !important;
  }
  & > .v-input__slider {
    display: flex;
    align-items: center;
    & > .v-input__control .v-slider {
      margin: 0;
      .v-slider__track-fill {
        max-width: 100% !important;
      }
    }
    & > .v-input__prepend-outer,
    & > .v-input__append-outer {
      display: flex;
      align-items: center;
    }
  }
  &__volume {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: transparent !important;
    & .v-input__slider {
      width: 80px;
    }
    & .v-input__append-outer {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
</style>
