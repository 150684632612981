<template>
  <v-popup v-model="isShow">
    <template v-slot:title>MANAGE ROLES</template>
    <grid
      ref="grid"
      reorder-column
      resize-column
      hideMenuGrid
      fixed-header
      :columns="columns"
      :dataConfig="dataConfig"
      style="padding: unset !important"
      hidePagination
      hidePageSize
    >
      <template v-slot:item.numberUser="{ item }">
        <span
          class="secondary1--text text-decoration"
          style="cursor: pointer"
          @click="onNumberUserClick(item)"
          ><strong>{{ item.numberUser }}</strong></span
        >
      </template>
      <template v-slot:item.command="{ item }">
        <v-btn
          class="mr-10px px-2 btn-border__radius"
          small
          depressed
          width="80"
          color="info"
          @click="onEditClick(item)"
        >
          <v-icon v-text="icons.customEdit" class="mr-1" small></v-icon
          ><strong>Edit</strong>
        </v-btn>
      </template>
    </grid>
    <edit-role-popup
      v-if="editData.isShowPopup"
      :isShowPopup.sync="editData.isShowPopup"
      :initData="editData.data"
      @fetchData="fetchData"
    ></edit-role-popup>
  </v-popup>
</template>
<script>
import Grid from '../../../components/grid';
import { customEdit } from '../../../plugins/icons';
import EditRolePopup from './_edit-role-popup.vue';
export default {
  components: {
    Grid,
    'edit-role-popup': EditRolePopup,
  },
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShow: {
      get() {
        return this.isShowPopup;
      },
      set(val) {
        this.$emit('update:isShowPopup', val);
      },
    },
  },
  data() {
    return {
      icons: {
        customEdit,
      },
      editData: {
        isShowPopup: false,
        data: {},
      },
      columns: [
        {
          text: 'Role Name',
          value: 'name',
        },
        {
          text: '#User',
          value: 'numberUser',
        },
        {
          text: 'Command',
          value: 'command',
          hideFilter: true,
          sortable: false,
          with: 100,
        },
      ],
      dataConfig: {
        url: 'api/user/roles/grid',
        page: 1,
        pageSize: 20,
      },
    };
  },
  methods: {
    onEditClick(item) {
      this.editData.isShowPopup = true;
      this.editData.data = item;
    },
    onNumberUserClick(item) {
      this.$emit('filterByRole', item.name);
      this.isShow = false;
    },
    fetchData() {
      this.$refs.grid.read();
    },
  },
};
</script>
