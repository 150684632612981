<template>
  <v-popup v-model="isShow" width730 contentClass="bulk-resources-popup">
    <template v-slot:title>EDIT BULK ITEMS</template>
    <div class="mb-3">
      <v-simple-table class="v-table-bulk-items">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Type</th>
              <th class="text-left">Title</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in listItem" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.typeName }}</td>
              <td class="d-flex align-center justify-space-between">
                {{ item.title }}
                <v-btn
                  v-if="listItem.length > 2"
                  icon
                  text
                  x-small
                  @click="onRemoveItem(item, index)"
                >
                  <v-icon color="error">{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <v-panel
      v-if="isSameType"
      title="CATEGORY"
      hideExpand
      color="primary3"
      class="mb-3"
    >
      <div class="pa-20px v-bulk-items-category">
        <v-radio-group
          v-model="categoryValue"
          column
          class="my-0 ml-n1"
          hide-details
        >
          <v-radio
            :ripple="false"
            v-for="(item, index) in listCategory"
            :key="index"
            :value="item.value"
            class="mb-1"
          >
            <template v-slot:label>
              <span class="font-weight-medium">
                {{ item.text }}
              </span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </v-panel>
    <div>
      <p class="mb-0">
        <b>Status</b>
      </p>
      <v-dropdownlist
        :data-items="listStatus"
        v-model="statusValue"
        :itemText="'text'"
        :itemValue="'value'"
        class="mr-2"
        hide-details
        width="100%"
      >
      </v-dropdownlist>
    </div>
    <template v-slot:footer>
      <v-btn
        elevation="0"
        color="secondary1 white--text"
        class="mr-5"
        width="100px"
        @click="onSaveEdit"
      >
        <strong>SAVE</strong>
      </v-btn>
      <v-btn color="error" elevation="0" width="100px" @click="isShow = false">
        <strong>CANCEL</strong>
      </v-btn>
    </template>
  </v-popup>
</template>

<script>
import api from '../../../api/resources';
import { mdiClose } from '@mdi/js';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bulkItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isSameType() {
      const item = this.listItem[0];
      return this.listItem.every((c) => c.typeName === item.typeName);
    },
    isSameUser() {
      const item = this.listItem[0];
      const sameItemId = this.listItem.every(
        (c) => c.createdById === item.createdById,
      );
      if (sameItemId) {
        return (
          this.$store.state.authenticate.user.profileId === item.createdById
        );
      } else return sameItemId;
    },
    isContributor() {
      return this.$store.state.authenticate.user.role === 'Contributor';
    },
    listStatus() {
      if (this.isContributor && this.isSameUser) {
        return [
          { text: 'Pending review', value: 2 },
          { text: 'Private', value: 1 },
        ];
      }
      if (this.isContributor && !this.isSameUser) {
        return [{ text: 'Pending review', value: 2 }];
      }
      if (!this.isContributor && !this.isSameUser) {
        return [
          { text: 'Draft', value: 3 },
          { text: 'Pending review', value: 2 },
          { text: 'Publish', value: 0 },
        ];
      }
      return this.$store.state.enums.resourceStatus;
    },
  },
  data() {
    return {
      listItem: [
        {
          id: 1,
          typeName: 'Document',
          title:
            "Dr. Berg's Healthy Ketogenic Diet Basics: Step 1 - Intermittent",
        },
        {
          id: 2,
          typeName: 'Document',
          title: 'The Healthiest Pizza in the World - Dr.Berg',
        },
      ],
      icons: { mdiClose },
      listCategory: [
        { text: 'Category', value: 0 },
        { text: 'Category 1', value: 1 },
        { text: 'Category 2', value: 2 },
      ],
      categoryValue: 0,
      statusValue: 0,
    };
  },
  watch: {
    bulkItems: {
      deep: true,
      handler() {
        this.listItem = [...this.bulkItems];
      },
    },
  },
  mounted() {
    this.onResizeColumn();
    this.listItem = [...this.bulkItems];
    this.getListCategory();
    if (this.$store.state.authenticate.user.role === 'Contributor') {
      this.statusValue = 2;
      // this.listStatus = [
      //   { text: 'Pending review', value: 2 },
      //   { text: 'Draft', value: 3 },
      //   { text: 'Private', value: 1 },
      // ];
    }
  },
  methods: {
    onRemoveItem(item, index) {
      if (this.listItem.some((c) => c.id === item.id)) {
        this.listItem.splice(index, 1);
        this.getListCategory();
      }
      // this.listItem = this.listItem.map((c) => {
      //   if (c.id !== item.id) {
      //     return c;
      //   }
      // });
    },
    getListCategory() {
      if (this.isSameType) {
        api.getListCategory(this.listItem[0].type).then((res) => {
          if (res.status === 200) {
            const listItem = [...res.data];
            this.listCategory = listItem.map((c) => {
              let item = { text: c.title, value: c.id };
              return item;
            });
            this.categoryValue = this.listCategory[0]?.value;
          }
        });
      }
    },
    onSaveEdit() {
      const listId = this.listItem.map((c) => c.id);
      api
        .bulkEdit(listId, this.categoryValue, this.statusValue)
        .then((res) => {
          if (res.status === 200) {
            this.$notify.success('Successfully edited bulk action');
            this.$emit('fetchData');
            this.isShow = false;
          }
        })
        .catch((err) => {
          this.$notify.error(err);
        });
    },
    onResizeColumn() {
      const table = document.querySelector('.v-table-bulk-items');
      const cols = table.querySelectorAll('th');
      [].forEach.call(cols, (col) => {
        // Create a resizer element
        const resizer = document.createElement('div');
        resizer.classList.add('v-table__resizeable__border');
        // Set the height
        resizer.style.height = `${col.offsetHeight}px`;
        // Add a resizer element to the column
        col.appendChild(resizer);
        // Will be implemented in the next section
        this.createResizableColumn(col, resizer);
      });
    },
    createResizableColumn(col, resizer) {
      // Track the current position of mouse
      let x = 0;
      let w = 0;
      const mouseDownHandler = (e) => {
        // Get the current mouse position
        x = e.clientX;
        // Calculate the current width of column
        const styles = window.getComputedStyle(col);
        w = parseInt(styles.width, 10);
        // Attach listeners for document's events
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };
      const mouseMoveHandler = (e) => {
        // Determine how far the mouse has been moved
        const dx = e.clientX - x;
        // Update the width of column
        col.style.width = `${w + dx}px`;
      };
      // When user releases the mouse, remove the existing event listeners
      const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };
      resizer.addEventListener('mousedown', mouseDownHandler);
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  .v-card {
    .bulk-resources-popup {
      padding-bottom: 10px !important;
    }
  }
}
.v-table-bulk-items {
  .v-data-table {
    &__wrapper {
      table {
        width: 100%;
        border-spacing: 0;
        border-left: 1px solid var(--color-primary2);
        border-bottom: 1px solid var(--color-primary2);
        thead {
          height: 80px;
          tr {
            background-color: var(--color-secondary);
            th {
              color: white !important;
              border-right: 1px solid var(--color-primary2);
              text-transform: uppercase;
              &:nth-last-child(1) {
                border-right: 0 !important;
              }
              position: relative;
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: var(--color-primary3);
            }
            td {
              border-right: 1px solid var(--color-primary2);
            }
          }
        }
      }
    }
  }
}
.v-table__resizeable__border {
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  cursor: ew-resize;
  user-select: none;
}
.v-bulk-items-category {
  &__radio {
    .v-input {
      &__control {
        .v-input__slot {
          .v-input--radio-group {
            &__input {
              .v-radio {
                .v-input--selection-controls__input {
                  height: 18px;
                  width: 18px;
                  .v-icon {
                    height: 16px;
                    // padding-bottom: 1px;
                    &__svg {
                      height: 15px !important;
                      width: 15px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
