<template>
  <v-popup v-model="isShow" :loading="isLoading" :contentClass="'pb-0 pb-3'">
    <template v-slot:title>{{
      !dataId ? 'Add New User' : 'Edit User'
    }}</template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
      <v-form
        @submit.prevent="handleSubmit(formOnSubmit)"
        @reset.prevent="reset"
      >
        <v-row>
          <v-col cols="6" class="py-0">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="First Name"
            >
              <b>First Name</b>
              <v-text-field
                autofocus
                outlined
                dense
                error
                v-model="initData.firstName"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6" class="py-0">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Last Name"
            >
              <b>Last Name</b>
              <v-text-field
                outlined
                dense
                error
                v-model="initData.lastName"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6" class="py-0">
            <ValidationProvider
              rules="required|email|email-unique"
              v-slot="{ errors }"
              name="Email"
            >
              <b>Email</b>
              <v-text-field
                outlined
                dense
                error
                v-model="initData.email"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6" class="py-0">
            <ValidationProvider
              rules="required|user-regex|user-unique"
              v-slot="{ errors }"
              name="User Name"
            >
              <b>User Name</b>
              <v-text-field
                outlined
                dense
                error
                v-model="initData.userName"
                :error-messages="errors"
                autocomplete="off"
              >
                <template v-slot:message>
                  <span v-html="errors[0]"></span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6" class="py-0" v-if="!dataId">
            <ValidationProvider
              rules="required|passwordRegex"
              v-slot="{ errors }"
              name="Password"
            >
              <b>Password</b>
              <v-text-field
                outlined
                dense
                error
                v-model="initData.password"
                :error-messages="errors"
                type="password"
                autocomplete="off"
              >
                <template v-slot:message>
                  <span v-html="errors[0]"></span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6" class="py-0" v-if="!dataId">
            <ValidationProvider
              rules="required|password:@Password"
              v-slot="{ errors }"
              name="Confirm Password"
            >
              <b>Confirm Password</b>
              <v-text-field
                outlined
                dense
                error
                v-model="initData.confirmPassword"
                :error-messages="errors"
                type="password"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6" class="py-0">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Role"
            >
              <label class="primary--text" for="role">
                <strong>Role</strong>
              </label>
              <v-dropdownlist
                :errorMessages="errors"
                v-model="initData.role"
                :itemText="'text'"
                :itemValue="'value'"
                :dataItems="roles"
                :placeholder="'Select role'"
                :hideDetails="(errors || []).length == 0"
              >
              </v-dropdownlist>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <template v-slot:footer>
      <div class="mt-10px">
        <v-btn
          type="submit"
          depressed
          class="mr-5 font-weight-bold primary4--text"
          color="secondary1"
          width="100"
          :disabled="!hasFormChange"
          @click="formOnSubmit()"
          >SAVE</v-btn
        >
        <v-btn
          type="reset"
          depressed
          class="font-weight-bold mr-5"
          color="error"
          width="100"
          @click="
            reset();
            isShow = false;
          "
          >CANCEL</v-btn
        >
        <v-btn
          v-if="!!dataId"
          depressed
          class="font-weight-bold"
          outlined
          color="error"
          @click="onDeleteClick()"
          >DELETE THIS USER</v-btn
        >
      </div>
    </template>
  </v-popup>
</template>

<script>
import { extend } from 'vee-validate';
import api from '../../../api/authenticate';
import { mapState } from 'vuex';
export default {
  props: {
    isShowPopup: {
      default: false,
    },
    dataId: {
      default: 0,
    },
  },
  computed: {
    ...mapState('enums', ['roles']),
    isShow: {
      get() {
        return !!this.isShowPopup;
      },
      set(val) {
        this.$emit('update:isShowPopup', val);
      },
    },
  },
  data() {
    return {
      initData: {
        id: '',
        profileId: 0,
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        role: '',
      },
      initDataDefault: {},
      initDataCompare: '',
      isLoading: false,
      hasFormChange: false,
      position: '',
      name: '',
    };
  },
  mounted() {
    this.initDataDefault = { ...this.initData };
    extend('user-unique', {
      validate: this.checkUsernameUnique,
    });
    extend('user-regex', {
      validate: (value) => /^([A-Za-z0-9]+)$/.test(value),
      message:
        '<span>The User Name must contain alphanumeric characters only <br /> (no spaces, no special characters).</span>',
    });
    extend('email-unique', {
      validate: this.checkEmailUnique,
    });
    extend('password', {
      params: ['target'],
      validate(value, { target }) {
        return value === target;
      },
      message: 'The Password and confirmation password do not match.',
    });
    extend('passwordRegex', {
      validate: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&"()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d !"#$%&"()*+,-./:;<=>?@[\\\]^_`{|}~]{6,}/.test(
          value,
        ),
      message:
        'The Password must have minimum six characters, at least <br /> one uppercase letter, one lowercase letter, one number <br> and one special character',
    });
  },
  methods: {
    async checkUsernameUnique(val) {
      try {
        const response = await this.$http.get(
          `/user/validUser/${val}?id=${this.initData.profileId}`,
        );
        if (response.data.isSuccess) {
          return true;
        }
        return response.data.message;
      } catch (err) {
        return false;
      }
    },
    async checkEmailUnique(val) {
      try {
        const response = await this.$http.get(
          `/user/validEmail/${val}?id=${this.initData.profileId}`,
        );
        if (response.data.isSuccess) {
          return true;
        }
        return response.data.message;
      } catch (err) {
        return false;
      }
    },
    formOnSubmit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.isLoading = true;
          (this.dataId == null ? this.addUser : this.updateUser)(
            this.initData,
          ).finally(() => {
            this.isLoading = false;
          });
        }
      });
    },
    addUser(data) {
      this.isLoading = true;
      return this.$http
        .post('/user/', data)
        .then(this.callbackHandler)
        .catch((err) => this.$notify.error(err));
    },
    updateUser(data) {
      this.isLoading = true;
      return this.$http
        .put('/user/' + data.profileId, data)
        .then(this.callbackHandler)
        .catch((err) => this.$notify.error(err));
    },
    callbackHandler(response) {
      if (response.status === 200 && response.data.isSuccess) {
        this.isShow = false;
        this.$emit('fetchGrid');
        this.$notify.success(response.data.message);
      } else {
        this.$notify.error(response.data.message);
      }
    },
    setFormData(data) {
      this.initData = { ...this.initDataDefault };
      this.initData = Object.assign({}, this.initData, data);
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.observer.reset();
    },
    getUserById() {
      this.isLoading = true;
      api
        .getUserById(this.dataId)
        .then((res) => {
          if (res.status === 200) {
            this.initData = { ...res.data };
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => (this.isLoading = false));
    },
    onDeleteClick() {
      this.$emit('deleteUser', this.initData);
    },
  },
  watch: {
    isShowPopup(val) {
      if (val) {
        if (this.dataId != null) this.getUserById();
      } else this.reset();
    },
    initData: {
      handler() {
        this.$nextTick(() => {
          this.hasFormChange =
            this.initDataCompare != JSON.stringify(this.initData);
        });
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.v-input >>> .v-text-field__details {
  padding-right: 0px !important;
}
</style>
