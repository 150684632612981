<template>
  <div
    :class="{
      'pagination primary--text': true,
      'pagination--center': centering,
    }"
  >
    <div class="pagination__list" v-if="!hidePagination">
      <button
        type="button"
        :class="{
          'v-pagination__navigation': true,
          'v-pagination__navigation--disabled': currentPageInput == 1,
        }"
        @click="currentPageInput = 1"
      >
        <v-icon v-text="icons.mdiSkipPrevious"></v-icon>
      </button>
      <v-pagination
        v-model="currentPageInput"
        :length="totalPages"
        :color="$customColors.secondary1"
        :total-visible="totalVisible"
        :next-icon="icons.mdiMenuRight"
        :prev-icon="icons.mdiMenuLeft"
        @next="$emit('next')"
        @previous="$emit('previous')"
        ref="pagination"
      ></v-pagination>
      <button
        type="button"
        :class="{
          'v-pagination__navigation': true,
          'v-pagination__navigation--disabled': currentPageInput == totalPages,
        }"
        @click="currentPageInput = totalPages"
      >
        <v-icon v-text="icons.mdiSkipNext"></v-icon>
      </button>
      <section class="d-flex align-center">
        <v-dropdownlist
          :data-items="listPageSize"
          v-model="itemPerPage"
          hide-details
          height="30px"
          width="70px"
          class="mx-1"
          v-if="!hidePageSize"
        >
          <template v-slot:append>
            <slot name="append">
              <v-icon-svg
                class="mb-1"
                small
                :path="icons.customDown"
              ></v-icon-svg>
            </slot>
          </template>
        </v-dropdownlist>
        <span v-if="!hidePageSize">{{ message }}</span>
      </section>
    </div>
    <v-spacer v-if="!hideInfo || !hideRefresh"></v-spacer>
    <section class="d-flex align-center">
      <span v-if="!hideInfo">{{ itemRangeOfCurrentPage }}</span>
      <v-btn
        icon
        small
        @click="$emit('click:refresh')"
        color="primary"
        v-if="!hideRefresh"
      >
        <v-icon v-text="icons.mdiRefresh"></v-icon>
      </v-btn>
    </section>
  </div>
</template>

<script>
import {
  mdiMenuRight,
  mdiMenuLeft,
  mdiSkipPrevious,
  mdiSkipNext,
  mdiChevronDown,
  mdiRefresh,
} from '@mdi/js';
import { customDown } from '../plugins/icons';
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    total: {
      type: Number,
      default: 0,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
      default: 20,
    },
    value: {
      type: Number,
      required: true,
      default: 1,
    },
    message: {
      type: String,
      default: 'items/page',
    },
    listPageSize: {
      type: Array,
      default: () => [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '200', value: 200 },
      ],
      required: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    hidePageSize: {
      type: Boolean,
      default: false,
    },
    hideRefresh: {
      type: Boolean,
      default: false,
    },
    hideInfo: {
      type: Boolean,
      default: false,
    },
    centering: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPageInput: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    totalPages() {
      return this.perPage == 0 ? 1 : Math.ceil(this.total / this.perPage) || 1;
    },
    itemPerPage: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit('update:perPage', val);
      },
    },
    itemRangeOfCurrentPage() {
      let numberItemStart =
        this.perPage * (this.value - 1) + (this.total == 0 ? 0 : 1);
      let numberItemEnd = Math.min(
        (this.perPage == 0 ? this.total : this.perPage) * this.value,
        this.total,
      );
      return `${numberItemStart}-${numberItemEnd}/${this.total} items`;
    },
    totalVisible() {
      return this.maxVisibleButtons > this.totalPages
        ? this.totalPages
        : this.maxVisibleButtons;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.pagination)
        this.$refs.pagination.maxButtons = this.totalVisible;
    });
  },
  methods: {
    onItemPerPageChange(value) {
      this.$emit('change:page-size', value);
      this.$nextTick(() => {
        if (this.$refs.pagination)
          this.$refs.pagination.maxButtons = this.totalVisible;
      });
    },
  },
  watch: {
    total() {
      this.$nextTick(() => {
        if (this.$refs.pagination)
          this.$refs.pagination.maxButtons = this.totalVisible;
      });
    },
    perPage() {
      this.$nextTick(() => {
        if (this.$refs.pagination)
          this.$refs.pagination.maxButtons = this.totalVisible;
      });
    },
  },
  data() {
    return {
      icons: {
        mdiMenuRight,
        mdiMenuLeft,
        mdiSkipPrevious,
        mdiSkipNext,
        mdiChevronDown,
        mdiRefresh,
        customDown,
      },
    };
  },
};
</script>

<style lang="scss">
.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &--center {
    justify-content: center;
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 1185px) {
      justify-content: unset;
    }
  }
  .v-pagination {
    width: auto;
    &__item,
    &__navigation {
      box-shadow: unset !important;
      border: thin solid rgba(0, 0, 0, 0.38);
      // background: white;
      // color: var(--color-primary);
    }
    &__item:focus,
    &__navigation:focus {
      outline: unset;
      border: solid 1px var(--color-primary);
      border-radius: 2px;
    }
    // &__item:hover,
    // &__navigation:hover {
    //   outline: unset;
    //   border: unset;
    //   background: var(--color-secondary1);
    //   color: white;
    // }
  }
  .v-text-field {
    width: 90px;
    height: 30px;
  }
  .v-input .v-input__slot {
    min-height: 30px !important;
    height: 30px;
  }
  .v-input .v-input__append-inner {
    margin-top: 3px !important;
  }
  .v-dropdownlist {
    min-width: 80px;
  }
}
</style>
