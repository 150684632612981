<template>
  <v-container class="fill-height secondary2" fluid justify-center>
    <v-card class="elevation-6 pa-50px" max-width="440" width="100%">
      <v-flex class="d-flex flex-column align-center">
        <v-img :src="logoImage"></v-img>
        <p
          v-if="!isTokenFailed"
          class="text-center font-size-large font-weight-bold mt-5 mb-2"
        >
          {{
            !!resetSuccess
              ? 'Reset Password Confirmation'
              : 'Welcome to Dr.Berg CRM!'
          }}
        </p>
        <p
          v-if="isTokenFailed"
          class="text-center font-size-large font-weight-bold mt-5 mb-2"
        >
          Reset Password Link Expired
        </p>
      </v-flex>
      <v-card-text class="pa-0">
        <ValidationObserver ref="observer" v-if="!isTokenFailed">
          <div v-if="!!resetSuccess">
            <p class="text-center mb-2">
              <strong>Your password has been reset.</strong>
            </p>
            <v-btn
              elevation="0"
              width="100%"
              class="mb-0 mt-2 text-center"
              color="secondary1"
            >
              <router-link to="/login" class="font-weight-bold primary4--text"
                >RETURN TO LOGIN</router-link
              >
            </v-btn>
          </div>
          <v-form v-else ref="form" @submit.prevent="formSubmit">
            <label for="email" class="primary--text font-weight-bold subtitle-1"
              >Email</label
            >
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors }"
              name="Email"
            >
              <v-text-field
                name="email"
                type="text"
                :error-messages="errors"
                v-model="emailTemp"
                dense
                outlined
                readonly
              />
            </ValidationProvider>
            <label
              for="password"
              class="primary--text font-weight-bold subtitle-1"
              >New Password</label
            >
            <ValidationProvider
              rules="required|passwordRegex"
              vid="password"
              v-slot="{ errors }"
              name="NewPassword"
            >
              <v-text-field
                name="password"
                type="password"
                :error-messages="errors"
                v-model="password"
                dense
                outlined
              >
                <template v-slot:message>
                  <span v-html="errors[0]"></span>
                </template>
              </v-text-field>
            </ValidationProvider>
            <label
              for="confirmPassword"
              class="primary--text font-weight-bold subtitle-1"
              >Confirm Pasword</label
            >
            <ValidationProvider
              rules="required|password:@password"
              v-slot="{ errors }"
              name="ConfirmPassword"
            >
              <v-text-field
                name="confirmPassword"
                type="password"
                :error-messages="errors"
                v-model="confirmPassword"
                dense
                outlined
              >
                <template v-slot:message>
                  <span v-html="errors[0]"></span>
                </template>
              </v-text-field>
            </ValidationProvider>
            <v-btn
              type="submit"
              color="secondary1"
              depressed
              block
              class="font-weight-bold white--text mt-1"
              >RESET YOUR PASSWORD</v-btn
            >
          </v-form>
        </ValidationObserver>
        <p
          v-if="isTokenFailed"
          class="primary--text text-center font-weight-bold"
        >
          Please make new request
        </p>
        <v-btn
          depressed
          color="secondary1"
          to="/forgot"
          class="font-weight-bold white--text"
          block
          v-if="isTokenFailed"
          >RESET PASSWORD REQUEST</v-btn
        >
      </v-card-text>

      <v-overlay v-model="hasLoading" absolute color="white">
        <!-- <v-progress-circular indeterminate color="white"></v-progress-circular> -->
        <LottiePlayer
          style="width: 80px; height: 80px"
          :animation-data="LoadingJson"
          :loop="true"
        ></LottiePlayer>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import logoImage from '../../assets/logo_login.png';
import api from '../../api/authenticate';
import { extend } from 'vee-validate';
import LoadingJson from '../../assets/lottieData/Loading/Loading_2.json';
import LottiePlayer from '../../components/_lottie-player';
export default {
  components: {
    LottiePlayer,
  },
  data() {
    return {
      emailTemp: '',
      password: '',
      confirmPassword: '',
      logoImage,
      resetSuccess: false,
      token: '',
      hasLoading: false,
      LoadingJson,
      isTokenFailed: false,
    };
  },
  props: {
    id: {
      type: String,
    },
  },
  created() {
    this.emailTemp = this.$route.query.email;
    this.token = this.$route.query.token;
    extend('password', {
      params: ['target'],
      validate(value, { target }) {
        return value === target;
      },
      message: 'The Password and confirmation password do not match.',
    });
    extend('passwordRegex', {
      validate: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{6,}/.test(
          value,
        ),
      message:
        'The Password must have minimum six characters, at least <br /> one uppercase letter, one lowercase letter, one number <br> and one special character',
    });
    this.validData();
  },
  methods: {
    formSubmit() {
      let self = this;
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.hasLoading = true;
          api
            .resetPassword(
              self.emailTemp,
              self.password,
              self.confirmPassword,
              self.token,
            )
            .then((res) => {
              if (res.data.isSuccess) {
                self.resetSuccess = true;
              } else {
                this.$notify.error(res.error || res.data.message);
              }
            })
            .catch(function (err) {
              self.$notify.error(err.message);
            })
            .finally(() => {
              this.hasLoading = false;
            });
        }
      });
    },
    validData() {
      let self = this;
      this.hasLoading = true;
      return this.$http
        .get('/user/ForgotPassword/check', {
          params: {
            email: self.emailTemp,
            token: self.token,
          },
        })
        .then((res) => {
          self.isTokenFailed = !res.data.isSuccess;
        })
        .catch(function () {
          self.isTokenFailed = true;
        })
        .finally(() => {
          this.hasLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.v-btn >>> .font-weight-bold {
  text-decoration: none !important;
}
</style>
