<template>
  <v-popup v-model="computedIsActive" contentClass="v-editor__image-popup">
    <template v-slot:title>Insert Image</template>
    <v-text-field
      placeholder="Enter image url..."
      dense
      outlined
      v-model.trim="url"
      color="primary"
      hide-details
      v-if="computedIsActive"
    ></v-text-field>
    <div class="d-flex align-center">
      <v-divider></v-divider>
      <span class="mx-2">OR</span>
      <v-divider></v-divider>
    </div>
    <v-file-input
      accept="image/*"
      truncate-length="102"
      placeholder="Import image..."
      dense
      outlined
      color="primary"
      :rules="rules"
      class="v-file-input"
      @change="fileInputOnChange"
      v-if="computedIsActive"
    ></v-file-input>
    <v-btn
      depressed
      color="secondary"
      class="mt-4 font-weight-bold"
      @click="insertOnClick"
      >INSERT</v-btn
    >
  </v-popup>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
  },
  computed: {
    computedIsActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      url: '',
      urlBase64: '',
      rules: [
        (value) =>
          !value || value.size < 1000000 || 'The image cannot exceed 1MB.',
      ],
    };
  },
  methods: {
    fileInputOnChange(val) {
      if (val) {
        let fileReader = new FileReader();
        let self = this;
        fileReader.onload = function (fileLoadedEvent) {
          self.urlBase64 = fileLoadedEvent.target.result;
          // console.log((Buffer.byteLength(self.urlBase64, 'utf8') / 1048576).toFixed(2));
        };
        fileReader.readAsDataURL(val);
      } else {
        this.urlBase64 = '';
      }
    },
    insertOnClick() {
      if (Buffer.byteLength(this.urlBase64, 'utf8') < 1048576) {
        this.$emit('insert', this.url || this.urlBase64);
        this.computedIsActive = false;
      }
    },
  },
  watch: {
    value(val) {
      if (!val) {
        Object.assign(this.$data, this.$options.data.call(this));
      }
    },
  },
};
</script>

<style></style>
