import axios from 'axios';
import { mapUrl } from '../';

const saveSettingOption = (data) => {
  let fd = new FormData();
  fd.append('googleAPIKey', data.googleApi);
  fd.append('isUsingSanbox', data.environmentValue);
  (data.file || []).forEach((c) => {
    fd.append('filePath', c);
  });
  fd.append('certPass', data.certPass);
  return axios.put(mapUrl('api/notification/saveNewSetting'), fd);
};

const getCurrentSetting = () => {
  return axios.get(mapUrl(`api/notification/getCurrentSetting`));
};

export default { saveSettingOption, getCurrentSetting };
