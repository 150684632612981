var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"pr-10px",staticStyle:{"max-height":"calc(100% - 80px)","overflow":"hidden auto"},attrs:{"tag":"div"}},[_c('v-form',[_c('v-row',{staticClass:"mb-1 mt-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-label',[_c('strong',[_vm._v("Title")])]),_c('ValidationProvider',{staticClass:"flex-grow-1 primary--text",attrs:{"rules":"required|max:200","name":"Title","customMessages":{
            max: 'The Title field cannot be greater than 200 characters',
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"py-0",attrs:{"outlined":"","dense":"","counter":"200","placeholder":("Enter title for " + (_vm.inforData.typeName.toLowerCase())),"error-messages":errors},model:{value:(_vm.objectData.title),callback:function ($$v) {_vm.$set(_vm.objectData, "title", $$v)},expression:"objectData.title"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-0 mb-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-label',[_c('strong',[_vm._v("Coupon Link")])]),_c('ValidationProvider',{staticClass:"flex-grow-1 primary--text",attrs:{"rules":"required","name":"Coupon Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"py-0",attrs:{"outlined":"","hide-details":"","dense":"","error-messages":errors},model:{value:(_vm.objectData.couponLink),callback:function ($$v) {_vm.$set(_vm.objectData, "couponLink", $$v)},expression:"objectData.couponLink"}})]}}])})],1)],1),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-label',[_c('strong',[_vm._v("Notes")])]),_c('ValidationProvider',{staticClass:"flex-grow-1 primary--text",attrs:{"rules":"max:400","name":"Notes","customMessages":{
            max: 'The Notes field cannot be greater than 400 characters',
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-textarea',{staticClass:"py-0",attrs:{"outlined":"","dense":"","counter":"400","error-messages":errors},model:{value:(_vm.objectData.description),callback:function ($$v) {_vm.$set(_vm.objectData, "description", $$v)},expression:"objectData.description"}})]}}])})],1)],1),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0"},[_c('ValidationProvider',{attrs:{"rules":"tagsMax","name":"Tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b',[_vm._v("Tags")]),_c('v-multiple-select',{attrs:{"hide-selected":"","createable":"","displayValue":function (val) { return '#' + val; },"error-messages":errors,"messages":" "},on:{"input":_vm.onTagsInput,"update:search-input":function($event){_vm.tagsSearchInput = $event || ''}},scopedSlots:_vm._u([(!errors[0])?{key:"message",fn:function(){return [_c('div',{staticClass:"mb-0",class:{
                  'v-counter theme--light': true,
                  'error--text': _vm.computedTagsCounter > 500,
                }},[_vm._v(" "+_vm._s(_vm.computedTagsCounter)+" / 500 ")])]},proxy:true}:null],null,true),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})]}}])})],1)],1)],1),_c('v-overlay',{attrs:{"color":"white","absolute":"","opacity":".5"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-loading-spinner')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }