<template>
  <v-dialog
    v-model="isShow"
    persistent
    :max-width="maxWidth"
    class="v-popup"
    content-class="v-popup__content"
    @click:outside="$emit('click:outside', $event)"
    overlay-color="primary"
    scrollable
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on"></slot>
    </template>
    <v-card>
      <div
        class="v-popup__title font-size-subtitle-2 px-30px font-weight-bold"
        v-if="$common.hasSlot('title')"
      >
        <slot name="title"></slot>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="primary"
          @click="closebuttonOnClick"
          small
          v-if="!hideClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </div>
      <span class="px-30px">
        <v-divider></v-divider>
      </span>
      <div :class="computedContentClass" @scroll.passive="handleScroll">
        <slot></slot>
      </div>
      <div
        v-if="$common.hasSlot('footer')"
        class="v-popup__footer mb-50px ml-30px mt-10px"
        :class="`${footerClass ? footerClass : ''}`"
      >
        <slot name="footer"></slot>
      </div>
      <v-overlay :value="loading" color="white" absolute opacity=".5">
        <v-loading-spinner></v-loading-spinner>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';
export default {
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    xxSmall: {
      type: Boolean,
      default: false,
    },
    xMedium: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    xxLarge: {
      type: Boolean,
      default: false,
    },
    xxxLarge: {
      type: Boolean,
      default: false,
    },
    xxxxLarge: {
      type: Boolean,
      default: false,
    },
    width660: {
      type: Boolean,
      default: false,
    },
    width760: {
      type: Boolean,
      default: false,
    },
    width730: {
      type: Boolean,
      default: false,
    },
    width1415: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: [Array, String],
      default: '',
    },
    footerClass: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    beforeClose: {
      default: undefined,
      type: Function,
    },
  },
  computed: {
    isShow: {
      get() {
        return typeof this.value === 'undefined' ? this.isDataShow : this.value;
      },
      set(val) {
        this.isDataShow = val;
        this.$emit('input', val);
      },
    },
    maxWidth() {
      if (this.large) return 1100;
      else if (this.xLarge) return 900;
      else if (this.small) return 600;
      else if (this.xxSmall) return 440;
      else if (this.xSmall) return 350;
      else if (this.xMedium) return 600;
      else if (this.xxxLarge) return 1560;
      else if (this.xxLarge) return 1400;
      else if (this.xxxxLarge) return 1200;
      else if (this.width760) return 760;
      else if (this.width730) return 730;
      else if (this.width1415) return 1415;
      else if (this.width660) return 660;
      else return 820;
    },
    computedContentClass() {
      let classes = [
        'v-popup__content',
        'overflow-y-auto',
        'overflow-x-hidden',
        'px-30px',
        'pb-50px',
        'pt-5',
      ];
      if (Array.isArray(this.contentClass)) {
        classes = classes.concat(this.contentClass);
      } else if (this.contentClass) {
        classes.push(this.contentClass);
      }
      return classes;
    },
  },
  data() {
    return {
      isDataShow: false,
      icons: {
        mdiClose,
      },
    };
  },
  methods: {
    closebuttonOnClick() {
      if (this.beforeClose) {
        this.beforeClose({
          next: () => {
            this.isShow = false;
          },
        });
      } else {
        this.isShow = false;
      }
    },
    handleScroll() {
      if (this.isShow) this.$emit('handleScroll');
    },
  },
};
</script>

<style lang="scss">
.v-popup {
  &__title {
    display: flex;
    height: 75px;
    min-height: 75px;
    align-items: center;
    color: var(--color-primary);
    text-transform: uppercase;
    z-index: 4;
  }
}
</style>
