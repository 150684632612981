<template>
  <ValidationObserver
    ref="observer"
    tag="div"
    style="max-height: calc(100% - 80px); overflow: hidden auto"
    class="pr-10px"
  >
    <v-form>
      <v-row class="mb-1 mt-0">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Title</strong>
          </v-label>
          <ValidationProvider
            rules="required|max:200"
            v-slot="{ errors }"
            name="Title"
            :customMessages="{
              max: 'The Title field cannot be greater than 200 characters',
            }"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              counter="200"
              dense
              v-model="objectData.title"
              :placeholder="`Enter title for ${inforData.typeName.toLowerCase()}`"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="mb-1 mt-0">
        <v-col
          v-for="(item, index) in objectData.listChapter"
          cols="12"
          class="py-0"
          :key="index"
        >
          <fieldset class="v-fieldset mb-2">
            <legend>CHAPTER {{ index + 1 }}</legend>
            <v-row class="my-0">
              <v-col cols="12" class="py-0" style="position: relative">
                <v-btn
                  v-if="objectData.listChapter.length > 1"
                  icon
                  small
                  style="position: absolute; right: 5px; top: -23px"
                  @click="objectData.listChapter.splice(index, 1)"
                >
                  <v-icon style="transform: translate(4px, 3px)">
                    {{ icons.customTrash }}
                  </v-icon>
                </v-btn>
                <v-label>
                  <strong>Description</strong>
                </v-label>
                <ValidationProvider
                  rules="max:400"
                  v-slot="{ errors }"
                  name="Description"
                  :customMessages="{
                    max: 'The Description field cannot be greater than 400 characters',
                  }"
                  class="flex-grow-1 primary--text"
                >
                  <v-textarea
                    class="py-0"
                    outlined
                    dense
                    v-model="item.description"
                    counter="400"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" class="py-0">
                <select-file
                  :file="item.attachments"
                  :fileRemove="item.deletedFiles"
                  labelName="Add Audio Book File"
                  :isMutiple="false"
                  :imagePreviewProps="item.imagePreview"
                  @change:file="addChapterFile(index, $event)"
                  @remove:file="removeChapterFile(index, $event)"
                  @hasDeletedPreview="item.imagePreview = ''"
                  validFiles="audio/*"
                />
                <p
                  v-if="!!item.isError"
                  class="error--text text-right mb-0 font-italic"
                  style="font-size: 12px; line-height: 14px"
                >
                  File is required
                </p>
              </v-col>
            </v-row>
          </fieldset>
        </v-col>
      </v-row>
      <v-btn text tile height="25" class="px-0 mb-3" @click="addChapter">
        <v-icon style="padding-bottom: 1px" class="mr-1" color="secondary1">
          {{ icons.mdiPlusThick }}
        </v-icon>
        Add New Chapter
      </v-btn>
      <v-row class="my-0">
        <v-col class="py-0">
          <ValidationProvider v-slot="{ errors }" rules="tagsMax" name="Tags">
            <b>Tags</b>
            <v-multiple-select
              v-model="tags"
              hide-selected
              createable
              @input="onTagsInput"
              :displayValue="(val) => '#' + val"
              @update:search-input="tagsSearchInput = $event || ''"
              :error-messages="errors"
              messages=" "
            >
              <template v-slot:message v-if="!errors[0]">
                <div
                  class="mb-0"
                  :class="{
                    'v-counter theme--light': true,
                    'error--text': computedTagsCounter > 500,
                  }"
                >
                  {{ computedTagsCounter }} / 500
                </div>
              </template>
            </v-multiple-select>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay v-model="loading" color="white" absolute opacity=".5">
      <v-loading-spinner></v-loading-spinner>
    </v-overlay>
  </ValidationObserver>
</template>

<script>
import { mdiPlusBox, mdiClose, mdiPlusThick } from '@mdi/js';
import { customTrash } from '../../../plugins/icons';
import SelectFile from './_select-files.vue';
import { extend } from 'vee-validate';
import api from '../../../api/resources';

export default {
  components: {
    SelectFile,
  },
  props: {
    featuredImageFile: {
      type: [Array, FileList],
    },
    inforData: {
      type: Object,
      default: () => {},
    },
    initData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    extend('tagsMax', {
      validate: (val) =>
        val.reduce((count, tag) => {
          count += tag.length;
          return count;
        }, 0) +
          (val.length - 1) <=
        500,
      message: 'The Tags field cannot be greater than 500 characters.',
    });
  },
  computed: {
    computedTagsCounter() {
      return (
        this.tags.reduce((count, tag) => {
          count += tag.length;
          return count;
        }, 0) +
        (this.tags.length - (this.tags.length ? 1 : 0)) +
        (this.tagsSearchInput.length +
          (this.tagsSearchInput.length && this.tags.length ? 1 : 0))
      );
    },
  },
  watch: {
    'inforData.status'() {
      this.objectData.status = this.inforData.status;
    },
    'inforData.typeName'() {
      this.objectData.type = this.inforData.typeName;
    },
    'inforData.category'() {
      this.objectData.category = this.inforData.category;
    },
    'inforData.featuredImageFile': {
      deep: true,
      handler() {
        this.objectData.featuredImageFile = [
          ...this.inforData.featuredImageFile,
        ];
      },
    },
    initData: {
      deep: true,
      handler() {
        this.setFormData();
      },
    },
  },
  data() {
    return {
      icons: { mdiPlusBox, mdiClose, mdiPlusThick, customTrash },
      loading: false,
      objectData: {
        status: 0,
        title: '',
        category: this.inforData.category,
        type: this.inforData.typeName,
        featuredImageFile: [],
        listChapter: [
          {
            id: null,
            imagePreview: '',
            attachments: [],
            deletedFiles: [],
            description: '',
          },
        ],
      },
      listCategory: [
        { text: 'Article', value: 0 },
        { text: 'Event', value: 1 },
      ],
      imagePreview: '',
      tags: [],
      tagsSearchInput: '',
      fileUrl: 'https://drbergappdev.blob.core.windows.net/resource-',
    };
  },
  methods: {
    checkFileRequired(file = null, filePaths = '') {
      if (this.initData.id) {
        if (!file || file.length == 0) {
          if (filePaths.length == 0) {
            return false;
          } else return true;
        } else {
          return true;
        }
      } else {
        if (!file || file.length == 0) {
          return false;
        } else return true;
      }
    },
    onTagsInput() {
      let lastChip = this.tags[this.tags.length - 1];
      if (lastChip != null && lastChip.trim() == '') {
        this.tags.splice(-1);
      }
    },
    setFormData() {
      if (this.initData.id) {
        this.objectData.title = this.initData.title;
        this.objectData.status = this.initData.status;
        this.tags = this.initData.listTags.filter((c) => c !== '');
        if (
          this.initData.resourceItems &&
          this.initData.resourceItems.length > 0
        ) {
          this.objectData.listChapter = this.initData.resourceItems.map((c) => {
            let item = {
              id: c.id,
              // imagePreview: `${this.fileUrl}${this.initData.id}/${c.filePaths}`,
              imagePreview: c.filePaths,
              attachments: [],
              deletedFiles: [],
              description: c.description,
            };
            return item;
          });
        }
      }
    },
    addChapter() {
      const item = [{ attachments: [], deletedFiles: [], description: '' }];
      this.objectData.listChapter = [...this.objectData.listChapter, ...item];
    },
    addChapterFile(index, file) {
      this.objectData.listChapter = this.objectData.listChapter.map((c, i) => {
        if (index === i) c.attachments = [...file];
        return c;
      });
      this.setErorrKey();
    },
    removeChapterFile(index, file) {
      this.objectData.listChapter = this.objectData.listChapter.map((c, i) => {
        if (index === i) {
          c.attachments = [...file.attachments];
          c.deletedFiles = [...file.deletedFiles];
          // if (c.filePaths && c.filePaths.split(',')[index]) {
          //   let item = c.filePaths.split(',');
          //   item.splice(index, 1);
          //   c.filePaths = item;
          // }
        }
        return c;
      });
      this.setErorrKey();
    },
    setErorrKey() {
      this.objectData.listChapter = this.objectData.listChapter.map((c) => {
        let item = {
          id: c.id,
          imagePreview: c.imagePreview,
          attachments: c.attachments,
          deletedFiles: c.deletedFiles,
          description: c.description,
          isError: !this.checkFileRequired(c.attachments, c.imagePreview),
        };
        return item;
      });
    },
    save() {
      this.$refs.observer.validate().then((valid) => {
        this.setErorrKey();
        const isValidFile = !this.objectData.listChapter.some(
          (c) => !this.checkFileRequired(c.attachments, c.imagePreview),
        );
        if (valid && isValidFile) {
          this.loading = true;
          const data = {
            title: this.objectData.title,
            tags: this.tags,
            type: this.inforData.type,
            featuredImageFile: this.objectData.featuredImageFile,
            status: this.inforData.statusValue,
            resourcesCategoryId: this.objectData.category,
            listChapter: this.objectData.listChapter,
            password: this.inforData.passWord,
          };
          api
            .createdResource(data, this.initData.id || null)
            .then((res) => {
              if (res.status === 200 && res.data.isSuccess) {
                this.$notify.success(res.data.message);
                this.$router.push({ path: '/resources' });
              } else {
                this.$notify.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$notify.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.v-editor >>> .v-input.v-textarea .v-input__control > .v-input__slot {
  min-height: 150px;
  max-height: 300px;
}
</style>
