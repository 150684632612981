import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import Resources from './resources';
import Moderaion from './moderation';
import Admin from './admin';
import Login from '../views/login/index.vue';
import Forgot from '../views/login/forgot.vue';
import Reset from '../views/login/reset.vue';
import UserProfile from '../views/profile';
import ErrorGroup from './error';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    _meta: {
      title: 'Login',
    },
    get meta() {
      return this._meta;
    },
    set meta(value) {
      this._meta = value;
    },
  },
  {
    name: 'forgot',
    path: '/forgot',
    _component: Forgot,
    get component() {
      return this._component;
    },
    set component(value) {
      this._component = value;
    },
    meta: {
      title: 'Forgot password',
    },
  },
  {
    name: 'reset',
    path: '/reset',
    component: Reset,
    meta: {
      title: 'Reset password',
    },
  },
  {
    name: 'userprofile',
    path: '/userprofile',
    component: UserProfile,
    meta: {
      title: 'My Profile',
      breadcrumbs: () => [{ text: 'My Profile', to: '/userprofile' }],
    },
  },
  ...Resources,
  ...Moderaion,
  ...Admin,
  ...ErrorGroup,
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  if (to.fullPath.substr(0, 2) === '/#') {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  const notAllowedIfLoginPages = ['login', 'forgot', 'reset'];
  const publicPages = ['404', ...notAllowedIfLoginPages];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('user-token');
  if (authRequired && !loggedIn) {
    return next('/login?callbackUrl=' + to.path);
  }
  if (notAllowedIfLoginPages.includes(to.name) && loggedIn) return next('/');
  next();
});
router.afterEach((to) => {
  document.title = `${to.meta.title || 'Home'} - Dr Berg App`;
});
export default router;
