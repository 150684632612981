<template>
  <ValidationObserver
    ref="observer"
    tag="div"
    class="pr-10px"
    style="max-height: calc(100% - 80px); overflow: hidden auto"
  >
    <v-form>
      <v-row class="mb-1 mt-0">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Title</strong>
          </v-label>
          <ValidationProvider
            rules="required|max:200"
            v-slot="{ errors }"
            name="Title"
            :customMessages="{
              max: 'The Title field cannot be greater than 200 characters',
            }"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              dense
              counter="200"
              v-model="objectData.title"
              :placeholder="`Enter title for ${inforData.typeName.toLowerCase()}`"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Coupon Link</strong>
          </v-label>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="Coupon Link"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              hide-details
              dense
              v-model="objectData.couponLink"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Notes</strong>
          </v-label>
          <ValidationProvider
            rules="max:400"
            v-slot="{ errors }"
            name="Notes"
            :customMessages="{
              max: 'The Notes field cannot be greater than 400 characters',
            }"
            class="flex-grow-1 primary--text"
          >
            <v-textarea
              class="py-0"
              outlined
              dense
              v-model="objectData.description"
              counter="400"
              :error-messages="errors"
            ></v-textarea>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col class="py-0">
          <ValidationProvider v-slot="{ errors }" rules="tagsMax" name="Tags">
            <b>Tags</b>
            <v-multiple-select
              v-model="tags"
              hide-selected
              createable
              @input="onTagsInput"
              :displayValue="(val) => '#' + val"
              @update:search-input="tagsSearchInput = $event || ''"
              :error-messages="errors"
              messages=" "
            >
              <template v-slot:message v-if="!errors[0]">
                <div
                  class="mb-0"
                  :class="{
                    'v-counter theme--light': true,
                    'error--text': computedTagsCounter > 500,
                  }"
                >
                  {{ computedTagsCounter }} / 500
                </div>
              </template>
            </v-multiple-select>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay v-model="loading" color="white" absolute opacity=".5">
      <v-loading-spinner></v-loading-spinner>
    </v-overlay>
  </ValidationObserver>
</template>

<script>
import { mdiPlusBox, mdiClose } from '@mdi/js';
import { extend } from 'vee-validate';
import api from '../../../api/resources';
export default {
  props: {
    inforData: {
      type: Object,
      default: () => {},
    },
    initData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    extend('tagsMax', {
      validate: (val) =>
        val.reduce((count, tag) => {
          count += tag.length;
          return count;
        }, 0) +
          (val.length - 1) <=
        500,
      message: 'The Tags field cannot be greater than 500 characters.',
    });
  },
  computed: {
    computedTagsCounter() {
      return (
        this.tags.reduce((count, tag) => {
          count += tag.length;
          return count;
        }, 0) +
        (this.tags.length - (this.tags.length ? 1 : 0)) +
        (this.tagsSearchInput.length +
          (this.tagsSearchInput.length && this.tags.length ? 1 : 0))
      );
    },
  },
  watch: {
    'inforData.status'() {
      this.objectData.status = this.inforData.status;
    },
    'inforData.typeName'() {
      this.objectData.type = this.inforData.typeName;
    },
    'inforData.category'() {
      this.objectData.category = this.inforData.category;
    },
    'inforData.featuredImageFile': {
      deep: true,
      handler() {
        this.objectData.featuredImageFile = [
          ...this.inforData.featuredImageFile,
        ];
      },
    },
    initData: {
      deep: true,
      handler() {
        this.setFormData();
      },
    },
  },
  data() {
    return {
      icons: { mdiPlusBox, mdiClose },
      loading: false,
      objectData: {
        status: 0,
        title: '',
        category: this.inforData.category,
        type: this.inforData.typeName,
        featuredImageFile: [],
        description: '',
        couponLink: '',
      },
      listCategory: [
        { text: 'Article', value: 0 },
        { text: 'Event', value: 1 },
      ],
      imagePreview: '',
      tags: [],
      tagsSearchInput: '',
    };
  },
  methods: {
    onTagsInput() {
      let lastChip = this.tags[this.tags.length - 1];
      if (lastChip != null && lastChip.trim() == '') {
        this.tags.splice(-1);
      }
    },
    setFormData() {
      if (this.initData.id) {
        this.objectData.title = this.initData.title;
        this.objectData.status = this.initData.status;
        this.objectData.description = this.initData.description;
        this.objectData.couponLink = this.initData.link;
        this.tags = this.initData.listTags.filter((c) => c !== '');
      }
    },
    save() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          const data = {
            title: this.objectData.title,
            description: this.objectData.description,
            link: this.objectData.couponLink,
            tags: this.tags,
            type: this.inforData.type,
            featuredImageFile: this.objectData.featuredImageFile,
            status: this.inforData.statusValue,
            resourcesCategoryId: this.objectData.category,
          };
          api
            .createdResource(data, this.initData.id || null)
            .then((res) => {
              if (res.status === 200 && res.data.isSuccess) {
                this.$notify.success(res.data.message);
                this.$router.push({ path: '/resources' });
              } else {
                this.$notify.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$notify.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.v-editor >>> .v-input.v-textarea .v-input__control > .v-input__slot {
  min-height: 150px;
  max-height: 300px;
}
</style>
