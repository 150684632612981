<template>
  <div>
    <label class="primary--text">
      <strong>
        <v-icon-svg
          :path="icons.customAttachment"
          width="13px"
          height="13px"
          transform="translate(-655.654 -288) scale(1.2)"
        ></v-icon-svg>
        {{ labelName }}</strong
      ></label
    >
    <p class="mb-1" v-if="attachments.length > 0">
      {{ attachments.length }} file attached
      <span v-if="totalSize != 0"
        >({{ (totalSize / 1048576).toFixed(2) }} MB)</span
      >
    </p>
    <div
      class="d-flex align-end py-1"
      v-if="
        imagePreview.length > 0 && attachments.length == 0 && isImagePreview
      "
    >
      <v-img
        max-height="80"
        min-height="80"
        min-width="80"
        max-width="80"
        class="mr-2"
        :src="imagePreview"
      ></v-img>
      <v-btn icon x-small @click="hasDeletedPreview">
        <v-icon class="error--text" x-small>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </div>
    <div
      class="d-flex align-end py-1 cursor-pointer"
      v-if="
        imagePreview.length > 0 && attachments.length == 0 && !isImagePreview
      "
    >
      <span @click="onDownloadDocument(imagePreview)">
        {{ getFileName(imagePreview) }}
      </span>
      <v-btn icon x-small @click="hasDeletedPreview">
        <v-icon class="error--text" x-small>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </div>
    <p
      class="pr-1 mb-0 text-decoration ml-5"
      v-for="(file, index) in attachments"
      :key="index"
    >
      <small>
        <strong>
          <template v-if="isImagePreview && !isMutiple">
            <v-img
              max-height="80"
              min-height="80"
              min-width="80"
              max-width="80"
              :src="imagePreview"
            ></v-img>
          </template>
          <template v-else>
            {{ file.name }}
          </template>
          <v-btn icon x-small @click="onRemoveFileClick(file, index)">
            <v-icon class="error--text" x-small>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </strong>
      </small>
    </p>
    <div class="d-flex align-baseline primary2">
      <input
        type="file"
        ref="file"
        style="display: none"
        :multiple="isMutiple"
        @change="attachmentFile($event)"
        :accept="validFiles"
      />
      <v-btn
        elevation="0"
        class="ma-2"
        height="30"
        color="primary"
        @click="$refs.file.click()"
      >
        <strong>SELECT FILE {{ isMutiple ? '(S)' : '' }}</strong>
      </v-btn>
      <p class="primary--text">* Total maximum size: 20MB</p>
    </div>
    <div class="error--text font-weight-bold" style="font-size: 12px">
      {{ errorMsg }}
    </div>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js';
import { customAttachment } from '../../../plugins/icons';

export default {
  props: {
    validFiles: {
      type: String,
      default:
        '.jpg,.png,.doc,.docx,.pdf,.zip,.rar,.txt,.mp4,.mp3,.mov,.eml,.xlsx,.xls,.csv,.gif',
    },
    labelName: {
      type: String,
      default: '',
    },
    file: {
      type: Array,
    },
    fileRemove: {
      type: Array,
    },
    isImagePreview: {
      type: Boolean,
      default: false,
    },
    imagePreviewProps: {
      type: String,
    },
    isMutiple: {
      type: Boolean,
      default: true,
    },
    hasDownloadFile: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    file: {
      deep: true,
      handler() {
        this.attachments = [...this.file];
      },
    },
    fileRemove: {
      deep: true,
      handler() {
        this.deletedFiles = [...this.fileRemove];
      },
    },
    imagePreviewProps() {
      if (!this.isMutiple && this.imagePreviewProps) {
        this.imagePreview = this.imagePreviewProps;
      }
    },
  },
  mounted() {
    if (this.imagePreviewProps) {
      this.imagePreview = this.imagePreviewProps;
    }
  },
  data() {
    return {
      icons: { mdiClose, customAttachment },
      attachments: [],
      totalSize: 0,
      deletedFiles: [],
      errorMsg: '',
      imagePreview: '',
    };
  },
  methods: {
    getFileName(item) {
      if (item && item.length > 0) {
        const lastPath = item.lastIndexOf('/');
        if (lastPath !== -1)
          return item.substr(lastPath + 1, item.length).trim();
        // return this.modifyPath(item.substr(lastPath + 1, item.length));
        // else return this.modifyPath(item);
        else return item.trim();
      } else return '';
    },
    // modifyPath(filePath) {
    //   const name = filePath.substr(
    //     filePath.lastIndexOf('.') - 2,
    //     filePath.length,
    //   );
    //   if (filePath.length > 30)
    //     return filePath
    //       .trim()
    //       .substring(0, 22)
    //       .concat('...' + name);
    //   else return filePath.trim();
    // },
    onDownloadDocument(url) {
      if (this.hasDownloadFile) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    hasDeletedPreview() {
      this.imagePreview = '';
      this.$emit('hasDeletedPreview');
    },
    attachmentFile(event) {
      let totalSizeCurrent = 0;
      [...this.attachments].forEach((Element) => {
        return (totalSizeCurrent += Element.size || 0);
      });
      let totalSizeUpload = 0;
      (event.target.files
        ? [...event.target.files]
        : [...event.dataTransfer.files]
      ).forEach((Element) => {
        return (totalSizeUpload += Element.size || 0);
      });
      if (totalSizeCurrent < 20971520 - totalSizeUpload) {
        if (this.isMutiple) {
          event.target.files
            ? this.attachments.push(...event.target.files)
            : this.attachments.push(...event.dataTransfer.files);
        } else {
          event.target.files
            ? (this.attachments = [...event.target.files])
            : (this.attachments = [...event.dataTransfer.files]);
        }
        this.totalSize = 0;
        this.attachments.forEach((e) => {
          return (this.totalSize += e.size || 0);
        });
        this.onChangePreview(event);
        this.$emit('change:file', this.attachments);
        this.errorMsg = '';
      } else {
        // this.$notify.error(`Maximum upload file size: 20MB. Please recheck`)
        this.errorMsg = 'Maximum upload file size: 20MB. Please recheck';
        return false;
      }
    },
    onRemoveFileClick(file, index) {
      this.attachments.splice(index, 1);
      this.$refs.file.value = null;
      if (file.id) {
        this.deletedFiles.push(file.id);
      }
      this.totalSize = 0;
      this.attachments.forEach((e) => {
        return (this.totalSize += e.size || 0);
      });
      this.imagePreview = '';
      this.$emit('remove:file', {
        attachments: this.attachments,
        deletedFiles: this.deletedFiles,
      });
    },
    onChangePreview(event) {
      if (this.isImagePreview && !this.isMutiple) {
        if (event.target.files && event.target.files[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    },
  },
};
</script>

<style></style>
