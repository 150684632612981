import { Node } from 'tiptap';
export const MEDIA_REGEX_MATCH = [
  {
    name: 'dailymotion',
    regex: /^([(http(s)?)://(www.)]?)+(dailymotion\.com\/video\/(\w+))/,
    url(url) {
      let match = this.regex.exec(url);
      const id = match[3];
      return `https://www.dailymotion.com/embed/video/${id}`;
    },
    match(url) {
      return this.regex.test(url);
    },
  },
  {
    name: 'youtube',
    regex: [
      /^([(http(s)?)://(www.)]?)+((?:m\.)?youtube\.com\/watch\?v=([\w-]+))/,
      /^([(http(s)?)://(www.)]?)+((?:m\.)?youtube\.com\/v\/([\w-]+))/,
      /^([(http(s)?)://(www.)]?)+(youtube\.com\/embed\/([\w-]+))/,
      /^([(http(s)?)://(www.)]?)+(youtu\.be\/([\w-]+))/,
    ],
    url(url) {
      let match = this.regex.find((c) => c.test(url)).exec(url);
      const id = match[3];
      return `https://www.youtube.com/embed/${id}`;
    },
    match(url) {
      return this.regex.some((c) => c.test(url));
    },
  },
  {
    name: 'vimeo',
    regex: [
      /^([(http(s)?)://(www.)]?)+(vimeo\.com\/(\d+))/,
      /^([(http(s)?)://(www.)]?)+(vimeo\.com\/[^/]+\/[^/]+\/video\/(\d+))/,
      /^([(http(s)?)://(www.)]?)+(vimeo\.com\/album\/[^/]+\/video\/(\d+))/,
      /^([(http(s)?)://(www.)]?)+(vimeo\.com\/channels\/[^/]+\/(\d+))/,
      /^([(http(s)?)://(www.)]?)+(vimeo\.com\/groups\/[^/]+\/videos\/(\d+))/,
      /^([(http(s)?)://(www.)]?)+(vimeo\.com\/ondemand\/[^/]+\/(\d+))/,
      /^([(http(s)?)://(www.)]?)+(player\.vimeo\.com\/video\/(\d+))/,
    ],
    url(url) {
      let match = this.regex.find((c) => c.test(url)).exec(url);
      const id = match[3];
      return `https://player.vimeo.com/video/${id}`;
    },
    match(url) {
      return this.regex.some((c) => c.test(url));
    },
  },
  {
    name: 'screencast',
    regex: [
      /^([(http(s)?)://(www.)]?)+(screencast\.com\/t\/+([\w-]+))/,
      /^([(http(s)?)://(www.)]?)+(screencast\.com\/([\w-\\/%20]+(\/media\/)+[\w-]+))/,
    ],
    url(url) {
      let match = this.regex.find((c) => c.test(url)).exec(url);
      const id = match[3];
      return `https://www.screencast.com/${id}/embed`;
    },
    match(url) {
      if (this.regex[0].test(url))
        return 'Please enter long screencast url following format: http://www.screencast.com/*/media/*';
      return this.regex[1].test(url);
    },
  },
];
export default class Embed extends Node {
  get name() {
    return 'embed';
  }

  get schema() {
    return {
      attrs: {
        src: {
          default: null,
        },
      },
      group: 'block',
      selectable: true,
      draggable: true,
      parseDOM: [
        {
          tag: 'iframe',
          getAttrs: (dom) => ({
            src: dom.getAttribute('src'),
          }),
        },
      ],
      toDOM: (node) => [
        'iframe',
        {
          src: node.attrs.src,
          frameborder: 0,
          allowfullscreen: 'true',
        },
      ],
    };
  }

  // get view() {
  // 	return {
  // 		props: ["node", "updateAttrs", "view"],
  // 		computed: {
  // 			src: {
  // 				get() {
  // 					console.log(this.node.attrs);
  // 					return this.node.attrs.src;
  // 				},
  // 				set(src) {
  // 					this.updateAttrs({
  // 						src,
  // 					});
  // 				},
  // 			},
  // 		},
  // 		template: `
  // 			<div class="iframe">
  // 				<iframe class="iframe__embed"></iframe>
  // 			</div>
  // 		`,
  // 	};
  // }
  commands({ type }) {
    return (attrs) => (state, dispatch) => {
      if (attrs) {
        let src = (attrs || {}).src;
        let match = MEDIA_REGEX_MATCH.find((c) => c.match(src));

        if (match && typeof match.match(src) === 'boolean' && match.url(src)) {
          attrs.src = match.url(src);
          const { selection } = state;
          const position = selection.$cursor
            ? selection.$cursor.pos
            : selection.$to.pos;
          const node = type.create(attrs);
          const transaction = state.tr.insert(position, node);
          dispatch(transaction);
        }
      }
    };
  }
}
