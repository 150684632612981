<template>
  <ValidationObserver
    ref="observer"
    tag="div"
    class="pr-10px"
    style="max-height: calc(100% - 80px); overflow: hidden auto"
  >
    <v-form>
      <v-row class="mb-1 mt-0">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Title</strong>
          </v-label>
          <ValidationProvider
            rules="required|max:200"
            v-slot="{ errors }"
            name="Title"
            :customMessages="{
              max: 'The Title field cannot be greater than 200 characters',
            }"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              dense
              counter="200"
              v-model="objectData.title"
              :placeholder="`Enter title for ${inforData.typeName.toLowerCase()}`"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Description</strong>
          </v-label>
          <div>
            <v-editor
              v-model="objectData.description"
              outlined
              hide-details
              class="d-flex flex-column overflow-hidden"
            ></v-editor>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2">
        <v-col cols="12" class="py-0">
          <select-file
            :file="objectData.attachments"
            :fileRemove="objectData.deletedFiles"
            labelName="Add Banner Image"
            isImagePreview
            :isMutiple="false"
            validFiles="image/*"
            :imagePreviewProps="bannerImagePreview"
            @change:file="addFile"
            @remove:file="removeFile"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2 v-recipe-attr">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Attribute</strong>
          </v-label>
        </v-col>
        <v-col cols="3" class="py-0">
          <ValidationProvider
            v-slot="{ errors }"
            name="Calories"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              dense
              v-model="objectData.attribute.calories"
              height="36"
              hide-details
              :error-messages="errors"
            >
              <template v-slot:prepend-inner>
                <v-btn
                  class="inside-button border-r ma-0"
                  icon
                  tile
                  height="36"
                  width="71"
                >
                  Calories
                </v-btn>
              </template>
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="3" class="py-0">
          <ValidationProvider
            v-slot="{ errors }"
            name="Fat"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              hide-details
              dense
              height="36"
              v-model="objectData.attribute.fat"
              :error-messages="errors"
            >
              <template v-slot:prepend-inner>
                <v-btn
                  class="inside-button border-r ma-0"
                  icon
                  tile
                  height="36"
                  width="71"
                >
                  Fat (g)
                </v-btn>
              </template>
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="3" class="py-0">
          <ValidationProvider
            v-slot="{ errors }"
            name="Carb"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              height="36"
              hide-details
              dense
              v-model="objectData.attribute.carb"
              :error-messages="errors"
            >
              <template v-slot:prepend-inner>
                <v-btn
                  class="inside-button border-r ma-0"
                  icon
                  tile
                  height="36"
                  width="71"
                >
                  Carb (g)
                </v-btn>
              </template>
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="3" class="py-0">
          <ValidationProvider
            v-slot="{ errors }"
            name="Protein"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              dense
              hide-details
              height="36"
              v-model="objectData.attribute.protein"
              :error-messages="errors"
            >
              <template v-slot:prepend-inner>
                <v-btn
                  class="inside-button border-r ma-0"
                  icon
                  tile
                  height="36"
                  width="75"
                >
                  Protein (g)
                </v-btn>
              </template>
            </v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="6" class="py-0">
          <v-label>
            <strong>Video URL</strong>
          </v-label>
          <ValidationProvider
            v-slot="{ errors }"
            name="Video URL"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              dense
              v-model="objectData.videoURL"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="3" class="py-0">
          <v-label>
            <strong>Serve (person)</strong>
          </v-label>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="Serve (person)"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              dense
              v-model="objectData.serve"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="3" class="py-0">
          <v-label>
            <strong>Duration (min)</strong>
          </v-label>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="Duration (min)"
            class="flex-grow-1 primary--text"
          >
            <v-text-field
              class="py-0"
              outlined
              dense
              v-model="objectData.duration"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col class="py-0">
          <fieldset class="v-fieldset mb-2">
            <legend>INGREDIENTS</legend>
            <v-row class="my-0">
              <v-col cols="12" class="py-0">
                <v-label>
                  <strong>Description</strong>
                </v-label>
                <!-- <ValidationProvider
                  rules="max:400"
                  v-slot="{ errors }"
                  name="Description"
                  :customMessages="{
                    max: 'The Description field cannot be greater than 400 characters',
                  }"
                  class="flex-grow-1 primary--text"
                >
                  <v-textarea
                    class="py-0"
                    outlined
                    dense
                    v-model="objectData.ingredient.description"
                    counter="400"
                    :error-messages="errors"
                  ></v-textarea>
                  <p
                    class="error--text font-size-small d-flex justify-end"
                    v-if="!!errors[0]"
                  >
                    {{ errors[0] }}
                  </p>
                </ValidationProvider> -->
                <v-editor
                  v-model="objectData.ingredient.description"
                  outlined
                  hide-details
                  class="d-flex flex-column overflow-hidden mb-2"
                ></v-editor>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" class="py-0">
                <select-file
                  :file="objectData.ingredient.attachments"
                  :fileRemove="objectData.ingredient.deletedFiles"
                  labelName="Add Ingredient Image"
                  isImagePreview
                  :isMutiple="false"
                  :imagePreviewProps="objectData.ingredient.imagePreview"
                  validFiles="image/*"
                  @change:file="addIngredientFile"
                  @remove:file="removeIngredientFile"
                  @hasDeletedPreview="objectData.ingredient.imagePreview = ''"
                />
              </v-col>
            </v-row>
          </fieldset>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col
          v-for="(item, index) in objectData.listStep"
          cols="12"
          class="py-0"
          :key="index"
        >
          <fieldset class="v-fieldset mb-2">
            <legend>STEP {{ index + 1 }}</legend>
            <v-row class="my-0">
              <v-col cols="12" class="py-0" style="position: relative">
                <v-btn
                  v-if="objectData.listStep.length > 1"
                  icon
                  small
                  style="position: absolute; right: 5px; top: -23px"
                  @click="objectData.listStep.splice(index, 1)"
                >
                  <v-icon style="transform: translate(4px, 3px)">
                    {{ icons.customTrash }}
                  </v-icon>
                </v-btn>
                <v-label>
                  <strong>Description</strong>
                </v-label>
                <ValidationProvider
                  rules="required|max:400"
                  v-slot="{ errors }"
                  :name="`Description ${index}`"
                  :customMessages="{
                    required: 'The Description field is required',
                    max: 'The Description field cannot be greater than 400 characters',
                  }"
                  class="flex-grow-1 primary--text"
                >
                  <v-textarea
                    class="py-0"
                    outlined
                    dense
                    v-model="item.description"
                    counter="400"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" class="py-0">
                <select-file
                  :file="item.attachments"
                  :fileRemove="item.deletedFiles"
                  labelName="Add Step Image"
                  isImagePreview
                  :imagePreviewProps="item.imagePreview"
                  :isMutiple="false"
                  validFiles="image/*"
                  @change:file="addStepFile(index, $event)"
                  @remove:file="removeStepFile(index, $event)"
                  @hasDeletedPreview="item.imagePreview = ''"
                />
              </v-col>
            </v-row>
          </fieldset>
        </v-col>
      </v-row>
      <v-btn text tile height="25" class="px-0 mb-3" @click="addStep">
        <v-icon style="padding-bottom: 1px" class="mr-1" color="secondary1">
          {{ icons.mdiPlusThick }}
        </v-icon>
        Add New Step
      </v-btn>
      <v-row class="my-0">
        <v-col class="py-0">
          <ValidationProvider v-slot="{ errors }" rules="tagsMax" name="Tags">
            <b>Tags</b>
            <v-multiple-select
              v-model="tags"
              hide-selected
              createable
              @input="onTagsInput"
              :displayValue="(val) => '#' + val"
              @update:search-input="tagsSearchInput = $event || ''"
              :error-messages="errors"
              messages=" "
            >
              <template v-slot:message v-if="!errors[0]">
                <div
                  class="mb-0"
                  :class="{
                    'v-counter theme--light': true,
                    'error--text': computedTagsCounter > 500,
                  }"
                >
                  {{ computedTagsCounter }} / 500
                </div>
              </template>
            </v-multiple-select>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay v-model="loading" color="white" absolute opacity=".5">
      <v-loading-spinner></v-loading-spinner>
    </v-overlay>
  </ValidationObserver>
</template>

<script>
import { mdiPlusBox, mdiClose, mdiPaperclip, mdiPlusThick } from '@mdi/js';
import { customTrash } from '../../../plugins/icons';
import SelectFile from './_select-files.vue';
import { extend } from 'vee-validate';
import api from '../../../api/resources';

export default {
  components: {
    SelectFile,
  },
  props: {
    inforData: {
      type: Object,
      default: () => {},
    },
    initData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    extend('tagsMax', {
      validate: (val) =>
        val.reduce((count, tag) => {
          count += tag.length;
          return count;
        }, 0) +
          (val.length - 1) <=
        500,
      message: 'The Tags field cannot be greater than 500 characters.',
    });
  },
  computed: {
    computedTagsCounter() {
      return (
        this.tags.reduce((count, tag) => {
          count += tag.length;
          return count;
        }, 0) +
        (this.tags.length - (this.tags.length ? 1 : 0)) +
        (this.tagsSearchInput.length +
          (this.tagsSearchInput.length && this.tags.length ? 1 : 0))
      );
    },
  },
  watch: {
    'inforData.status'() {
      this.objectData.status = this.inforData.status;
    },
    'inforData.typeName'() {
      this.objectData.type = this.inforData.typeName;
    },
    'inforData.category'() {
      this.objectData.category = this.inforData.category;
    },
    'inforData.featuredImageFile': {
      deep: true,
      handler() {
        this.objectData.featuredImageFile = [
          ...this.inforData.featuredImageFile,
        ];
      },
    },
    initData: {
      deep: true,
      handler() {
        this.setFormData();
      },
    },
  },
  data() {
    return {
      icons: { mdiPlusBox, mdiClose, mdiPaperclip, mdiPlusThick, customTrash },
      loading: false,
      objectData: {
        status: 0,
        title: '',
        category: this.inforData.category,
        type: this.inforData.typeName,
        featuredImageFile: [],
        description: '',
        couponLink: '',
        note: '',
        attachments: [],
        deletedFiles: [],
        attribute: {
          calories: '',
          fat: '',
          carb: '',
          protein: '',
        },
        videoURL: '',
        serve: '',
        duration: '',
        ingredient: {
          id: null,
          imagePreview: '',
          attachments: [],
          deletedFiles: [],
          description: '',
        },
        listStep: [
          { id: null, attachments: [], deletedFiles: [], description: '' },
        ],
      },
      listCategory: [
        { text: 'Article', value: 0 },
        { text: 'Event', value: 1 },
      ],
      imagePreview: '',
      tags: [],
      tagsSearchInput: '',
      bannerImagePreview: '',
      fileUrl: 'https://drbergappdev.blob.core.windows.net/resource-',
    };
  },
  methods: {
    checkFileRequired(file = null, filePaths = '') {
      if (this.initData.id) {
        if (!file || file.length == 0) {
          if (filePaths.length == 0) {
            return false;
          } else return true;
        } else {
          return true;
        }
      } else {
        if (!file || file.length == 0) {
          return false;
        } else return true;
      }
    },
    onTagsInput() {
      let lastChip = this.tags[this.tags.length - 1];
      if (lastChip != null && lastChip.trim() == '') {
        this.tags.splice(-1);
      }
    },
    setFormData() {
      if (this.initData.id) {
        this.objectData.title = this.initData.title;
        this.objectData.status = this.initData.status;
        this.objectData.description = this.initData.description;
        this.objectData.attribute.calories = this.initData.calories || '';
        this.objectData.attribute.fat = this.initData.fat || '';
        this.objectData.attribute.carb = this.initData.carb || '';
        this.objectData.attribute.protein = this.initData.protein || '';
        this.objectData.videoURL = this.initData.videoURL || '';
        this.objectData.serve = this.initData.serve || '';
        this.objectData.duration = this.initData.duration || '';
        this.bannerImagePreview = this.initData.bannerImage;
        this.tags = this.initData.listTags.filter((c) => c !== '');
        if (
          this.initData.resourceItems &&
          this.initData.resourceItems.length > 0
        ) {
          let listItem = [...this.initData.resourceItems];
          let ingredientItem = listItem.shift();
          this.objectData.ingredient.description = ingredientItem.description;
          this.objectData.ingredient.id = ingredientItem.id;
          // this.objectData.ingredient.imagePreview = `${this.fileUrl}${this.initData.id}/${ingredientItem.filePaths}`;
          this.objectData.ingredient.imagePreview = ingredientItem.filePaths;
          // this.objectData.ingredient.attachments = ingredientItem.filePaths;
          // let listItem = [...this.initData.resourceItems];
          // listItem.shift();
          this.objectData.listStep = listItem.map((c) => {
            let item = {
              id: c.id,
              // imagePreview: `${this.fileUrl}${this.initData.id}/${c.filePaths}`,
              imagePreview: c.filePaths,
              attachments: [],
              deletedFiles: [],
              description: c.description,
            };
            return item;
          });
        }
      }
    },
    addStep() {
      const item = [{ attachments: [], deletedFiles: [], description: '' }];
      this.objectData.listStep = [...this.objectData.listStep, ...item];
    },
    addStepFile(index, file) {
      this.objectData.listStep = this.objectData.listStep.map((c, i) => {
        if (index === i) c.attachments = [...file];
        return c;
      });
    },
    removeStepFile(index, file) {
      this.objectData.listStep = this.objectData.listStep.map((c, i) => {
        if (index === i) {
          c.attachments = [...file.attachments];
          c.deletedFiles = [...file.deletedFiles];
          // if (c.filePaths && c.filePaths.split(',')[index]) {
          //   let item = c.filePaths.split(',');
          //   item.splice(index, 1);
          //   c.filePaths = item;
          // }
        }
        return c;
      });
    },
    addIngredientFile(file) {
      this.objectData.ingredient.attachments = [...file];
    },
    removeIngredientFile(file) {
      this.objectData.ingredient.attachments = [...file.attachments];
      this.objectData.ingredient.deletedFiles = [...file.deletedFiles];
    },
    removeFile(file) {
      this.objectData.attachments = [...file.attachments];
      this.fileRemove = [...file.deletedFiles];
    },
    addFile(file) {
      this.objectData.attachments = [...file];
    },
    save() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          const data = {
            title: this.objectData.title,
            description: this.objectData.description,
            tags: this.tags,
            type: this.inforData.type,
            featuredImageFile: this.objectData.featuredImageFile,
            bannerImageFile: this.objectData.attachments,
            status: this.inforData.statusValue,
            resourcesCategoryId: this.objectData.category,
            ingredient: this.objectData.ingredient,
            listStep: this.objectData.listStep,
            attribute: this.objectData.attribute,
            videoURL: this.objectData.videoURL,
            serve: this.objectData.serve,
            duration: this.objectData.duration,
          };
          api
            .createdResource(data, this.initData.id || null)
            .then((res) => {
              if (res.status === 200 && res.data.isSuccess) {
                this.$notify.success(res.data.message);
                this.$router.push({ path: '/resources' });
              } else {
                this.$notify.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$notify.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.v-editor >>> .v-input.v-textarea .v-input__control > .v-input__slot {
  min-height: 150px;
  max-height: 300px;
}
.v-recipe-attr >>> .v-text-field .v-input__slot {
  padding-left: 0 !important;
}
.v-recipe-attr >>> .v-text-field .v-input__slot .v-input__prepend-inner {
  margin-top: 0 !important;
}
</style>
