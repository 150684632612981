import Dialog from './_VDialog';

function install(Vue, globalOptions) {
  let nextComp = null;
  let comp = null;
  function createComp(options) {
    const Component = new Vue(Dialog);
    Object.assign(Component, globalOptions, options);
    document.getElementById('app').appendChild(Component.$mount().$el);
    return Component;
  }
  Vue.prototype.$confirm = (title, options) => init(title, options);
  let defaults = {
    yesText: 'YES',
    noText: 'NO',
    warningMessage: '',
    warningType: 'warning',
    width: 400,
  };
  function init(title, options) {
    return new Promise((resolve, reject) => {
      try {
        if (comp) {
          nextComp = { title: title, options: options };
          comp.isShow = false;
        } else {
          let defaultOptions = Object.assign({}, defaults, options);
          defaultOptions = Object.assign({}, defaultOptions, {
            title: title,
          });
          comp = createComp(defaultOptions);
          comp.$on('click:yes', (inputValue) => {
            if (defaultOptions.isFromDeleteUser) {
              resolve({ isYesClick: true, returnData: inputValue });
            } else {
              resolve(true);
            }
          });
          comp.$on('click:no', () => {
            if (defaultOptions.showInput)
              resolve({
                isYesClick: false,
              });
            else resolve(false);
          });
          comp.$on('statusChange', (isActive, wasActive) => {
            if (wasActive && !isActive) {
              comp.$nextTick(() => {
                comp.$destroy();
                comp = null;
                if (nextComp) {
                  init(nextComp.title, nextComp.options);
                  nextComp = null;
                }
              });
            }
          });
        }
      } catch (ex) {
        reject(ex);
      }
    });
  }
}
export default {
  install,
};
