<template>
  <v-container fluid class="v-page-profile pa-0">
    <v-overlay :value="overlay">
      <v-img :src="avatarZoom" width="auto" @click="overlay = false" />
      <p style="float: right">Click image to close</p>
    </v-overlay>
    <v-card
      :style="cardHeight"
      flat
      style="overflow-y: auto"
      :loading="isLoading"
    >
      <avatar-user
        @showImage="overlay = true"
        @onFormReset="getUserById()"
        @update:imageUrl="getUserById()"
        :imageUrl.sync="userProfile.profileImage"
        :userProfile="userProfile"
        :profileId="userProfile.profileId"
        :shortName="shortName"
        :isLoading.sync="isLoading"
      ></avatar-user>
      <form-profile
        class="mt-50px pt-6 px-10px"
        @onFormReset="getUserById()"
        @hasChangeValue="onChangeData($event)"
        ref="formProfile"
        :userProfile.sync="userProfile"
        :isLoading.sync="isLoading"
      ></form-profile>
      <v-card-actions class="px-20px pt-0 mt-5">
        <v-btn
          depressed
          color="secondary1 white--text"
          class="mr-3"
          width="100px"
          @click="updateProfile()"
          :disabled="!hasChangeValue"
        >
          <strong>SAVE</strong>
        </v-btn>
        <v-btn color="error" depressed width="100px" @click="cancel()">
          <strong>CANCEL</strong>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { customEdit } from '../../plugins/icons';
import apiAuth from '../../api/authenticate';
import avatarUser from './_avatar';
import formProfile from './_form';
export default {
  components: {
    'avatar-user': avatarUser,
    'form-profile': formProfile,
  },
  computed: {
    cardHeight() {
      return {
        height:
          this.$vuetify.breakpoint.height -
          this.$vuetify.application.top -
          30 +
          'px',
      };
    },
  },
  data() {
    return {
      isLoading: false,
      overlay: false,
      hasChangeValue: false,
      validFiles: '.jpg,.png',
      icons: {
        customEdit,
      },

      userProfile: {
        id: '',
        profileId: 0,
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        profileImage: '',
        role: '',
      },
      avatarZoom: '',
      shortName: '',
      checkChangeValue: false,
    };
  },
  methods: {
    getUserById() {
      apiAuth
        .getUserById(this.$store.state.authenticate.user.profileId)
        .then((res) => {
          if (res.status === 200) {
            Object.assign(this.userProfile, res.data);
            this.shortName =
              this.userProfile.firstName.substring(0, 1) +
              this.userProfile.lastName.substring(0, 1);
            this.$nextTick(() => {
              this.hasChangeValue = false;
            });
          }
        })
        .finally(() => {
          this.avatarZoom = this.userProfile.profileImage.replace('_w64', '');
        });
    },
    updateProfile() {
      this.$refs.formProfile.save();
    },
    cancel() {
      this.$refs.formProfile.reset();
    },
    onChangeData(value) {
      this.hasChangeValue = value;
    },
  },
  created() {
    this.getUserById();
    if (!this.hasChangeValue) window.onbeforeunload = null;
  },
  watch: {
    hasChangeValue() {
      if (!this.hasChangeValue) {
        window.onbeforeunload = null;
      } else {
        window.onbeforeunload = function () {
          return 'Changes you made may not be saved.';
        };
      }
    },
  },
};
</script>
