<template>
  <ValidationObserver
    ref="observer"
    tag="div"
    class="pr-10px"
    style="max-height: calc(100% - 80px); overflow: hidden auto"
  >
    <v-form>
      <v-row class="mb-1 mt-0">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Title</strong>
          </v-label>
          <ValidationProvider
            rules="required|max:200"
            v-slot="{ errors }"
            name="Title"
            :customMessages="{
              max: 'The Title field cannot be greater than 200 characters',
            }"
            class="flex-grow-1 primary--text font-weight-regular"
          >
            <v-text-field
              class="py-0"
              outlined
              dense
              v-model="objectData.title"
              counter="200"
              :placeholder="`Enter title for ${inforData.typeName.toLowerCase()}`"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-label>
            <strong>Description</strong>
          </v-label>
          <div>
            <v-editor
              v-model="objectData.description"
              outlined
              hide-details
              class="d-flex flex-column overflow-hidden"
            ></v-editor>
          </div>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <select-file
            :file="objectData.attachments"
            :fileRemove="objectData.deletedFiles"
            :labelName="`Add ${inforData.typeName} File`"
            :isMutiple="false"
            :imagePreviewProps="imagePreview"
            @change:file="addFile"
            @remove:file="removeFile"
            @hasDeletedPreview="imagePreview = ''"
            :hasDownloadFile="inforData.typeName == 'Document'"
            :validFiles="
              inforData.typeName == 'Audio' ? 'audio/*' : '.doc, .docx, .pdf'
            "
          />
          <p
            v-if="!!objectData.isErrorFile"
            class="error--text text-right mb-0 font-italic"
            style="font-size: 12px; line-height: 14px"
          >
            File is required
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-0 mt-2">
        <v-col class="py-0">
          <ValidationProvider v-slot="{ errors }" rules="tagsMax" name="Tags">
            <b>Tags</b>
            <v-multiple-select
              v-model="tags"
              hide-selected
              createable
              @input="onTagsInput"
              :displayValue="(val) => '#' + val"
              @update:search-input="tagsSearchInput = $event || ''"
              :error-messages="errors"
              messages=" "
            >
              <template v-slot:message v-if="!errors[0]">
                <div
                  class="mb-0"
                  :class="{
                    'v-counter theme--light': true,
                    'error--text': computedTagsCounter > 500,
                  }"
                >
                  {{ computedTagsCounter }} / 500
                </div>
              </template>
            </v-multiple-select>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay v-model="loading" color="white" absolute opacity=".5">
      <v-loading-spinner></v-loading-spinner>
    </v-overlay>
  </ValidationObserver>
</template>

<script>
import { mdiPlusBox, mdiClose } from '@mdi/js';
import SelectFile from './_select-files.vue';
import { extend } from 'vee-validate';
import api from '../../../api/resources';

export default {
  components: {
    SelectFile,
  },
  props: {
    inforData: {
      type: Object,
      default: () => {},
    },
    initData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    extend('tagsMax', {
      validate: (val) =>
        val.reduce((count, tag) => {
          count += tag.length;
          return count;
        }, 0) +
          (val.length - 1) <=
        500,
      message: 'The Tags field cannot be greater than 500 characters.',
    });
    // this.getListCategory();
  },
  computed: {
    computedTagsCounter() {
      return (
        this.tags.reduce((count, tag) => {
          count += tag.length;
          return count;
        }, 0) +
        (this.tags.length - (this.tags.length ? 1 : 0)) +
        (this.tagsSearchInput.length +
          (this.tagsSearchInput.length && this.tags.length ? 1 : 0))
      );
    },
  },
  watch: {
    'inforData.status'() {
      this.objectData.status = this.inforData.status;
    },
    'inforData.typeName'() {
      this.objectData.type = this.inforData.typeName;
    },
    'inforData.category'() {
      this.objectData.category = this.inforData.category;
    },
    'inforData.featuredImageFile': {
      deep: true,
      handler() {
        this.objectData.featuredImageFile = [
          ...this.inforData.featuredImageFile,
        ];
      },
    },
    initData: {
      deep: true,
      handler() {
        this.setFormData();
      },
    },
  },
  data() {
    return {
      icons: { mdiPlusBox, mdiClose },
      loading: false,
      objectData: {
        status: 0,
        title: '',
        category: this.inforData.category,
        type: this.inforData.typeName,
        featuredImageFile: [],
        description: '',
        attachments: [],
        deletedFiles: [],
        isErrorFile: false,
      },
      listCategory: [
        { text: 'Article', value: 0 },
        { text: 'Event', value: 1 },
      ],
      imagePreview: '',
      tags: [],
      tagsSearchInput: '',
    };
  },
  methods: {
    checkFileRequired(file = null, filePaths = '') {
      if (this.initData.id) {
        if (!file || file.length == 0) {
          if (filePaths.length == 0) {
            return false;
          } else return true;
        } else {
          return true;
        }
      } else {
        if (!file || file.length == 0) {
          return false;
        } else return true;
      }
    },
    onTagsInput() {
      let lastChip = this.tags[this.tags.length - 1];
      if (lastChip != null && lastChip.trim() == '') {
        this.tags.splice(-1);
      }
    },
    setFormData() {
      if (this.initData.id) {
        this.objectData.title = this.initData.title;
        this.objectData.status = this.initData.status;
        this.objectData.description = this.initData.description;
        this.tags = this.initData.listTags.filter((c) => c !== '');
        this.imagePreview = this.initData.bannerImage;
        // if (
        //   this.initData.resourceItems &&
        //   this.initData.resourceItems.length > 0
        // ) {
        //   this.listChapter = this.initData.resourceItems.map((c) => {
        //     let item = {
        //       attachments: c.filePaths,
        //       deletedFiles: [],
        //       description: c.description,
        //     };
        //     return item;
        //   });
        // }
      }
    },
    removeFile(file) {
      this.objectData.attachments = [...file.attachments];
      this.fileRemove = [...file.deletedFiles];
      this.objectData.isErrorFile = !this.checkFileRequired(
        this.objectData.attachments,
        this.imagePreview,
      );
    },
    addFile(file) {
      this.objectData.attachments = [...file];
      this.objectData.isErrorFile = !this.checkFileRequired(
        this.objectData.attachments,
        this.imagePreview,
      );
    },
    save() {
      this.$refs.observer.validate().then((valid) => {
        this.objectData.isErrorFile = !this.checkFileRequired(
          this.objectData.attachments,
          this.imagePreview,
        );
        if (valid && !this.objectData.isErrorFile) {
          this.loading = true;
          const data = {
            title: this.objectData.title,
            description: this.objectData.description,
            bannerImageFile: this.objectData.attachments,
            tags: this.tags,
            type: this.inforData.type,
            featuredImageFile: this.objectData.featuredImageFile,
            status: this.inforData.statusValue,
            resourcesCategoryId: this.inforData.category,
          };
          api
            .createdResource(data, this.initData.id || null)
            .then((res) => {
              if (res.status === 200 && res.data.isSuccess) {
                this.$notify.success(res.data.message);
                this.$router.push({ path: '/resources' });
              } else {
                this.$notify.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$notify.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.v-editor >>> .v-input.v-textarea .v-input__control > .v-input__slot {
  min-height: 150px;
  max-height: 300px;
}
</style>
