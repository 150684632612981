<template>
  <v-menu
    v-model="datetimePickerShow"
    :close-on-content-click="false"
    offset-y
    max-width="290px"
    class="v-datepicker"
    content-class="v-datepicker__content"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        autocomplete="off"
        v-model="displayValue"
        dense
        color="primary"
        :readonly="readonly"
        v-on="on"
        :height="height"
        v-bind:class="inputClass"
        outlined
        v-bind:style="{ width: widthText, 'min-width': minWidthText }"
        :hide-details="hideDetails"
        @blur="displayDateTimeOnBlur"
        :error-messages="errorMessages"
        class="v-datepicker__input"
        :placeholder="placeholder"
      >
        <template v-slot:append>
          <v-btn
            class="primary2 v-datepicker__input__icon"
            icon
            tile
            :height="height"
            :width="height"
            v-on="on"
          >
            <v-icon-svg
              small
              :path="icons.calendarMonth"
              color="primary"
              class="primary--text"
            ></v-icon-svg>
            <!-- <v-icon>{{icons.mdiCalendarMonth}}</v-icon> -->
          </v-btn>
          <template v-if="$common.hasSlot('append')">
            <slot name="append"></slot>
          </template>
        </template>
        <template v-slot:append-outer v-if="$common.hasSlot('append-outer')">
          <slot name="append-outer"></slot>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :next-icon="icons.mdiMenuRight"
      :prev-icon="icons.mdiMenuLeft"
      v-model="computedValue"
      :width="isFormDatePicker ? '100%' : '230'"
      color="primary"
      no-title
      @input="datetimePickerShow = false"
      :min="disabledPastDate"
      :show-current="computedShowCurrentDate"
    >
      <template>
        <v-divider></v-divider>
        <span>{{ $moment(computedValue).format('ddd, MMM Do, YYYY') }}</span>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mdiCalendarMonth, mdiMenuLeft, mdiMenuRight } from '@mdi/js';
import { customReminder } from '../plugins/icons';
const ISO_FORMAT = 'YYYY-MM-DD';
export default {
  props: {
    showCurrentDate: {
      type: String,
    },
    disabledPastDate: {
      type: String,
      default: '',
    },
    isFormDatePicker: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Date, String],
    },
    format: {
      type: String,
      default: 'MM/DD/YYYY',
    },
    width: {
      type: [Number, String],
      default: '230px',
    },
    minWidth: {
      type: [Number, String],
      default: '230px',
    },
    height: {
      type: [Number, String],
      default: '36',
    },
    inputClass: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    isSetETime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedShowCurrentDate() {
      return this.$moment(
        typeof this.showCurrentDate == 'undefined'
          ? this.isSetETime
            ? this.getETTimeCompute
            : new Date()
          : this.showCurrentDate,
      ).format(ISO_FORMAT);
    },
    computedValue: {
      get() {
        return this.$moment(
          typeof this.value == 'undefined'
            ? this.dataValue
            : this.value ||
                (this.isSetETime ? this.getETTimeCompute : new Date()),
        ).format(ISO_FORMAT);
      },
      set(val) {
        if (val) {
          let date = this.$moment(val).toDate();
          this.dateTimeModel = this.$moment(date).format(this.format);
          this.$emit('input', this.dateTimeModel);
          this.dataValue = this.dateTimeModel;
        } else {
          this.$emit('input', null);
        }
      },
    },
    getETTimeCompute() {
      return this.$store.state.userProfile.dateTimeETNow;
    },
    displayValue: {
      get() {
        if (this.value == null) return '';
        return this.$moment(this.computedValue).format(this.format);
      },
      set(value) {
        this.dateTimeModel = value;
      },
    },
    widthText() {
      if (typeof this.width === 'string') return this.width;
      else return this.width + 'px';
    },
    minWidthText() {
      if (typeof this.minWidth === 'string') return this.minWidth;
      else return this.minWidth + 'px';
    },
  },
  data() {
    return {
      dataValue: new Date(),
      datetimePickerShow: false,
      dateTimeModel: '',
      icons: {
        mdiCalendarMonth,
        mdiMenuRight,
        mdiMenuLeft,
        calendarMonth: customReminder,
      },
    };
  },
  methods: {
    displayDateTimeOnBlur() {
      if (this.readonly) return;
      let date = new Date(this.dateTimeModel);
      if (this.dateTimeModel != this.displayValue) {
        this.computedValue = date == 'Invalid Date' ? null : this.dateTimeModel;
      }
    },
  },
  watch: {
    datetimePickerShow() {
      this.dateTimeModel = this.value;
    },
  },
};
</script>

<style lang="scss">
.v-datepicker {
  &__input {
    &__icon {
      border-width: 0 !important;
    }
    .v-input__slot {
      padding-right: 0 !important;
    }
    .v-input__append-inner {
      margin: 0 !important;
    }
  }
  &__content {
    .v-picker {
      width: 100%;
      .v-date-picker-table {
        height: max-content !important;
        padding: 0px 0px;
      }
      .theme--light.v-date-picker-table th {
        color: var(--color-primary) !important;
        font-size: 14px;
        padding: 3px 0;
      }
      td {
        padding: 1px;
      }
      table {
        border-spacing: 0 !important;
      }
      thead {
        background: var(--color-primary3);
      }
      tbody .v-btn {
        height: 26px;
        width: 26px;
      }
      tbody .v-btn__content {
        font-size: 14px;
      }
      .v-picker__actions {
        padding: 0;
        flex-direction: column;
        height: 40px;
        font-weight: bold;
        line-height: 39px;
        margin-top: 4px;
      }
      .v-divider {
        width: 100%;
      }
    }
  }
}
</style>
