<template>
  <v-popup large v-model="isShow">
    <template v-slot:title>MANAGE CATEGORIES</template>
    <div class="v-page-grid-full pa-0" style="height: 100%">
      <grid
        ref="grid"
        show-filter
        reorder-column
        resize-column
        fixed-header
        class="v-grid-spam-interaction v-grid-manage-category"
        :sort="{ sortFieldName: 'id', isAsc: false }"
        :columns="columns"
        :dataConfig="dataConfig"
        @click:row="onDetailClick"
      >
        <template v-slot:append-toolbar-menu>
          <v-btn
            color="secondary1"
            height="36"
            class="white--text ml-10px"
            @click="onAddNewCaterogy"
          >
            <strong>ADD NEW CATEGORY</strong>
          </v-btn>
        </template>
        <!-- <template v-slot:item.id="{ item }">
          <strong class="secondary--text">{{ item.id }}</strong>
        </template> -->
        <template v-slot:item.title="{ item }">
          <strong v-if="item.id == -1"
            ><em>{{ item.title }} (default)</em></strong
          >
          <span v-else>{{ item.title }}</span>
        </template>
        <template v-slot:item.typeName="{ item }">
          <strong v-if="item.id == -1">-</strong>
          <span v-else>{{ item.typeName }}</span>
        </template>
        <template v-slot:item.countPost="{ item }">
          <strong
            class="secondary1--text text-decoration-underline cursor-pointer"
            @click.stop="redirectResource(item)"
          >
            {{ item.countPost }}
          </strong>
        </template>
        <template v-slot:item.command="{ item }">
          <v-btn
            class="px-2 rounded"
            small
            color="info"
            v-if="![5, 6].includes(item.type)"
            elevation="0"
            @click.stop="onEditItem(item)"
          >
            <v-icon size="14" class="mr-1">{{ icons.customEdit }}</v-icon>
            Edit
          </v-btn>
          <v-btn
            class="ml-3 px-2 rounded"
            v-if="![5, 6].includes(item.type)"
            small
            color="error"
            elevation="0"
            @click.stop="onDelete(item)"
          >
            <v-icon size="14" class="mr-1 mb-2px">
              {{ icons.mdiCloseThick }}
            </v-icon>
            Delete
          </v-btn>
        </template>
      </grid>
      <add-edit-popup
        v-if="isShowPopup"
        :editId="editId"
        v-model="isShowPopup"
      />
      <category-detail
        @onEditDetail="editFromDetail"
        :initData="dataItem"
        v-model="isShowDetail"
        @filterCategory="redirectResource($event)"
      />
    </div>
  </v-popup>
</template>

<script>
import grid from '../../../components/grid';
import { customEdit } from '../../../plugins/icons';
import { mdiCloseThick } from '@mdi/js';
import CategoryDetail from './_detail-popup.vue';
import AddEditPopup from './_add-edit-popup.vue';
import api from '../../../api/category';

export default {
  components: {
    grid,
    CategoryDetail,
    AddEditPopup,
  },
  props: {
    value: {
      type: Boolean,
    },
    initData: {
      type: Object,
    },
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      isShowPopup: false,
      isShowDetail: false,
      dataItem: {},
      editId: null,
      icons: { customEdit, mdiCloseThick },
      columns: [
        { text: 'Id', value: 'id', width: 100, dataType: 'Number' },
        { text: 'Category Name', value: 'title' },
        {
          text: 'Type',
          value: 'typeName',
          ddlValues: [
            { text: 'All', value: null },
            { text: 'Audio', value: 'Audio' },
            { text: 'Audio book', value: 'Audio Book' },
            { text: 'Coupon', value: 'Coupon' },
            { text: 'Document', value: 'Document' },
            { text: 'Recipe', value: 'Recipe' },
            { text: 'Video', value: 'Video' },
          ],
          hideComparison: true,
        },
        {
          text: '#Post',
          value: 'countPost',
          hideFilter: true,
        },
        {
          text: 'Command',
          value: 'command',
          hideFilter: true,
          sortable: false,
          width: 250,
        },
      ],
      dataConfig: {
        url: 'api/resourceCategory',
        page: 1,
        pageSize: 50,
      },
    };
  },
  watch: {
    isShowDetail() {
      if (!this.isShowDetail) this.$refs.grid.itemFocusOut();
    },
    isShowPopup() {
      if (!this.isShowPopup) this.editId = null;
    },
  },
  methods: {
    redirectResource(item) {
      this.$emit('filterCategory', item);
      this.isShow = false;
      // this.$router.push({ name: 'resources.all' }).then(() => {
      //   this.$eventBus.$emit('resource-filter-category', item);
      // });
    },
    onDelete(item) {
      this.$confirm(
        `Are you sure you want to <span class='error--text'>DELETE</span> this category? <br/>
        <span class="secondary1--text font-weight-regular">${item.title}</span> <br/>
        <span class="font-weight-regular">Note: The items belong to this will be sent to <span class="secondary1--text">Unassigned</span> category</span>
        `,
        {
          // warningMessage: `This action cannot be undone`,
          width: 550,
        },
      ).then((yes) => {
        if (yes) {
          api
            .deleteCategory(item.id)
            .then((res) => {
              if (res.status === 200 && res.data.isSuccess) {
                this.$notify.success(res.data.message);
                this.$refs.grid.read();
              } else {
                this.$notify.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$notify.error(err);
            });
        }
      });
    },
    onDetailClick(dataItem, focusItem) {
      this.dataItem = dataItem;
      this.isShowDetail = true;
      focusItem(true);
    },
    onAddNewCaterogy() {
      this.isShowPopup = true;
    },
    onEditItem(item) {
      this.editId = item.id;
      this.isShowPopup = true;
    },
    editFromDetail($event) {
      this.editId = $event;
      this.isShowPopup = true;
    },
  },
};
</script>

<style>
.v-grid-manage-category .v-grid__toolbar > .d-flex {
  flex-direction: row !important;
  justify-content: start !important;
}
</style>
