import { commentNode } from '../common/node-helper';
import store from '../../store';
import router from '../../router';
function install(Vue) {
  Vue.directive('auth', {
    bind: toggle,
    update: toggle,
  });

  function toggle(el, binding, vnode) {
    let userRoles = store.getters['authenticate/userRoles'];
    if (binding.arg === 'roles') {
      if (Object.keys(binding.modifiers).length === 0) {
        let roles = (binding.value || '').split(',');
        if (userRoles.filter((c) => roles.includes(c)).length === 0) {
          vnode.componentInstance.$nextTick(() => {
            commentNode(el, vnode);
          });
        }
      }
      if (binding.modifiers.disabled) {
        let roles = (binding.value || '').split(',');
        if (userRoles.filter((c) => roles.includes(c)).length === 0) {
          vnode.componentInstance.$nextTick(() => {});
        }
      }
    }
  }
  router.beforeEach((to, from, next) => {
    function process() {
      if (to.meta && to.meta.roles) {
        let userRoles = store.getters['authenticate/userRoles'];
        let roles = to.meta.roles.split(',');
        if (userRoles.filter((c) => roles.includes(c)).length === 0) {
          return next('/403');
        }
      }
      next();
    }
    // process();
    if (store.state.authenticate.appStatus === 'loading') {
      store.watch(
        () => store.state.authenticate.appStatus,
        (status) => {
          if (status === 'finished') process();
        },
      );
    } else {
      process();
    }
  });
}
export { install };
