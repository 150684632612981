<template>
  <div
    class="d-flex flex-column align-center justify-center"
    style="width: 100px; height: 100px; background: #eeeeee"
  >
    <v-icon-svg
      :path="icons.customCamera"
      transform="scale(0.2) translate(8 10)"
      style="color: #bbbbbb"
      width="70px"
      height="60px"
    ></v-icon-svg>
    <span class="mt-n4" style="font-size: 10px; color: #bbbbbb">
      NO IMAGE
    </span>
  </div>
</template>

<script>
import { customCamera } from '../plugins/icons';
export default {
  data() {
    return {
      icons: { customCamera },
    };
  },
};
</script>

<style></style>
