import { render, staticRenderFns } from "./_edit-role-popup.vue?vue&type=template&id=53392e1e&scoped=true&"
import script from "./_edit-role-popup.vue?vue&type=script&lang=js&"
export * from "./_edit-role-popup.vue?vue&type=script&lang=js&"
import style0 from "./_edit-role-popup.vue?vue&type=style&index=0&id=53392e1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53392e1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VChip,VForm,VLabel,VListItem,VListItemContent,VListItemTitle,VTextField})
