<template>
  <div
    class="v-page-grid-full pa-0 mt-20px mx-20px"
    style="height: calc(100% - 20px)"
  >
    <grid
      ref="grid"
      show-select
      show-bulk-action
      show-filter
      reorder-column
      resize-column
      fixed-header
      :sort="{ sortFieldName: 'publishedDate', isAsc: false }"
      class="v-grid-spam-interaction"
      :filters="filter"
      :columns="columns"
      :dataConfig="dataConfig"
      freezeDataItems
      @click:row="onDetailClick"
    >
      <template v-slot:append-toolbar-menu>
        <div class="d-flex align-center">
          <v-dropdownlist
            :data-items="listType"
            v-model="typeValue"
            :itemText="'name'"
            :itemValue="'value'"
            class="mr-2"
            style="width: 200px"
            hide-details
          >
          </v-dropdownlist>
          <v-menu
            open-on-click
            bottom
            right
            offset-y
            color="primary"
            content-class="v-dropdownlist__menu__content"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="secondary1"
                class="font-weight-bold primary4--text px-3"
                height="36px"
                v-on="on"
              >
                <span>ADD NEW</span>
                <v-icon-svg
                  class="ml-10px"
                  small
                  :path="icons.customDown"
                ></v-icon-svg>
              </v-btn>
            </template>
            <v-list class="cursor-pointer">
              <v-list-item
                v-for="item in listAddNewBtn"
                :key="item.value"
                color="primary"
                :to="item.path"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            depressed
            color="error1"
            class="font-weight-bold primary4--text px-3 ml-10px"
            height="36px"
            v-auth:roles="'Administrator,Editor,Author'"
            @click="openManageCategory"
          >
            <span>MANAGE CATEGORIES</span>
          </v-btn>
        </div>
      </template>
      <template v-slot:bulk-action="{ items }">
        <template v-if="typeValue === 0">
          <v-btn
            class="text-uppercase ml-3 bulk-btn rounded-sm"
            small
            color="info"
            elevation="0"
            :disabled="!!items.find((c) => c.typeName === 'Video')"
            @click="bulkEdit(items)"
          >
            <b>EDIT</b>
          </v-btn>
          <v-btn
            class="text-uppercase ml-10px bulk-btn rounded-sm"
            small
            color="error"
            elevation="0"
            :disabled="!!items.find((c) => c.typeName === 'Video')"
            @click="bulkMoveToTrash(items)"
          >
            <b>DELETE</b>
          </v-btn>
          <span
            class="ml-10px"
            v-if="items.find((c) => c.typeName === 'Video')"
          >
            Cannot edit/remove Video items
          </span>
        </template>
        <template v-else>
          <v-btn
            class="text-uppercase ml-3 font-size-medium bulk-btn rounded-sm"
            small
            color="warning"
            elevation="0"
            @click="bulkRestore(items)"
          >
            <b>RESTORE</b>
          </v-btn>
          <v-btn
            class="text-uppercase ml-3 font-size-medium bulk-btn rounded-sm"
            small
            color="error"
            elevation="0"
            @click="bulkDeletePermanently(items)"
          >
            <b>DELETE PERMANENTLY</b>
          </v-btn>
        </template>
      </template>
      <template v-slot:item.resourceCategoryId="{ item }">
        {{ item.resourceCategoryName }}
      </template>
      <template v-slot:item.publishedDate="{ item }">
        {{
          item.publishedDate ?$moment(item.publishedDate).format('MM/DD/YYYY hh:mm A') : '',
        }}
      </template>
      <template v-slot:item.createdByName="{ item }">
        {{ item.createdByName }} ({{ item.createdById }})
      </template>
      <template v-slot:item.updatedOn="{ item }">
        {{ $moment(item.updatedOn).format('MM/DD/YYYY hh:mm A') }}
      </template>
      <template v-slot:item.tags="{ item }">
        <v-chip
          v-show="item.listTags[0] != ''"
          label
          v-for="(tag, index) in item.listTags"
          :key="index"
          class="pa-1 mr-1 my-1"
          outlined
          :style="{ 'background-color': $customColors.primary4 + '!important' }"
          >#{{ tag }}</v-chip
        >
      </template>
      <template v-slot:item.command="{ item }">
        <div v-if="!item.isTrash && item.typeName !== 'Video'" class="d-flex">
          <v-btn
            class="rounded px-2"
            small
            color="info"
            elevation="0"
            @click.stop="editItem(item)"
          >
            <v-icon class="mr-1" size="14">{{ icons.customEdit }}</v-icon>
            Edit
          </v-btn>
          <v-btn
            class="ml-3 px-2 rounded"
            small
            color="error"
            elevation="0"
            @click.stop="moveToTrashItem(item)"
          >
            <v-icon size="14" class="mr-1 mb-2px">
              {{ icons.mdiCloseThick }}
            </v-icon>
            Delete
          </v-btn>
        </div>
        <div v-if="item.isTrash">
          <v-btn
            class="px-2 rounded"
            small
            color="error"
            elevation="0"
            @click.stop="onDelete(item)"
          >
            <v-icon size="14" class="mr-1 mb-2px">
              {{ icons.mdiCloseThick }}
            </v-icon>
            Delete Permanently
          </v-btn>
          <v-btn
            class="ml-3 px-2 rounded"
            small
            color="info"
            elevation="0"
            @click.stop="onRestore(item)"
          >
            <v-icon size="14" class="pb-2px mr-1">
              {{ icons.mdiFileRestore }}
            </v-icon>
            Restore
          </v-btn>
        </div>
      </template>
    </grid>
    <template v-if="dataItem.typeName === 'Coupon'">
      <coupon-detail
        v-model="isShowPopup"
        :id="dataItem.id"
        @fetchData="fetchData"
      />
    </template>
    <template
      v-if="['Audio', 'Document'].includes(dataItem.typeName) && isShowPopup"
    >
      <document-audio-detail
        v-model="isShowPopup"
        :id="dataItem.id"
        :type="dataItem.typeName"
        @fetchData="fetchData"
      />
    </template>
    <template v-if="dataItem.typeName === 'Recipe' && isShowPopup">
      <recipe-detail
        v-model="isShowPopup"
        :id="dataItem.id"
        @fetchData="fetchData"
      />
    </template>
    <template v-if="dataItem.typeName === 'Audio Book'">
      <audio-book-detail
        v-if="isShowPopup"
        v-model="isShowPopup"
        :id="dataItem.id"
        @fetchData="fetchData"
      />
    </template>
    <template v-if="dataItem.typeName === 'Video'">
      <video-detail
        v-if="isShowPopup"
        v-model="isShowPopup"
        :id="dataItem.id"
      />
    </template>
    <bulk-edit
      v-if="isShowBulkPopup"
      v-model="isShowBulkPopup"
      :bulk-items="bulkItems"
      @fetchData="$refs.grid.read()"
    />
    <manage-category
      @filterCategory="filterCategory"
      v-model="isShowCategory"
    />
  </div>
</template>

<script>
import grid from '../../../components/grid';
import { mdiCloseThick, mdiFileRestore } from '@mdi/js';
import { customDown, customEdit } from '../../../plugins/icons';
import CouponDetail from './_coupon-detail-popup.vue';
import DocumentAudioDetail from './_document-audio-detail-popup.vue';
import RecipeDetail from './_recipe-detail-popup.vue';
import AudioBookDetail from './_audio-book-detail-popup.vue';
import VideoDetail from './_video-detail-popup.vue';
import BulkEdit from './_bulk-edit-popup.vue';
import ManageCategory from '../../admin/categories/_manage-category-popup.vue';
import api from '../../../api/resources';

export default {
  components: {
    grid,
    CouponDetail,
    DocumentAudioDetail,
    RecipeDetail,
    AudioBookDetail,
    VideoDetail,
    BulkEdit,
    ManageCategory,
  },
  created() {
    this.$eventBus.$on('resourceFilterByAuthor', (authorName) => {
      if (!!authorName && authorName != '') {
        this.filter = [
          {
            propertyName: 'createdByName',
            value: authorName,
            comparison: 0,
          },
        ];
        this.$nextTick(() => {
          this.$refs.grid.fetchAll(['data']);
        });
      }
    });
  },
  data() {
    return {
      icons: {
        mdiCloseThick,
        customDown,
        customEdit,
        mdiFileRestore,
      },
      isShowPopup: false,
      isShowBulkPopup: false,
      isShowCategory: false,
      dataItem: {
        id: 0,
        typeName: 'Audio',
      },
      bulkItems: [],
      filter: [],
      listAddNewBtn: [
        { text: 'Audio', value: 1, path: '/resources/add-new-audio' },
        { text: 'Audio book', value: 2, path: '/resources/add-new-audio-book' },
        { text: 'Coupon', value: 3, path: '/resources/add-new-coupon' },
        { text: 'Document', value: 4, path: '/resources/add-new-document' },
        { text: 'Recipe', value: 5, path: '/resources/add-new-recipe' },
      ],
      listType: [
        { name: 'All - Default', value: 0 },
        { name: 'Trash', value: 1 },
      ],
      typeValue: 0,
      columns: [
        {
          text: 'Id',
          value: 'id',
          dataType: 'Number',
        },
        {
          text: 'Type',
          value: 'typeName',
          ddlValues: [
            { text: 'All', value: null },
            { text: 'Audio', value: 'Audio' },
            { text: 'Audio book', value: 'Audio Book' },
            { text: 'Coupon', value: 'Coupon' },
            { text: 'Document', value: 'Document' },
            { text: 'Recipe', value: 'Recipe' },
            { text: 'Video', value: 'Video' },
          ],
          hideComparison: true,
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Status',
          value: 'statusName',
          ddlValues: [
            { text: 'All', value: null },
            { text: 'Draft', value: 'Draft' },
            { text: 'Pending Review', value: 'Pending Review' },
            { text: 'Private', value: 'Private' },
            { text: 'Publish ', value: 'Publish' },
          ],
          hideComparison: true,
        },
        {
          text: 'Category',
          value: 'resourceCategoryId',
          ddlValues: [
            { text: 'All', value: null },
            { text: 'Breakfast', value: 'breakfast' },
          ],
        },
        {
          text: 'Published Date',
          value: 'publishedDate',
          dataType: 'Date',
          width: 200,
        },
        {
          text: 'Tags',
          value: 'tags',
          width: 200,
          hidden: true,
        },
        {
          text: 'Created By',
          value: 'createdByName',
          hidden: true,
        },
        {
          text: 'Updated On',
          value: 'updatedOn',
          dataType: 'Date',
          width: 200,
          hidden: true,
        },
        {
          text: 'Command',
          value: 'command',
          width: 340,
          hideFilter: true,
          sortable: false,
        },
      ],
      dataConfig: {
        url: 'api/resources',
        page: 1,
        pageSize: 50,
        requestDatas: {
          isTrash: false,
        },
      },
    };
  },
  mounted() {
    // this.changeTypeRessource();
    this.changeTypeValue();
    this.$nextTick(() => {
      this.getListCategory();
    });
  },
  methods: {
    openManageCategory() {
      this.isShowCategory = true;
    },
    filterCategory($event) {
      this.filter = [
        {
          propertyName: 'resourceCategoryId',
          value: $event.id,
          comparison: 0,
        },
      ];
      this.$nextTick(() => {
        this.$refs.grid.fetchAll(['data']);
      });
    },
    getListCategory() {
      this.$nextTick(() => {
        api.getListCategory().then((res) => {
          if (res.status === 200) {
            const listItem = [...res.data];
            let dataDDL = listItem.map((c) => {
              let item = { text: c.title, value: c.id };
              return item;
            });
            let columnTypeId = this.columns.find(
              (c) => c.value == 'resourceCategoryId',
            );
            this.$set(columnTypeId, 'ddlValues', [
              { text: 'All', value: null },
              ...dataDDL,
            ]);
          }
        });
      });
    },
    // changeTypeRessource() {
    //   const item = this.$route.name.split('.').pop();
    //   if (item !== 'all') {
    //     this.filter = [
    //       {
    //         propertyName: 'typeName',
    //         value: item.charAt(0).toUpperCase() + item.slice(1),
    //         comparison: 0,
    //       },
    //     ];
    //   } else this.filter = [];
    // },
    editItem(item) {
      this.$router.push({
        path: `/resources/edit-${item.typeName
          .toLowerCase()
          .replace(' ', '-')}/${item.id}`,
      });
    },
    moveToTrashItem(item) {
      // api
      //   .moveToTrash(item.id)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       this.$notify.success(res.data.message);
      //       this.fetchData();
      //     }
      //   })
      //   .catch((err) => this.$notify.error(err));
      this.$confirm(
        `Are you sure you want to move this ${item.typeName} to <span class='error--text'>TRASH</span> now?<br/>
        <span class="secondary1--text font-weight-regular">${item.title}</span>`,
        {
          width: 450,
        },
      ).then((isYes) => {
        if (isYes) {
          api
            .moveToTrash(item.id)
            .then((res) => {
              if (res.status === 200) {
                this.$notify.success(res.data.message);
                this.fetchData();
              }
            })
            .catch((err) => this.$notify.error(err));
        }
      });
    },
    bulkEdit(items) {
      this.bulkItems = [...items];
      this.isShowBulkPopup = true;
    },
    bulkMoveToTrash(items) {
      // const listId = items.map((c) => c.id);
      // api
      //   .bulkMoveToTrash(listId)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       this.$notify.success(res.data.message);
      //       this.fetchData();
      //     }
      //   })
      //   .catch((err) => this.$notify.error(err));
      this.$confirm(
        `Are you sure you want to move ${items.length} items to <span class='error--text'>TRASH</span> now?`,
        {
          width: 480,
        },
      ).then((isYes) => {
        if (isYes) {
          const listId = items.map((c) => c.id);
          api
            .bulkMoveToTrash(listId)
            .then((res) => {
              if (res.status === 200) {
                this.$notify.success(res.data.message);
                this.fetchData();
              }
            })
            .catch((err) => this.$notify.error(err));
        }
      });
    },
    bulkDeletePermanently(items) {
      this.$confirm(
        `Are you sure you want to <span class='error--text'>DELETE</span> ${items.length} items permanently?`,
        {
          warningMessage: `This action cannot be undone`,
        },
      ).then((isYes) => {
        if (isYes) {
          const listId = items.map((c) => c.id);
          api.bulkDeteleResource(listId).then((res) => {
            if (res.status === 200) {
              this.$notify.success(res.data.message);
              this.fetchData();
            }
          });
        }
      });
    },
    bulkRestore(items) {
      const listId = items.map((c) => {
        return c.id;
      });
      api.bulkRestore(listId).then((res) => {
        if (res.status === 200) {
          this.$notify.success(res.data.message);
          this.fetchData();
        }
      });
    },
    onRestore(item) {
      api.restoreResource(item.id).then((res) => {
        if (res.status === 200) {
          this.$notify.success(res.data.message);
          this.fetchData();
          this.isShowPopup = false;
        }
      });
    },
    onDelete(item) {
      this.$confirm(
        `Are you sure you want to <span class='error--text'>DELETE</span> this ${item.typeName}?<br>
        <span class="secondary1--text">${item.title}</span>`,
        {
          warningMessage: `This action cannot be undone`,
        },
      ).then((isYes) => {
        if (isYes) {
          api
            .deteleResource(item.id)
            .then((res) => {
              if (res.status === 200) {
                this.$notify.success(res.data.message);
                this.fetchData();
                this.isShowPopup = false;
              }
            })
            .catch((err) => this.$notify.error(err));
        }
      });
    },
    onDetailClick(dataItem, focusItem) {
      this.dataItem = dataItem;
      this.isShowPopup = true;
      focusItem(true);
    },
    fetchData() {
      this.$refs.grid.read();
    },
    changeTypeValue() {
      if (this.typeValue == 1) {
        this.dataConfig.page = 1;
        this.dataConfig.requestDatas.isTrash = true;
      } else {
        this.dataConfig.page = 1;
        this.dataConfig.requestDatas.isTrash = false;
      }
      this.$nextTick(() => {
        this.$refs.grid.resetCurrentPage();
        this.$refs.grid.read();
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name !== 'admin.user' && vm.filter.length > 0) {
        vm.filter = [];
      }
    });
  },
  watch: {
    isShowPopup() {
      if (!this.isShowPopup) {
        this.$refs.grid.itemFocusOut();
      }
    },
    // '$route.name'() {
    //   this.changeTypeRessource();
    // },
    typeValue() {
      this.changeTypeValue();
    },
    '$route.name'() {
      this.$nextTick(() => {
        if (this.typeValue == 1) {
          this.typeValue = 0;
        }
      });
      if (this.$route.name == 'resources.all') {
        this.$nextTick(() => {
          this.getListCategory();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.v-grid-spam-interaction {
  .v-grid {
    &__toolbar {
      > div.d-flex {
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
