import Vue from 'vue';
import CustomIcon from './_custom-svg-icon';
import DateTimePicker from './_date-time-picker';
import Dropdownlist from './_dropdownlist';
import InputDatepicker from './_input-datepicker';
import Paginations from './_paginations';
import Popup from './_popup';
import Panel from './_panel';
import Editor from './editor';
import MultipleSelect from './_multiple-select';
import LoadingSpinner from './_loading-spinner';
import NoImage from './_no-image';

Vue.component('v-icon-svg', CustomIcon);
Vue.component('v-dropdownlist', Dropdownlist);
Vue.component('v-datetime-picker', DateTimePicker);
Vue.component('v-input-datepicker', InputDatepicker);
Vue.component('v-paging-bar', Paginations);
Vue.component('v-popup', Popup);
Vue.component('v-panel', Panel);
Vue.component('v-editor', Editor);
Vue.component('v-multiple-select', MultipleSelect);
Vue.component('v-loading-spinner', LoadingSpinner);
Vue.component('v-no-image', NoImage);
