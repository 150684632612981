<template>
  <v-card flat class="v-editor">
    <EditorMenuBar :editor="editor" v-slot="{ commands, isActive }">
      <v-toolbar dense color="secondary" height="38" class="mb-1" elevation="0">
        <v-btn
          small
          tile
          @click.stop="commands.bold"
          icon
          :class="{ primary: isActive.bold() }"
          dark
        >
          <v-icon size="20">{{ icons.mdiFormatBold }}</v-icon>
        </v-btn>
        <v-btn
          small
          tile
          @click.prevent="commands.italic"
          icon
          :class="{ primary: isActive.italic() }"
          dark
        >
          <v-icon size="20">{{ icons.mdiFormatItalic }}</v-icon>
        </v-btn>
        <v-btn
          small
          tile
          @click.prevent="commands.underline"
          icon
          :class="{ primary: isActive.underline() }"
          dark
        >
          <v-icon size="18">{{ icons.mdiFormatUnderline }}</v-icon>
        </v-btn>
        <v-btn
          small
          tile
          @click.prevent="commands.strike"
          icon
          :class="{ primary: isActive.strike() }"
          dark
        >
          <v-icon size="16">{{ icons.mdiFormatStrikethroughVariant }}</v-icon>
        </v-btn>
        <v-dropdownlist
          v-if="!hideHeading"
          @click.prevent
          style="height: 30px"
          :data-items="headingData.items"
          placeholder="Heading"
          hide-details
          height="30px"
          width="120px"
          :value="headingData.active(isActive.heading)"
          @change="commands.heading({ level: $event })"
        ></v-dropdownlist>
        <v-btn
          small
          tile
          @click.prevent="commands.bullet_list"
          icon
          :class="{ primary: isActive.bullet_list() }"
          dark
        >
          <v-icon size="20">{{ icons.mdiFormatListBulleted }}</v-icon>
        </v-btn>
        <v-btn
          small
          tile
          @click.prevent="commands.ordered_list"
          icon
          :class="{ primary: isActive.ordered_list() }"
          dark
        >
          <v-icon size="20">{{ icons.mdiFormatListNumbered }}</v-icon>
        </v-btn>
        <v-btn small tile @click.prevent="commands.undo" icon dark>
          <v-icon size="20">{{ icons.mdiUndoVariant }}</v-icon>
        </v-btn>
        <v-btn small tile @click.prevent="commands.redo" icon dark>
          <v-icon size="20">{{ icons.mdiRedoVariant }}</v-icon>
        </v-btn>
        <v-btn small tile @click.stop="onClearFormatClick" icon dark>
          <v-icon size="20">{{ icons.mdiFormatClear }}</v-icon>
        </v-btn>
        <v-btn
          v-if="!hideImage"
          small
          tile
          icon
          dark
          @click.prevent="imageCommand(commands, isActive)"
        >
          <v-icon size="20">{{ icons.mdiImageSizeSelectActual }}</v-icon>
        </v-btn>
        <v-btn
          v-if="!hideVideo"
          small
          tile
          icon
          dark
          @click="videoCommand(commands)"
        >
          <v-icon size="18">{{ icons.customMovie }}</v-icon>
        </v-btn>
        <v-menu offset-y v-if="showAvailable">
          <template v-slot:activator="{ on, value }">
            <v-btn
              class="primary4 d-flex justify-space-between px-10px"
              v-on="on"
              height="30px"
              width="330px"
              :active-class="'primary4'"
            >
              Available placeholders
              <v-icon
                size="20"
                class="ml-5"
                v-text="icons[value ? 'mdiChevronUp' : 'mdiChevronDown']"
              >
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              dense
              v-for="(item, index) in listPlaceholder"
              :key="index"
              @click.prevent="onPlaceholderSelect(item)"
              class="primary4"
              style="min-height: 30px"
            >
              <v-hover v-slot:default="{ hover }">
                <v-list-item-title
                  class="font-size-medium"
                  style="font-weight: 400"
                  :class="
                    hover ? 'primary--text font-weight-bold' : 'accent--text'
                  "
                  >{{ item.name }}</v-list-item-title
                >
              </v-hover>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </EditorMenuBar>
    <v-input ref="input" v-bind:class="classes" v-bind="$props">
      <fieldset aria-hidden="true" v-if="outlined">
        <legend></legend>
      </fieldset>
      <EditorContent
        ref="content"
        class="v-editor-content"
        :editor="editor"
      ></EditorContent>
    </v-input>
    <InsertImagePopup
      v-model="imageCommandData.isShow"
      @insert="onInsertImageSubmit"
    ></InsertImagePopup>
    <InsertVideoPopup
      v-model="videoCommandData.isShow"
      @insert="onInsertVideoSubmit"
    ></InsertVideoPopup>
  </v-card>
</template>

<script>
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import VTextarea from 'vuetify/lib/components/VTextarea';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  ListItem,
  Image,
} from 'tiptap-extensions';
import {
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatStrikethroughVariant,
  mdiFormatUnderline,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiUndoVariant,
  mdiRedoVariant,
  mdiImageSizeSelectActual,
  mdiFormatClear,
} from '@mdi/js';
import { customMovie } from '../../plugins/icons';
import InsertImagePopup from './_insert-image-popup';
import InsertVideoPopup from './_insert-video-popup';
import Embed from './Embed';
export default {
  extends: VTextarea,
  name: 'v-editor',
  components: {
    EditorContent,
    EditorMenuBar,
    InsertImagePopup,
    InsertVideoPopup,
  },
  props: {
    hideImage: {
      type: Boolean,
      default: false,
    },
    hideHeading: {
      type: Boolean,
      default: false,
    },
    hideVideo: {
      type: Boolean,
      default: false,
    },
    showAvailable: {
      type: Boolean,
      default: false,
    },
    listPlaceholder: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    classes() {
      return {
        'v-editor': true,
        ...VTextarea.options.computed.classes.call(this),
      };
    },
  },
  data() {
    return {
      ...VTextarea.options.data,
      editor: null,
      icons: {
        mdiFormatBold,
        mdiFormatItalic,
        mdiFormatStrikethroughVariant,
        mdiFormatUnderline,
        mdiFormatListBulleted,
        mdiFormatListNumbered,
        mdiUndoVariant,
        mdiRedoVariant,
        mdiImageSizeSelectActual,
        customMovie,
        mdiChevronDown,
        mdiChevronUp,
        mdiFormatClear,
      },
      toolbarItems: [
        {
          name: 'Bold',
          command: 'bold',
          isActive: 'bold',
          icon: 'mdiFormatBold',
        },
        {
          name: 'Italic',
          command: 'italic',
          isActive: 'italic',
          icon: 'mdiFormatItalic',
        },
        {
          name: 'Underline',
          command: 'underline',
          isActive: 'underline',
          icon: 'mdiFormatUnderline',
          iconSize: 18,
        },
        {
          name: 'Strike Through',
          command: 'strike',
          isActive: 'strike',
          icon: 'mdiFormatStrikethroughVariant',
          iconSize: 16,
        },
        {
          name: 'Bullet List',
          command: 'bullet_list',
          isActive: 'bullet_list',
          icon: 'mdiFormatListBulleted',
        },
        {
          name: 'Numbered List',
          command: 'ordered_list',
          isActive: 'ordered_list',
          icon: 'mdiFormatListNumbered',
        },
        {
          name: 'Undo',
          command: 'undo',
          icon: 'mdiUndoVariant',
        },
        {
          name: 'Redo',
          command: 'redo',
          icon: 'mdiRedoVariant',
        },
      ],
      headingData: {
        items: [
          { value: 1, text: 'Heading 1' },
          { value: 2, text: 'Heading 2' },
          { value: 3, text: 'Heading 3' },
        ],
        active: (active) => {
          if (active({ level: 1 })) return 1;
          if (active({ level: 2 })) return 2;
          if (active({ level: 3 })) return 3;
          return 0;
        },
      },
      imageCommandData: {
        isShow: false,
      },
      videoCommandData: {
        isShow: false,
      },
      selectPlaceholder: null,
    };
  },
  methods: {
    imageCommand() {
      this.imageCommandData.isShow = true;
    },
    selectImageClick() {},
    onInsertImageSubmit(url) {
      if (url) {
        this.editor.commands.image({ src: url });
      }
    },
    videoCommand() {
      this.videoCommandData.isShow = true;
    },
    onInsertVideoSubmit(url) {
      if (url) {
        this.editor.commands.embed({ src: url });
      }
    },
    onPlaceholderSelect(item) {
      const transaction = this.editor.state.tr.insertText(`{{${item.value}}}`);
      this.editor.view.dispatch(transaction);
      this.editor.view.dom.focus();
    },
    onClearFormatClick() {
      this.editor.view.dom.focus();
      const formatMarks = Object.keys(this.editor.marks);
      formatMarks.forEach((format) => {
        if (this.editor.activeMarks[format]()) {
          this.editor.commands[format]();
        }
      });
      this.editor.commands.paragraph();
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new OrderedList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        new ListItem(),
        new Image(),
        new Embed(),
      ],
      content: this.value,
      onFocus: () => {
        this.isFocused = true;
      },
      onBlur: () => {
        this.isFocused = false;
      },
      onUpdate: ({ getHTML }) => {
        this.$emit('input', getHTML());
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  watch: {
    value(val) {
      if (val != this.editor.getHTML()) this.editor.setContent(this.value);
    },
  },
};
</script>

<style lang="scss">
.v-editor {
  .ProseMirror {
    outline: none;
    height: 100%;
    overflow-y: auto;
    img {
      max-width: 100%;
    }
  }
  .v-editor-content {
    width: 100%;
    margin-right: -12px;
    overflow: hidden;
    padding-bottom: 4px;
  }
  .v-dropdownlist {
    .v-input__slot {
      min-height: 30px !important;
    }
  }
  &__image-popup {
    .v-file-input .v-input__prepend-outer {
      display: none;
    }
  }
  iframe {
    width: 32rem;
    height: 18rem;
  }
}
</style>
