var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'pagination primary--text': true,
    'pagination--center': _vm.centering,
  }},[(!_vm.hidePagination)?_c('div',{staticClass:"pagination__list"},[_c('button',{class:{
        'v-pagination__navigation': true,
        'v-pagination__navigation--disabled': _vm.currentPageInput == 1,
      },attrs:{"type":"button"},on:{"click":function($event){_vm.currentPageInput = 1}}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icons.mdiSkipPrevious)}})],1),_c('v-pagination',{ref:"pagination",attrs:{"length":_vm.totalPages,"color":_vm.$customColors.secondary1,"total-visible":_vm.totalVisible,"next-icon":_vm.icons.mdiMenuRight,"prev-icon":_vm.icons.mdiMenuLeft},on:{"next":function($event){return _vm.$emit('next')},"previous":function($event){return _vm.$emit('previous')}},model:{value:(_vm.currentPageInput),callback:function ($$v) {_vm.currentPageInput=$$v},expression:"currentPageInput"}}),_c('button',{class:{
        'v-pagination__navigation': true,
        'v-pagination__navigation--disabled': _vm.currentPageInput == _vm.totalPages,
      },attrs:{"type":"button"},on:{"click":function($event){_vm.currentPageInput = _vm.totalPages}}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icons.mdiSkipNext)}})],1),_c('section',{staticClass:"d-flex align-center"},[(!_vm.hidePageSize)?_c('v-dropdownlist',{staticClass:"mx-1",attrs:{"data-items":_vm.listPageSize,"hide-details":"","height":"30px","width":"70px"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._t("append",function(){return [_c('v-icon-svg',{staticClass:"mb-1",attrs:{"small":"","path":_vm.icons.customDown}})]})]},proxy:true}],null,true),model:{value:(_vm.itemPerPage),callback:function ($$v) {_vm.itemPerPage=$$v},expression:"itemPerPage"}}):_vm._e(),(!_vm.hidePageSize)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)],1):_vm._e(),(!_vm.hideInfo || !_vm.hideRefresh)?_c('v-spacer'):_vm._e(),_c('section',{staticClass:"d-flex align-center"},[(!_vm.hideInfo)?_c('span',[_vm._v(_vm._s(_vm.itemRangeOfCurrentPage))]):_vm._e(),(!_vm.hideRefresh)?_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('click:refresh')}}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icons.mdiRefresh)}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }