<template>
  <v-card
    flat
    height="100%"
    :img="backgroundImage"
    style="background-position-y: bottom; overflow: hidden auto"
    class="white"
  >
    <v-list color="transparent" class="d-inline-block" style="width: 100%">
      <v-row class="my-0">
        <v-col cols="12" style="max-width: 462px" class="py-0">
          <template v-for="(item, key) in pageList">
            <v-hover v-slot:default="{ hover }" :key="key">
              <v-list-item
                :to="item.path"
                dense
                class="v-list-item--none-hover"
                :ripple="false"
                v-auth:roles="item.roles"
              >
                <v-list-item-avatar size="38" tile>
                  <v-btn
                    :outlined="!hover"
                    height="38px"
                    width="38px"
                    min-width="38px"
                    class="pa-0"
                    :ripple="false"
                    color="primary"
                  >
                    <v-icon
                      v-if="!item.isSvgIcon"
                      size="24"
                      :class="{
                        'svg-icon__transform':
                          item.name == 'Manage Media Library',
                      }"
                      v-text="item.icon"
                    ></v-icon>
                    <v-icon-svg
                      v-else
                      :height="'24px'"
                      :width="'24px'"
                      :path="item.icon"
                      :transform="!!item.transform ? item.transform : ''"
                    ></v-icon-svg>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content
                  class="font-weight-bold font-size-medium"
                  >{{ item.name }}</v-list-item-content
                >
              </v-list-item>
            </v-hover>
          </template>
        </v-col>
      </v-row>
    </v-list>
  </v-card>
</template>

<script>
import backgroundImage from '../../assets/home-page-bg.png';
import {
  customManageUser,
  // customeCategory,
  customDrBergProfile,
} from '../../plugins/icons';

export default {
  data() {
    return {
      backgroundImage: backgroundImage,
      pageList: [
        {
          name: 'Manage Users',
          path: '/admin/manage-users',
          icon: customManageUser,
          isSvgIcon: true,
          transform: 'translate(-423.345 -229.345) scale(0.65)',
          roles: 'Administrator',
        },
        // {
        //   name: 'Manage Categories',
        //   path: '/admin/categories',
        //   icon: customeCategory,
        //   isSvgIcon: true,
        //   transform: 'translate(-414.2 -367.9) scale(0.6)',
        //   roles: 'Administrator,Editor,Author',
        // },
        {
          name: 'Manage Dr. Berg Profile',
          path: '/admin/manage-drberg-profile',
          icon: customDrBergProfile,
          isSvgIcon: true,
          transform: 'translate(-388.202 -359.887) scale(0.6)',
          roles: 'Administrator,Editor,Author',
        },
      ],
    };
  },
};
</script>

<style scoped>
.svg-icon__transform {
  transform: translate(-2px, -2px);
}
</style>
