<template>
  <v-container
    class="ml-4 mt-20px"
    style="height: 100%; max-width: calc(100% - 30px)"
  >
    <v-row>
      <v-col class="white pt-5 px-20px" cols="12">
        <p class="mb-4 text-uppercase" style="font-size: 20px">
          <strong>Manage Auto Sync</strong>
        </p>
        <p>
          <strong>YouTube Account:</strong>
          {{ account }}
        </p>
        <div>
          <v-btn
            height="36"
            color="success"
            class="v-btn-sync__animate"
            @click="syncYoutubeApi"
            :loading="isLoading"
            :disabled="isLoading"
          >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>{{ icons.mdiAutorenew }}</v-icon>
              </span>
            </template>
            <v-icon class="mr-2">{{ icons.mdiAutorenew }}</v-icon>
            SYNC NOW
          </v-btn>
          <span class="ml-2">Last Updated {{ lastUpdate }}</span>
          <v-btn
            height="36"
            color="success"
            class="v-btn-sync__animate ml-5"
            @click="syncResource"
            :loading="isLoadingResource"
            :disabled="isLoadingResource"
          >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>{{ icons.mdiAutorenew }}</v-icon>
              </span>
            </template>
            <v-icon class="mr-2">{{ icons.mdiAutorenew }}</v-icon>
            SYNC RESOURCE NOW
          </v-btn>
          <span class="ml-2">Last Updated {{ lastResourceUpdate }}</span>
        </div>
        <!-- <v-card rounded class="px-5 pt-30px pb-5">
          
        </v-card> -->
      </v-col>
    </v-row>
    <v-row
      class="white my-0"
      style="height: calc(100% - 150px); max-heigth: 100%"
    >
      <v-col cols="12" class="v-page-grid-full px-5 pt-5">
        <grid
          ref="grid"
          show-filter
          reorder-column
          resize-column
          fixed-header
          :show-toolbar="false"
          :show-footer="true"
          filter-mode="menu"
          style="padding: 0 !important"
          class="v-grid-youtube-api"
          :sort="{
            sortFieldName: 'time',
            isAsc: false,
          }"
          :columns="columns"
          :dataConfig="dataConfig"
        >
          <template v-slot:item.playListImport="{ item }">
            {{ item.playListImport }}/{{ item.playListTotal }}
          </template>
          <template v-slot:item.videoImport="{ item }">
            {{ item.videoImport }}/{{ item.videoTotal }}
          </template>
          <template v-slot:item.isActive="{ item }">
            {{ item.isActive ? 'Successful' : 'Failed' }}
          </template>
          <template v-slot:item.time="{ item }">
            {{ $moment(item.time).format('MM/DD/YYYY hh:mm A') }}
          </template>
          <template v-slot:item.syncedBy="{ item }">
            <span
              :class="{
                'font-italic': item.syncedBy == 'System Proceeded (-1)',
              }"
            >
              {{ item.syncedBy }}
            </span>
          </template>
          <template v-slot:item.errorMessage="{ item }">
            {{ item.errorMessage }}
          </template>
        </grid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiAutorenew } from '@mdi/js';
import grid from '../../components/grid';

export default {
  components: {
    grid,
  },
  data() {
    return {
      icons: {
        mdiAutorenew,
      },
      account: 'drericberg123',
      columns: [
        { text: 'Time', value: 'time' },
        { text: 'Status', value: 'isActive' },
        { text: '#Playlist imported', value: 'playListImport' },
        { text: '#Video imported', value: 'videoImport' },
        { text: 'Synced By', value: 'syncedBy' },
        { text: 'Message', value: 'errorMessage' },
      ],
      dataConfig: {
        url: 'api/youtubeApi',
        page: 1,
        pageSize: 50,
      },
      lastUpdate: this.$moment().format('MM/DD/YYYY hh:mm A'),
      lastResourceUpdate: this.$moment().format('MM/DD/YYYY hh:mm A'),
      isLoading: false,
      isLoadingResource: false,
    };
  },
  mounted() {
    this.getLastUpdate();
  },
  methods: {
    syncYoutubeApi() {
      this.isLoading = true;
      this.$http('/youtubeApi/callYoutubeApi')
        .then((res) => {
          if (res.status === 200) {
            this.$notify.success(res.data.message);
            this.$nextTick(() => {
              this.$refs.grid.read();
              this.getLastUpdate();
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getLastUpdate(type) {
      this.$http(`/youtubeApi/sync-resource-last-date?type=${type}`).then(
        (res) => {
          if (res.status === 200) {
            if (type == 4) {
              this.lastResourceUpdate = this.$moment(res.data.time).format(
                'MM/DD/YYYY hh:mm A',
              );
            } else {
              this.lastUpdate = this.$moment(res.data.time).format(
                'MM/DD/YYYY hh:mm A',
              );
            }
          }
        },
      );
    },
    syncResource() {
      this.isLoadingResource = true;
      this.$http('/youtubeApi/sync-resource')
        .then((res) => {
          if (res.status === 200) {
            this.$notify.success(res.data.message);
            this.$nextTick(() => {
              this.$refs.grid.read();
              this.getLastUpdate(4);
            });
          }
        })
        .finally(() => {
          this.isLoadingResource = false;
        });
    },
  },
};
</script>

<style lang="scss">
.v-grid-youtube-api {
  padding: 0 !important;
  thead {
    .v-data-table__progress {
      th {
        top: 80px !important;
      }
    }
  }
}

.v-btn-sync {
  &__animate {
    font-weight: bold;
    padding-left: 10px !important;
    padding-right: 10px !important;
    &:hover {
      .v-btn__content {
        .v-icon {
          svg {
            -webkit-animation: spin 1.5s linear infinite;
            -moz-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
          }
        }
      }
    }
    & > .v-btn__loader > .custom-loader {
      animation: spin 1s linear infinite;
      display: flex;
    }
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
