<template>
  <v-card
    class="d-flex align-end"
    flat
    height="140"
    :img="backgroundImage"
    style="background-position-y: bottom"
  >
    <div class="ml-5 mr-5 d-flex align-end avatar-custom__position">
      <v-badge
        avatar
        bordered
        color="primary4"
        overlap
        offset-x="45"
        :style="canChangeImage ? '' : 'width: 0px'"
        offset-y="175"
      >
        <template v-slot:badge v-if="canChangeImage">
          <input
            type="file"
            ref="file"
            style="display: none"
            :accept="validFiles"
            @change="updateAva($event)"
          />
          <v-btn
            dark
            icon
            class="button-border__radiusnone pa-0 primary4"
            height="25px"
            style="z-index: 2"
            width="25px"
            @click="$refs.file.click()"
          >
            <v-icon
              class="pt-0"
              color="primary"
              v-text="icons.mdiCamera"
              small
            ></v-icon>
          </v-btn>
        </template>
        <v-avatar
          class="border mr-5"
          tile
          size="180"
          :color="imageUrl ? 'primary4' : 'secondary2'"
        >
          <img
            v-if="imageUrl"
            style="object-fit: cover"
            @dblclick="showImage()"
            :src="imageUrl"
            alt="avatar"
          />
          <span v-else class="primary--text font-size-title font-weight-bold">{{
            shortName
          }}</span>
        </v-avatar>
      </v-badge>
      <div v-if="!!profileId">
        <p class="mb-0 font-size-subtitle-2">
          <strong
            >{{ userProfile.firstName }} {{ userProfile.lastName }}</strong
          >
        </p>
        <template>
          <v-btn
            height="26"
            class="mr-10px px-2 primary4--text mb-1"
            elevation="0"
            :color="'secondary1'"
            style="cursor: context-menu"
            >{{ userProfile.role }}</v-btn
          >
        </template>
      </div>
      <div v-else>
        <p class="mb-0 font-size-subtitle-2">
          <strong>Dr. Eric Berg</strong>
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mdiCamera } from '@mdi/js';
import backgroundImage from '../../assets/BG-profile.png';

export default {
  props: {
    imageUrl: {
      type: String,
    },
    profileId: Number,
    userProfile: Object,
    shortName: String,
    isLoading: Boolean,
    canChangeImage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      validFiles: '.jpg,.png',
      icons: {
        mdiCamera,
      },
      imageFile: [],
      backgroundImage: backgroundImage,
    };
  },
  methods: {
    updateAva(event) {
      this.$emit('update:isLoading', true);
      this.imageFile = event.target.files;
      if ([...this.imageFile].some((c) => c.size > 25000000)) {
        this.$notify.error(`Maximum size 25MB/Image, please check again`);
        this.imageFile = [];
        this.$refs.file.value = null;
        return false;
      }
      let data = new FormData();
      let arrayFilelist = Array.from(this.imageFile);
      arrayFilelist.forEach((element) => {
        data.append('Avatar', element);
      });
      if (this.profileId) {
        this.$http
          .put('user/profile/updateAvatar/' + this.profileId, data)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess) {
              this.$emit('update:imageUrl');
              this.$notify.success('Successfully updated image');
              this.$store.dispatch('authenticate/validUser');
            }
            this.imageFile = [];
            this.$refs.file.value = null;
          })
          .finally(() => {
            this.$emit('update:isLoading', false);
          });
      } else {
        this.$http
          .put('/drbergProfile/updateAvatar', data)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess) {
              this.$notify.success('Successfully updated image');
              this.$emit('update:imageUrl', res.data.message);
            }
            this.imageFile = [];
            this.$refs.file.value = null;
          })
          .finally(() => {
            this.$emit('update:isLoading', false);
          });
      }
    },
    showImage() {
      this.$emit('showImage');
    },
  },
};
</script>

<style scoped>
.border {
  border: solid 3px var(--color-primary3) !important;
}
.button-border__radiusnone {
  border-radius: unset !important;
}
.v-badge--bordered >>> .v-badge__badge::after {
  border-radius: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.avatar-custom__position {
  position: absolute;
  bottom: -70px;
}
</style>
