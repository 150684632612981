<template>
  <div class="pa-3 bulk-action primary--text">
    <span v-if="[0, 1].includes(value)">{{ message }}</span>
    <template v-else>
      <span>{{ selectedMesssage }}</span>
      <template v-if="$common.hasSlot()">
        <slot></slot>
      </template>
      <template v-else>
        <v-btn
          outlined
          color="primary"
          small
          class="mx-2"
          v-if="actionItemsIsString"
          >{{ actionItems }}</v-btn
        >
        <v-menu
          offset-y
          v-else
          class="mx-2"
          content-class="v-dropdownlist__menu__content"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="primary" class="mx-2" outlined small v-on="on">
              Actions
              <v-icon small v-text="icons.mdiChevronDown"></v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              @click="$emit('click:action', item)"
              dense
              v-for="(item, index) in actionItems"
              :key="index"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </template>
  </div>
</template>

<script>
import { mdiChevronDown } from '@mdi/js';
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    message: {
      type: String,
      default: 'Select items to apply bulk actions',
    },
    actionItems: {
      type: [Array, String],
      default: 'Action',
    },
  },
  computed: {
    selectedMesssage() {
      return `${this.value} ${this.value > 1 ? 'items' : 'item'} selected`;
    },
    actionItemsIsString() {
      return typeof this.actionItems === 'string';
    },
  },
  data() {
    return {
      icons: { mdiChevronDown },
    };
  },
};
</script>
<style scoped>
.bulk-action {
  border-bottom: solid 1px var(--color-primary2) !important;
}
</style>
