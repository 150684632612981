<template>
  <div class="v-grid__toolbar mb-10px">
    <div
      class="d-flex align-center"
      :class="
        !!showClearFilter && !isLeftButton
          ? 'justify-space-between flex-row-reverse'
          : ''
      "
    >
      <template v-if="showConfig">
        <v-dropdownlist
          class="flex-grow-0 primary2--text"
          hide-details
          :data-items="listSaved"
          style="width: 200px"
          v-model="selectItemId"
          item-value="id"
          item-text="name"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content :class="item.id !== 0 ? 'pl-2' : ''">
              <v-list-item-title>
                {{ item.name }}
                <span v-if="item.isDefault" class="caption">- default</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            <div class="v-select__selection v-select__selection--comma">
              {{ item.name }}
              <span v-if="item.isDefault" class="caption">- default</span>
            </div>
          </template>
        </v-dropdownlist>
        <v-menu offset-y content-class="v-dropdownlist__menu__content">
          <template v-slot:activator="{ on }">
            <v-btn
              tile
              elevation="0"
              outlined
              v-on="on"
              height="36"
              class="mx-4 px-2"
              min-width="50"
            >
              <v-icon small>{{ icons.mdiCog }}</v-icon>
              <v-icon small class="ml-1">{{ icons.mdiChevronDown }}</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              dense
              v-for="(item, key) in actionsList"
              :key="key"
              @click="actions[item]()"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          x-small
          outlined
          class="secondary1 white--text pa-0 mr-2"
          style="border-radius: 5px"
          min-width="26"
          @click="createNewConfigClick"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
        <slot name="append-toolbar-config"></slot>
        <v-spacer></v-spacer>
      </template>
      <div class="d-flex">
        <div
          v-if="noteForConfig && $vuetify.breakpoint.width > 805"
          class="success--text mr-10px font-italic align-self-center"
          style="line-height: 18px; white-space: nowrap"
        >
          {{ noteForConfig }}
        </div>
        <v-menu
          v-if="!hideMenuGrid"
          nudge-bottom="36"
          offset-overflow
          content-class="v-dropdownlist__menu__content"
          allow-overflow
          ref="add_remove_column"
          :close-on-content-click="false"
          v-model="addRemoveColumnActive"
          :max-height="maxHeight"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              tile
              elevation="0"
              outlined
              v-on="on"
              height="36"
              class="px-2 mr-4 addRemoveColumn"
              min-width="50"
            >
              Add/Remove column
              <v-icon small class="ml-4">{{ icons.mdiChevronDown }}</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <template v-for="(item, key) in headersList">
              <v-list-item
                dense
                v-if="item.text"
                :key="key"
                @click.prevent="showHideColumnClick(item)"
              >
                <v-list-item-action class="ma-0 mr-2">
                  <v-icon color="primary" size="22">{{
                    $vuetify.icons.values[
                      item.hidden ? 'checkboxOff' : 'checkboxOn'
                    ]
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ item.parent }} {{ item.text }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn
          v-if="!hideMenuGrid || !!showClearFilter"
          outlined
          color="primary"
          height="36"
          @click="clearFilterClick"
          class="font-weight-bold"
        >
          <v-icon small>{{ icons.mdiFilterRemove }}</v-icon
          >CLEAR ALL FILTERS
        </v-btn>
      </div>
      <slot name="append-toolbar-menu"></slot>
    </div>
    <v-alert
      v-model="isShowAlert"
      v-if="showConfig"
      color="warning"
      class="mt-10px font-size-medium mb-0"
      dark
      dismissible
      dense
    >
      You've made changes to the grid layout.
      <v-btn
        v-if="selectItem.id != 0"
        color="secondary1"
        small
        class="mx-2 text-uppercase"
        elevation="0"
        @click="saveChangeClick"
      >
        <b>Save Change</b>
      </v-btn>
      <v-btn
        color="secondary1"
        small
        class="mx-2 text-uppercase"
        elevation="0"
        @click="actions['Save as new list']()"
      >
        <b>Save as New List</b> </v-btn
      >or
      <v-btn @click="resetSetting" class="px-1" color="primary" text small>
        <b>Reset</b>
      </v-btn>
    </v-alert>
    <save-change-popup
      :data-item="saveChangePopupData.initData"
      @onSaveClick="saveChangePopupSaveClick($event)"
      :is-show.sync="saveChangePopupData.isShow"
      :loading="saveChangePopupData.loading"
      :title="saveChangePopupData.title"
    ></save-change-popup>
  </div>
</template>

<script>
import {
  mdiChevronDown,
  mdiCog,
  mdiPlus,
  mdiFilterRemove,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
} from '@mdi/js';
import SaveChangePopup from './_save-change-popup';
import { inboxAttachment } from '../../plugins/icons';
import api from '../../api/grid-config';

export default {
  components: {
    'save-change-popup': SaveChangePopup,
  },
  props: {
    maxHeight: {
      type: String,
    },
    isLeftButton: {
      type: Boolean,
      default: false,
    },
    showClearFilter: {
      type: Boolean,
      default: false,
    },
    hideMenuGrid: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    showConfig: {
      type: Boolean,
      default: false,
    },
    showHideColum: {
      type: Boolean,
      default: false,
    },
    gridConfig: {},
    noteForConfig: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedGridConfig() {
      return this.gridConfig.selected || {};
    },
    listSaved() {
      // createdById
      // gridTypeEnum
      // id:
      // isDefault:
      // isOwned:
      // isPublic:
      // isSysAdmin:
      // name:
      // setting:
      // sharedType:
      let sharedList = this.gridConfig.savedList.filter(
        (c) => c.sharedType === 2 && c.id !== 0,
      );
      let mySharedList = this.gridConfig.savedList.filter(
        (c) => c.sharedType === 1 && c.id !== 0,
      );
      let myList = this.gridConfig.savedList.filter(
        (c) => c.sharedType === 0 && c.id !== 0,
      );
      let all = this.gridConfig.savedList.find((c) => c.id === 0);
      let list = [all];
      if (myList.length > 0) {
        list.push({ header: 'My List' });
        list = list.concat(myList);
      }
      if (mySharedList.length > 0) {
        list.push({ header: 'My Shared List' });
        list = list.concat(mySharedList);
      }
      if (sharedList.length > 0) {
        list.push({ header: 'Shared List' });
        list = list.concat(sharedList);
      }
      return list;
    },
    isShowAlert: {
      get() {
        return this.gridConfig.hasConfigChanged;
      },
      set(val) {
        let config = Object.assign({}, this.gridConfig, {
          hasConfigChanged: val,
        });
        this.$emit('update:gridConfig', config);
      },
    },
    selectItemId: {
      get() {
        return this.computedGridConfig.id;
      },
      set(val) {
        if (this.computedGridConfig.id != val) this.$emit('select-config', val);
      },
    },
    selectItem() {
      return (
        this.gridConfig.savedList.find((c) => c.id == this.selectItemId) || {}
      );
    },
    actionsList() {
      let menuList = [];
      // if (this.selectItemId === 0) {
      //   menuList.push(
      //     ...["Duplicate"].concat(
      //       this.selectItem.isDefault ? [] : ["Set as Default"]
      //     )
      //   );
      // } else {
      //   if (this.selectItem.isDefault && this.selectItem.isDefault) {
      //     menuList.push("Delete", "Duplicate", "Share", "Rename");
      //   } else {
      //     menuList.push(
      //       "Set as Default",
      //       "Delete",
      //       "Duplicate",
      //       "Share",
      //       "Rename"
      //     );
      //   }
      // }
      if (this.selectItem.isPublic && !this.selectItem.isOwned) {
        menuList.push('Duplicate');
      } else {
        if (!this.selectItem.isDefault) {
          menuList.push('Set as Default');
        }
        if (this.selectItem.id === 0) {
          menuList.push('Duplicate');
        } else if (this.selectItem.sharedType === 0) {
          menuList.push('Delete', 'Duplicate', 'Share', 'Rename');
        } else {
          menuList.push('Delete', 'Duplicate', 'Rename');
        }
      }
      return menuList;
    },
    headersList() {
      let colums = this.computedGridConfig.config.headers || [];
      let colums1 = colums.reduce((cols, col) => {
        if (col.isGroup) {
          cols = cols.concat(col.headers);
        } else {
          cols.push(col);
        }
        return cols;
      }, []);
      return colums1;
    },
  },
  data() {
    return {
      alert: false,
      icons: {},
      addRemoveColumnActive: false,
      saveChangePopupData: {
        isShow: false,
        loading: false,
        title: 'Save as new list',
      },
      actions: {
        Duplicate: this.duplicate,
        'Set as Default': this.setDefaut,
        'Save as new list': this.saveAsNewList,
        Delete: this.delete,
        Rename: this.rename,
        Share: this.share,
      },
      sharedListType: ['My List', 'My List Shared', 'Shared List'],
    };
  },
  methods: {
    setHeightDDL() {
      if (!this.hideMenuGrid) {
        let list = this.$refs.add_remove_column.$children[1].$el;
        let heightGrid =
          this.$refs.add_remove_column.$parent.$parent.$el.clientHeight;
        list.style.maxHeight = heightGrid - 80 + 'px';
      }
    },
    resetSetting() {
      this.$emit('reset-config');
    },
    showHideColumnClick(headerItem) {
      this.$emit('showHideColumn', headerItem.value);
    },
    clearFilterClick() {
      this.$emit('clear-filter');
    },
    createNewConfigClick() {
      this.$emit('hideAllColumns');
      this.addRemoveColumnActive = true;
    },
    saveAsNewList() {
      this.saveChangePopupData = {
        isShow: true,
        title: 'Save as new list',
        initData: {
          name: '',
          isPublic: false,
        },
        type: 'addnew',
      };
    },
    saveChangePopupSaveClick(data) {
      this.saveChangePopupData.loading = true;
      if (this.saveChangePopupData.type === 'addnew') {
        api
          .addConfig(
            data.name,
            this.computedGridConfig.config,
            this.name,
            data.isPublic,
          )
          .then((result) => {
            if (result.status === 200 && result.data.isSuccess) {
              this.$notify.success(result.data.message);
              this.saveChangePopupData.isShow = false;
              this.$emit('on-config-change', 'addnew', result.data.data);
            } else {
              this.$notify.error(result.data.message);
            }
          })
          .finally(() => {
            this.saveChangePopupData.loading = false;
          });
      }
      if (this.saveChangePopupData.type === 'duplicate') {
        api
          .duplicate(
            this.selectItemId,
            data.name,
            this.computedGridConfig.config,
            this.name,
            data.isPublic,
          )
          .then((result) => {
            if (result.status === 200 && result.data.isSuccess) {
              this.$notify.success(result.data.message);
              this.saveChangePopupData.isShow = false;
              this.$emit('on-config-change', 'duplicate', result.data.data);
            } else {
              this.$notify.error(result.data.message);
            }
          })
          .finally(() => {
            this.saveChangePopupData.loading = false;
          });
      }
      if (this.saveChangePopupData.type === 'rename') {
        api
          .rename(this.selectItemId, data.name, data.isPublic)
          .then((result) => {
            if (result.status === 200 && result.data.isSuccess) {
              this.$notify.success(result.data.message);
              this.saveChangePopupData.isShow = false;
              this.$emit('on-config-change', 'rename');
            } else {
              this.$notify.error(result.data.message);
            }
          })
          .finally(() => {
            this.saveChangePopupData.loading = false;
          });
      }
    },
    saveChangeClick() {
      api
        .saveChange(this.computedGridConfig.id, this.computedGridConfig.config)
        .then((result) => {
          if (result.status === 200 && result.data.isSuccess) {
            this.$notify.success(result.data.message);
            this.$emit('on-config-change', 'savechange');
          } else {
            this.$notify.error(result.data.message);
          }
        })
        .finally(() => {});
    },
    duplicate() {
      this.saveChangePopupData = {
        isShow: true,
        title: 'Duplicate list',
        initData: {
          name: this.selectItem.name + ' Copy',
          isPublic: false,
        },
        type: 'duplicate',
      };
    },
    setDefaut() {
      api.setDefault(this.selectItemId, this.name).then((result) => {
        if (result.status === 200 && result.data.isSuccess) {
          this.$notify.success(result.data.message);
          this.$emit('on-config-change', 'setdefault');
        } else {
          this.$notify.error(result.data.message);
        }
      });
    },
    delete() {
      this.$confirm('Are you sure you want to delete this list?').then(
        (yes) => {
          if (yes) {
            api.remove(this.selectItemId).then((result) => {
              if (result.status === 200 && result.data.isSuccess) {
                this.$notify.success(result.data.message);
                this.$emit('on-config-change', 'delete');
              } else {
                this.$notify.error(result.data.message);
              }
            });
          }
        },
      );
    },
    rename() {
      this.saveChangePopupData = {
        isShow: true,
        title: 'Rename',
        initData: {
          name: this.selectItem.name,
          isPublic: this.selectItem.isPublic,
        },
        type: 'rename',
      };
    },
    share() {
      api.share(this.selectItemId).then((result) => {
        if (result.status === 200 && result.data.isSuccess) {
          this.$notify.success(result.data.message);
          this.$emit('on-config-change', 'share', this.selectItemId);
        } else {
          this.$notify.error(result.data.message);
        }
      });
    },
  },
  created() {
    this.icons = Object.freeze({
      mdiChevronDown,
      mdiCog,
      mdiPlus,
      mdiFilterRemove,
      mdiCheckboxBlankOutline,
      mdiCheckboxMarked,
      attachment: inboxAttachment,
    });
  },
  watch: {
    addRemoveColumnActive(val) {
      if (val)
        this.$nextTick().then(() => {
          this.setHeightDDL();
        });
    },
  },
};
</script>

<style></style>
