<template>
  <v-container
    class="mx-5 mt-5 pa-0"
    style="height: 100%; max-width: calc(100% - 40px)"
  >
    <v-row style="height: 100%" class="mx-0 mt-0">
      <v-col class="white pt-5 pl-5" cols="6">
        <div class="mb-20px">
          <v-btn
            class="white--text"
            height="36"
            color="error1"
            elevation="0"
            @click="isShowPopup = true"
          >
            <v-icon class="mr-1">{{ icons.mdiCog }}</v-icon>
            <strong>SETTING OPTIONS</strong>
          </v-btn>
        </div>
        <div>
          <p class="mb-0">
            <strong>Message</strong>
          </p>
          <v-textarea
            class="py-0 font-weight-bold"
            outlined
            dense
            v-model="message"
            counter="400"
          ></v-textarea>
        </div>
        <div class="v-device-checkbox">
          <v-checkbox v-model="isSendIOS" hide-details :ripple="false" dense>
            <template v-slot:label>
              <span
                style="padding-top: 3px"
                :class="{ 'font-weight-bold': isSendIOS }"
              >
                Send to IOS Devices
              </span>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="isSendAndroid"
            hide-details
            :ripple="false"
            dense
          >
            <template v-slot:label>
              <span
                style="padding-top: 3px"
                :class="{ 'font-weight-bold': isSendAndroid }"
              >
                Send to Android Devices
              </span>
            </template>
          </v-checkbox>
        </div>
        <div class="mt-5">
          <v-btn
            height="36"
            class="px-10px font-weight-bold"
            :disabled="message == ''"
            color="secondary"
          >
            SEND
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" class="pa-0" style="max-height: 100%">
        <v-row class="white ma-0" style="height: 100%; max-heigth: 100%">
          <v-col
            cols="12"
            class="v-page-grid-full pr-5 pt-5"
            style="height: 100%"
          >
            <p
              class="text-uppercase"
              style="font-size: 26px; line-height: 28px"
            >
              <strong>PUSH NOTIFICATION LOGs</strong>
            </p>
            <grid
              ref="grid"
              show-filter
              reorder-column
              resize-column
              fixed-header
              :show-toolbar="false"
              filter-mode="menu"
              style="padding: 0 !important"
              class="v-grid-push-notification"
              :columns="columns"
            >
              <template v-slot:item.time="{ item }">
                {{ $moment(item.time).format('hh:mm - MM/DD/YYYY') }}
              </template>
            </grid>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <setting-options v-model="isShowPopup" />
  </v-container>
</template>

<script>
import { mdiCog } from '@mdi/js';
import grid from '../../components/grid';
import SettingOptions from './_setting-options-popup.vue';

export default {
  components: {
    grid,
    SettingOptions,
  },
  data() {
    return {
      icons: {
        mdiCog,
      },
      message: '',
      isSendIOS: false,
      isSendAndroid: true,
      isShowPopup: false,
      columns: [
        { text: 'Send Time', value: 'time' },
        { text: 'Message', value: 'message' },
        { text: 'Sender', value: 'sender' },
      ],
      dataConfig: {
        url: 'api/moderation/push-notification',
        page: 1,
        pageSize: 50,
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.v-device-checkbox {
  .v-input--checkbox {
    .v-input--selection-controls__input {
      .v-icon {
        .v-icon__svg {
          height: 24px !important;
          width: 24px !important;
        }
      }
    }
  }
}
</style>
