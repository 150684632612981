var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-popup',{attrs:{"large":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("MANAGE CATEGORIES")]},proxy:true}]),model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('div',{staticClass:"v-page-grid-full pa-0",staticStyle:{"height":"100%"}},[_c('grid',{ref:"grid",staticClass:"v-grid-spam-interaction v-grid-manage-category",attrs:{"show-filter":"","reorder-column":"","resize-column":"","fixed-header":"","sort":{ sortFieldName: 'id', isAsc: false },"columns":_vm.columns,"dataConfig":_vm.dataConfig},on:{"click:row":_vm.onDetailClick},scopedSlots:_vm._u([{key:"append-toolbar-menu",fn:function(){return [_c('v-btn',{staticClass:"white--text ml-10px",attrs:{"color":"secondary1","height":"36"},on:{"click":_vm.onAddNewCaterogy}},[_c('strong',[_vm._v("ADD NEW CATEGORY")])])]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.id == -1)?_c('strong',[_c('em',[_vm._v(_vm._s(item.title)+" (default)")])]):_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"item.typeName",fn:function(ref){
var item = ref.item;
return [(item.id == -1)?_c('strong',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.typeName))])]}},{key:"item.countPost",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"secondary1--text text-decoration-underline cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.redirectResource(item)}}},[_vm._v(" "+_vm._s(item.countPost)+" ")])]}},{key:"item.command",fn:function(ref){
var item = ref.item;
return [(![5, 6].includes(item.type))?_c('v-btn',{staticClass:"px-2 rounded",attrs:{"small":"","color":"info","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.onEditItem(item)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"14"}},[_vm._v(_vm._s(_vm.icons.customEdit))]),_vm._v(" Edit ")],1):_vm._e(),(![5, 6].includes(item.type))?_c('v-btn',{staticClass:"ml-3 px-2 rounded",attrs:{"small":"","color":"error","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c('v-icon',{staticClass:"mr-1 mb-2px",attrs:{"size":"14"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloseThick)+" ")]),_vm._v(" Delete ")],1):_vm._e()]}}])}),(_vm.isShowPopup)?_c('add-edit-popup',{attrs:{"editId":_vm.editId},model:{value:(_vm.isShowPopup),callback:function ($$v) {_vm.isShowPopup=$$v},expression:"isShowPopup"}}):_vm._e(),_c('category-detail',{attrs:{"initData":_vm.dataItem},on:{"onEditDetail":_vm.editFromDetail,"filterCategory":function($event){return _vm.redirectResource($event)}},model:{value:(_vm.isShowDetail),callback:function ($$v) {_vm.isShowDetail=$$v},expression:"isShowDetail"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }