const resourceStatus = [
  { text: 'Draft', value: 3 },
  { text: 'Pending Review', value: 2 },
  { text: 'Private', value: 1 },
  { text: 'Publish ', value: 0 },
];
const contactStageFilters = [
  { text: 'All Entities', value: 0 },
  { text: 'Prospects', value: 1 },
  { text: 'Customers', value: 2 },
  { text: 'Work Items', value: -1 },
];
const roles = [
  { text: 'Administrator', value: 'Administrator' },
  { text: 'Author', value: 'Author' },
  { text: 'Contributor', value: 'Contributor' },
  { text: 'Editor', value: 'Editor' },
  { text: 'Subscriber', value: 'Subscriber' },
];
const comparisonEnum = [
  { id: 0, name: 'Is Equal To', operator: '=', groupName: 'All' },
  {
    id: 1,
    name: 'Is Less Than',
    operator: '<',
    groupName: 'Number,Date,DateTime,Duration',
  },
  {
    id: 2,
    name: 'Is Less Than Or Equal To',
    operator: '<=',
    groupName: 'Number,Date,DateTime,Duration',
  },
  {
    id: 3,
    name: 'Is Greater Than',
    operator: '>',
    groupName: 'Number,Date,DateTime,Duration',
  },
  {
    id: 4,
    name: 'Is Greater Than Or Equal To',
    operator: '>=',
    groupName: 'Number,Date,DateTime,Duration',
  },
  { id: 5, name: 'Is Not Equal To', operator: '<>', groupName: 'All' },
  { id: 6, name: 'Contains', operator: 'In', groupName: 'String' },
  { id: 7, name: 'Starts With', operator: '_=', groupName: 'String' },
  { id: 8, name: 'Ends With', operator: '=_', groupName: 'String' },
  {
    id: 9,
    name: 'Does Not Contain',
    operator: '<>In',
    groupName: 'String',
  },
  { id: 10, name: 'Is Null', operator: 'null', groupName: 'All' },
  {
    id: 11,
    name: 'Is Not Null',
    operator: '<>null',
    groupName: 'All',
  },
];
const contactStatus = [
  { text: 'New', value: 0, color: 'secondary1' },
  { text: 'In Progress', value: 1, color: 'warning' },
  { text: 'Pending', value: 2, color: 'primary' },
  { text: 'Lost', value: 3, color: 'error1' },
  { text: 'On flow', value: 4, color: 'success' },
  { text: 'To follow-up', value: 5, color: 'secondary3' },
];
const orderStatus = [
  { text: 'Canceled', value: 7 },
  { text: 'Closed', value: 6 },
  { text: 'Completed', value: 4 },
  { text: 'None', value: 0 },
  { text: 'On Hold', value: 3 },
  { text: 'Payment Review', value: 9 },
  { text: 'Paypal Canceled Reversal', value: 11 },
  { text: 'Paypal Reversed', value: 13 },
  { text: 'Pending', value: 10 },
  { text: 'Pending Payment', value: 1 },
  { text: 'Pending Paypal', value: 12 },
  { text: 'Processing', value: 2 },
  { text: 'Suspected Fraud', value: 8 },
];
const workItemType = [
  { text: 'Quiz Result Consultations', value: 1 },
  { text: 'Mini-course Registration', value: 2 },
  { text: 'Abandoned Shopping Cart Follow-up', value: 3 },
  { text: 'Abandoned Checkout Follow-up', value: 4 },
  { text: 'Newsletter Subscription', value: 5 },
];
const orderSourceType = [
  { text: 'All', value: null },
  { text: 'Shop Order', value: 0 },
  { text: 'Rep Order', value: 1 },
  { text: 'Funnel Order', value: 2 },
  { text: 'Chat Order', value: 3 },
  { text: 'BOT Order', value: 4 },
  { text: 'Other', value: 5 },
];
const state = {
  resourceStatus: Object.freeze(resourceStatus),
  roles: Object.freeze(roles),
  contactStageFilters: Object.freeze(contactStageFilters),
  comparisonEnum: Object.freeze(comparisonEnum),
  contactStatus: Object.freeze(contactStatus),
  orderStatus: Object.freeze(orderStatus),
  workItemType: Object.freeze(workItemType),
  listCountries: [],
  workItemTypes: [],
  allWorkItemTypes: [],
  workItemTypesFilters: [],
  orderSourceType: Object.freeze(orderSourceType),
};
const getters = {
  getContactStageFilters: (state) => {
    return state.contactStageFilters;
  },
  getContactStageFiltersWithoutAll: (state) =>
    state.entityStageSource.filter((x) => {
      return x.value != 0;
    }),
  comparisonsByGroup: (state) => (group) => {
    return state.comparisonEnum.filter(
      (c) =>
        c.groupName == 'All' ||
        c.groupName
          .split(',')
          .map((c) => c.toLowerCase())
          .includes((group || '').trim().toLowerCase()),
    );
  },
  comparisonByOperator: (state) => (operator) => {
    if (operator)
      return state.comparisonEnum.find((c) => c.operator == operator);
    else return state.comparisonEnum[0];
  },
  comparisonNullable: (state) => {
    let nullIds = [10, 11];
    return state.comparisonEnum.filter((c) => nullIds.includes(c.id));
  },
  getContactStatus: (state) => {
    return state.contactStatus;
  },
  getOrderStatus: (state) => {
    return state.orderStatus;
  },
  getOrderSourceType: (state) => {
    return state.orderSourceType;
  },
  listCountries: (state) => state.listCountries,
};
export { state, getters };
