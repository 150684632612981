<template>
  <v-container class="fill-height secondary2" fluid justify-center>
    <v-card class="elevation-6 pa-50px" max-width="440" width="100%">
      <v-flex class="d-flex flex-column align-center">
        <v-img :src="logoImage"></v-img>
        <p class="text-center font-size-large font-weight-bold mt-5 mb-2">
          {{
            confirmForgotPassword
              ? 'Forgot Password Confirmation'
              : 'Forgot Your Password?'
          }}
        </p>
      </v-flex>
      <v-card-text class="pa-0">
        <ValidationObserver ref="observer">
          <p
            v-if="!!confirmForgotPassword"
            class="text-center mb-2 font-size-medium"
          >
            <strong>Please check password reset email sent to</strong><br />
            <strong>{{ email }}</strong>
          </p>
          <v-form v-else ref="form" @submit.prevent="formSubmit">
            <label for="email" class="primary--text font-weight-bold subtitle-1"
              >Email</label
            >
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors }"
              name="Email"
            >
              <v-text-field
                name="email"
                type="text"
                :error-messages="errors"
                v-model="email"
                dense
                outlined
                hasLoading
              />
            </ValidationProvider>
            <v-btn
              type="submit"
              color="secondary1"
              depressed
              block
              class="font-weight-bold white--text"
              >SEND RECOVERY EMAIL</v-btn
            >
            <p class="mb-0 mt-2 text-center">
              <router-link to="/login" class="font-weight-bold secondary1--text"
                >Return to Login</router-link
              >
            </p>
          </v-form>
          <v-btn
            depressed
            width="100%"
            v-if="!!confirmForgotPassword"
            class="mb-0 mt-2 text-center font-weight-bold primary4--text"
            color="secondary1"
            @click="formSubmit"
            :disabled="isDisable"
            >{{
              isDisable
                ? 'Resend in ' + countDown + ' seconds...'
                : 'RESEND RECOVERY EMAIL'
            }}</v-btn
          >
          <p class="mb-0 mt-2 text-center" v-if="!!confirmForgotPassword">
            <router-link to="/login" class="font-weight-bold secondary1--text"
              >Return to Login</router-link
            >
          </p>
        </ValidationObserver>
      </v-card-text>
      <v-overlay v-model="hasLoading" absolute color="primary">
        <!-- <v-progress-circular indeterminate color="white"></v-progress-circular> -->
        <LottiePlayer
          style="width: 80px; height: 80px"
          :animation-data="LoadingJson"
          :loop="true"
        ></LottiePlayer>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import logoImage from '../../assets/logo_login.png';
import api from '../../api/authenticate';
import LoadingJson from '../../assets/lottieData/Loading/Loading_2.json';
import LottiePlayer from '../../components/_lottie-player';
export default {
  components: {
    LottiePlayer,
  },
  data() {
    return {
      email: '',
      logoImage,
      confirmForgotPassword: false,
      hasLoading: false,
      LoadingJson,
      isDisable: false,
      countDown: 30,
    };
  },
  methods: {
    formSubmit() {
      let self = this;
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.hasLoading = true;
          api
            .forgotPassword(self.email)
            .then((res) => {
              if (res.data.isSuccess) {
                if (self.confirmForgotPassword) {
                  this.$notify.success('Resend email successfully.');
                  this.isDisable = true;
                  let interval = setInterval(() => {
                    this.countDown--;
                    if (this.countDown == 0) {
                      this.isDisable = false;
                      clearInterval(interval);
                      this.countDown = 30;
                    }
                  }, 1000);
                }
                self.confirmForgotPassword = true;
              } else {
                this.$notify.error(
                  res.error ||
                    'This email does not exist. Please retry with a different one.',
                );
              }
            })
            .catch(function (err) {
              self.$notify.error(err.message);
            })
            .finally(() => {
              this.hasLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.v-btn >>> .font-weight-bold {
  text-decoration: none !important;
}
</style>
