<template>
  <v-popup v-model="isShow" :loading="isLoading">
    <template v-slot:title> EDIT ROLE </template>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit, reset }"
      tag="div"
    >
      <v-form
        @submit.prevent="handleSubmit(formOnSubmit)"
        @reset.prevent="reset"
      >
        <ValidationProvider name="Role Name" rules="required">
          <v-label>
            <strong class="primary--text">Role Name</strong>
          </v-label>
          <v-text-field
            v-model="initData.name"
            class="py-0"
            outlined
            dense
            disabled
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider name="user">
          <v-label>
            <strong class="primary--text">User</strong>
          </v-label>
          <v-multiple-select
            v-model="users"
            :data-items="allUser"
            item-value="id"
            :item-text="'fullName'"
            :displayItemText="(item) => displayValue(item)"
            placeholder="Select user..."
            :closeOnContentClick="false"
            :displayValue="(item) => displayValue(item)"
          >
            <template v-slot:item="slotProps">
              <v-list-item v-if="slotProps.item.id == -1">
                <v-list-item-content>
                  <v-list-item-title class="pl-2 primary3--text"
                    >{{ slotProps.item.fullName }} ({{ slotProps.item.id }})
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-content v-else>
                <v-list-item-title class="pl-2 primary--text"
                  >{{ slotProps.item.fullName }} ({{ slotProps.item.id }})
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="slotProps">
              <v-chip
                label
                :close="slotProps.item.id != -1"
                outlined
                class="chip-category"
                v-bind="slotProps.attrs"
                :input-value="slotProps.selected"
                @click:close="slotProps.remove(slotProps.item)"
                :close-icon="$vuetify.icons.values.close"
                >{{ displayValue(slotProps.item) }}</v-chip
              >
            </template>
          </v-multiple-select>
        </ValidationProvider>
        <div class="mt-2">
          <v-btn
            type="submit"
            depressed
            class="mr-5 font-weight-bold primary4--text"
            color="secondary1"
            width="100"
            >SAVE</v-btn
          >
          <v-btn
            type="reset"
            depressed
            class="font-weight-bold mr-5"
            color="error"
            width="100"
            @click="onCancelClick"
            >CANCEL</v-btn
          >
        </div>
      </v-form>
    </ValidationObserver>
  </v-popup>
</template>
<script>
import api from '../../../api/authenticate';

export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    initData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    if (this.$store.state.authenticate.users.length == 0)
      this.$store.dispatch('authenticate/getAllUser');
    this.getProfileId();
  },
  data() {
    return {
      allUser: this.$store.state.authenticate.users,
      users: '',
      isLoading: false,
    };
  },
  computed: {
    isShow: {
      get() {
        return this.isShowPopup;
      },
      set(val) {
        this.$emit('update:isShowPopup', val);
      },
    },
  },
  methods: {
    displayValue(item) {
      return `${item.fullName} (${item.id})`;
    },
    onChange(event) {
      console.log(event);
    },
    onCancelClick() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.isShow = false;
    },
    formOnSubmit() {
      this.isLoading = true;
      api
        .updateRole(this.initData.id, this.users)
        .then((res) => {
          if (res.statusText == 'OK') {
            this.$notify.success(res.data);
            this.$emit('fetchData');
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.isShow = false;
        });
    },
    getProfileId() {
      api.getProfileIdsByRoleId(this.initData.id).then((res) => {
        if (res.status === 200) {
          this.users = [...res.data];
        }
      });
    },
  },
};
</script>
<style scoped>
.chip-category >>> .v-icon__svg {
  fill: red !important;
}
</style>
