<template>
  <v-card
    flat
    height="100%"
    style="background-position-y: bottom; overflow: hidden auto"
    :img="backgroundImage"
    class="white"
  >
    <v-list color="transparent" class="d-inline-block" style="width: 100%">
      <v-row class="my-0">
        <v-col cols="12" style="max-width: 462px" class="py-0">
          <template v-for="(item, key) in pageList">
            <v-hover v-slot:default="{ hover }" :key="key">
              <v-list-item
                :to="item.path"
                dense
                class="v-list-item--none-hover"
                :ripple="false"
              >
                <v-list-item-avatar size="38" tile>
                  <v-btn
                    :outlined="!hover"
                    height="38px"
                    width="38px"
                    min-width="38px"
                    class="pa-0"
                    :ripple="false"
                    color="primary"
                  >
                    <v-icon
                      v-if="!item.isSvgIcon"
                      size="24"
                      v-text="item.icon"
                    ></v-icon>
                    <v-icon-svg
                      v-else
                      :height="'24px'"
                      :width="'24px'"
                      :path="item.icon"
                      :transform="!!item.transform ? item.transform : ''"
                    ></v-icon-svg>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content
                  class="font-weight-bold font-size-medium"
                  >{{ item.name }}</v-list-item-content
                >
              </v-list-item>
            </v-hover>
          </template>
        </v-col>
      </v-row>
    </v-list>
  </v-card>
</template>

<script>
import { /*customPushNoti,*/ customYouTubeApi } from '../../plugins/icons';
import backgroundImage from '../../assets/home-page-bg.png';

export default {
  data() {
    return {
      backgroundImage: backgroundImage,
      pageList: [
        // {
        //   name: 'Push Notifications',
        //   path: '/moderation/push-notifications',
        //   icon: customPushNoti,
        //   isSvgIcon: true,
        //   transform: 'translate(-584 -375.025) scale(0.65)',
        // },
        {
          name: 'Manage Resources Synced',
          path: '/moderation/manage-youtube-api',
          icon: customYouTubeApi,
          isSvgIcon: true,
          transform: 'translate(-539.513 -373.156) scale(0.6)',
        },
      ],
    };
  },
};
</script>

<style scoped>
.svg-icon__transform {
  transform: translate(-2px, -2px);
}
</style>
