<template>
  <v-snackbar
    style="min-height: 30px; color: white"
    :class="maxWidth ? 'fullWidth' : ''"
    v-model="active"
    left
    :timeout="timeout"
    :color="color"
  >
    <div v-html="text"></div>
    <template v-slot:action="">
      <v-btn
        v-if="extendText != ''"
        dark
        color="white"
        text
        @click="
          active = false;
          $emit('extend-click');
        "
        small
        class="mr-0"
        >{{ extendText }}</v-btn
      >
      <v-btn
        v-for="(item, index) in buttons"
        :key="index"
        dark
        :color="item.color || 'primary'"
        text
        @click="
          active = false;
          $emit('extend-actions-click', index);
        "
        :class="index != 0 ? 'ml-0' : 'mr-0'"
        small
        >{{ item.text }}</v-btn
      >
      <v-icon
        small
        v-text="icons.mdiClose"
        @click="active = false"
        :class="{ 'ml-0': extendText != '' || buttons }"
        dark
      ></v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiClose } from '@mdi/js';
export default {
  props: {
    text: {
      type: String,
    },
    showClose: {
      type: Boolean,
    },
    extendText: {
      type: String,
    },
    color: {
      type: String,
    },
    timeout: {
      type: Number,
    },
    buttons: {
      type: Array,
    },
    maxWidth: {
      type: Boolean,
    },
  },
  data() {
    return {
      active: false,
      icons: {
        mdiClose,
      },
    };
  },
  mounted() {
    this.active = true;
  },
  watch: {
    active(active, wasActive) {
      this.$emit('statusChange', active, wasActive);
    },
  },
};
</script>
<style lang="scss">
.v-snack {
  .v-snack__wrapper {
    max-height: 30px;
    min-height: 30px;
    color: #ffffff !important;
    margin: 10px 20px;
  }
}
.fullWidth {
  .v-snack__wrapper {
    max-height: 30px;
    max-width: 100%;
    width: 100%;
    margin: 0;
    color: #ffffff !important;
    .v-snack__content {
      font-size: 14px;
      margin-left: 60px;
    }
    button {
      background: white;
      border-radius: 50%;
      color: red;
      font-weight: bold;
    }
  }
}
</style>
>
