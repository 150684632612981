var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"pr-10px",staticStyle:{"max-height":"calc(100% - 80px)","overflow":"hidden auto"},attrs:{"tag":"div"}},[_c('v-form',[_c('v-row',{staticClass:"mb-1 mt-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-label',[_c('strong',[_vm._v("Title")])]),_c('ValidationProvider',{staticClass:"flex-grow-1 primary--text",attrs:{"rules":"required|max:200","name":"Title","customMessages":{
            max: 'The Title field cannot be greater than 200 characters',
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"py-0",attrs:{"outlined":"","counter":"200","dense":"","placeholder":("Enter title for " + (_vm.inforData.typeName.toLowerCase())),"error-messages":errors},model:{value:(_vm.objectData.title),callback:function ($$v) {_vm.$set(_vm.objectData, "title", $$v)},expression:"objectData.title"}})]}}])})],1)],1),_c('v-row',{staticClass:"mb-1 mt-0"},_vm._l((_vm.objectData.listChapter),function(item,index){return _c('v-col',{key:index,staticClass:"py-0",attrs:{"cols":"12"}},[_c('fieldset',{staticClass:"v-fieldset mb-2"},[_c('legend',[_vm._v("CHAPTER "+_vm._s(index + 1))]),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[(_vm.objectData.listChapter.length > 1)?_c('v-btn',{staticStyle:{"position":"absolute","right":"5px","top":"-23px"},attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.objectData.listChapter.splice(index, 1)}}},[_c('v-icon',{staticStyle:{"transform":"translate(4px, 3px)"}},[_vm._v(" "+_vm._s(_vm.icons.customTrash)+" ")])],1):_vm._e(),_c('v-label',[_c('strong',[_vm._v("Description")])]),_c('ValidationProvider',{staticClass:"flex-grow-1 primary--text",attrs:{"rules":"max:400","name":"Description","customMessages":{
                  max: 'The Description field cannot be greater than 400 characters',
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-textarea',{staticClass:"py-0",attrs:{"outlined":"","dense":"","counter":"400","error-messages":errors},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('select-file',{attrs:{"file":item.attachments,"fileRemove":item.deletedFiles,"labelName":"Add Audio Book File","isMutiple":false,"imagePreviewProps":item.imagePreview,"validFiles":"audio/*"},on:{"change:file":function($event){return _vm.addChapterFile(index, $event)},"remove:file":function($event){return _vm.removeChapterFile(index, $event)},"hasDeletedPreview":function($event){item.imagePreview = ''}}}),(!!item.isError)?_c('p',{staticClass:"error--text text-right mb-0 font-italic",staticStyle:{"font-size":"12px","line-height":"14px"}},[_vm._v(" File is required ")]):_vm._e()],1)],1)],1)])}),1),_c('v-btn',{staticClass:"px-0 mb-3",attrs:{"text":"","tile":"","height":"25"},on:{"click":_vm.addChapter}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"padding-bottom":"1px"},attrs:{"color":"secondary1"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlusThick)+" ")]),_vm._v(" Add New Chapter ")],1),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0"},[_c('ValidationProvider',{attrs:{"rules":"tagsMax","name":"Tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b',[_vm._v("Tags")]),_c('v-multiple-select',{attrs:{"hide-selected":"","createable":"","displayValue":function (val) { return '#' + val; },"error-messages":errors,"messages":" "},on:{"input":_vm.onTagsInput,"update:search-input":function($event){_vm.tagsSearchInput = $event || ''}},scopedSlots:_vm._u([(!errors[0])?{key:"message",fn:function(){return [_c('div',{staticClass:"mb-0",class:{
                  'v-counter theme--light': true,
                  'error--text': _vm.computedTagsCounter > 500,
                }},[_vm._v(" "+_vm._s(_vm.computedTagsCounter)+" / 500 ")])]},proxy:true}:null],null,true),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})]}}])})],1)],1)],1),_c('v-overlay',{attrs:{"color":"white","absolute":"","opacity":".5"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-loading-spinner')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }