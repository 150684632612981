import axios from 'axios';
import { mapUrl, mapQueryParams } from '../';
import store from '../../store';
import moment from 'moment';

const getByName = function (name) {
  return axios.get('gridconfig/' + encodeURIComponent(name));
};
const getListDataByUrl = function (url, parrams) {
  let parramsBuilded = parrams;
  let params;
  if (parramsBuilded.searchText != undefined) {
    params = mapQueryParams({
      searchText: parramsBuilded.searchText,
      page: parramsBuilded.page,
      pageSize: parramsBuilded.pageSize,
      ...parramsBuilded.requestData,
    });
  } else {
    params = mapQueryParams({
      page: parramsBuilded.page,
      pageSize: parramsBuilded.pageSize,
      ...parramsBuilded.requestData,
    });
  }
  return axios.get(mapUrl(url + '?' + params), {
    params: {
      filters: parramsBuilded.filters,
      sort: parramsBuilded.sort,
      aggregates: parramsBuilded.aggregates,
    },
  });
};
const addConfig = function (name, setting, type, isPublic) {
  let fd = new FormData();
  fd.append('name', name);
  fd.append('setting', JSON.stringify(setting));
  fd.append('type', type);
  fd.append('isPublic', !!isPublic);
  return axios.put('gridconfig', fd);
};
const saveChange = function (id, setting) {
  let fd = new FormData();
  fd.append('setting', JSON.stringify(setting));
  return axios.put('gridconfig/' + id, fd);
};
const duplicate = function (configId, name, setting, type, isPublic) {
  let fd = new FormData();
  fd.append('name', name);
  fd.append('setting', JSON.stringify(setting));
  fd.append('type', type);
  fd.append('isPublic', !!isPublic);
  return axios.post('gridconfig/duplicate/' + configId, fd);
};
const setDefault = function (id, type) {
  return axios.post('gridconfig/setDefault/' + id + '?type=' + type);
};
const rename = function (id, name, isPublic) {
  return axios.post(
    'gridconfig/rename/' + id + '?name=' + name + '&isPublic=' + isPublic,
  );
};
const remove = function (id) {
  return axios.delete('gridconfig/' + id);
};
const share = function (id) {
  return axios.post('gridconfig/share/' + id);
};
const buildRequestParrams = function (
  search,
  page,
  pageSize,
  sort,
  filters = [],
  requestData,
  aggregates,
) {
  const comparisonByOperator = store.getters['enums/comparisonByOperator'];
  let params = {};
  if (search) {
    params['searchText'] = search;
  }
  params['page'] = page || 1;
  params['pageSize'] = pageSize == 0 ? 0 : pageSize || 20;
  params['requestData'] = requestData || {};
  let datafilter = JSON.stringify(
    filters.reduce((fs, x) => {
      let f = {
        PropertyName: x.propertyName,
        Value:
          x.value instanceof Date
            ? moment(x.value).format('YYYY-MM-DDTHH:mm:ss')
            : x.value,
        Comparison: comparisonByOperator(x.comparison).id,
      };
      if (x.and) {
        let value =
          x.and.value instanceof Date
            ? moment(x.and.value).format('YYYY-MM-DDTHH:mm:ss')
            : x.and.value;
        Object.assign(f, {
          AndFilter: {
            PropertyName: x.and.propertyName,
            Value: value,
            Comparison: comparisonByOperator(x.and.comparison).id,
          },
        });
      }
      if (x.or) {
        let value =
          x.or.value instanceof Date
            ? moment(x.or.value).format('YYYY-MM-DDTHH:mm:ss')
            : x.or.value;
        Object.assign(f, {
          OrFilter: {
            PropertyName: x.or.propertyName,
            Value: value,
            Comparison: comparisonByOperator(x.or.comparison).id,
          },
        });
      }
      fs.push(f);
      return fs;
    }, []),
  );
  let dataSort = null;
  if (sort) {
    dataSort = JSON.stringify(sort);
  }
  let dataAggregates = null;
  if (aggregates) {
    dataAggregates = [];
    Object.keys(aggregates).forEach((c) => {
      dataAggregates.push({
        propertyName: c,
        types: Object.keys(aggregates[c]),
      });
    });
    dataAggregates = JSON.stringify(dataAggregates);
  }
  params['filters'] = datafilter;
  params['sort'] = dataSort;
  params['aggregates'] = dataAggregates;
  return params;
};

export default {
  getByName,
  getListDataByUrl,
  addConfig,
  saveChange,
  duplicate,
  setDefault,
  rename,
  remove,
  buildRequestParrams,
  share,
};
