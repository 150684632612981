<template>
  <v-card
    flat
    height="100%"
    style="background-position-y: bottom; overflow: hidden auto"
    class="white d-flex justify-center align-center"
    :img="backgroundImage"
  >
    <div class="text-center" style="margin-top: -200px">
      <p class="mb-0 text-uppercase" style="font-size: 26px">
        <strong>
          HELLO
          <span class="warning--text"> {{ displayName }}</span>
          <span>!</span>
        </strong>
      </p>
      <h1 style="font-size: 50px; line-height: 60px" class="text-uppercase">
        Welcome Back To Dr. Berg app
      </h1>
    </div>
  </v-card>
</template>

<script>
import backgroundImage from '../assets/home-page-bg.png';

export default {
  name: 'Home',
  computed: {
    displayName() {
      return this.$store.state.authenticate.user.profileName;
    },
  },
  data() {
    return {
      backgroundImage: backgroundImage,
    };
  },
};
</script>
