var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('v-form',{staticClass:"mt-10",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.formOnSubmit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-row',[_c('div',{staticClass:"pa-10px",style:([0, 1].includes(_vm.breakPointWidth)
            ? 'width: 520px;'
            : _vm.breakPointWidth == 2
            ? 'width: calc(50% - 20px)'
            : 'width: calc(100% - 20px)')},[_c('v-row',{staticClass:"ml-3"},[_c('v-col',{staticClass:"py-0 pl-0 pr-10px",attrs:{"cols":"6"}},[_c('v-label',[_c('strong',{staticClass:"primary--text"},[_vm._v("First Name")])]),_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required","customMessages":{ required: 'Enter first name' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"py-0",attrs:{"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", $$v)},expression:"formData.firstName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-label',[_c('strong',{staticClass:"primary--text"},[_vm._v("Last Name")])]),_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required","customMessages":{ required: 'Enter last name' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"py-0",attrs:{"type":"text","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", $$v)},expression:"formData.lastName"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"ml-3"},[_c('v-col',{staticClass:"py-0 pl-0 mb-3 pr-0",attrs:{"cols":"12"}},[_c('v-label',[_c('strong',{staticClass:"primary--text"},[_vm._v("User Name")])]),_c('v-text-field',{staticClass:"py-0 primary--text primary3",attrs:{"readonly":"","disabled":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formData.userName),callback:function ($$v) {_vm.$set(_vm.formData, "userName", $$v)},expression:"formData.userName"}})],1)],1),_c('v-row',{staticClass:"ml-3"},[_c('v-col',{staticClass:"py-0 pl-0 mb-3 pr-0",attrs:{"cols":"12"}},[_c('v-label',[_c('strong',{staticClass:"primary--text"},[_vm._v("Email")])]),_c('v-text-field',{staticClass:"py-0 primary--text primary3",attrs:{"readonly":"","disabled":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-1 pt-0 pl-0 ml-5",attrs:{"cols":"12"}},[(!_vm.isShowChangePass)?_c('a',{staticClass:"secondary1--text text-decoration",on:{"click":function($event){return _vm.openChangePass()}}},[_c('strong',[_vm._v("Change password")])]):_vm._e()])],1)],1),_c('div',{staticClass:"pa-10px",style:([0, 1].includes(_vm.breakPointWidth)
            ? 'width: 520px;'
            : _vm.breakPointWidth == 2
            ? 'width: calc(50% - 10px)'
            : 'width: calc(100% - 30px)')},[_c('v-row',[(_vm.isShowChangePass)?_c('v-col',{staticClass:"py-0 pl-0 ml-5",attrs:{"cols":"12"}},[_c('v-label',{staticClass:"primary--text",attrs:{"for":"currentPassword"}},[_c('strong',{staticClass:"primary--text"},[_vm._v("Current Password")])]),_c('ValidationProvider',{attrs:{"rules":"required","name":"current Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"py-0",attrs:{"name":"currentPassword","type":"password","error-messages":errors,"outlined":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(errors[0])}})]},proxy:true}],null,true),model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[(_vm.isShowChangePass)?_c('v-col',{staticClass:"py-0 pl-0 ml-5",attrs:{"cols":"12"}},[_c('v-label',{attrs:{"for":"password"}},[_c('strong',{staticClass:"primary--text"},[_vm._v("New Password")])]),_c('ValidationProvider',{attrs:{"rules":"required|passwordRegex","vid":"password","name":"New Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"py-0",class:!!errors[0] &&
                  errors[0].indexOf('minimum of 6 characters') > -1
                    ? 'alert-error-message'
                    : '',attrs:{"error-messages":errors,"name":"password","type":"password","outlined":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(errors[0])}})]},proxy:true}],null,true),model:{value:(_vm.formData.newPassword),callback:function ($$v) {_vm.$set(_vm.formData, "newPassword", $$v)},expression:"formData.newPassword"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[(_vm.isShowChangePass)?_c('v-col',{staticClass:"py-0 pl-0 ml-5",attrs:{"cols":"12"}},[_c('v-label',{attrs:{"for":"confirmPassword"}},[_c('strong',{staticClass:"primary--text"},[_vm._v("Confirm New Password")])]),_c('ValidationProvider',{attrs:{"rules":"required|password:@password","name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{staticClass:"py-0",attrs:{"name":"confirmPassword","type":"password","error-messages":errors,"outlined":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(errors[0])}})]},proxy:true}],null,true),model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}})]}}],null,true)})],1):_vm._e()],1)],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }