/**
 * Convert a string to HTML entities
 */
String.prototype.toHtmlEntities = function () {
  return this.replace(/./gm, function (s) {
    // return "&#" + s.charCodeAt(0) + ";";
    return s.match(/[a-z0-9\s]+/i) ? s : '&#' + s.charCodeAt(0) + ';';
  });
};

/**
 * Create string from HTML entities
 */
String.fromHtmlEntities = function (string) {
  return (string + '').replace(/&#\d+;/gm, function (s) {
    return String.fromCharCode(s.match(/\d+/gm)[0]);
  });
};

export function getThumbnailImage(imgUrl) {
  let url = imgUrl;
  if (
    url &&
    url.match(/drbergstorage.blob.core.windows.net/g) &&
    !url.match(/_w64.png($|\?)/g)
  ) {
    let ext = /[.]/.exec(url) ? /[^.]+$/.exec(url) : undefined;
    if (ext) {
      let regex = `\\.(${ext[0].replace('.', '')})($|\\?)`;
      url = url.replace(new RegExp(regex), '_w64.png$2');
    }
  }
  return url;
}
