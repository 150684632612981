import axios from 'axios';
import store from '../store';
import moment from 'moment';
import { mapQueryParamsEncodeURI } from './';
async function install(Vue) {
  axios.defaults.baseURL = window.location.origin + '/api';
  axios.defaults.headers.common['TimeZone'] = moment().utcOffset();

  axios.downloadFile = function (id = null, url, queryPath) {
    let basePath = url || '/documents/Download';
    let downloadUrl = id ? `${basePath}/${id}` : basePath;
    if (queryPath) {
      downloadUrl = downloadUrl + '?' + mapQueryParamsEncodeURI(queryPath);
    }
    return axios
      .get(downloadUrl, {
        responseType: 'blob', // had to add this one here
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          /filename[^;\n=]*=((['']).*?\2|[^;\n]*)/
            .exec(response.headers['content-disposition'])[1]
            .replace(/'/g, ''),
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        if (error.response.status === 404)
          Vue.prototype.$notify.error('File not found');
        else console.error(error);
      });
  };

  axios.downloadAudio = function (url, title) {
    return axios
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${title}.wav`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        if (error.response.status == '404')
          Vue.prototype.$notify.error('File not found');
        else console.error(error);
      });
  };
  axios.ping = async function () {
    return new Promise(function (resolve) {
      axios
        .get('/pip')
        .then((res) => {
          if (res.status >= 200 && res.status <= 308) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  };
  Vue.prototype.$http = axios;
  axios.interceptors.response.use(undefined, function (err) {
    return new Promise(function () {
      if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        store.dispatch('authenticate/logout');
      }
      throw err;
    });
  });
  const token = localStorage.getItem('user-token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    let t0 = performance.now();
    await store
      .dispatch('authenticate/validUser')
      .then((response) => {
        let t1 = performance.now();
        Vue.prototype.$user = response.data;
        Vue.prototype.$commonConfig = response.data.commonConfig;
        let utcNow = moment.utc(response.headers.date);
        utcNow.add(t1 - t0 + 1, 'ms');
        window.dateNow = utcNow.local().toDate();
        t0 = performance.now();
        setInterval(() => {
          t1 = performance.now();
          utcNow.add(100 + (t1 - t0 - 100), 'ms');
          window.dateNow = utcNow.local().toDate();
          t0 = performance.now();
        }, 100);
      })
      .catch((err) => {
        store.dispatch('authenticate/logout');
        throw err;
      });
  }
}

export default { install };
