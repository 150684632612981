<template>
  <v-card outlined class="v-panel" :class="computedContentClass">
    <v-toolbar height="40" dense :color="color" elevation="0">
      <slot name="toolbar:prepend"></slot>
      <v-toolbar-title class="font-size-medium font-weight-bold">{{
        title
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="toolbar:append"></slot>
      <v-btn icon @click="isExpand = !isExpand" dark v-if="!hideExpand">
        <v-icon v-text="icons.mdiChevronDown"></v-icon>
      </v-btn>
    </v-toolbar>
    <v-expand-transition>
      <div v-show="isExpand" class="v-panel__content">
        <slot></slot>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mdiChevronDown } from '@mdi/js';
export default {
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary white--text',
    },
    hideExpand: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: [String, Array],
      default: '',
    },
  },
  computed: {
    isExpand: {
      get() {
        return typeof this.value == 'undefined' ? this.expand : this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.expand = val;
      },
    },
    computedContentClass() {
      let classes = ['v-panel'];
      if (Array.isArray(this.contentClass)) {
        classes = classes.concat(this.contentClass);
      } else if (this.contentClass) {
        classes.push(this.contentClass);
      }
      return classes;
    },
  },
  data() {
    return {
      expand: true,
      icons: {
        mdiChevronDown,
      },
    };
  },
};
</script>

<style scope>
.v-panel {
  border: solid thin var(--color-primary2) !important;
}
.v-panel a {
  color: var(--color-secondary1) !important;
}
.v-panel p {
  margin-bottom: 8px !important;
}
</style>
