import Moderation from '../views/moderation';
import Moderation_Nav from '../views/moderation/nav.vue';
import Manage_Youtube_Api from '../views/moderation/_manage-youtube-api.vue';
import Push_Notification from '../views/moderation/_push-notification.vue';

export default [
  {
    path: '/moderation',
    name: 'moderation',
    component: Moderation,
    meta: {
      title: 'Moderation',
    },
    children: [
      {
        path: '/',
        component: Moderation_Nav,
        meta: {
          title: 'Moderation',
          roles: 'Administrator,Editor',
        },
      },
      {
        path: 'manage-youtube-api',
        component: Manage_Youtube_Api,
        meta: {
          title: 'Resources Synced',
          breadcrumbs: () => [
            { text: 'Moderation', to: '/moderation' },
            {
              text: 'Manage Resources Synced',
              to: '/moderation/manage-youtube-api',
            },
          ],
          roles: 'Administrator,Editor',
        },
      },
      {
        path: 'push-notifications',
        component: Push_Notification,
        meta: {
          title: 'Push Notification',
          breadcrumbs: () => [
            { text: 'Moderation', to: '/moderation' },
            {
              text: 'Push Notifications',
              to: '/moderation/push-notification',
            },
          ],
        },
      },
    ],
  },
];
