const state = {
  user: {
    isAuthorized: false,
  },
  roles: [],
  appStatus: 'finished',
  users: [],
};
const getters = {
  userRoles: (state) => (state.user.role || '').split(','),
  roles: (state) => state.roles || [],
};
export { state, getters };
