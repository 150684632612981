import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import customColors from './custom-colors';
Vue.use(Vuetify);
Vue.prototype.$customColors = customColors;
const opts = {
  theme: {
    themes: {
      light: {
        primary: '#013253',
        secondary: '#3076A5',
        accent: '#5F6368',
        error: '#FA2B2B',
        warning: '#F99F3D',
        info: '#116ADF',
        success: '#00B95D',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      checkboxOff:
        'M6,5C5.4,5,5,5.4,5,6v12c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1H6 M6,4h12c1.1,0,2,0.9,2,2 v12c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V6C4,4.9,4.9,4,6,4z',
      checkboxOn:
        'M6.1,4H18c1.1,0,2,0.9,2,2V18c0,1.1-0.9,2-2,2H6.1c-1.1-0.1-2-1-2-2V6.1C4,4.9,4.9,4,6.1,4z M19,5.4 c-4.8,2.4-7.9,9.5-7.9,9.5l-1.9-3.5l-3.9,2.3c2.4,1.1,4.4,2.7,6,4.8c1.4-2.5,5.7-7.6,7.9-8C18.4,8.5,18.9,7.1,19,5.4z',
    },
  },
};

export default new Vuetify(opts);
