<template>
  <v-popup v-model="isShow" :loading="isLoading" xxxxLarge contentClass="pb-5">
    <template v-slot:title>Coupon detail</template>
    <v-card elevation="0" class="d-flex flex-column" width="100%">
      <p class="font-italic mb-0" style="line-height: 18px">
        {{ $moment(initData.updatedOn).format('MMMM DD, YYYY hh:mm A') }}
      </p>
      <v-card-title class="pa-0 my-2 font-size-subtitle-1 font-weight-bold">
        {{ initData.title }}
      </v-card-title>
      <v-row class="d-flex flex-row mb-2 mt-0">
        <v-col cols="4" class="d-flex flex-column">
          <span>
            <strong>Created By: </strong>
            {{ initData.createdByName }} ({{ initData.createdById }})
          </span>
          <span>
            <strong>Category: </strong>
            {{ initData.resourceCategoryName }}
          </span>
        </v-col>
        <v-col cols="2" class="d-flex flex-column">
          <span>
            <strong>Status: </strong>
            {{ initData.statusName }}
          </span>
          <span>
            <strong>Type: </strong>
            {{ initData.typeName }}
          </span>
        </v-col>
        <v-col cols="6" class="mb-0">
          <strong>
            Link Coupon:
            <a
              style="word-wrap: break-word; word-break: break-all"
              class="secondary1--text"
              :href="initData.link"
              target="_blank"
            >
              {{ modifyLink(initData.link) }}
            </a>
          </strong>
        </v-col>
      </v-row>
      <div class="mb-2">
        {{ initData.description }}
      </div>
      <v-row class="mt-0 mb-2">
        <v-col>
          <v-label>
            <strong>Featured Image: </strong>
          </v-label>
          <v-img
            v-if="initData.featuredImage"
            max-height="100"
            min-height="100"
            min-width="100"
            max-width="100"
            :src="initData.featuredImage"
          ></v-img>
          <v-no-image v-else />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <strong class="mr-2">Tags:</strong>
          <template v-for="(tag, index) in initData.listTags">
            <v-chip
              v-if="tag != ''"
              class="mr-2 px-1 primary--text mb-2"
              color="primary4"
              label
              small
              outlined
              :key="index"
            >
              <span class="font-size-medium">#{{ tag }}</span>
            </v-chip>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <template v-slot:footer>
      <v-btn
        elevation="0"
        color="info white--text"
        class="mr-5"
        width="100px"
        @click="onEdit"
        v-if="!initData.isTrash"
      >
        <strong>EDIT</strong>
      </v-btn>
      <div v-else class="d-inline mr-10px">
        <v-btn
          class="px-2"
          color="error"
          elevation="0"
          @click.stop="onDelete(initData)"
        >
          <v-icon size="14" class="mr-1 mb-2px">
            {{ icons.mdiCloseThick }}
          </v-icon>
          <strong>DELETE PERMANENLY</strong>
        </v-btn>
        <v-btn
          class="ml-3 px-2"
          color="info"
          elevation="0"
          @click.stop="onRestore(initData)"
        >
          <v-icon size="14" class="pb-2px mr-1">
            {{ icons.mdiFileRestore }}
          </v-icon>
          <strong>RESTORE</strong>
        </v-btn>
      </div>
      <v-btn color="error" elevation="0" width="100px" @click="isShow = false">
        <strong>CANCEL</strong>
      </v-btn>
    </template>
  </v-popup>
</template>

<script>
import api from '../../../api/resources';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    listStatus() {
      return this.$store.state.enums.resourceStatus;
    },
    statusName() {
      return this.$store.state.enums.resourceStatus.find(
        (c) => c.value === this.initData.status,
      ).text;
    },
  },
  data() {
    return {
      initData: {
        status: 0,
        title: '',
        category: 0,
        type: '',
        featuredImageFile: [],
        couponLink: '',
        note: '',
        createdOn: this.$moment().format('MMMM DD, YYYY'),
        createdBy: '',
      },
      isLoading: false,
    };
  },
  watch: {
    isShow() {
      if (this.isShow) this.getCouponDetail();
    },
  },
  created() {
    this.getCouponDetail();
  },
  methods: {
    modifyLink(link) {
      if (link) {
        if (link.length > 150)
          return link.trim().substring(0, 147).concat('...');
        else return link.trim();
      } else return '';
    },
    getCouponDetail() {
      this.isLoading = true;
      api
        .getResourcesDetail(this.id)
        .then((res) => {
          if (res.status === 200) {
            this.initData = { ...res.data };
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRestore(item) {
      api.restoreResource(item.id).then((res) => {
        if (res.status === 200) {
          this.$notify.success(res.data.message);
          this.$emit('fetchData');
          this.isShow = false;
        }
      });
    },
    onDelete(item) {
      this.$confirm(
        `Are you sure you want to <span class='error--text'>DELETE</span> this ${item.typeName}?<br>
        <span class="secondary1--text">${item.title}</span>`,
        {
          warningMessage: `This action cannot be undone`,
        },
      ).then((isYes) => {
        if (isYes) {
          api
            .deteleResource(item.id)
            .then((res) => {
              if (res.status === 200) {
                this.$notify.success(res.data.message);
                this.$emit('fetchData');
                this.isShow = false;
              }
            })
            .catch((err) => this.$notify.error(err));
        }
      });
    },
    onEdit() {
      this.isShow = false;
      this.$router.push({
        path: `/resources/edit-coupon/${this.id}`,
      });
    },
  },
};
</script>

<style></style>
