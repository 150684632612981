<template>
  <v-autocomplete
    class="align-center ma-0"
    style="width: 514px"
    placeholder="Search for Resources items..."
    color="secondary1"
    dense
    outlined
    :loading="searchResource.isLoading"
    :search-input.sync="searchResource.text"
    :items="searchResource.items"
    :item-text="'searchText'"
    :item-value="(item) => `${item.typeName}/${item.id}`"
    no-filter
    :menu-props="{ contentClass: 'v-dropdownlist__menu__content' }"
    @change="onEnterRedirectDetail"
    v-model="searchResource.params"
  >
    <template v-slot:append>
      <v-icon-svg small class="primary--text" :path="icons.search"></v-icon-svg>
    </template>
    <template v-slot:item="data">
      <v-row class="my-0" style="max-width: 645px">
        <v-col cols="6" class="d-flex flex-column pa-10px">
          <div>
            <strong>Id:</strong>
            {{ data.item.id }} |
            <strong>Status: </strong>
            {{ data.item.statusName }}
          </div>
          <div>
            <strong>Type:</strong>
            {{ data.item.typeName }}
          </div>
        </v-col>
        <v-col cols="6" class="d-flex flex-column pa-10px">
          <div>
            <strong>Title:</strong>
            {{ data.item.title }}
          </div>
          <div>
            <strong>Tags:</strong>
            {{ data.item.tags }}
          </div>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { customsearch } from '../plugins/icons';
import api from '../api/resources';

export default {
  props: {
    hasEnterRedirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        search: customsearch,
      },
      searchResource: {
        id: null,
        items: [],
        text: '',
        params: {},
        isLoading: false,
        pageSize: 10,
      },
      dataItem: {
        id: 0,
        typeName: 'Audio',
      },
      isShowPopup: false,
    };
  },
  watch: {
    'searchResource.text'() {
      if (this.searchResource.text) {
        this.getSearchEntity();
      }
    },
  },
  methods: {
    getSearchEntity() {
      const searchText =
        this.searchResource.text.length > 0
          ? this.searchResource.text.trim()
          : '';
      if (searchText && searchText.length > 0) {
        this.searchResource.isLoading = true;
        api
          .getResources(searchText, this.searchResource.pageSize)
          .then((res) => {
            this.searchResource.items = res.data.data;
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.searchResource.isLoading = false;
          });
      } else {
        this.searchResource.items = [];
        this.searchResource.isLoading = false;
      }
    },
    onEnterRedirectDetail(data) {
      this.dataItem.typeName = data.split('/')[0];
      this.dataItem.id = +data.split('/')[1];
      this.$emit('onShowPopup', this.dataItem);
      this.searchResource.params = {};
      this.searchResource.text = '';
      // const path = this.$route.path.toLowerCase();
      // if (path !== `/resources/edit-${data}`.toLowerCase()) {
      //   this.$router
      //     .push({
      //       path: `/resources/edit-${data}`,
      //     })
      //     .finally(() => {
      //       this.searchResource.params = '';
      //       this.searchResource.text = '';
      //     });
      // } else {
      //   this.searchResource.params = '';
      //   this.searchResource.text = '';
      // }
    },
  },
};
</script>

<style scoped>
.v-text-field.v-text-field--enclosed >>> .v-text-field__details {
  display: none;
}
.v-input ::v-deep .v-progress-linear {
  top: calc(100% - 3px) !important;
}
</style>
