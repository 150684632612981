<template>
  <LottiePlayer
    style="width: 80px; height: 80px"
    :animation-data="LoadingJson"
    :loop="true"
  ></LottiePlayer>
</template>

<script>
import LoadingJson from '../assets/lottieData/Loading/Loading_2.json';
import LottiePlayer from './_lottie-player';
export default {
  components: {
    LottiePlayer,
  },
  data() {
    return {
      LoadingJson,
    };
  },
};
</script>

<style></style>
