<template>
  <v-popup v-model="isShow" xxxxLarge :loading="isLoading" contentClass="pb-5">
    <template v-slot:title>{{ type }} detail</template>
    <v-card elevation="0" class="d-flex flex-column" width="100%">
      <p class="font-italic mb-0" style="line-height: 18px">
        {{ $moment(initData.updatedOn).format('MMMM DD, YYYY hh:mm A') }}
      </p>
      <v-card-title class="pa-0 my-2 font-size-subtitle-1 font-weight-bold">
        {{ initData.title }}
      </v-card-title>
      <div class="d-flex flex-row mb-2">
        <p class="d-flex flex-column col-4 mb-0 pl-0">
          <span>
            <strong>Created By: </strong>
            {{ initData.createdByName }} ({{ initData.createdById }})
          </span>
          <span>
            <strong>Category: </strong>
            {{ initData.resourceCategoryName }}
          </span>
        </p>
        <p class="d-flex flex-column col-4 mb-0">
          <span>
            <strong>Status: </strong>
            {{ statusName }}
          </span>
          <span>
            <strong>Type: </strong>
            {{ type }}
          </span>
        </p>
      </div>
      <v-row>
        <v-col
          cols="4"
          class="d-flex pa-2 ml-10px rounded cursor-pointer"
          style="border: 1px var(--color-primary2) solid"
          v-if="
            initData.bannerImage &&
            initData.bannerImage.length > 0 &&
            initData.type == 3
          "
          @click="onDownloadDocument(initData.bannerImage)"
        >
          <div style="height: 20px">
            <v-icon-svg
              height="20px"
              width="20px"
              class="secondary--text"
              :transform="setTransform()"
              :path="setFileIcon()"
            ></v-icon-svg>
          </div>
          <div class="ml-2">
            <p class="mb-0" style="line-height: 20px">
              <!-- <strong>File name: </strong> -->
              {{ getFileName(initData.bannerImage) }}
            </p>
            <!-- <p class="mb-0">
              <strong>Size: </strong>
            </p> -->
          </div>
        </v-col>
        <v-col
          cols="5"
          class="d-flex flex-column pa-5 ml-10px rounded"
          style="border: 1px var(--color-primary2) solid"
          v-if="
            initData.bannerImage &&
            initData.bannerImage.length > 0 &&
            initData.type != 3
          "
        >
          <audio-player
            v-if="isShow"
            class="mb-0"
            style="width: 100%; max-width: 100%; height: 36px"
            :audioURL="initData.bannerImage"
          />
          <!-- <div>
            <p class="mb-0" style="line-height: 20px">
              <strong>File name: </strong>
              {{ getFileName(initData.bannerImage) }}
            </p>
          </div> -->
        </v-col>
        <v-col cols="12">
          <div
            class="v-document__description"
            v-html="initData.description"
          ></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label>
            <strong>Featured Image: </strong>
          </v-label>
          <v-img
            v-if="initData.featuredImage"
            max-height="100"
            min-height="100"
            min-width="100"
            max-width="100"
            :src="initData.featuredImage"
          ></v-img>
          <v-no-image v-else />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0 mt-10px">
          <strong class="mr-2">Tags:</strong>
          <template v-for="(tag, index) in initData.listTags">
            <v-chip
              v-if="tag != ''"
              class="mr-2 px-1 primary--text mb-2"
              color="primary4"
              label
              small
              outlined
              :key="index"
            >
              <span class="font-size-medium">#{{ tag }}</span>
            </v-chip>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <template v-slot:footer>
      <v-btn
        elevation="0"
        color="info white--text"
        class="mr-5"
        width="100px"
        @click="onEdit"
        v-if="!initData.isTrash"
      >
        <strong>EDIT</strong>
      </v-btn>
      <div v-else class="d-inline mr-10px">
        <v-btn
          class="px-2"
          color="error"
          elevation="0"
          @click.stop="onDelete(initData)"
        >
          <v-icon size="14" class="mr-1 mb-2px">
            {{ icons.mdiCloseThick }}
          </v-icon>
          <strong>DELETE PERMANENLY</strong>
        </v-btn>
        <v-btn
          class="ml-3 px-2"
          color="info"
          elevation="0"
          @click.stop="onRestore(initData)"
        >
          <v-icon size="14" class="pb-2px mr-1">
            {{ icons.mdiFileRestore }}
          </v-icon>
          <strong>RESTORE</strong>
        </v-btn>
      </div>
      <v-btn color="error" elevation="0" width="100px" @click="isShow = false">
        <strong>CANCEL</strong>
      </v-btn>
    </template>
  </v-popup>
</template>

<script>
import { customFileIcon, customDocFile } from '../../../plugins/icons';
import AudioPlayer from '../../../components/_audio.vue';
import api from '../../../api/resources';

export default {
  components: {
    AudioPlayer,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'Document',
    },
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    listStatus() {
      return this.$store.state.enums.resourceStatus;
    },
    statusName() {
      return (
        this.$store.state.enums.resourceStatus.find(
          (c) => c.value === this.initData.status,
        )?.text || this.initData.statusName
      );
    },
  },
  data() {
    return {
      icons: { customFileIcon, customDocFile },
      initData: {
        status: 0,
        title: '',
        category: 0,
        type: '',
        description: '',
        createdOn: this.$moment().format('MMMM DD, YYYY'),
        createdBy: '',
      },
      isLoading: false,
    };
  },
  watch: {
    isShow() {
      if (this.isShow) this.getDetail();
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    onDownloadDocument(url) {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    getFileName(item) {
      if (item && item.length > 0) {
        const lastPath = item.lastIndexOf('/');
        if (lastPath !== -1)
          return this.modifyPath(item.substr(lastPath + 1, item.length));
        else return this.modifyPath(item);
      } else return '';
    },
    modifyPath(filePath) {
      const name = filePath.substr(
        filePath.lastIndexOf('.') - 2,
        filePath.length,
      );
      if (filePath.length > 30)
        return filePath
          .trim()
          .substring(0, 22)
          .concat('...' + name);
      else return filePath.trim();
    },
    setFileIcon() {
      if (this.initData.type == 3) {
        let file = this.initData.bannerImage;
        let fileName = file.substr(file.lastIndexOf('.') + 1, file.length);
        if (fileName === 'doc' || fileName === 'docx') {
          return this.icons.customDocFile;
        } else return this.icons.customFileIcon;
      } else return this.customFileIcon;
    },
    setTransform() {
      if (this.initData.type == 3) {
        let file = this.initData.bannerImage;
        let fileName = file.substr(file.lastIndexOf('.') + 1, file.length);
        if (fileName === 'doc' || fileName === 'docx') {
          return 'scale(0.4)';
        } else return 'translate(1 0) scale(0.17)';
      } else return 'translate(1 0) scale(0.17)';
    },
    getDetail() {
      this.isLoading = true;
      api
        .getResourcesDetail(this.id)
        .then((res) => {
          if (res.status === 200) {
            this.initData = { ...res.data };
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
      // const data = {
      //   id: this.id,
      //   status: 0,
      //   title: 'Mot title gi do',
      //   category: 0,
      //   type: this.type,
      //   featuredImageFile: [],
      //   attachments: [],
      //   description:
      //     "<p class='secondary--text font-weight-bold'>Mot cai description chang han</p>",
      //   createdOn: this.$moment().format('MMMM DD, YYYY'),
      //   createdBy: 'Tony Ciotti',
      // };
      // this.initData = { ...data };
    },
    onRestore(item) {
      api.restoreResource(item.id).then((res) => {
        if (res.status === 200) {
          this.$notify.success(res.data.message);
          this.$emit('fetchData');
          this.isShow = false;
        }
      });
    },
    onDelete(item) {
      this.$confirm(
        `Are you sure you want to <span class='error--text'>DELETE</span> this ${item.typeName}?<br>
        <span class="secondary1--text">${item.title}</span>`,
        {
          warningMessage: `This action cannot be undone`,
        },
      ).then((isYes) => {
        if (isYes) {
          api
            .deteleResource(item.id)
            .then((res) => {
              if (res.status === 200) {
                this.$notify.success(res.data.message);
                this.$emit('fetchData');
                this.isShow = false;
              }
            })
            .catch((err) => this.$notify.error(err));
        }
      });
    },
    onEdit() {
      this.isShow = false;
      this.$router.push({
        path: `/resources/edit-${
          this.type === 'Audio' ? 'audio' : 'document'
        }/${this.id}`,
      });
    },
  },
};
</script>

<style scoped>
/* .v-document__description {
  max-height: 300px;
  overflow-y: auto;
} */
.v-document__description >>> p {
  margin-bottom: 0 !important;
}
.v-document__description >>> img {
  max-width: 100%;
}
</style>
