<template>
  <v-popup v-model="isShow" :loading="isLoading" xxxxLarge contentClass="pb-5">
    <template v-slot:title>Recipe detail</template>
    <v-card elevation="0" class="d-flex flex-column" width="100%">
      <p class="font-italic mb-0" style="line-height: 18px">
        {{ $moment(initData.updatedOn).format('MMMM DD, YYYY hh:mm A') }}
      </p>
      <v-card-title class="pa-0 my-2 font-size-subtitle-1 font-weight-bold">
        {{ initData.title }}
      </v-card-title>
      <div class="d-flex flex-row">
        <p class="d-flex flex-column col-4 pl-0">
          <span>
            <strong>Created By: </strong>
            {{ initData.createdByName }} ({{ initData.createdById }})
          </span>
          <span>
            <strong>Category: </strong>
            {{ initData.resourceCategoryName }}
          </span>
        </p>
        <p class="d-flex flex-column col-4">
          <span>
            <strong>Status: </strong>
            {{ initData.statusName }}
          </span>
          <span>
            <strong>Type: </strong>
            {{ initData.typeName }}
          </span>
        </p>
      </div>
      <div class="d-flex" style="border: 1px solid var(--color-primary3)">
        <div v-if="initData.bannerImage" class="d-flex pa-10px rounded">
          <div>
            <v-img
              v-if="initData.bannerImage"
              max-height="100"
              min-height="100"
              min-width="100"
              max-width="100"
              :src="initData.bannerImage"
            ></v-img>
          </div>
          <!-- <div class="ml-5">
            <p class="mb-0">
              <strong>File name: </strong>
              {{ getFileName(initData.featuredImage) }}
            </p>
            <p class="mb-0">
              <strong>Size: </strong>
            </p>
          </div> -->
        </div>
        <div class="ml-20px d-flex flex-column justify-center">
          <p class="mb-0">
            <v-icon-svg
              width="10"
              height="10"
              class="mr-2"
              transform="translate(-19 -19) scale(2.2)"
              :path="icons.mdiCircleSmall"
            ></v-icon-svg>
            <strong>Serve: </strong>
            {{ initData.serve }}
          </p>
          <p class="mb-0">
            <v-icon-svg
              width="10"
              height="10"
              class="mr-2"
              transform="translate(-19 -19) scale(2.2)"
              :path="icons.mdiCircleSmall"
            ></v-icon-svg>
            <strong>Duration (min): </strong>
            {{ initData.duration }}
          </p>
          <p class="mb-0">
            <v-icon-svg
              width="10"
              height="10"
              class="mr-2"
              transform="translate(-19 -19) scale(2.2)"
              :path="icons.mdiCircleSmall"
            ></v-icon-svg>
            <strong>
              <span>Video URL: </span>
              <a
                class="secondary--text"
                :href="initData.videoURL"
                target="_blank"
              >
                {{ initData.videoURL }}
              </a>
            </strong>
          </p>
          <p class="mb-0">
            <v-icon-svg
              width="10"
              height="10"
              class="mr-2"
              transform="translate(-19 -19) scale(2.2)"
              :path="icons.mdiCircleSmall"
            ></v-icon-svg>
            <strong>Attribute: </strong>
            <template v-if="initData.calories">
              (Cal) {{ initData.calories }},
            </template>
            <template v-if="initData.fat">(Fat) {{ initData.fat }},</template>
            <template v-if="initData.carb">
              (Carb) {{ initData.carb }},
            </template>
            <template v-if="initData.protein">
              (Protein) {{ initData.protein }}
            </template>
          </p>
        </div>
      </div>
      <div class="v-recipe__description" v-html="initData.description"></div>
      <fieldset class="v-fieldset mb-2">
        <legend>ingredients</legend>
        <v-row class="my-0">
          <v-col v-if="ingredient" cols="2" class="py-0">
            <div class="d-flex pa-10px rounded">
              <div>
                <v-img
                  v-if="ingredient.filePath"
                  max-height="100"
                  min-height="100"
                  min-width="100"
                  max-width="100"
                  :src="ingredient.filePath"
                ></v-img>
                <v-no-image v-else />
              </div>
              <!-- <div class="ml-5">
                <p class="mb-0">
                  <strong>File name: </strong>
                  {{ getFileName(ingredient.filePath) }}
                </p>
                <p class="mb-0">
                  <strong>Size: </strong>
                </p>
              </div> -->
            </div>
          </v-col>
          <v-col v-if="ingredient" cols="10" class="py-0 d-flex">
            <div><strong>Description: </strong></div>
            <div class="ml-2" v-html="ingredient.description"></div>
          </v-col>
        </v-row>
      </fieldset>
      <div class="mb-3" v-for="(item, index) in listStep" :key="index">
        <div
          class="
            secondary
            d-flex
            justify-space-between
            align-center
            px-10px
            cursor-pointer
          "
          style="height: 40px"
          :style="
            item.isShow ? 'border-radius: 2px 2px 0 0' : 'border-radius: 2px'
          "
          @click="onShowStep(index)"
        >
          <span class="font-weight-bold white--text">STEP {{ index + 1 }}</span>
          <v-icon
            color="white"
            :style="!item.isShow ? '' : 'transform: rotate(180deg)'"
          >
            {{ icons.mdiMenuDown }}
          </v-icon>
        </div>
        <div v-show="item.isShow" class="pa-20px">
          <v-row class="my-0">
            <v-col cols="2" class="py-0">
              <div class="d-flex pa-10px rounded">
                <div>
                  <v-img
                    v-if="item.filePath"
                    max-height="100"
                    min-height="100"
                    min-width="100"
                    max-width="100"
                    :src="item.filePath"
                  ></v-img>
                  <v-no-image v-else />
                </div>
                <!-- <div class="ml-5">
                  <p class="mb-0">
                    <strong>File name: </strong>
                    {{ getFileName(item.filePath) }}
                  </p>
                  <p class="mb-0">
                    <strong>Size: </strong>
                  </p>
                </div> -->
              </div>
            </v-col>
            <v-col cols="10" class="py-0 d-flex">
              <div v-html="item.description"></div>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row>
        <v-col>
          <v-label>
            <strong>Featured Image: </strong>
          </v-label>
          <v-img
            v-if="initData.featuredImage"
            max-height="100"
            min-height="100"
            min-width="100"
            max-width="100"
            :src="initData.featuredImage"
          ></v-img>
          <v-no-image v-else />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0 mt-20px">
          <strong class="mr-2">Tags:</strong>
          <template v-for="(tag, index) in initData.listTags">
            <v-chip
              v-if="tag != ''"
              class="mr-2 px-1 primary--text mb-2"
              color="primary4"
              label
              small
              outlined
              :key="index"
            >
              <span class="font-size-medium">#{{ tag }}</span>
            </v-chip>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <template v-slot:footer>
      <v-btn
        elevation="0"
        color="info white--text"
        class="mr-5"
        width="100px"
        @click="onEdit"
        v-if="!initData.isTrash"
      >
        <strong>EDIT</strong>
      </v-btn>
      <div v-else class="d-inline mr-10px">
        <v-btn
          class="px-2"
          color="error"
          elevation="0"
          @click.stop="onDelete(initData)"
        >
          <v-icon size="14" class="mr-1 mb-2px">
            {{ icons.mdiCloseThick }}
          </v-icon>
          <strong>DELETE PERMANENLY</strong>
        </v-btn>
        <v-btn
          class="ml-3 px-2"
          color="info"
          elevation="0"
          @click.stop="onRestore(initData)"
        >
          <v-icon size="14" class="pb-2px mr-1">
            {{ icons.mdiFileRestore }}
          </v-icon>
          <strong>RESTORE</strong>
        </v-btn>
      </div>
      <v-btn color="error" elevation="0" width="100px" @click="isShow = false">
        <strong>CANCEL</strong>
      </v-btn>
    </template>
  </v-popup>
</template>

<script>
import { mdiCircleSmall, mdiMenuDown } from '@mdi/js';
import api from '../../../api/resources';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    listStatus() {
      return this.$store.state.enums.resourceStatus;
    },
    statusName() {
      return this.$store.state.enums.resourceStatus.find(
        (c) => c.value === this.initData.status,
      ).text;
    },
  },
  data() {
    return {
      icons: { mdiCircleSmall, mdiMenuDown },
      isLoading: false,
      initData: {
        status: 0,
        title: '',
        category: 0,
        type: '',
        createdOn: this.$moment().format('MMMM DD, YYYY'),
        createdBy: '',
      },
      ingredient: {
        attachments: [],
        deletedFiles: [],
        filePath: '',
        description: '',
      },
      listStep: [],
      fileUrl: 'https://drbergappdev.blob.core.windows.net/resource-',
    };
  },
  watch: {
    isShow() {
      if (this.isShow) this.getCouponDetail();
    },
  },
  created() {
    this.getCouponDetail();
  },
  methods: {
    getFileName(item) {
      const lastPath = item.lastIndexOf('/');
      if (lastPath !== -1)
        return this.modifyPath(item.substr(lastPath + 1, item.length));
      else return this.modifyPath(item);
    },
    modifyPath(filePath) {
      const name = filePath.substr(
        filePath.lastIndexOf('.') - 2,
        filePath.length,
      );
      if (filePath.length > 30)
        return filePath
          .trim()
          .substring(0, 22)
          .concat('...' + name);
      else return filePath.trim();
    },
    onShowStep(index) {
      this.listStep = this.listStep.map((c, i) => {
        if (index === i) c.isShow = !c.isShow;
        return c;
      });
      // this.initData.listStep = [...this.initData.listStep];
    },
    getCouponDetail() {
      this.isLoading = true;
      api
        .getResourcesDetail(this.id)
        .then((res) => {
          if (res.status === 200) {
            this.initData = { ...res.data };
            let listItem = [...this.initData.resourceItems];
            let ingredientItem = listItem.shift();
            this.ingredient.description = ingredientItem.description || '';
            this.ingredient.filePath = ingredientItem.filePaths;
            // let listItem = [...this.initData.resourceItems];
            // listItem.shift();
            this.listStep = listItem.map((c) => {
              let item = {
                attachments: [],
                deletedFiles: [],
                filePath: c.filePaths,
                description: c.description || '',
                isShow: false,
              };
              return item;
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRestore(item) {
      api.restoreResource(item.id).then((res) => {
        if (res.status === 200) {
          this.$notify.success(res.data.message);
          this.$emit('fetchData');
          this.isShow = false;
        }
      });
    },
    onDelete(item) {
      this.$confirm(
        `Are you sure you want to <span class='error--text'>DELETE</span> this ${item.typeName}?<br>
        <span class="secondary1--text">${item.title}</span>`,
        {
          warningMessage: `This action cannot be undone`,
        },
      ).then((isYes) => {
        if (isYes) {
          api
            .deteleResource(item.id)
            .then((res) => {
              if (res.status === 200) {
                this.$notify.success(res.data.message);
                this.isShow = false;
                this.$emit('fetchData');
              }
            })
            .catch((err) => this.$notify.error(err));
        }
      });
    },
    onEdit() {
      this.isShow = false;
      this.$router.push({
        path: `/resources/edit-recipe/${this.id}`,
      });
    },
  },
};
</script>

<style scoped>
.v-recipe__description {
  margin-top: 20px;
}
.v-recipe__description >>> p {
  margin-bottom: 0 !important;
}
.v-recipe__description >>> img {
  max-width: 100%;
}
</style>
