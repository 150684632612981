export default {
  primary: '#013253',
  primary1: '#98acb9',
  primary2: '#cbd5dc',
  primary3: '#e4e9ec',
  primary4: '#ffffff',
  secondary: '#3076a5',
  secondary1: '#0091a8',
  secondary2: '#65bcca',
  accent: '#5f6368',
  accent1: '#8f8f8f',
  error: '#FA2B2B',
  error1: '#FC642D',
  warning: '#F99F3D',
  warning1: '#fedfd4',
  info: '#116ADF',
  success: '#00B95D',
};
