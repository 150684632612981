<template>
  <v-select
    v-bind:style="{
      width: widthText || false,
      'max-width': widthText || false,
      'min-width': minWidthText,
      'v-dropdownlist': true,
    }"
    dense
    :flat="flat"
    :solo="solo"
    :label="label"
    :background-color="disabled || readonly ? 'primary3' : backgroundColor"
    v-model="dataValue"
    :items="dataItems"
    :hide-details="hideDetails"
    outlined
    :menu-props="{
      'nudge-bottom': 30,
      'offset-overflow': true,
      'allow-overflow': true,
      maxHeight: maxHeightMenuDDL,
      offsetY: true,
      dense: true,
      contentClass: 'v-dropdownlist__menu__content v-dropdownlist__menu__hover',
    }"
    :color="color"
    :placeholder="placeholder"
    :error-messages="errorMessages"
    :rules="rules"
    :item-text="itemText"
    :item-value="itemValue"
    :item-disabled="itemDisable"
    @blur="$emit('blur', $event)"
    @change="$emit('change', $event)"
    @click="dropdownOnClick"
    @focus="$emit('focus', $event)"
    @keydown="$emit('keydown', $event)"
    @mousedown="$emit('mousedown', $event)"
    @update:error="$emit('update:error', $event)"
    @update:list-index="$emit('update:list-index', $event)"
    @update:search-input="$emit('update:search-input', $event)"
    ref="dropdownlist"
    :height="height"
    :disabled="disabled"
    :readonly="readonly"
    :multiple="multiple"
    class="v-dropdownlist"
  >
    <template v-slot:prepend-item v-if="$common.hasSlot('prepend-item')">
      <slot name="prepend-item"></slot>
    </template>
    <template v-slot:append-outer v-if="$common.hasSlot('append-outer')">
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:append>
      <slot name="append">
        <v-icon-svg
          v-show="!hideAppend"
          small
          class="primary--text"
          :path="icons.customDown"
        ></v-icon-svg>
      </slot>
    </template>
    <template v-slot:item="slotProps" v-if="$common.hasSlot('item')">
      <slot name="item" v-bind="slotProps"></slot>
    </template>
    <template v-slot:selection="slotProps" v-if="$common.hasSlot('selection')">
      <slot name="selection" v-bind="slotProps"></slot>
    </template>
  </v-select>
</template>

<script>
import { mdiChevronDown } from '@mdi/js';
import { customDown } from '../plugins/icons';
export default {
  props: {
    maxHeight: {
      type: [String, Number],
      default: 'unset',
    },
    flat: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    dataItems: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
    backgroundColor: {
      type: String,
      default: 'primary4',
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    itemDisable: {
      type: [String, Array, Function],
      default: 'disable',
    },
    itemText: {
      type: [String, Array, Function],
      default: 'text',
    },
    itemValue: {
      type: [String, Array, Function],
      default: 'value',
    },
    value: {},
    width: {
      type: [Number, String],
      default: '',
    },
    minWidth: {
      type: [Number, String],
      default: '50px',
    },
    height: {
      default: 36,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hideAppend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiChevronDown,
        customDown,
      },
    };
  },
  computed: {
    maxHeightMenuDDL() {
      if (this.maxHeight === 'unset') return 500;
      if (typeof this.maxHeight === 'string')
        return parseInt(this.maxHeight.replace('px', '')) != 0
          ? parseInt(this.maxHeight.replace('px', ''))
          : '';
      else return this.maxHeight == 0 ? 180 : this.maxHeight;
    },
    widthText() {
      if (typeof this.width === 'string') return this.width;
      else return this.width + 'px';
    },
    minWidthText() {
      if (typeof this.minWidth === 'string') return this.minWidth;
      else return this.minWidth + 'px';
    },
    dataValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    dropdownOnClick(ev) {
      this.$emit('click', ev);
      let content = this.$refs.dropdownlist.$refs.menu.$refs.content;
      if (content && content.style.display !== 'none') {
        this.$refs.dropdownlist.isMenuActive = false;
      }
    },
  },
};
</script>
