<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    :height="heightBySize"
    :width="widthBySize"
    role="img"
    aria-hidden="true"
    :size="size"
  >
    <path fill="currentColor" :transform="transform" :d="path" />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '18px',
    },
    width: {
      type: String,
      default: '18px',
    },
    path: {
      type: String,
      default: '',
    },
    size: {
      type: [String, Number],
      default: 20,
    },
    xxSmall: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    xxLarge: {
      type: Boolean,
      default: false,
    },
    xMedium: {
      type: Boolean,
      default: false,
    },
    transform: {
      type: String,
      default: '',
    },
  },
  computed: {
    heightBySize: function () {
      if (this.xxSmall) return '5px';
      if (this.xSmall) return '12px';
      if (this.small) return '16px';
      if (this.xMedium) return '19px';
      if (this.medium) return '20px';
      if (this.xLarge) return '24px';
      if (this.large) return '30px';
      if (this.xxLarge) return '33px';
      return this.height;
    },
    widthBySize: function () {
      if (this.xxSmall) return '5px';
      if (this.xSmall) return '12px';
      if (this.small) return '16px';
      if (this.xMedium) return '19px';
      if (this.medium) return '20px';
      if (this.xLarge) return '24px';
      if (this.large) return '30px';
      if (this.xxLarge) return '33px';
      return this.width;
    },
  },
};
</script>
