import moment from 'moment';
export default {
  toLocal(format) {
    let aDate = moment.utc(this.format('YYYY-MM-DDThh:mm:ss'));
    return aDate.local().format(format);
  },
  toUtc(format) {
    return new moment(this).utc().format(format);
  },
};
