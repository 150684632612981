var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{ref:"dropdownlist",staticClass:"v-dropdownlist",style:({
    width: _vm.widthText || false,
    'max-width': _vm.widthText || false,
    'min-width': _vm.minWidthText,
    'v-dropdownlist': true,
  }),attrs:{"dense":"","flat":_vm.flat,"solo":_vm.solo,"label":_vm.label,"background-color":_vm.disabled || _vm.readonly ? 'primary3' : _vm.backgroundColor,"items":_vm.dataItems,"hide-details":_vm.hideDetails,"outlined":"","menu-props":{
    'nudge-bottom': 30,
    'offset-overflow': true,
    'allow-overflow': true,
    maxHeight: _vm.maxHeightMenuDDL,
    offsetY: true,
    dense: true,
    contentClass: 'v-dropdownlist__menu__content v-dropdownlist__menu__hover',
  },"color":_vm.color,"placeholder":_vm.placeholder,"error-messages":_vm.errorMessages,"rules":_vm.rules,"item-text":_vm.itemText,"item-value":_vm.itemValue,"item-disabled":_vm.itemDisable,"height":_vm.height,"disabled":_vm.disabled,"readonly":_vm.readonly,"multiple":_vm.multiple},on:{"blur":function($event){return _vm.$emit('blur', $event)},"change":function($event){return _vm.$emit('change', $event)},"click":_vm.dropdownOnClick,"focus":function($event){return _vm.$emit('focus', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)},"mousedown":function($event){return _vm.$emit('mousedown', $event)},"update:error":function($event){return _vm.$emit('update:error', $event)},"update:list-index":function($event){return _vm.$emit('update:list-index', $event)},"update:search-input":function($event){return _vm.$emit('update:search-input', $event)}},scopedSlots:_vm._u([(_vm.$common.hasSlot('prepend-item'))?{key:"prepend-item",fn:function(){return [_vm._t("prepend-item")]},proxy:true}:null,(_vm.$common.hasSlot('append-outer'))?{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true}:null,{key:"append",fn:function(){return [_vm._t("append",function(){return [_c('v-icon-svg',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideAppend),expression:"!hideAppend"}],staticClass:"primary--text",attrs:{"small":"","path":_vm.icons.customDown}})]})]},proxy:true},(_vm.$common.hasSlot('item'))?{key:"item",fn:function(slotProps){return [_vm._t("item",null,null,slotProps)]}}:null,(_vm.$common.hasSlot('selection'))?{key:"selection",fn:function(slotProps){return [_vm._t("selection",null,null,slotProps)]}}:null],null,true),model:{value:(_vm.dataValue),callback:function ($$v) {_vm.dataValue=$$v},expression:"dataValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }