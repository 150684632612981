import moment from 'moment';
import plugins from './plugins';
function install(Vue) {
  Object.keys(plugins).forEach((plugin) => {
    moment.prototype[plugin] = plugins[plugin];
  });
  Vue.prototype.$moment = moment;
}

export default {
  install,
};
