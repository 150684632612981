import SnackbarItem from './_VSnackbar';

function install(Vue, globalOptions) {
  let nextComp = null;
  let comp = null;
  function createComp(options) {
    const Component = new Vue(SnackbarItem);

    Object.assign(Component, globalOptions, options);
    document.getElementById('app').appendChild(Component.$mount().$el);
    return Component;
  }
  Vue.prototype.$notify = {
    success: (text, options) => init(text, options, 'success'),
    error: (text, options) => init(text, options, 'error'),
    primary: (text, options) => init(text, options, 'primary'),
    info: (text, options) => init(text, options, 'info'),
    default: (text, options) => init(text, options, 'secondary'),
    warning: (text, options) => init(text, options, 'warning'),
    warningCall: (text, options) => init(text, options, 'error1'),
  };
  let defaults = {
    color: 'default',
    text: '',
    timeout: 6000,
    showClose: true,
    extendText: '',
    extendClick: function () {},
    buttons: [],
  };
  // Add Vue instance methods by attaching them to Vue.prototype.
  function init(text, options, color) {
    if (comp) {
      nextComp = { text: text, options: options, color: color };
      comp.active = false;
    } else {
      let defaultOptions = Object.assign({}, defaults, options);
      defaultOptions = Object.assign({}, defaultOptions, {
        text: text,
        color: color,
      });
      comp = createComp(defaultOptions);
      comp.$on('extend-click', defaultOptions.extendClick);
      comp.$on('extend-actions-click', (index) =>
        defaultOptions.buttons[index].action(comp),
      );
      comp.$on('statusChange', (isActive, wasActive) => {
        if (wasActive && !isActive) {
          comp.$nextTick(() => {
            comp.$destroy();
            comp = null;
            if (nextComp) {
              init(nextComp.text, nextComp.options, nextComp.color);
              nextComp = null;
            }
          });
        }
      });
    }
  }
}

export default {
  install,
};
