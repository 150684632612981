import api from '../../../api/authenticate';
import Vue from 'vue';
const actions = {
  login({ commit }, { username, password, isRemember }) {
    return new Promise(function (resolve, reject) {
      api
        .login(username, password, isRemember)
        .then(function (response) {
          if (response.data.isSuccess) {
            commit('loginSuccess', response.data.data);
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  logout({ commit }) {
    api.logout().finally(function () {
      commit('logout');
    });
  },
  async validUser({ commit, dispatch }) {
    commit('updateStatus', 'loading');
    return new Promise(function (resolve) {
      api
        .getUser()
        .then(function (response) {
          if (response.status === 200 && response.data) {
            if (!response.data.userName) {
              commit('updateStatus', 'finished');
              dispatch('logout');
            } else {
              dispatch('getRolesList').finally(() => {
                resolve(response);
                commit('updateStatus', 'finished');
              });
              commit('validUser', response.data);
            }
          } else {
            commit('updateStatus', 'finished');
            dispatch('logout');
          }
        })
        .catch(function (err) {
          console.error(err);
          commit('updateStatus', 'finished');
          dispatch('logout');
        });
    });
  },
  getRolesList({ commit }) {
    return new Promise(function (resolve, reject) {
      api
        .getRolesList()
        .then(function (response) {
          if (response.status === 200) {
            resolve(response);
            commit('getRolesList', response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  getAllUser({ commit }) {
    return new Promise(function (resolve, reject) {
      api
        .getAllUser()
        .then(function (response) {
          if (response.status === 200) {
            commit('getAllUser', response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
};
const mutations = {
  updateStatus(state, status) {
    state.appStatus = status;
  },
  loginSuccess(state, data) {
    localStorage.setItem('user-token', data.token);
  },
  logout(state) {
    state.appStatus = 'loading';
    state.user.isAuthorized = false;
    localStorage.removeItem('user-token');
    localStorage.removeItem('last-request');
    location.href = location.origin;
  },
  validUser(state, data) {
    state.user = Object.assign({}, state.user, data);
    state.user.isAuthorized = true;
    const bus = () => Vue.prototype.$eventBus;
    bus().$emit('successGetUser', data);
  },
  // fetchRoles(state, data) {
  //   state.roles = data;
  // },
  getRolesList(state, data) {
    state.roles = data;
  },
  getAllUser(state, data) {
    state.users = data;
  },
};
export { actions, mutations };
