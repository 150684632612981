<template>
  <v-popup v-model="isShow" :loading="isLoading" xxxxLarge contentClass="pb-5">
    <template v-slot:title>Audio book detail</template>
    <v-card elevation="0" class="d-flex flex-column" width="100%">
      <p class="font-italic mb-0" style="line-height: 18px">
        {{ $moment(initData.updatedOn).format('MMMM DD, YYYY hh:mm A') }}
      </p>
      <v-card-title class="pa-0 my-2 font-size-subtitle-1 font-weight-bold">
        {{ initData.title }}
      </v-card-title>
      <div class="d-flex flex-row">
        <p class="d-flex flex-column col-4 pl-0">
          <span>
            <strong>Created By: </strong>
            {{ initData.createdByName }} ({{ initData.createdById }})
          </span>
          <span>
            <strong>Category: </strong>
            {{ initData.resourceCategoryName }}
          </span>
        </p>
        <p class="d-flex flex-column col-4">
          <span>
            <strong>Status: </strong>
            {{ statusName }}
          </span>
          <span>
            <strong>Type: </strong>
            {{ initData.typeName }}
          </span>
        </p>
        <p class="d-flex flex-column col-4">
          <span>
            <strong>Password: </strong>
            {{ initData.password }}
          </span>
        </p>
      </div>
      <div class="mb-3" v-for="(item, index) in listChapter" :key="index">
        <div
          class="
            secondary
            d-flex
            justify-space-between
            align-center
            px-10px
            cursor-pointer
          "
          style="height: 40px"
          :style="
            item.isShow ? 'border-radius: 2px 2px 0 0' : 'border-radius: 2px'
          "
          @click="onShowChapter(index)"
        >
          <span class="font-weight-bold white--text">
            CHAPTER {{ index + 1 }}
          </span>
          <v-icon
            color="white"
            :style="!item.isShow ? '' : 'transform: rotate(180deg)'"
          >
            {{ icons.mdiMenuDown }}
          </v-icon>
        </div>
        <div
          v-show="item.isShow"
          class="pa-20px"
          style="border: 1px solid var(--color-primary3)"
        >
          <v-row class="my-0">
            <v-col cols="6" class="py-0 mb-20px">
              <div class="pa-0 rounded">
                <audio-player
                  class="mb-0"
                  style="width: 100%; max-width: 100%; height: 36px"
                  :audioURL="item.filePath"
                />
                <!-- <p class="mb-0">
                  <strong>File name: </strong>
                  {{ getFileName(item.filePath) }}
                </p>
                <p class="mb-0">
                  <strong>Size: </strong>
                </p> -->
              </div>
            </v-col>
            <v-col cols="12" class="py-0 d-flex">
              <div
                class="audio-book__description"
                v-html="item.description"
              ></div>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row>
        <v-col>
          <v-label>
            <strong>Featured Image: </strong>
          </v-label>
          <v-img
            v-if="initData.featuredImage"
            max-height="100"
            min-height="100"
            min-width="100"
            max-width="100"
            :src="initData.featuredImage"
          ></v-img>
          <v-no-image v-else />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0 mt-20px">
          <strong class="mr-2">Tags:</strong>
          <template v-for="(tag, index) in initData.listTags">
            <v-chip
              v-if="tag != ''"
              class="mr-2 px-1 primary--text mb-2"
              color="primary4"
              label
              small
              outlined
              :key="index"
            >
              <span class="font-size-medium">#{{ tag }}</span>
            </v-chip>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <template v-slot:footer>
      <v-btn
        elevation="0"
        color="info white--text"
        class="mr-5"
        width="100px"
        @click="onEdit"
        v-if="!initData.isTrash"
      >
        <strong>EDIT</strong>
      </v-btn>
      <div v-else class="d-inline mr-10px">
        <v-btn
          class="px-2"
          color="error"
          elevation="0"
          @click.stop="onDelete(initData)"
        >
          <v-icon size="14" class="mr-1 mb-2px">
            {{ icons.mdiCloseThick }}
          </v-icon>
          <strong>DELETE PERMANENLY</strong>
        </v-btn>
        <v-btn
          class="ml-3 px-2"
          color="info"
          elevation="0"
          @click.stop="onRestore(initData)"
        >
          <v-icon size="14" class="pb-2px mr-1">
            {{ icons.mdiFileRestore }}
          </v-icon>
          <strong>RESTORE</strong>
        </v-btn>
      </div>
      <v-btn color="error" elevation="0" width="100px" @click="isShow = false">
        <strong>CANCEL</strong>
      </v-btn>
    </template>
  </v-popup>
</template>

<script>
import { mdiMenuDown } from '@mdi/js';
import AudioPlayer from '../../../components/_audio.vue';
import api from '../../../api/resources';

export default {
  components: {
    AudioPlayer,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    listStatus() {
      return this.$store.state.enums.resourceStatus;
    },
    statusName() {
      return this.$store.state.enums.resourceStatus.find(
        (c) => c.value === this.initData.status,
      ).text;
    },
  },
  data() {
    return {
      icons: { mdiMenuDown },
      isLoading: false,
      initData: {
        status: 0,
        title: '',
        category: 0,
        type: '',
        createdOn: this.$moment().format('MMMM DD, YYYY'),
        createdBy: '',
      },
      listChapter: [],
      fileUrl: 'https://drbergappdev.blob.core.windows.net/resource-',
    };
  },
  watch: {
    isShow() {
      if (this.isShow) this.getCouponDetail();
    },
  },
  created() {
    this.getCouponDetail();
  },
  methods: {
    getFileName(item) {
      if (item) {
        const lastPath = (item || '').lastIndexOf('/');
        if (lastPath !== -1)
          return this.modifyPath(item.substr(lastPath + 1, item.length));
        else return this.modifyPath(item);
      } else return '';
    },
    modifyPath(filePath) {
      const name = (filePath || '').substr(
        (filePath || '').lastIndexOf('.') - 2,
        (filePath || '').length,
      );
      if (filePath.length > 30)
        return (filePath || '')
          .trim()
          .substring(0, 22)
          .concat('...' + name);
      else return (filePath || '').trim();
    },
    onRestore(item) {
      api.restoreResource(item.id).then((res) => {
        if (res.status === 200) {
          this.$notify.success(res.data.message);
          this.$emit('fetchData');
          this.isShow = false;
        }
      });
    },
    onDelete(item) {
      this.$confirm(
        `Are you sure you want to <span class='error--text'>DELETE</span> this ${item.typeName}?<br>
        <span class="secondary1--text">${item.title}</span>`,
        {
          warningMessage: `This action cannot be undone`,
        },
      ).then((isYes) => {
        if (isYes) {
          api
            .deteleResource(item.id)
            .then((res) => {
              if (res.status === 200) {
                this.$notify.success(res.data.message);
                this.$emit('fetchData');
                this.isShow = false;
              }
            })
            .catch((err) => this.$notify.error(err));
        }
      });
    },
    onShowChapter(index) {
      this.listChapter = this.listChapter.map((c, i) => {
        if (index === i) c.isShow = !c.isShow;
        return c;
      });
    },
    getCouponDetail() {
      this.isLoading = true;
      api
        .getResourcesDetail(this.id)
        .then((res) => {
          if (res.status === 200) {
            this.initData = { ...res.data };
            this.listChapter = this.initData.resourceItems.map((c) => {
              let item = {
                attachments: [],
                deletedFiles: [],
                filePath: c.filePaths,
                description: c.description || '',
                isShow: false,
              };
              return item;
            });
            // this.initData.listChapter = this.initData.listChapter.map((c) => {
            //   c.isShow = false;
            //   return c;
            // });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onEdit() {
      this.isShow = false;
      this.$router.push({
        path: `/resources/edit-audio-book/${this.id}`,
      });
    },
  },
};
</script>

<style scoped>
.audio-book__description >>> p {
  margin-bottom: 0 !important;
}
.audio-book__description >>> img {
  max-width: 100%;
}
</style>
