import Admin from '../views/admin';
import Admin_Nav from '../views/admin/nav.vue';
import Admin_User from '../views/admin/user/index.vue';
import Admin_DrBergProfile from '../views/admin/drberg-profile.vue';
// import Admin_Category from '../views/admin/categories/index.vue';

export default [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      title: 'Admin',
      roles: 'Administrator,Editor',
    },
    children: [
      {
        path: '/',
        component: Admin_Nav,
        meta: {
          title: 'Admin',
        },
        roles: 'Administrator,Editor,Author',
      },
      {
        path: 'manage-users',
        component: Admin_User,
        name: 'admin.user',
        meta: {
          title: 'Manage Users',
          breadcrumbs: () => [
            { text: 'Admin', to: '/admin' },
            { text: 'Manage Users', to: '/admin/manage-users' },
          ],
          roles: 'Administrator',
        },
      },
      {
        path: 'manage-drberg-profile',
        component: Admin_DrBergProfile,
        name: 'admin.drberg',
        meta: {
          title: 'Manage Dr.Berg Profile',
          breadcrumbs: () => [
            { text: 'Admin', to: '/admin' },
            {
              text: 'Manage Dr.Berg Profile',
              to: '/admin/manage-drberg-profile',
            },
          ],
        },
      },
      // {
      //   path: 'categories',
      //   component: Admin_Category,
      //   name: 'admin.categories',
      //   meta: {
      //     title: 'Manage Categories',
      //     breadcrumbs: () => [
      //       { text: 'Admin', to: '/admin' },
      //       { text: 'Manage Categories', to: '/admin/categories' },
      //     ],
      //   },
      // },
    ],
  },
];
