// import { commentNode } from "./node-helper";
import './prototype';
import moment from 'moment';
function install(Vue) {
  eventBus(Vue);
  let common = {};
  common['hasSlot'] = function (name = 'default') {
    return !!this.$slots[name] || !!this.$scopedSlots[name];
  };
  /**
   * @param { String, Object, Array } dataCopy Array: [{format, content}], Object: {format: content}
   *
   * @param { String } format
   */
  common['setClipboard'] = function (dataCopy, format = 'text/plain') {
    let el = document.createElement('input');
    el.setAttribute('tabIndex', 'true');
    el.setAttribute('aria-hidden', 'true');
    el.addEventListener('copy', (ev) => {
      if (typeof dataCopy === 'string') {
        ev.clipboardData.setData(format, dataCopy);
      }
      if (typeof dataCopy === 'object' && !Array.isArray(dataCopy)) {
        ev.clipboardData.setData(dataCopy.format || format, dataCopy.data);
      }
      if (Array.isArray(dataCopy)) {
        dataCopy.forEach((data) => {
          ev.clipboardData.setData(data.format || format, data.data);
        });
      }
      el.remove();
      ev.preventDefault();
    });
    document.body.appendChild(el);
    el.focus();
    el.select();
    document.execCommand('copy');
  };
  common['genUUID'] = function () {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
    return uuid;
  };

  Vue.mixin({
    computed: {
      $common() {
        let obj = {};
        Object.keys(common).forEach((key) => {
          obj[key] = (...parrams) => common[key].call(this, ...parrams);
        });
        return {
          ...obj,
        };
      },
    },
  });
  //hidden
  Vue.directive('hide', {
    bind: vFieldErrors,
    update: vFieldErrors,
  });
  function vFieldErrors(el, binding, vnode) {
    let value = binding.value;
    if (
      binding.arg === 'counter' &&
      ['v-textarea', 'v-text-field'].includes(vnode.componentOptions.tag)
    ) {
      if (
        (Array.isArray(value) && value.length > 0) ||
        (typeof value === 'string' && value.trim().length > 0)
      ) {
        el.getElementsByClassName('v-counter')[0].style.display = 'none';
      } else {
        el.getElementsByClassName('v-counter')[0].style.display = '';
      }
    }
  }
  //filter
  Vue.filter('durationTime', (startTime, endTime) => {
    endTime = endTime || new Date();
    let start = new moment(startTime).milliseconds(0);
    let duration = new moment(endTime).milliseconds(0).diff(start);
    let h = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let m = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    let s = Math.floor((duration % (1000 * 60)) / 1000);
    if (h < 1) return `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
    return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}:${
      s < 10 ? '0' + s : s
    }`;
  });
  Vue.filter('durationSecond', (duration) => {
    let h = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let m = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    let s = Math.floor((duration % (1000 * 60)) / 1000);
    if (h === 0) return `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
    return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}:${
      s < 10 ? '0' + s : s
    }`;
  });
  //Tooltip

  Vue.directive('tooltip-triangle', {
    bind: vTooltipTriangle,
    update: vTooltipTriangle,
  });
  function vTooltipTriangle(el, binding, vnode) {
    let vmInstance = vnode.componentInstance;
    function posCalc(triangle, contentPos) {
      if (vmInstance.activatorElement) {
        let rect = vmInstance.activatorElement.getBoundingClientRect();
        let centerOffsetLeft = rect.left + rect.width / 2;
        let triangleLeft = centerOffsetLeft - contentPos.left - 6;
        triangle.style.left = triangleLeft + 'px';
      } else {
        triangle.style.left = '50%';
        triangle.style.transform = 'translateX(-50%)';
      }
      if (vmInstance.top) {
        triangle.style.bottom = '-8px';
      }
      if (vmInstance.bottom) {
        triangle.style.top = '-8px';
        triangle.style.transform = 'rotateX(180deg)';
      }
    }
    function setTriangle() {
      let content = vmInstance.$refs.content;
      if (content) {
        setTimeout(() => {
          let triangle = content.getElementsByClassName(
              'v-tooltip__triangle',
            )[0],
            dataColor = (vmInstance.color || 'accent').split(' ');

          if (!triangle) {
            triangle = document.createElement('i');
            triangle.classList.add('v-tooltip__triangle');
            dataColor.forEach((elm) => {
              if (elm.includes('--text')) {
                triangle.classList.add(elm);
              } else if (elm.includes('lighten') || elm.includes('darken')) {
                triangle.classList.add('text--' + elm);
              } else {
                triangle.classList.add(elm + '--text');
              }
            });

            let dataStyle =
              'border-left-color: transparent !important;border-right-color: transparent !important;';

            triangle.setAttribute('style', dataStyle);
            content.appendChild(triangle);
          }

          let contentPos = {
            left: parseFloat(vmInstance.calculatedLeft || '0'),
            top: parseFloat(vmInstance.calculatedTop || '0'),
            width: content.clientWidth,
          };
          posCalc(triangle, contentPos);
        }, 100);
      }
    }
    if (vnode.componentOptions.tag === 'v-tooltip') {
      setTriangle();
      vmInstance.$watch(
        function () {
          return this.isActive;
        },
        function () {
          setTriangle();
        },
      );
    }
  }
}

export default {
  install,
};

function eventBus(Vue) {
  const EventBus = new Vue();
  Vue.prototype.$eventBus = {};
  Vue.prototype.$eventBus['$emit'] = (ev, ...parrams) => {
    return EventBus.$emit(ev, ...parrams);
  };

  Vue.prototype.$eventBus['$off'] = (ev, callback) => {
    return EventBus.$off(ev, callback);
  };
  Vue.prototype.$eventBus['$on'] = (ev, callback) => {
    EventBus.$off(ev, callback);
    return EventBus.$on(ev, callback);
  };
  Vue.prototype.$progress = {
    show: () => {
      EventBus.$emit('change:progress', true);
    },
    hide: () => {
      EventBus.$emit('change:progress', false);
    },
  };
}
