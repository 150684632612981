import axios from 'axios';
export default {
  login(username, password, isRemember) {
    return axios.post('user/login', {
      username,
      password,
      isRemember,
    });
  },
  logout() {
    return axios.post('user/logout');
  },
  getUser() {
    return axios.get('user/auth');
  },
  getAllUsers() {
    return axios.get('user/others');
  },
  getRolesList() {
    return axios.get('user/roles');
  },
  forgotPassword(email) {
    return axios.post('user/ForgotPassword/Request', { email });
  },
  resetPassword(email, password, confirmPassword, token) {
    let params = {
      email,
      password,
      confirmPassword,
      token,
    };
    return axios.post('user/ForgotPassword/Confirm', params);
  },
  getUserById(id) {
    return axios.get(`user/${id}`);
  },
  getAllUser() {
    return axios.get(`user/all`);
  },
  getProfileIdsByRoleId(id) {
    return axios.get(`user/userByRole?roleId=${id}`);
  },
  updateRole(id, userIds) {
    let data = new FormData();
    userIds.forEach((id) => {
      data.append('profileIds', id);
    });
    return axios.put(`user/role?roleId=${id}`, data);
  },
};
