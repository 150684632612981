<template>
  <v-popup v-model="computedIsActive" contentClass="v-editor__image-popup">
    <template v-slot:title>Add Video</template>
    <div class="d-flex align-center mb-2">
      <v-text-field
        placeholder="Enter video url (youtube, vimeo, dailymotion, screencast)..."
        dense
        outlined
        v-model.trim="url"
        color="primary"
        hide-details
        v-if="computedIsActive"
      ></v-text-field>
      <v-btn
        depressed
        :disabled="!validUrl"
        color="secondary"
        class="ml-2 font-weight-bold"
        @click="insertOnClick"
        >ADD</v-btn
      >
    </div>
    <iframe
      v-if="validUrl"
      width="100%"
      height="540"
      :src="embedUrl"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </v-popup>
</template>

<script>
import { MEDIA_REGEX_MATCH } from './Embed';
export default {
  props: {
    value: {
      default: false,
    },
  },
  computed: {
    computedIsActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      url: '',
      embedUrl: '',
      validUrl: false,
    };
  },
  methods: {
    insertOnClick() {
      this.$emit('insert', this.url);
      this.computedIsActive = false;
      this.validUrl = false;
    },
    getEmbed() {
      let match = MEDIA_REGEX_MATCH.find((c) => c.match(this.url));
      if (match && typeof match.match(this.url) === 'string') {
        this.$notify.error(match.match(this.url));
        return;
      }
      if (match) {
        this.embedUrl = match.url(this.url);
        this.validUrl = true;
      }
    },
  },
  watch: {
    value(val) {
      if (!val) {
        Object.assign(this.$data, this.$options.data.call(this));
      }
    },
    url() {
      this.getEmbed();
    },
  },
};
</script>

<style></style>
