<template>
  <v-container fluid class="v-page-profile pa-0">
    <v-card
      :style="cardHeight"
      flat
      style="overflow-y: auto"
      :loading="isLoading"
    >
      <avatar-user
        @showImage="overlay = true"
        :imageUrl.sync="initData.featuredImage"
        :isLoading.sync="isLoading"
        :canChangeImage="isAdmin"
      ></avatar-user>
      <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit, reset }"
        tag="div"
        class="pt-10 pl-2"
      >
        <v-form
          @submit.prevent="handleSubmit(formOnSubmit)"
          @reset.prevent="reset"
          class="mt-10 pt-10"
        >
          <v-row>
            <div
              class="pa-10px"
              :style="
                [0, 1].includes(breakPointWidth)
                  ? 'width: 1200px;'
                  : 'width: calc(100% - 20px)'
              "
            >
              <v-row class="ml-3">
                <v-col cols="6" class="py-0 pl-0 pr-10px">
                  <v-label>
                    <strong class="primary--text">Email</strong>
                  </v-label>
                  <ValidationProvider
                    name="Email"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors"
                      v-model="initData.email"
                      class="py-0"
                      outlined
                      dense
                      :disabled="!isAdmin"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-label>
                    <strong class="primary--text">Website URL</strong>
                  </v-label>
                  <ValidationProvider
                    name="Website URL"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      type="text"
                      :error-messages="errors"
                      v-model="initData.websiteUrl"
                      class="py-0"
                      outlined
                      dense
                      :disabled="!isAdmin"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="ml-3">
                <v-col cols="6" class="py-0 pl-0 pr-10px">
                  <v-label>
                    <strong class="primary--text">Phone Number</strong>
                  </v-label>
                  <ValidationProvider
                    name="Phone Number"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors"
                      v-model="initData.phoneNumberFormat"
                      class="py-0"
                      outlined
                      dense
                      :disabled="!isAdmin"
                      @change="onChangeFormat(initData.phoneNumberFormat)"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-label>
                    <strong class="primary--text">Shop Link</strong>
                  </v-label>
                  <ValidationProvider
                    name="Shop Link"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      type="text"
                      :error-messages="errors"
                      v-model="initData.shopLink"
                      class="py-0"
                      outlined
                      dense
                      :disabled="!isAdmin"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="ml-3">
                <v-col cols="12" class="pa-0">
                  <v-label>
                    <strong class="primary--text">About Dr.Berg</strong>
                  </v-label>
                  <p v-if="!isAdmin">{{ initData.description }}</p>
                  <ValidationProvider
                    name="Description"
                    rules="required"
                    v-slot="{ errors }"
                    v-else
                  >
                    <v-textarea
                      class="py-0"
                      outlined
                      dense
                      v-model="initData.description"
                      counter="1000"
                      :error-messages="errors"
                    ></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
          </v-row>
        </v-form>
      </ValidationObserver>
      <v-card-actions class="px-20px pt-0 mt-3">
        <v-btn
          depressed
          color="secondary1 white--text"
          class="mr-3"
          width="100px"
          @click="updateProfile()"
          v-if="isAdmin"
          :disabled="!isHasChange"
        >
          <strong>SAVE</strong>
        </v-btn>
        <v-btn
          v-else
          depressed
          color="secondary1 white--text"
          class="mr-3"
          width="100px"
          @click="onBackClick()"
        >
          <strong>BACK</strong>
        </v-btn>
        <v-btn
          color="error"
          depressed
          width="100px"
          @click="cancel()"
          v-if="isAdmin"
        >
          <strong>CANCEL</strong>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import avatarUser from '../profile/_avatar.vue';
export default {
  components: {
    'avatar-user': avatarUser,
  },
  computed: {
    breakPointWidth() {
      const { width } = this.$vuetify.breakpoint;
      if (width >= 1410) return 0;
      if (width >= 1170) return 1;
      if (width >= 730) return 2;
      else return 3;
    },
    cardHeight() {
      return {
        height:
          this.$vuetify.breakpoint.height -
          this.$vuetify.application.top -
          30 +
          'px',
      };
    },
    isAdmin() {
      return this.$store.state.authenticate.user.role == 'Administrator';
    },
    isHasChange() {
      return JSON.stringify(this.initData) != this.dataCompare;
    },
  },
  data() {
    return {
      isLoading: false,
      overlay: false,
      validFiles: '.jpg,.png',

      initData: {
        email: '',
        phoneNumber: '',
        websiteUrl: '',
        shopLink: '',
        description: '',
        featuredImage: '',
        phoneNumberFormat: '',
      },
      dataCompare: this.initData,
    };
  },
  methods: {
    onChangeFormat(number) {
      var cleaned = number.replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        this.initData.phoneNumberFormat =
          match[1] + '-' + match[2] + '-' + match[3];
      }
    },
    getProfile() {
      return this.$http.get('/drbergProfile').then((res) => {
        if (res.status === 200) {
          Object.assign(this.initData, res.data);
          this.onChangeFormat(this.initData.phoneNumber);
          this.dataCompare = JSON.stringify(this.initData);
        }
      });
    },
    updateProfile() {
      this.isLoading = true;
      this.initData.phoneNumber = this.initData.phoneNumberFormat;
      return this.$http
        .put('/drbergProfile', this.initData)
        .then((res) => {
          if (res.status === 200) {
            this.$notify.success('Update successfully.');
            this.getProfile();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancel() {
      if (this.isHasChange) this.getProfile();
      else this.$router.push('/admin');
    },
    onBackClick() {
      this.$router.push('/admin');
    },
  },
  created() {
    this.getProfile();
    if (!this.hasChangeValue) window.onbeforeunload = null;
  },
  watch: {
    hasChangeValue() {
      if (!this.hasChangeValue) {
        window.onbeforeunload = null;
      } else {
        window.onbeforeunload = function () {
          return 'Changes you made may not be saved.';
        };
      }
    },
  },
};
</script>
<style>
.v-input--is-dirty.v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input[type='text']:disabled {
  color: var(--color-primary);
}
</style>
