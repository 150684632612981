var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-popup',{attrs:{"loading":_vm.isLoading,"contentClass":'pb-0 pb-3'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(!_vm.dataId ? 'Add New User' : 'Edit User'))]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"mt-10px"},[_c('v-btn',{staticClass:"mr-5 font-weight-bold primary4--text",attrs:{"type":"submit","depressed":"","color":"secondary1","width":"100","disabled":!_vm.hasFormChange},on:{"click":function($event){return _vm.formOnSubmit()}}},[_vm._v("SAVE")]),_c('v-btn',{staticClass:"font-weight-bold mr-5",attrs:{"type":"reset","depressed":"","color":"error","width":"100"},on:{"click":function($event){_vm.reset();
          _vm.isShow = false;}}},[_vm._v("CANCEL")]),(!!_vm.dataId)?_c('v-btn',{staticClass:"font-weight-bold",attrs:{"depressed":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.onDeleteClick()}}},[_vm._v("DELETE THIS USER")]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
          var reset = ref.reset;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.formOnSubmit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b',[_vm._v("First Name")]),_c('v-text-field',{attrs:{"autofocus":"","outlined":"","dense":"","error":"","error-messages":errors},model:{value:(_vm.initData.firstName),callback:function ($$v) {_vm.$set(_vm.initData, "firstName", $$v)},expression:"initData.firstName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b',[_vm._v("Last Name")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","error":"","error-messages":errors},model:{value:(_vm.initData.lastName),callback:function ($$v) {_vm.$set(_vm.initData, "lastName", $$v)},expression:"initData.lastName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|email|email-unique","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b',[_vm._v("Email")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","error":"","error-messages":errors},model:{value:(_vm.initData.email),callback:function ($$v) {_vm.$set(_vm.initData, "email", $$v)},expression:"initData.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|user-regex|user-unique","name":"User Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b',[_vm._v("User Name")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","error":"","error-messages":errors,"autocomplete":"off"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(errors[0])}})]},proxy:true}],null,true),model:{value:(_vm.initData.userName),callback:function ($$v) {_vm.$set(_vm.initData, "userName", $$v)},expression:"initData.userName"}})]}}],null,true)})],1),(!_vm.dataId)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|passwordRegex","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b',[_vm._v("Password")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","error":"","error-messages":errors,"type":"password","autocomplete":"off"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(errors[0])}})]},proxy:true}],null,true),model:{value:(_vm.initData.password),callback:function ($$v) {_vm.$set(_vm.initData, "password", $$v)},expression:"initData.password"}})]}}],null,true)})],1):_vm._e(),(!_vm.dataId)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|password:@Password","name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b',[_vm._v("Confirm Password")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","error":"","error-messages":errors,"type":"password"},model:{value:(_vm.initData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.initData, "confirmPassword", $$v)},expression:"initData.confirmPassword"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"primary--text",attrs:{"for":"role"}},[_c('strong',[_vm._v("Role")])]),_c('v-dropdownlist',{attrs:{"errorMessages":errors,"itemText":'text',"itemValue":'value',"dataItems":_vm.roles,"placeholder":'Select role',"hideDetails":(errors || []).length == 0},model:{value:(_vm.initData.role),callback:function ($$v) {_vm.$set(_vm.initData, "role", $$v)},expression:"initData.role"}})]}}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }