<template>
  <component
    v-bind:is="computedVuetifyComponent"
    v-bind:style="computedStyles"
    v-model="dataValue"
    :background-color="backgroundColor"
    class="v-dropdownlist v-dropdownlist--mutiple"
    :items="dataItems"
    :no-data-text="noDataText"
    :hide-details="hideDetails"
    :menu-props="{
      offsetY: true,
      dense: true,
      closeOnContentClick: closeOnContentClick,
      contentClass:
        'v-dropdownlist__menu__content v-dropdownlist--mutiple__menu__content',
    }"
    :color="color"
    :placeholder="placeholder"
    :error-messages="errorMessages"
    :rules="rules"
    :item-text="itemText"
    :item-value="itemValue"
    :height="height"
    :disabled="disabled"
    :readonly="readOnly"
    :hide-selected="hideSelected"
    :counter="counter"
    :counter-value="counterValue"
    :suffix="suffix"
    :messages="messages"
    dense
    append-icon
    multiple
    outlined
    @blur="$emit('blur', $event)"
    @change="$emit('change', $event)"
    @click="$emit('click', $event)"
    @focus="$emit('focus', $event)"
    @keydown="$emit('keydown', $event)"
    @mousedown="$emit('mousedown', $event)"
    @update:error="$emit('update:error', $event)"
    @update:list-index="$emit('update:list-index', $event)"
    @update:search-input="$emit('update:search-input', $event)"
    ref="dropdownlist"
  >
    <template v-slot:prepend-item>
      <slot name="prepend-item"></slot>
    </template>
    <template v-slot:append-outer v-if="$common.hasSlot('append-outer')">
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:item="slotProps">
      <slot name="item" v-bind="slotProps">
        <v-list-item-content>
          <v-list-item-title>{{
            displayItemText
              ? displayItemText(slotProps.item)
              : slotProps.item[itemText] || slotProps.item
          }}</v-list-item-title>
        </v-list-item-content>
      </slot>
    </template>
    <template v-slot:selection="slotProps">
      <slot name="selection" v-bind="slotProps" v-bind:remove="remove">
        <v-chip
          label
          v-bind="slotProps.attrs"
          :input-value="slotProps.selected"
          :close="!disabled && !slotProps.item.disabled"
          @click="slotProps.select"
          @click:close="remove(slotProps.item)"
          :close-icon="$vuetify.icons.values.close"
          outlined
          >{{
            displayValue
              ? displayValue(slotProps.item)
              : slotProps.item[itemText] || slotProps.item
          }}</v-chip
        >
      </slot>
    </template>
    <template v-slot:message="slotProps" v-if="$common.hasSlot('message')">
      <slot name="message" v-bind="slotProps"></slot>
    </template>
  </component>
</template>

<script>
import { mdiChevronDown } from '@mdi/js';
import { VAutocomplete, VCombobox } from 'vuetify/lib';

export default {
  components: { VAutocomplete, VCombobox },
  props: {
    dataItems: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
    backgroundColor: {
      type: String,
      default: 'primary4',
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    messages: {
      type: [String, Array],
      default: () => [],
    },
    hideDetails: {
      type: [String, Boolean],
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: [String, Array, Function],
      default: 'text',
    },
    itemValue: {
      type: [String, Array, Function],
      default: 'value',
    },
    value: {},
    width: {
      type: [Number, String],
      default: '',
    },
    minWidth: {
      type: [Number, String],
      default: '50px',
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    displayValue: {
      type: Function,
      default: undefined,
    },
    displayItemText: {
      type: Function,
      default: undefined,
    },
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
    createable: {
      type: Boolean,
      default: false,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: [Number, String],
      default: null,
    },
    counterValue: {
      type: Function,
      default: null,
    },
    noDataText: {
      type: String,
      default: 'No data available',
    },
    suffix: {},
  },
  data() {
    return {
      icons: { mdiChevronDown },
      valueTemp: [],
    };
  },
  computed: {
    widthText() {
      if (typeof this.width === 'string') return this.width;
      else return this.width + 'px';
    },
    minWidthText() {
      if (typeof this.minWidth === 'string') return this.minWidth;
      else return this.minWidth + 'px';
    },
    dataValue: {
      get() {
        return typeof this.value === 'undefined' ? this.valueTemp : this.value;
      },
      set(val) {
        let type = this.dataValue.length > val.length ? 'remove' : 'add';
        let valueChanged =
          type === 'remove'
            ? this.dataValue.filter((c) => !val.includes(c))
            : val.filter((c) => !this.dataValue.includes(c));
        this.$emit('valueChange', {
          type: type,
          value: this.dataValue,
          valueChanged: valueChanged,
        });
        this.$emit('input', val);
        this.valueTemp = val;
      },
    },
    computedStyles() {
      return {
        width: this.widthText || false,
        'max-width': this.widthText || false,
        'min-width': this.minWidthText,
      };
    },
    computedVuetifyComponent() {
      return this.createable ? 'v-combobox' : 'v-autocomplete';
    },
  },
  methods: {
    remove(item) {
      let list = this.dataValue.slice(0);
      const index = list.indexOf(item[this.itemValue] || item);
      if (index >= 0) {
        list.splice(index, 1);
        this.dataValue = list;
        this.$emit('change', list);
      }
      this.$refs.dropdownlist.$refs.menu.onResize();
    },
  },
};
</script>
<style scoped>
.v-dropdownlist--mutiple
  >>> .v-input__control
  .v-select__slot
  .v-chip
  button
  svg {
  color: var(--color-error);
}
</style>
