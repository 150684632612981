<template>
  <v-popup v-model="isShow" :loading="isLoading">
    <template v-slot:title>
      {{ editId && editId !== 0 ? 'Edit' : 'Add new' }} category
    </template>
    <ValidationObserver ref="observer" tag="div">
      <v-form>
        <v-row class="my-0">
          <v-col cols="12" class="py-0">
            <v-label>
              <strong>Category Name</strong>
            </v-label>
            <ValidationProvider
              rules="required|name-unique"
              v-slot="{ errors }"
              ref="categoryName"
              name="name"
              class="flex-grow-1 primary--text"
            >
              <v-text-field
                class="py-0 font-weight-bold"
                outlined
                dense
                v-model="objectData.name"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="6" class="pt-0">
            <v-label>
              <strong>Resource Type</strong>
            </v-label>
            <v-dropdownlist
              v-if="!editId"
              @click.stop
              :data-items="listType"
              v-model="objectData.type"
              :itemText="'text'"
              :itemValue="'value'"
              placeholder="Select Type"
              class="mr-2"
              hide-details
              width="100%"
            >
            </v-dropdownlist>
            <p
              class="text-right font-size-small error--text mb-0"
              style="line-height: 15px"
              v-if="isErrorType"
            >
              The Resource Type field is required
            </p>
            <v-text-field
              disabled
              v-if="editId"
              class="py-0 primary--text"
              outlined
              dense
              :value="typeName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="12" class="py-0">
            <v-label>
              <v-icon-svg
                :path="icons.customAttachment"
                width="13px"
                height="13px"
                class="mr-1"
                transform="translate(-655.654 -288) scale(1.2)"
              ></v-icon-svg>
              <strong>Featured Image</strong>
            </v-label>
            <div
              v-if="!imagePreview"
              class="d-flex align-baseline primary2 py-10px px-3"
            >
              <input
                v-show="false"
                ref="inputFiles"
                type="file"
                accept="image/*"
                @change="onChangeImage"
              />
              <v-btn
                height="30"
                class="font-weight-bold"
                color="primary"
                elevation="0"
                @click="$refs.inputFiles.click()"
              >
                SELECT FILE
              </v-btn>
            </div>
            <div v-if="imagePreview" class="d-flex align-end">
              <v-img
                max-height="80"
                min-height="80"
                min-width="80"
                max-width="80"
                :src="imagePreview"
              ></v-img>
              <v-btn icon small class="ml-1" @click="imagePreview = ''">
                <v-icon color="error">{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-0 mt-3">
          <v-col cols="12" class="py-0">
            <v-label>
              <strong>Description</strong>
            </v-label>
            <ValidationProvider
              rules="max:400"
              v-slot="{ errors }"
              name="Description"
              :customMessages="{
                max: 'The Description field cannot be greater than 400 characters',
              }"
              class="flex-grow-1 primary--text"
            >
              <v-textarea
                class="py-0 font-weight-bold"
                outlined
                dense
                v-model="objectData.description"
                counter="400"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <template v-slot:footer>
      <v-btn
        type="submit"
        depressed
        class="mr-5 font-weight-bold white--text"
        color="secondary1"
        width="100"
        @click="onSaveCategory"
      >
        SAVE
      </v-btn>
      <v-btn
        type="reset"
        depressed
        class="font-weight-bold mr-5"
        color="error"
        width="100"
        @click="isShow = false"
      >
        CANCEL
      </v-btn>
    </template>
  </v-popup>
</template>

<script>
import { customAttachment } from '../../../plugins/icons';
import { mdiClose } from '@mdi/js';
import api from '../../../api/category';
import { extend } from 'vee-validate';

export default {
  props: {
    value: Boolean,
    editId: Number,
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    typeName() {
      if (this.objectData.type !== -1) {
        return this.listType.find((c) => c.value === this.objectData.type).text;
      }
      return '';
    },
  },
  data() {
    return {
      isLoading: false,
      icons: { customAttachment, mdiClose },
      objectData: {
        name: '',
        type: -1,
        featuredImage: [],
        description: '',
      },
      isErrorType: false,
      imagePreview: '',
      listType: [
        { text: 'Audio', value: 0 },
        { text: 'Audio Book', value: 1 },
        { text: 'Coupon', value: 2 },
        { text: 'Document', value: 3 },
        { text: 'Recipe', value: 4 },
      ],
      fileUrl: 'https://drbergappdev.blob.core.windows.net/category-',
    };
  },
  watch: {
    isShow() {
      if (this.isShow) {
        if (this.editId && this.editId != 0) {
          this.getCategoryDetail();
        }
      } else {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    'objectData.type'() {
      this.checkErrorType();
      if (!this.editId) this.$refs.categoryName.validate();
    },
  },
  created() {
    if (this.editId && this.editId != 0) {
      this.getCategoryDetail();
    }
  },
  mounted() {
    extend('name-unique', {
      validate: this.checkNameUnique,
      message: 'The category name is already taken',
    });
  },
  methods: {
    checkNameUnique(val) {
      return api
        .isExistCategoryName(this.editId, val, this.objectData.type)
        .then((res) => {
          if (res.status === 200) return !res.data;
          else return false;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    },
    getCategoryDetail() {
      this.isLoading = true;
      api
        .getCategoryDetail(this.editId)
        .then((res) => {
          if (res.status === 200) {
            this.objectData.name = res.data.name;
            this.objectData.description = res.data.description;
            this.objectData.type = res.data.type;
            if (res.data.featuredImage.length > 0) {
              this.imagePreview = res.data.featuredImage;
            }
            // this.objectData = { ...res.data };
          }
        })
        .catch((err) => {
          this.$notify.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChangeImage(event) {
      if (event.target.files && event.target.files[0]) {
        this.objectData.featuredImage = event.target.files;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    checkErrorType() {
      this.isErrorType = this.objectData.type === -1;
    },
    onSaveCategory() {
      this.$refs.observer.validate().then((valid) => {
        this.checkErrorType();
        if (valid && !this.isErrorType) {
          this.isLoading = true;
          api
            .createCategory(this.objectData, this.editId, this.imagePreview)
            .then((res) => {
              if (res.status === 200 && res.data.isSuccess) {
                this.$notify.success(res.data.message);
                this.isShow = false;
              } else {
                this.$notify.error(res.data.error);
              }
            })
            .catch((err) => {
              this.$notify.error(err);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style></style>
