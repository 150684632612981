<template>
  <v-container class="fill-height secondary2" fluid justify-center>
    <v-card class="elevation-6 pa-50px" max-width="440" width="100%">
      <v-flex class="d-flex flex-column align-center">
        <v-img :src="logoImage"></v-img>
        <p class="text-center font-size-large font-weight-bold mt-5 mb-2">
          Welcome to Dr.Berg App
        </p>
      </v-flex>
      <v-card-text class="pa-0">
        <ValidationObserver ref="observer">
          <v-form ref="form" @submit.prevent="formSubmit">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="User Name"
            >
              <label
                for="userName"
                class="primary--text font-weight-bold subtitle-1"
                >User Name</label
              >
              <v-text-field
                name="userName"
                type="text"
                :error-messages="errors"
                v-model="userName"
                dense
                outlined
            /></ValidationProvider>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Password"
            >
              <label
                for="password"
                class="primary--text font-weight-bold subtitle-1"
                >Password</label
              >
              <v-text-field
                name="password"
                type="password"
                v-model="password"
                :error-messages="errors"
                dense
                outlined
              />
            </ValidationProvider>
            <div class="d-flex mr-5 align-start">
              <v-checkbox
                v-model="isRemember"
                class="mt-0 pt-0 ml-n1 remember-me"
                color="primary"
              >
              </v-checkbox>
              <span class="font-size-small mt-1">Remember me?</span>
            </div>
            <v-btn
              type="submit"
              color="secondary1"
              depressed
              block
              class="font-weight-bold white--text"
              >LOGIN</v-btn
            >
            <p class="mb-0 mt-2 text-center">
              <router-link
                to="/forgot"
                class="font-weight-bold secondary1--text font-size-medium"
                >Forgot Password?</router-link
              >
            </p>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-overlay v-model="hasLoading" absolute color="white">
        <v-progress-circular indeterminate color="white"></v-progress-circular>
        <LottiePlayer
          style="width: 80px; height: 80px"
          :animation-data="LoadingJson"
          :loop="true"
        ></LottiePlayer>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import logoImage from '../../assets/logo_login.png';
import LoadingJson from '../../assets/lottieData/Loading/Loading_2.json';
import LottiePlayer from '../../components/_lottie-player';
import moment from 'moment';
export default {
  components: {
    LottiePlayer,
  },
  data() {
    return {
      userName: '',
      password: '',
      isRemember: false,
      logoImage,
      hasLoading: false,
      LoadingJson,
      callbackUrl: location.origin,
    };
  },
  created() {
    if (this.$route.query.callbackUrl != undefined)
      this.callbackUrl += this.$route.query.callbackUrl;
  },
  methods: {
    ...mapActions('authenticate', ['login']),
    formSubmit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          let self = this;
          if (self.$refs.form.validate()) {
            this.hasLoading = true;
            self
              .login({
                username: self.userName,
                password: self.password,
                isRemember: this.isRemember,
              })
              .then((result) => {
                if (result.isSuccess) {
                  localStorage.setItem('last-request', new moment().format());
                  localStorage.setItem('userLogInManually', 1);
                  location.href = this.callbackUrl;
                }
              })
              .catch(function (err) {
                self.$notify.error(err.message);
                self.hasLoading = false;
              })
              .finally(() => (this.hasLoading = false));
          }
        }
      });
    },
  },
};
</script>

<style scoped>
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}
.remember-me >>> .v-icon__svg {
  height: 26px !important;
  width: 26px !important;
}
</style>
