<template>
  <v-dialog
    class="v-confirm"
    v-model="isShow"
    persistent
    :max-width="width"
    overlay-color="primary"
  >
    <v-card
      class="text-center py-50px px-10px v-confirm__content"
      :color="backgroundColor"
    >
      <lottie-player
        v-if="showIcon"
        ref="player"
        :style="iconStyle"
        :animationData="animationData"
        loop
      ></lottie-player>
      <v-card-text class="pb-10px">
        <ValidationObserver ref="observer">
          <p
            v-html="title"
            class="primary--text font-size-medium font-weight-bold mb-0"
          ></p>
          <p
            class="font-size-medium primary--text mb-2 mt-2"
            v-if="warningMessage != ''"
          >
            <span
              class="font-weight-bold error1--text"
              v-text="warningText"
            ></span>
            <span v-html="warningMessage"></span>
          </p>
          <template v-if="isFromDeleteUser">
            <p class="font-size-medium mt-2">
              What should be done with content owned by this user?
            </p>
            <v-radio-group
              v-model="isSelectUser"
              hide-details
              class="mt-0 ml-10"
              column
            >
              <v-radio
                :value="false"
                class="v-radio--small-dot mt-0"
                label="Deleted all content"
              ></v-radio>
              <v-radio
                :value="true"
                class="v-radio--small-dot mt-0"
                label="Attribute all content to"
              ></v-radio>
            </v-radio-group>
            <ValidationProvider
              v-if="isSelectUser"
              :rules="'required'"
              v-slot="{ errors }"
              name="user"
              ref="input"
            >
              <v-dropdownlist
                :errorMessages="errors"
                v-model="inputData"
                :itemText="'fullName'"
                :itemValue="'id'"
                :dataItems="users"
                :placeholder="'Select user'"
                class="text-left mt-2 ml-50px"
                :width="300"
                content-class="v-dropdownlist__menu__content"
              >
                <template v-slot:item="data">
                  <span>{{ data.item.fullName }} ({{ data.item.id }})</span>
                </template>
                <template v-slot:selection="data">
                  <span>{{ data.item.fullName }} ({{ data.item.id }})</span>
                </template>
              </v-dropdownlist>
            </ValidationProvider>
          </template>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="pb-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="yesClick"
          min-width="100"
          depressed
          class="font-weight-bold secondary1 mx-10px"
          v-if="!hideYes"
          :class="classYes"
          >{{ yesText }}</v-btn
        >
        <v-btn
          @click="
            $emit('click:no');
            hide();
          "
          min-width="100"
          depressed
          color="error"
          class="font-weight-bold mx-10px"
          :class="classNo"
          v-if="!hideNo"
          >{{ noText }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiContentCopy } from '@mdi/js';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    isLoadingChecking: {
      type: Boolean,
      default: false,
    },
    warningMessage: {
      type: String,
      default: '',
    },
    warningType: {
      type: String,
      default: 'warning',
    },
    yesText: { type: String, default: 'YES' },
    noText: { type: String, default: 'NO' },
    width: {
      type: Number,
      default: 400,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    beforeClose: {
      type: Function,
      default: undefined,
    },
    hideYes: {
      type: Boolean,
      default: false,
    },
    hideNo: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    classYes: {
      type: String,
      default: 'white--text',
    },
    classNo: {
      type: String,
      default: '',
    },
    counter: {
      type: [Number, Boolean, String],
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    animationData: {
      type: String,
      default: '',
    },
    iconStyle: {
      type: String,
      default: '',
    },
    isFromDeleteUser: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    warningText() {
      if (!this.warningType) return '';
      return this.warningType == 'error'
        ? 'Error: '
        : this.warningType == 'note'
        ? 'Note: '
        : this.warningType == 'none'
        ? ' '
        : 'Warning: ';
    },
  },
  data() {
    return {
      isShow: false,
      icons: {
        copy: mdiContentCopy,
      },
      isSelectUser: false,
      inputData: null,
    };
  },
  created() {
    this.$eventBus.$on('closeConfirm', () => {
      this.hide();
    });
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    emitSave() {
      this.$emit('click:yes', this.inputData);
      this.hide();
    },
    yesClick() {
      this.emitSave();
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      this.isShow = true;
      this.inputData = this.inputValue;
    });
  },
  watch: {
    isShow(active, wasActive) {
      this.$emit('statusChange', active, wasActive);
    },
  },
};
</script>

<style lang="scss">
.v-confirm__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }
  & .text-field-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50%;
  }
  & .v-input {
    & .v-messages__message {
      text-align: right;
    }
  }
  & .v-select--is-multi {
    & input {
      text-align: left;
    }
    & .v-messages__message {
      text-align: right;
    }
  }
  & .theme--light.v-input--is-disabled {
    input {
      color: var(--color-primary);
    }
  }
  .v-input--checkbox {
    .v-input__slot {
      min-height: 0px !important;
      margin-bottom: 0px !important;
      label {
        top: 3px;
      }
    }
    .v-messages {
      display: none;
    }
  }
  .v-date-range {
    &__input {
      input {
        padding-left: 10px !important;
        text-align: left !important;
      }
    }
  }
}
</style>
