<template>
  <div class="v-page-grid-full pa-0 mt-20px mx-20px">
    <grid
      ref="grid"
      show-filter
      showClearFilter
      reorder-column
      resize-column
      fixed-header
      :sort="{ sortFieldName: 'profileId', isAsc: false }"
      :filters.sync="filter"
      :columns="columns"
      :dataConfig="dataConfig"
    >
      <template v-slot:append-toolbar-menu>
        <div>
          <v-btn
            depressed
            height="36px"
            @click="onNewUserClick"
            class="secondary1 primary4--text mr-5 pa-10px"
          >
            <strong>ADD NEW USER</strong>
          </v-btn>
          <v-btn
            depressed
            class="error1 primary4--text mr-5 px-10px"
            @click="onManageRolesClick"
            height="36px"
          >
            <strong>MANAGE ROLES</strong>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.numberPost="{ item }">
        <a href="#" class="secondary1--text" @click="onNumberPostClick(item)"
          ><strong>{{ item.numberPost }}</strong></a
        >
      </template>
      <template v-slot:item.command="{ item }">
        <div v-if="item.profileId != -1">
          <v-btn
            class="mr-10px px-2 btn-border__radius"
            small
            depressed
            color="info"
            @click="onEditClick(item)"
          >
            <v-icon
              size="14"
              v-text="icons.customEdit"
              class="mr-1"
              small
            ></v-icon>
            Edit
          </v-btn>
          <v-btn
            depressed
            class="error primary4--text px-2"
            @click="onDeleteClick(item)"
            :height="'30px'"
            style="border-radius: 5px"
          >
            <v-icon size="14" v-text="icons.mdiCloseThick"></v-icon>
            Delete
          </v-btn>
        </div>
      </template>
    </grid>
    <new-edit-user-popup
      :isShowPopup.sync="editData.isShowPopup"
      :dataId="editData.id"
      @fetchGrid="fetchData()"
      @deleteUser="onDeleteClick($event)"
    ></new-edit-user-popup>
    <manage-role-popup
      :isShowPopup.sync="isShowManageRolePopup"
      @filterByRole="onfilter($event)"
      @fetchData="fetchData"
    ></manage-role-popup>
  </div>
</template>

<script>
import grid from '../../../components/grid';
import NewEditUserPopup from '../user/_new-edit-popup.vue';
import { mdiCloseThick } from '@mdi/js';
import { customEdit } from '../../../plugins/icons';
import { mapGetters } from 'vuex';
import ManageRolePopup from '../user/_roles-popup.vue';

export default {
  components: {
    grid,
    'new-edit-user-popup': NewEditUserPopup,
    'manage-role-popup': ManageRolePopup,
  },
  computed: {
    ...mapGetters('authenticate', ['roles']),
  },
  data() {
    return {
      editData: {
        isShowPopup: false,
        id: null,
      },
      isShowManageRolePopup: false,
      icons: {
        mdiCloseThick,
        customEdit,
      },
      filter: [],
      columns: [
        {
          text: 'Id',
          value: 'profileId',
          dataType: 'Number',
          width: 100,
        },
        {
          text: 'User Name',
          value: 'userName',
        },
        {
          text: 'First Name',
          value: 'firstName',
        },
        {
          text: 'Last Name',
          value: 'lastName',
        },
        {
          text: 'Email Address',
          value: 'email',
        },
        {
          text: 'Role',
          value: 'role',
          hideComparison: true,
          ddlValues: [
            { text: 'All', value: null },
            { text: 'Administrator', value: 'Administrator' },
            { text: 'Author', value: 'Author' },
            { text: 'Contributor', value: 'Contributor' },
            { text: 'Editor', value: 'Editor' },
            { text: 'Subscriber', value: 'Subscriber' },
          ],
        },
        {
          text: '#Post',
          value: 'numberPost',
          dataType: 'Number',
          hideComparison: true,
        },
        {
          text: 'Command',
          value: 'command',
          hideFilter: true,
          sortable: false,
          width: 250,
        },
      ],
      dataConfig: {
        url: 'api/user',
        page: 1,
        pageSize: 50,
      },
    };
  },
  created() {
    if (this.$store.state.authenticate.users.length == 0)
      this.$store.dispatch('authenticate/getAllUser');
  },
  methods: {
    fetchData() {
      this.$refs.grid.read();
      this.$store.dispatch('authenticate/getAllUser');
    },
    onNewUserClick() {
      this.editData.isShowPopup = true;
      this.editData.id = null;
    },
    onManageRolesClick() {
      this.isShowManageRolePopup = true;
    },
    onEditClick(item) {
      this.editData.isShowPopup = true;
      this.editData.id = item.profileId;
    },
    onDeleteClick(item) {
      var users = this.$store.state.authenticate.users;
      this.$confirm(
        `Are you sure you want to <span class='error--text'>DELETE</span> this user?</br><span class='secondary1--text'>ID ${item.profileId}: ${item.firstName} ${item.lastName} (${item.numberPost})</span>`,
        {
          isFromDeleteUser: item.numberPost != 0,
          users: users.filter((c) => c.id != item.profileId),
          width: 450,
        },
      ).then(async (isYes) => {
        if (isYes) {
          await this.$http
            .delete(
              `/user/${
                item.profileId
              }?isDeleteContent=${!isYes.returnData}&reassignId=${
                isYes.returnData
              }`,
            )
            .then((res) => {
              if (res.data.isSuccess) {
                this.$notify.success(res.data.message);
                this.fetchData();
                this.editData.isShowPopup = false;
              } else {
                this.$notify.success(res.data.message);
              }
            })
            .catch((ex) => {
              console.log(ex);
            });
        }
      });
    },
    onfilter(val) {
      if (val) {
        this.filter = [
          {
            propertyName: 'role',
            value: val,
            comparison: 0,
          },
        ];
        this.$nextTick(() => {
          this.$refs.grid.fetchAll(['data']);
        });
      }
    },
    onNumberPostClick(item) {
      this.$router.push('/resources');
      this.$nextTick(() => {
        this.$eventBus.$emit(
          'resourceFilterByAuthor',
          item.firstName + ' ' + item.lastName,
        );
      });
    },
  },
  watch: {
    isShowPopup() {
      if (!this.isShowPopup) {
        this.$refs.grid.itemFocusOut();
      }
    },
    '$route.name'() {
      if (this.$route.name !== 'admin.user') this.filter = [];
    },
  },
};
</script>
