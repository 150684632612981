<template>
  <v-popup v-model="isShow" :loading="isLoading" xxxxLarge contentClass="pb-5">
    <template v-slot:title>Video detail</template>
    <v-card elevation="0" class="d-flex flex-column" width="100%">
      <p class="font-italic mb-0" style="line-height: 18px">
        {{ $moment(initData.updatedOn).format('MMMM DD, YYYY hh:mm A') }}
      </p>
      <v-card-title class="pa-0 my-2 font-size-subtitle-1 font-weight-bold">
        {{ initData.title }}
      </v-card-title>
      <div class="d-flex flex-row">
        <p class="d-flex flex-column mr-50px">
          <span>
            <strong>Created By: </strong>
            {{ initData.createdByName }} ({{ initData.createdById }})
          </span>
          <span>
            <strong>Category: </strong>
            {{ initData.resourceCategoryName }}
          </span>
        </p>
        <p class="d-flex flex-column mr-50px">
          <span>
            <strong>Status: </strong>
            {{ initData.statusName }}
          </span>
          <span>
            <strong>Type: </strong>
            {{ initData.typeName }}
          </span>
        </p>
      </div>
      <!-- <div
        class="video__url"
        v-html="'https://www.youtube.com/watch?v=tF7pmUmyzTk'"
      ></div> -->
      <iframe
        width="533.55"
        height="300"
        :src="`https://www.youtube.com/embed/${initData.videoId}`"
        frameborder="0"
      ></iframe>
      <div class="mt-10px" v-html="initData.description"></div>
      <v-row>
        <v-col cols="12" class="py-0 mt-20px">
          <strong class="mr-2">Tags:</strong>
          <template v-for="(tag, index) in initData.listTags">
            <v-chip
              v-if="tag != ''"
              class="mr-2 px-1 primary--text mb-2"
              color="primary4"
              label
              small
              outlined
              :key="index"
            >
              <span class="font-size-medium">#{{ tag }}</span>
            </v-chip>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <template v-slot:footer>
      <v-btn color="error" elevation="0" width="100px" @click="isShow = false">
        <strong>CANCEL</strong>
      </v-btn>
    </template>
  </v-popup>
</template>

<script>
import api from '../../../api/resources';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    listStatus() {
      return this.$store.state.enums.resourceStatus;
    },
    statusName() {
      return this.$store.state.enums.resourceStatus.find(
        (c) => c.value === this.initData.status,
      ).text;
    },
  },
  data() {
    return {
      initData: {
        status: 0,
        title: '',
        category: 0,
        type: '',
        featuredImageFile: [],
        couponLink: '',
        description: '',
        createdOn: this.$moment().format('MMMM DD, YYYY'),
        createdBy: '',
      },
      isLoading: false,
    };
  },
  watch: {
    isShow() {
      if (this.isShow) this.getCouponDetail();
    },
  },
  created() {
    this.getCouponDetail();
  },
  methods: {
    getCouponDetail() {
      this.isLoading = true;
      api
        .getResourcesDetail(this.id)
        .then((res) => {
          if (res.status === 200) {
            this.initData = { ...res.data };
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
      // const data = {
      //   id: this.id,
      //   status: 0,
      //   title: 'Mot title gi do',
      //   category: 0,
      //   type: 'Video',
      //   videoUrl: '',
      //   note: 'Mot cai note chang han',
      //   createdOn: this.$moment().format('MMMM DD, YYYY'),
      //   createdBy: 'Tony Ciotti',
      //   listTag: ['Hue', 'Saigon', 'Abc'],
      // };
      // this.initData = { ...data };
    },
  },
};
</script>

<style scoped>
.video__url {
  margin-bottom: 10px;
}
.video__url >>> iframe {
  max-width: 533px;
  max-height: 300px;
}
</style>
