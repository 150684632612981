<template>
  <v-card
    flat
    elevation="0"
    class="v-grid pa-5"
    @mouseup="columnResizeOnMouseUp($event)"
    @mousemove="columnResizeOnMouseMove($event)"
  >
    <grid-toolbar
      v-if="showToolbar"
      class="mb-10px"
      @reset-config="
        resetSetting();
        rebindGrid();
      "
      @select-config="
        configSelect($event);
        rebindGrid();
      "
      @clear-filter="clearFilter"
      @showHideColumn="showHideColumn"
      @hideAllColumns="hideAllColumns"
      :showConfig="showConfig"
      :hideMenuGrid="hideMenuGrid"
      :showClearFilter="showClearFilter"
      :isLeftButton="isLeftButton"
      :grid-config.sync="gridConfig"
      :maxHeight="maxHeightAddRemoveColumn"
      :name="name"
      :note-for-config="noteForConfig"
      ref="toolbar"
      @on-config-change="onConfigChange"
    >
      <template v-slot:append-toolbar-config>
        <slot name="append-toolbar-config"></slot>
      </template>
      <template v-slot:append-toolbar-menu>
        <slot name="append-toolbar-menu"></slot>
      </template>
    </grid-toolbar>
    <v-data-table
      :fixed-header="fixedHeight || fixedHeader"
      :items="dataSource.data"
      :headers="showingHeaders"
      hide-default-footer
      :show-select="showSelect"
      :items-per-page="computedPageSize"
      :page="currentPage"
      v-model="selectItems"
      :server-items-length="dataSource.total"
      :class="computedDatatableClasses"
      calculate-widths
      :options.sync="gridOptions"
      :loading="loading"
      :sort-by="computedSort.sortFieldName"
      :sort-desc="!computedSort.isAsc"
      @click:row="$emit('click:row', $event, focusItem($event))"
      ref="datatable"
      v-if="isGridShow"
      hide-default-header
      :height="calcHeight"
      :single-select="!showSelect"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :expanded.sync="listItemExpanded"
    >
      <template v-slot:top>
        <bulk-action
          ref="bulkaction"
          v-if="showBulkAction"
          :value="selectItems.length"
          :action-items="actionItems"
        >
          <slot name="bulk-action" v-bind:items="selectItems"></slot>
        </bulk-action>
        <div
          v-if="showScrollbar && (fixedHeight || fixedHeader)"
          class="v-grid__scrollbar"
          :style="scrollbarStyles"
        >
          <div
            class="v-grid__scrollbar__header"
            :style="scrollBarTopHeight"
          ></div>
          <div class="v-grid__scrollbar__body">
            <div
              class="v-grid__scrollbar__scroller"
              :style="{ height: tableHeight }"
            ></div>
          </div>
        </div>
      </template>
      <template v-slot:header="{ props, on }">
        <tr class="v-grid__resizeable" v-if="resizeColumn">
          <td v-for="(headerItem, key) in props.headers" :key="key + 1">
            <span
              class="v-grid__resizeable__border"
              @mousedown="columnResizeOnMouseDown($event, headerItem.value)"
            ></span>
          </td>
        </tr>
        <tr
          class="v-grid__filters"
          ref="filters"
          v-if="showFilter && !isMenuFilter"
        >
          <td
            class="text-start pr-2 pl-2"
            v-for="(headerItem, key) in props.headers"
            :key="key + 2"
            ref="rowFilter"
          >
            <row-filter
              :value="
                (
                  computedFilters.find(
                    (c) => c.propertyName == headerItem.value,
                  ) || {}
                ).value
              "
              :config="headerItem"
              @filter="modifyFilter($event, headerItem.value)"
              :default-comparison="
                (
                  computedFilters.find(
                    (c) => c.propertyName == headerItem.value,
                  ) || {}
                ).comparison
              "
              :maxHeightMenuDDL="maxHeightRowFilterDDL"
            ></row-filter>
          </td>
        </tr>
        <grid-header
          :is-show-filter-has-group="isShowFilterHasGroup"
          :is-menu-filter="isMenuFilter"
          :data-props="props"
          :reorderColumn="reorderColumn"
          :name="name"
          :selectedConfig="selectedConfig"
          :show-select="showSelect"
          :is-show-filter="showFilter"
          :show-expand="showExpand"
          @toggle-select-all="on['toggle-select-all']($event)"
          @click:sort="on.sort($event)"
          @reorder-column="onReorderColumn"
          :filter-value="computedFilters"
          @filter="modifyFilter"
        ></grid-header>
      </template>
      <template
        v-for="slotitem in showingHeaders.filter((c) =>
          $common.hasSlot('item.' + c.value),
        )"
        v-slot:[getItemSlotName(slotitem.value)]="{ item, header, value }"
      >
        <slot
          :name="`item.${slotitem.value}`"
          v-bind:item="item"
          v-bind:header="header"
          v-bind:value="value"
          v-bind:focusItem="focusItem(item)"
        ></slot>
      </template>
      <template v-slot:item="slotProps" v-if="$common.hasSlot('item')">
        <slot
          name="item"
          v-bind="{ ...slotProps, focusItem: focusItem(slotProps.item) }"
        ></slot>
      </template>
      <template v-slot:body.append="{ headers }" v-if="dataConfig.aggregates">
        <tr class="v-grid__aggregates">
          <td v-for="header in headers" :key="header.value + 2">
            <slot
              :name="`aggregate.${header.value}`"
              v-bind="getAggregateField(header)"
            >
              <span v-html="getAggregateField(header).toJSON()"></span>
            </slot>
          </td>
        </tr>
      </template>
      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <v-btn
          @click.stop="onExpandButtonClick(item, expand, isExpanded)"
          depressed
          small
          color="warning"
          width="30"
          min-width="30"
          class="pa-0"
          :loading="
            expandedItemData[item[computedExpandId] || ''] === 'loading'
          "
        >
          <v-icon
            :style="isExpanded ? 'transform: rotateZ(90deg);' : ''"
            size="32"
            v-html="icons.mdiMenuRight"
            :color="isExpanded ? 'primary' : 'white'"
          ></v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <slot
          name="expanded-item"
          v-bind="{
            parentItem: item,
            item: expandedItemData[item[computedExpandId]],
            headers,
          }"
        >
          <td
            :colspan="headers.length"
            v-if="expandedItemData[item[computedExpandId]] === 'loading'"
          >
            Loading...
          </td>
          <td
            :colspan="headers.length"
            v-else-if="
              !expandedItemData[item[computedExpandId]] ||
              (expandedItemData[item[computedExpandId]] &&
                expandedItemData[item[computedExpandId]].length === 0)
            "
          >
            No data is available
          </td>
          <td class="pa-2" :colspan="headers.length" v-else>
            <v-simple-table class="v-simple-grid">
              <thead>
                <tr>
                  <template
                    v-for="(header, hindex) in headers.filter(
                      (c) =>
                        !['data-table-expand', 'data-table-select'].includes(
                          c.value,
                        ),
                    )"
                  >
                    <th
                      :key="'header' + header.value + hindex"
                      class="text-start"
                      v-text="header.text"
                    ></th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(dataItem, intemIndex) in expandedItemData[
                    item[computedExpandId]
                  ]"
                  :key="'expand-row-' + intemIndex"
                >
                  <template
                    v-for="(header, hindex) in headers.filter(
                      (c) =>
                        !['data-table-expand', 'data-table-select'].includes(
                          c.value,
                        ),
                    )"
                  >
                    <td :key="'row' + header.value + hindex" class="text-start">
                      <slot
                        :name="`item.${header.value}`"
                        v-bind:item="dataItem"
                        v-bind:header="header"
                        v-bind:value="dataItem[header.value]"
                      >
                        {{ dataItem[header.value] }}
                      </slot>
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </slot>
      </template>
      <template v-slot:footer>
        <v-paging-bar
          v-if="showFooter"
          :total="dataSource.total"
          :per-page.sync="computedPageSize"
          v-model="currentPage"
          @click:refresh="fetchAll('data', true)"
          ref="paging"
          :hidePagination="hidePagination"
          :hidePageSize="hidePageSize"
        ></v-paging-bar>
      </template>
      <template v-slot:no-data>No data is available</template>
    </v-data-table>
  </v-card>
</template>

<script>
import BulkAction from './_bulk-action';
import RowFilter from './_row-filters';
import GridToolbar from './_grid-config';
import GridHeader from './_headers';
import { mdiArrowDown, mdiMenuRight } from '@mdi/js';
import api from '../../api/grid-config';
import { mapQueryParams } from '../../api/index';

export default {
  components: {
    'bulk-action': BulkAction,
    'row-filter': RowFilter,
    'grid-toolbar': GridToolbar,
    'grid-header': GridHeader,
  },
  props: {
    maxHeightAddRemoveColumn: {
      type: String,
    },
    isLeftButton: {
      type: Boolean,
      default: false,
    },
    showScrollbar: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: Object,
      default: null,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    showBulkAction: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    actionItems: {
      type: [Array, String],
      default: 'Action',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    isShowFilterHasGroup: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    filterMode: {
      type: String,
      default: 'row',
    },
    showConfig: {
      type: Boolean,
      default: false,
    },
    hideMenuGrid: {
      type: Boolean,
      default: false,
    },
    showClearFilter: {
      type: Boolean,
      default: false,
    },
    reorderColumn: {
      type: Boolean,
      default: false,
    },
    resizeColumn: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: undefined,
    },
    fixedHeight: {
      type: Boolean,
      default: false,
    },
    minusHeight: {
      type: Number,
      default: 0,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    hidePageSize: {
      type: Boolean,
      default: false,
    },
    dataConfig: {
      type: Object,
      default: () => ({
        url: '',
        page: 1,
        pageSize: 20,
        requestDatas: {},
      }),
    },
    dataExpandConfig: {
      type: Object,
      default: () => ({
        url: '',
        urlFunction: null,
        dataId: 'id',
        page: 1,
        pageSize: 20,
        requestDatas: {},
      }),
    },
    expandFunction: Function,
    isOrder: {
      type: Boolean,
      default: false,
    },
    freezeDataItems: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    singleExpand: {
      type: Boolean,
      default: false,
    },
    noteForConfig: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectItems: [],
      loading: false,
      gridOptions: {},
      isFirstLoad: true,
      isGridShow: true,
      icons: { mdiArrowDown, mdiMenuRight },
      isMounted: false,
      dataResizeConfig: {
        isHasDown: false,
        header: '',
        width: 0,
        clientX: 0,
      },
      dataSource: {
        data: [],
        total: 0,
        page: 1,
        pageSize: 20,
        aggregates: {},
      },
      expandedItemData: {},
      gridConfig: {
        savedList: [],
        selected: {
          id: 0,
          config: {},
        },
        sort: null,
        filters: [],
        hasConfigChanged: false,
      },
      isSetConfig: false,
      fetchConfigType: '',
      total: '',
      tableHeight: '100%',
      scrollbarStyles: {
        top: this.showBulkAction ? '49px' : '0',
        bottom: this.showFooter ? '69px' : '0',
      },
      itemFocus: null,
      maxHeightRowFilterDDL: null,
      listItemExpanded: [],
    };
  },
  computed: {
    scrollBarTopHeight() {
      const plus =
        this.numberGroupLevel < 2 ? 0 : (this.numberGroupLevel - 2) * 40;
      return {
        height: 80 + plus + 'px',
      };
    },
    computedDatatableClasses() {
      const lvClass = 'v-grid__content--' + this.numberGroupLevel + '-lv';
      const classes = {
        'v-grid__content': true,
        'v-grid__content--reorder': this.reorderColumn,
        'v-grid__content--resize': this.resizeColumn,
        'v-grid__content--filter': this.showFilter,
      };
      classes[lvClass] = true;
      return classes;
    },
    numberGroupLevel() {
      return this.getGroupLevel(this.selectedConfig.headers || [], 1);
    },
    showingHeaders() {
      return this.getDisplayHeader(
        (this.selectedConfig.headers || this.columns).filter((c) => !c.hidden),
      );
    },
    selectedConfig() {
      return this.gridConfig.selected.config;
    },
    currentPage: {
      get() {
        return this.dataSource.page || 1;
      },
      set(val) {
        this.dataSource.page = val;
        this.$emit('change:page', val);
        if (!this.isSetConfig) {
          this.fetchAll(['data']);
        }
      },
    },
    computedPageSize: {
      get() {
        return this.dataSource.pageSize || 20;
      },
      set(val) {
        this.dataSource.pageSize = val;
        this.$emit('change:pageSize', val);
        this.fetchAll(['data']);
      },
    },
    computedFilters: {
      get() {
        return this.selectedConfig.filters || [];
      },
      set(val) {
        this.gridConfig.selected.config.filters = val;
        if (!this.isSetConfig) {
          this.gridConfig.hasConfigChanged = true;
          this.fetchAll(['data']);
        }
      },
    },
    computedSort: {
      get() {
        return this.selectedConfig.sort || {};
      },
      set(val) {
        this.gridConfig.selected.config.sort = val;
        if (!this.isSetConfig) {
          this.gridConfig.hasConfigChanged = true;
          this.fetchAll(['data']);
        }
      },
    },
    calcHeight() {
      if (!this.fixedHeight || !this.isMounted) return 'auto';
      let appHeight =
        this.$vuetify.breakpoint.height -
        this.$vuetify.application.top -
        this.minusHeight;
      let bulkActionHeight = this.getFullHeight(this.$refs.bulkaction);
      let toolbarHeight = this.getFullHeight(this.$refs.toolbar);
      let pagingHeight = this.getFullHeight(this.$refs.paging);
      return (
        appHeight - bulkActionHeight - toolbarHeight - pagingHeight - 42 - 30
      );
    },
    computedDefaultConfig() {
      return {
        headers: this.columns,
        filters: this.filters,
        sort: this.sort,
      };
    },
    isMenuFilter() {
      return this.filterMode == 'menu';
    },
    comparisonNullable() {
      return this.$store.getters['enums/comparisonNullable'];
    },
    computedExpandId() {
      return this.dataExpandConfig.dataId || 'id';
    },
  },
  methods: {
    resetCurrentPage() {
      this.dataSource.page = 1;
    },
    getAggregateField(header) {
      let methodsList = ['sum', 'count', 'average'];
      if (
        this.dataConfig.aggregates &&
        this.dataConfig.aggregates[header.value]
      ) {
        let aggregates = this.dataConfig.aggregates[header.value];
        let aggregateMethods = methodsList.filter((c) => aggregates[c]);
        let dataAggregate = (this.dataSource.aggregates || {})[header.value];
        let data = aggregateMethods.reduce((obj, data) => {
          obj[data] = dataAggregate ? dataAggregate[data] : 0;
          return obj;
        }, {});
        data['toJSON'] = function () {
          return aggregateMethods.reduce((text, data) => {
            text += data + ': ' + this[data];
            return text;
          }, '');
        };
        data['name'] = header.value;
        return data;
      }
      return {
        toJSON: function () {
          return '';
        },
      };
    },
    rebindGrid() {
      this.isGridShow = false;
      this.$nextTick().then(() => {
        this.isGridShow = true;
      });
    },
    getItemSlotName(slot) {
      return `item.${slot}`;
    },
    modifyFilter(filter, field) {
      let filters = this.computedFilters.splice(0);
      let item = filters.find((c) => c.propertyName == field);
      if (item) {
        if (
          (filter.value !== null && filter.value !== '') ||
          filter['with'] ||
          ['<>null', 'null'].includes(filter.comparison)
        ) {
          this.$set(item, 'comparison', filter.comparison);
          this.$set(item, 'value', filter.value);
          if (filter['with']) {
            delete item.or;
            delete item.and;
            this.$set(item, filter['with'].isAnd ? 'and' : 'or', {
              comparison: filter['with'].comparison,
              value: filter['with'].value,
              propertyName: field,
            });
          }
        } else {
          filters.splice(filters.indexOf(item), 1);
        }
      } else if (
        (filter.value !== null && filter.value !== '') ||
        ['<>null', 'null'].includes(filter.comparison)
      ) {
        item = {
          propertyName: field,
          value: filter.value,
          comparison: filter.comparison,
        };
        if (filter['with']) {
          this.$set(item, filter['with'].isAnd ? 'and' : 'or', {
            comparison: filter['with'].comparison,
            value: filter['with'].value,
            propertyName: field,
          });
        }
        filters.push(item);
      }
      this.isSetConfig = true;
      this.currentPage = 1;
      this.$nextTick(() => {
        this.isSetConfig = false;
        this.computedFilters = filters;
        this.$emit('filters-fromGrid', this.computedFilters.length);
      });
    },
    read() {
      this.fetchAll(['data']);
    },
    /**
     * @function getRequestParrams
     * @summary return parrams for grid grid
     * @returns {page, pageSize, searchText, sort, filters, aggregates, requestData}
     */
    getRequestParrams() {
      return api.buildRequestParrams(
        this.dataConfig.searchtext,
        this.dataSource.page,
        this.dataSource.pageSize,
        this.gridConfig.selected.config.sort,
        this.gridConfig.selected.config.filters,
        this.dataConfig.requestDatas,
        this.dataConfig.aggregates,
      );
    },
    fetchAll(type, firstLoad) {
      this.loading = true;
      if (firstLoad) this.isFirstLoad = true;
      let promises = [];
      if (!type || type.includes('config')) promises.push(this.fetchConfig);
      if (!type || type.includes('data')) promises.push(this.fetchData);
      promises[0]().finally(() => {
        if (promises[1]) {
          promises[1]().finally(() => {
            this.loading = false;
            this.$nextTick().then(() => {
              this.isFirstLoad = false;
            });
          });
        } else {
          this.loading = false;
          this.$nextTick().then(() => {
            this.isFirstLoad = false;
          });
        }
      });
    },
    fetchConfig() {
      if (this.showConfig) {
        return new Promise((resolve, reject) => {
          api
            .getByName(this.name)
            .then((result) => {
              if (result.status === 200) {
                this.gridConfig.savedList = result.data;
                if (
                  this.fetchConfigType == '' ||
                  this.fetchConfigType == 'delete'
                ) {
                  let defaultConf = this.gridConfig.savedList.find(
                    (c) => c.isDefault,
                  );
                  if (defaultConf.id == 0) {
                    this.gridConfig.selected.id = 0;
                    this.gridConfig.selected.config = JSON.parse(
                      JSON.stringify(this.computedDefaultConfig),
                    );
                  } else {
                    this.gridConfig.selected.id = defaultConf.id;
                    this.gridConfig.selected.config = JSON.parse(
                      defaultConf.setting,
                    );
                  }
                }
                this.fetchConfigType = '';
                resolve(result);
              } else {
                reject(result);
              }
            })
            .catch(function (err) {
              reject(err);
            });
        });
      } else {
        return new Promise((resolve) => {
          this.gridConfig.selected.id = 0;
          this.gridConfig.selected.config = JSON.parse(
            JSON.stringify(this.computedDefaultConfig),
          );
          resolve();
        });
      }
    },
    fetchData() {
      //reset items Select
      this.selectItems = [];
      return new Promise((resolve, reject) => {
        let parrams = this.getRequestParrams();
        api
          .getListDataByUrl(this.dataConfig.url, parrams)
          .then((result) => {
            if (result.status === 200) {
              let dataSource = {};
              if (this.isOrder) {
                dataSource = {
                  data: result.data.orders,
                  total: result.data.orders,
                };
              } else {
                dataSource = result.data;
              }
              if (this.freezeDataItems) {
                const data = Object.freeze(dataSource.data);
                this.dataSource = {
                  ...this.dataSource,
                  ...dataSource,
                  data: data,
                };
              } else {
                this.dataSource = { ...this.dataSource, ...dataSource };
              }
              this.$emit('dataGrid', result.data.total);
              this.$emit('dataLength', result.data.data.length);
              this.$emit('dataAggregates', result.data.aggregates);
              this.$nextTick(() => {
                this.genScrollbar();
              });
              resolve(result);
            } else {
              reject(result);
            }
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    configSelect(val) {
      if (this.showConfig) {
        this.isSetConfig = true;
        let selected = this.gridConfig.savedList.find((c) => c.id == val);
        if (selected.id == 0) {
          this.gridConfig.selected.id = 0;
          this.gridConfig.selected.config = JSON.parse(
            JSON.stringify(this.computedDefaultConfig),
          );
        } else {
          this.gridConfig.selected.id = selected.id;
          let config = JSON.parse(selected.setting);
          this.gridConfig.selected.config = config;
        }
        setTimeout(() => {
          this.gridConfig.hasConfigChanged = false;
          this.isSetConfig = false;
          this.$nextTick(() => {
            this.setScrollEvent();
          });
        });
      }
      this.fetchAll(['data']);
    },
    clearFilter() {
      this.computedFilters = [];
    },
    getFullHeight(ref) {
      if (!ref) return 0;
      let cs = getComputedStyle(ref.$el);
      return (
        parseInt(cs.getPropertyValue('margin-bottom') || 0) +
        parseInt(cs.getPropertyValue('margin-top') || 0) +
        ref.$el.offsetHeight
      );
    },
    columnResizeOnMouseDown(ev, header) {
      ev.preventDefault();
      if (header != 'data-table-select') {
        let elm = ev.target.parentElement;
        let width =
          elm.clientWidth -
          parseInt(getComputedStyle(elm).paddingLeft || 0) -
          1;
        this.dataResizeConfig = Object.assign({}, this.dataResizeConfig, {
          isHasDown: true,
          header: header,
          width: width,
          clientX: ev.clientX,
        });
        document.body.style.cursor = 'ew-resize';
      }
    },
    columnResizeOnMouseMove(ev) {
      if (this.dataResizeConfig.isHasDown) {
        this.columnResize(ev);
        document.body.style.cursor = 'ew-resize';
      }
    },
    columnResizeOnMouseUp(ev) {
      if (this.dataResizeConfig.isHasDown) {
        this.columnResize(ev);
        this.dataResizeConfig = Object.assign({}, this.dataResizeConfig, {
          isHasDown: false,
          header: '',
          width: 0,
          clientX: 0,
        });
        document.body.style.cursor = '';
      }
    },
    columnResize(ev) {
      let width =
        this.dataResizeConfig.width +
        (ev.clientX - this.dataResizeConfig.clientX);
      if (width != this.dataResizeConfig.width) {
        let item = this.findHeader(
          this.gridConfig.selected.config.headers,
          this.dataResizeConfig.header,
        );
        this.$set(item, 'width', width);
        this.gridConfig.hasConfigChanged = true;
      }
    },
    findHeader(headers, value) {
      let item = null;
      for (let element of headers) {
        if (!element.isGroup && element.value == value) {
          item = element;
          break;
        } else if (element.isGroup) {
          item = this.findHeader(element.headers, value);
          if (item) break;
        }
      }
      return item;
    },
    resetSetting() {
      let currentConf = this.gridConfig.savedList.find(
        (c) => c.id == this.gridConfig.selected.id,
      );
      if (currentConf.id == 0) {
        this.gridConfig.selected.id = 0;
        this.gridConfig.selected.config = JSON.parse(
          JSON.stringify(this.computedDefaultConfig),
        );
      } else {
        this.gridConfig.selected.id = currentConf.id;
        this.gridConfig.selected.config = JSON.parse(currentConf.setting);
      }
      this.gridConfig.hasConfigChanged = false;
      this.fetchAll(['data'], true);
    },
    showHideColumn(header) {
      let item = this.findHeader(
        this.gridConfig.selected.config.headers,
        header,
      );
      if (item && !item.preventHidden) {
        this.$set(item, 'hidden', !item.hidden);
        this.gridConfig.hasConfigChanged = true;
      }
    },
    hideAllColumns() {
      this.gridConfig.selected.config.headers.forEach((item) => {
        if (item && !item.preventHidden && !item.isGroup) {
          this.$set(item, 'hidden', true);
          this.gridConfig.hasConfigChanged = true;
        }
        if (item.isGroup) {
          item.headers.forEach((grItem) => {
            if (grItem && !grItem.preventHidden && !grItem.isGroup) {
              this.$set(grItem, 'hidden', true);
              this.gridConfig.hasConfigChanged = true;
            }
            if (grItem.isGroup) {
              grItem.headers;
            }
          });
        }
      });
      this.gridConfig.hasConfigChanged = true;
    },
    onReorderColumn(config) {
      let movedItem = this.findHeader(
        this.gridConfig.selected.config.headers,
        config.from.column,
      );
      let toItem = this.findHeader(
        this.gridConfig.selected.config.headers,
        config.to.column,
      );
      const remainingItems = this.gridConfig.selected.config.headers
        .filter((c) => c.value != config.from.column)
        .splice(0);

      let toIndex = this.gridConfig.selected.config.headers.indexOf(toItem);

      this.gridConfig.selected.config.headers = [
        ...remainingItems.slice(0, toIndex),
        movedItem,
        ...remainingItems.slice(toIndex),
      ];
      this.gridConfig.hasConfigChanged = true;
    },
    onConfigChange(ev, selectId) {
      this.fetchConfigType = ev;
      if (['savechange', 'setdefault', 'rename', 'delete'].includes(ev)) {
        this.fetchConfig();
      }
      if (['addnew', 'duplicate', 'share'].includes(ev)) {
        this.fetchConfig().then(() => {
          this.gridConfig.selected.id = selectId;
        });
      }
      this.gridConfig.hasConfigChanged = false;
    },
    changeSort() {
      if (!this.isFirstLoad && !this.isSetConfig) {
        if (this.gridOptions.sortBy.length == 0) {
          this.computedSort = null;
        } else {
          let sortBy = {
            sortFieldName: this.gridOptions.sortBy[0],
            isAsc: !this.gridOptions.sortDesc[0],
          };
          if (
            this.computedSort.sortFieldName != sortBy.sortFieldName ||
            this.computedSort.isAsc != sortBy.isAsc
          ) {
            this.computedSort = sortBy;
          }
        }
      }
    },
    getScrollbarWidth() {
      // Creating invisible container
      const wrap = document.createElement('div');
      wrap.classList.add('v-grid');
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      wrap.appendChild(outer);
      document.body.appendChild(wrap);

      // Creating inner element and placing it in the container
      const inner = document.createElement('div');
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      // Removing temporary elements from the DOM
      wrap.parentNode.removeChild(wrap);

      return scrollbarWidth;
    },
    genScrollbar() {
      let wrap = this.$el.querySelector('.v-data-table__wrapper');
      let hasScrollbar = wrap.offsetHeight - wrap.clientHeight;
      let scrollbarWidth = this.getScrollbarWidth();
      if (hasScrollbar) {
        this.tableHeight =
          wrap.scrollHeight -
          (
            this.$el.querySelector('.v-grid__scrollbar__header') || {
              clientHeight: 0,
            }
          ).clientHeight +
          'px';
      } else {
        this.tableHeight = wrap.scrollHeight - (80 + scrollbarWidth) + 'px';
      }
      if (this.showBulkAction && this.$refs.bulkaction) {
        this.scrollbarStyles.top =
          this.$refs.bulkaction.$el.offsetHeight + 'px';
      }
    },
    focusItem(item) {
      return (isFocus) => {
        this.itemFocus = isFocus ? item : null;
        this.$refs.datatable.select(item, isFocus, false);
      };
    },
    itemFocusOut() {
      if (this.itemFocus) {
        this.$refs.datatable.select(this.itemFocus, false, false);
        this.itemFocus = null;
      }
    },
    setScrollEvent() {
      let self = this;
      let scrollbar = this.$el.getElementsByClassName(
        'v-grid__scrollbar__body',
      )[0];
      if (scrollbar) {
        let wrap = this.$el.getElementsByClassName('v-data-table__wrapper')[0];
        let mouseAt = '';
        wrap.addEventListener('scroll', (ev) => {
          if (mouseAt === 'wrap') {
            scrollbar.scrollTop = ev.target.scrollTop;
          }
        });

        scrollbar.addEventListener('scroll', (ev) => {
          if (mouseAt === 'scrollbar') {
            wrap.scrollTop = ev.target.scrollTop;
          }
        });

        wrap.addEventListener('mouseenter', () => {
          mouseAt = 'wrap';
        });

        scrollbar.addEventListener('mouseenter', () => {
          mouseAt = 'scrollbar';
        });

        //using touch in mobile divice
        wrap.addEventListener('touchstart', () => {
          mouseAt = 'wrap';
        });
        scrollbar.addEventListener('touchstart', () => {
          mouseAt = 'scrollbar';
        });
        window.removeEventListener('resize', self.genScrollbar);
        window.addEventListener('resize', self.genScrollbar);
      }
    },
    getHeightFromRowFilter() {
      let heightWindow = window.innerHeight > 950 ? 950 : window.innerHeight;
      let offsetTopEl = 0;
      if (this.$parent.$refs.grid && this.$parent.$refs.grid.$refs.rowFilter) {
        offsetTopEl = this.$parent.$refs.grid.$refs.rowFilter
          ? this.$parent.$refs.grid.$refs.rowFilter[0].getBoundingClientRect()
              .top
          : 0;
        this.maxHeightRowFilterDDL =
          heightWindow - offsetTopEl > 150
            ? heightWindow - offsetTopEl - 150 + 'px'
            : heightWindow - offsetTopEl + 'px';
      } else {
        this.maxHeightRowFilterDDL = 0;
      }
      this.$nextTick(() => {
        let parentRef = this.$parent.$children.find((c) =>
          Object.keys(c.$refs).find((key) => key === 'rowFilter'),
        );
        if (parentRef && parentRef.$refs.rowFilter) {
          offsetTopEl = parentRef.$refs.rowFilter
            ? parentRef.$refs.rowFilter[0].getBoundingClientRect().top
            : 0;
          this.maxHeightRowFilterDDL =
            heightWindow - offsetTopEl > 150
              ? heightWindow - offsetTopEl - 150 + 'px'
              : heightWindow - offsetTopEl + 'px';
        }
      });
    },
    getDisplayHeader(headers) {
      let colums = headers.filter((c) => !c.hidden);
      colums = colums.reduce((cols, col) => {
        if (col.isGroup) {
          cols = cols.concat(this.getDisplayHeader(col.headers));
        } else {
          cols.push(col);
        }
        return cols;
      }, []);
      return colums;
    },
    getGroupLevel(headers, level) {
      const current = headers.some((c) => c.isGroup) ? level + 1 : level;
      const childCount = [current];
      for (let index = 0; index < headers.length; index++) {
        if (headers[index].isGroup) {
          childCount.push(
            this.getGroupLevel(headers[index].headers, level + 1),
          );
        }
      }
      return Math.max(...childCount);
    },
    onExpandButtonClick(item, expand, isExpanded) {
      if (isExpanded) {
        expand(!isExpanded);
        return;
      }
      const {
        url,
        urlFunction,
        dataId,
        // page,
        // pageSize,
        requestDatas,
      } = this.dataExpandConfig;
      let requestUrl = '';
      if (url) {
        const query = mapQueryParams(requestDatas || {});
        requestUrl = `${url}/?${dataId}=${item[dataId]}&${query}`;
      }
      if (!requestUrl && urlFunction) {
        requestUrl = urlFunction(item);
      }
      if (!requestUrl) {
        expand(!isExpanded);
        return;
      }
      const currentData = this.expandedItemData[item[dataId]];
      if (currentData === 'loading') {
        return;
      }
      if (currentData) {
        expand(!isExpanded);
        return;
      }
      if (currentData !== 'loading') {
        if (!currentData) {
          this.$set(this.expandedItemData, item[dataId], 'loading');
          this.$http
            .get(requestUrl)
            .then((res) => {
              if (res.status === 200) {
                this.$set(this.expandedItemData, item[dataId], res.data);
              } else {
                this.$set(this.expandedItemData, item[dataId], null);
              }
            })
            .catch(() => {
              this.$set(this.expandedItemData, item[dataId], null);
            })
            .finally(() => {
              expand(!isExpanded);
            });
        }
      }
    },
  },
  created() {
    this.isFirstLoad = true;
    this.fetchAll();
    this.dataSource = Object.assign({}, this.dataSource, {
      page: this.dataConfig.page,
      pageSize: this.dataConfig.pageSize,
    });
  },
  activated() {
    this.isFirstLoad = true;
    this.fetchAll();
    this.dataSource = Object.assign({}, this.dataSource, {
      page: this.dataConfig.page,
      pageSize: this.dataConfig.pageSize,
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
      this.getHeightFromRowFilter();
    });
    this.setScrollEvent();
  },
  beforeDestroy() {
    window.removeEventListener('resize', self.genScrollbar);
  },
  watch: {
    gridOptions(val, old) {
      if (
        val.sortBy &&
        old.sortBy &&
        val.sortDesc &&
        old.sortDesc &&
        val.sortBy != old.sortBy &&
        val.sortDesc != old.sortDesc
      ) {
        this.changeSort();
      }
    },
    columns: {
      handler(val, oldVal) {
        const currentValue = JSON.stringify(val);
        const oldValue = JSON.stringify(oldVal);
        if (oldValue == currentValue && this.showConfig) {
          let defaultConf = this.gridConfig.savedList.find((c) => c.isDefault);
          if (!defaultConf || defaultConf.id == 0) {
            this.gridConfig.selected.id = 0;
            this.gridConfig.selected.config = JSON.parse(
              JSON.stringify(this.computedDefaultConfig),
            );
          } else {
            this.gridConfig.selected.id = defaultConf.id;
            this.gridConfig.selected.config = JSON.parse(defaultConf.setting);
          }
        } else if (oldValue != currentValue && this.showConfig) {
          this.fetchConfig();
        } else if (!this.showConfig) {
          this.fetchConfig();
        }
      },
      deep: true,
    },
    selectItems() {
      this.$nextTick(() => {
        if (this.$refs.bulkaction) {
          this.scrollbarStyles.top =
            this.$refs.bulkaction.$el.offsetHeight + 'px';
        }
      });
    },
    filters: {
      handler() {
        this.gridConfig.selected.config.filters = JSON.parse(
          JSON.stringify(this.computedDefaultConfig.filters),
        );
      },
      deep: true,
    },
  },
};
</script>
