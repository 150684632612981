<template>
  <div v-if="isShowFilter">
    <comparitons-ddl
      :data-type="dataType"
      v-model="comparison"
      type="text-field"
      v-if="isMenu && !config.hideComparison"
      class="mb-10px"
      @change="comparisonChange"
      @input="comparisonChange"
    ></comparitons-ddl>
    <template
      v-if="(dataType == 'String' || dataType == 'Number') && !config.ddlValues"
    >
      <v-text-field
        @input="onInputChange"
        outlined
        dense
        hide-details
        color="primary2"
        height="30"
        v-model="textFilter"
        :clearable="dataType == 'String'"
        :type="dataType.toLowerCase()"
        :step="config.step ? 'any' : config.step"
      >
        <template v-slot:append v-if="!isMenu && !config.hideComparison">
          <comparitons-ddl
            :data-type="dataType"
            v-model="comparison"
            @input="comparisonChange"
          ></comparitons-ddl>
        </template>
      </v-text-field>
    </template>
    <template v-else-if="dataType == 'DateTime'">
      <v-datetime-picker
        @input="onInputChange"
        v-model="textFilter"
        :minWidth="width"
        :width="width"
        :hidedetails="true"
        :height="!isMenu ? 30 : 36"
        style="width: 100%"
      >
        <template v-slot:append-outer v-if="!isMenu && !config.hideComparison">
          <comparitons-ddl
            :data-type="dataType"
            v-model="comparison"
            @input="comparisonChange"
          ></comparitons-ddl>
        </template>
      </v-datetime-picker>
    </template>
    <template v-else-if="dataType == 'Date'">
      <v-input-datepicker
        @input="onInputChange"
        v-model="textFilter"
        :minWidth="width"
        :width="width"
        :hidedetails="true"
        format="MM/DD/YYYY"
        hide-details
        style="width: 100%"
        :height="!isMenu ? 30 : 36"
        :isSetETime="isETTime"
      >
        <template v-slot:append-outer v-if="!isMenu && !config.hideComparison">
          <comparitons-ddl
            :data-type="dataType"
            v-model="comparison"
            @input="comparisonChange"
          ></comparitons-ddl>
        </template>
      </v-input-datepicker>
    </template>
    <template v-else-if="dataType == 'Duration'">
      <v-text-field
        @input="onInputChange"
        outlined
        dense
        hide-details
        color="primary2"
        height="30"
        v-model="textFilter"
        :step="'any'"
        v-mask="'##:##:##'"
        maxlength="9"
        value="0"
      >
        <template v-slot:append v-if="!isMenu && !config.hideComparison">
          <comparitons-ddl
            :data-type="dataType"
            v-model="comparison"
            @input="comparisonChange"
          ></comparitons-ddl>
        </template>
      </v-text-field>
    </template>
    <template v-else-if="config.ddlValues">
      <v-dropdownlist
        class="primary2--text"
        hide-details
        :data-items="config.ddlValues"
        itemText="text"
        itemValue="value"
        @change="onInputChange"
        v-model="textFilter"
        :height="30"
        :width="width"
        :minWidth="width"
        :maxHeight="maxHeightMenuDDL"
        placeholder="Select..."
      >
        <template v-slot:append-outer v-if="!isMenu && !config.hideComparison">
          <comparitons-ddl
            :data-type="dataType"
            v-model="comparison"
            @input="comparisonChange"
          ></comparitons-ddl>
        </template>
      </v-dropdownlist>
    </template>
  </div>
</template>

<script>
import ComparitonsDdl from './_comparisons-ddl';
import _debounce from 'lodash/debounce';
export default {
  components: {
    'comparitons-ddl': ComparitonsDdl,
  },
  props: {
    maxHeightMenuDDL: {
      type: [String, Number],
      default: '200px',
    },
    config: {
      type: Object,
      default: () => {},
    },
    defaultComparison: {
      type: [String, Number],
      default: undefined,
    },
    value: {
      default: null,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShowFilter() {
      return (
        this.config.value !== 'data-table-select' && !this.config.hideFilter
      );
    },
    dataType() {
      return this.config.dataType || 'String';
    },
    isETTime() {
      return (this.config.isET && this.config.dataType == 'Date') || false;
    },
    width() {
      return '100%'; //this.isMenu ? "100%" : this.config.width || 100;
    },
    comparisonNullable() {
      return this.$store.getters['enums/comparisonNullable'];
    },
  },
  methods: {
    emitFilter(value) {
      let isNullValue = value === null || typeof value === 'undefined';
      this.$emit('filter', {
        comparison: this.comparison,
        value: !isNullValue
          ? value
          : this.comparisonNullable.find((c) => c.operator === this.comparison)
          ? this.defaultValues.get(this.dataType)
          : null,
      });
    },
    comparisonChange() {
      this.emitFilter(this.textFilter);
    },
    onInputChange: _debounce(function (ev) {
      this.emitFilter(ev);
    }, 300),
  },
  data() {
    return {
      comparison: '',
      textFilter: '',
      defaultValues: new Map([
        ['Date', null],
        ['DateTime', null],
        ['String', null],
        ['Number', 0],
        ['Enum', null],
      ]),
    };
  },
  created() {
    if (this.defaultComparison) this.comparison = this.defaultComparison;
    else
      this.comparison =
        this.dataType == 'String' && !this.config.ddlValues ? 'In' : '=';
    this.textFilter = this.comparisonNullable.find(
      (c) => c.operator == this.comparison,
    )
      ? ''
      : this.value;
  },
  watch: {
    'config.hidden'() {
      this.comparison =
        this.dataType == 'String' && !this.config.ddlValues ? 'In' : '=';
    },
    value(val) {
      if (this.dataType !== 'Number') {
        this.textFilter = this.comparisonNullable.find(
          (c) => c.operator == this.comparison,
        )
          ? ''
          : val;
      } else {
        this.textFilter = val;
      }
    },
    defaultComparison(val) {
      if (val) this.comparison = val;
      else
        this.comparison =
          this.dataType == 'String' && !this.config.ddlValues ? 'In' : '=';
    },
  },
};
</script>

<style></style>
