import Resources from '../views/resources';
// import Resources_Nav from '../views/resources/nav.vue';
import AllResource from '../views/resources/all-resources/index.vue';
import AddNew from '../views/resources/all-resources/_add-edit.vue';

export default [
  {
    path: '/resources',
    name: 'resources',
    component: Resources,
    meta: {
      title: 'Resources',
      roles: 'Administrator,Editor',
    },
    children: [
      {
        path: '/',
        name: 'resources.all',
        component: AllResource,
        meta: {
          title: 'Resources',
          breadcrumbs: () => [{ text: 'Resources', to: '/resources' }],
          roles: 'Administrator,Editor,Author,Contributor',
        },
      },
      {
        path: 'add-new-coupon',
        name: 'resources.addNewCoupon',
        meta: {
          title: 'Add New',
          breadcrumbs: () => [
            { text: 'Resources', to: '/resources' },
            { text: 'Add new coupon', to: '/resources/add-new-coupon' },
          ],
        },
        component: AddNew,
      },
      {
        path: 'edit-coupon/:id',
        name: 'resources.addNewCoupon',
        meta: {
          title: 'Coupon',
          breadcrumbs: (route) => [
            { text: 'Resources', to: '/resources' },
            {
              text: `Edit coupon ${route.params.id}`,
              to: `/resources/edit-coupon/${route.params.id}`,
            },
            // {
            //   text: route.params.id,
            //   to: `/resources/edit-coupon/${route.params.id}`,
            // },
          ],
        },
        component: AddNew,
      },
      {
        path: 'add-new-video',
        name: 'resources.addNewVideo',
        meta: {
          title: 'Add New',
          breadcrumbs: () => [
            { text: 'Resources', to: '/resources' },
            { text: 'Add new video', to: '/resources/add-new-video' },
          ],
        },
        component: AddNew,
      },
      {
        path: 'add-new-document',
        name: 'resources.addNewDocument',
        meta: {
          title: 'Add New',
          breadcrumbs: () => [
            { text: 'Resources', to: '/resources' },
            { text: 'Add new document', to: '/resources/add-new-document' },
          ],
        },
        component: AddNew,
      },
      {
        path: 'edit-document/:id',
        name: 'resources.addNewDocument',
        meta: {
          title: 'Document',
          breadcrumbs: (route) => [
            { text: 'Resources', to: '/resources' },
            {
              text: `Edit document ${route.params.id}`,
              to: `/resources/edit-document/${route.params.id}`,
            },
            // {
            //   text: route.params.id,
            //   to: `/resources/edit-document/${route.params.id}`,
            // },
          ],
        },
        component: AddNew,
      },
      {
        path: 'add-new-audio',
        name: 'resources.addNewAudio',
        meta: {
          title: 'Add New',
          breadcrumbs: () => [
            { text: 'Resources', to: '/resources' },
            { text: 'Add new audio', to: '/resources/add-new-audio' },
          ],
        },
        component: AddNew,
      },
      {
        path: 'edit-audio/:id',
        name: 'resources.addNewAudio',
        meta: {
          title: 'Audio',
          breadcrumbs: (route) => [
            { text: 'Resources', to: '/resources' },
            {
              text: `Edit audio ${route.params.id}`,
              to: `/resources/edit-audio/${route.params.id}`,
            },
            // {
            //   text: route.params.id,
            //   to: `/resources/edit-audio/${route.params.id}`,
            // },
          ],
        },
        component: AddNew,
      },
      {
        path: 'add-new-audio-book',
        name: 'resources.addNewAudioBook',
        meta: {
          title: 'Add New',
          breadcrumbs: () => [
            { text: 'Resources', to: '/resources' },
            { text: 'Add new audio book', to: '/resources/add-new-audio-book' },
          ],
        },
        component: AddNew,
      },
      {
        path: 'edit-audio-book/:id',
        name: 'resources.addNewAudioBook',
        meta: {
          title: 'Audio Book',
          breadcrumbs: (route) => [
            { text: 'Resources', to: '/resources' },
            {
              text: `Edit audio book ${route.params.id}`,
              to: `/resources/edit-audio-book/${route.params.id}`,
            },
            // {
            //   text: route.params.id,
            //   to: `/resources/edit-audio-book/${route.params.id}`,
            // },
          ],
        },
        component: AddNew,
      },
      {
        path: 'add-new-recipe',
        name: 'resources.addNewRecipe',
        meta: {
          title: 'Add New',
          breadcrumbs: () => [
            { text: 'Resources', to: '/resources' },
            { text: 'Add new recipe', to: '/resources/add-new-recipe' },
          ],
        },
        component: AddNew,
      },
      {
        path: 'edit-recipe/:id',
        name: 'resources.addNewRecipe',
        meta: {
          title: 'Recipe',
          breadcrumbs: (route) => [
            { text: 'Resources', to: '/resources' },
            {
              text: `Edit recipe ${route.params.id}`,
              to: `/resources/edit-recipe/${route.params.id}`,
            },
            // {
            //   text: route.params.id,
            //   to: `/resources/edit-recipe/${route.params.id}`,
            // },
          ],
        },
        component: AddNew,
      },
    ],
  },
];
