<template>
  <v-menu
    offset-y
    content-class="v-dropdownlist__menu__content"
    v-if="type === 'button'"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        small
        tile
        color="primary"
        class="primary3"
        style="border: 1px solid var(--color-primary2)"
      >
        <v-icon v-text="icons.mdiFilter" small></v-icon>
      </v-btn>
    </template>
    <v-list dense class="py-0">
      <template v-for="(item, key) in comparisonsByGroup">
        <v-list-item
          :key="key"
          color="primray"
          :class="{ 'v-list-item--active': itemActive == item.operator }"
          @click="itemActive = item.operator"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
  <v-dropdownlist
    v-else
    v-model="itemActive"
    :data-items="comparisonsByGroup"
    itemText="name"
    itemValue="operator"
    hide-details
    height="36"
  ></v-dropdownlist>
</template>

<script>
import { mdiFilter } from '@mdi/js';
export default {
  props: {
    dataType: {
      type: String,
      default: 'String',
    },
    value: {
      type: String,
      default: '=',
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    comparisonsByGroup() {
      return this.$store.getters['enums/comparisonsByGroup'](this.dataType);
    },
    itemActive: {
      get() {
        return (
          this.comparisonsByGroup.find((c) => c.operator == this.value) || {}
        ).operator;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      icons: {
        mdiFilter,
      },
    };
  },
  mounted() {},
};
</script>
